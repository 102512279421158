import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './HamburgerButton.styles';

const HamburgerButton = ({ onClick }) => {
    return <Styled.HamburgerButton onClick={ onClick } />;
};

HamburgerButton.defaultProps = {
    onClick: () => {},
};

HamburgerButton.propTypes = {
    onClick: PropTypes.func,
};

export default HamburgerButton;
