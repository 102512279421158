import {
    fetchGetPreSaleData,
} from '../fetch/fetch';

/**
 * 
 * @param {{id: string, code: string}} param0
 * @returns {Promise<(import('../types').ResponseObject)[]> }
 */
const GetPreSaleData = ({ id }) => {
    return fetchGetPreSaleData({ id });
};

const actions = {
    GetPreSaleData,
};

export default actions;
