import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Input.styles';

const Input = ({
    type, value, onChange, name, isRequired, maxlength, placeholder, isReadOnly, isAutoFocused,
}) => {
    return (
        <Styled.Input
            className={ `input-${type}` }
            type={ type }
            readOnly={ isReadOnly }
            placeholder={ placeholder }
            value={ value }
            maxLength={ maxlength }
            name={ name }
            required={ isRequired }
            onChange={ onChange }
            autoFocus={ isAutoFocused }
        />
    );
};

Input.defaultProps = {
    type: 'text',
    isRequired: true,
    value: '',
    onChange: () => {},
    name: '',
    maxlength: null,
    placeholder: null,
    isReadOnly: false,
    isAutoFocused: false,
};

Input.propTypes = {
    type: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func,
    maxlength: PropTypes.number,
    placeholder: PropTypes.string,
    isReadOnly: PropTypes.bool,
    isAutoFocused: PropTypes.bool,
};

export default Input;
