import styled from 'styled-components';
import { pxToRem } from '../../utils/commonUtils';
import CONFIG from '../../config';

const { DEVICE_SIZE } = CONFIG;

export const AddOrRemoveButton = styled.div`
  position: relative;
  width: 150px;
  font-size: ${pxToRem(20)};
  font-weight: 700;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: ${({ $isSelected, theme }) => $isSelected ? 'white' : theme.primaryColor};
  border: 1px solid ${({ $isSelected, theme }) => $isSelected ? 'black' : theme.primaryColor};
  color: ${({ $isSelected }) => $isSelected ? 'black' : 'white'};
  cursor: pointer;
  transition: all 0.3s;
  &::before {
    padding: 6px;
    margin-right: -10px;
    content: '⨯';
    font-size: ${pxToRem(24)};
    font-weight: 700;
    color: ${({ $isSelected }) => $isSelected ? 'black' : 'white'};
    display: inline-block;
    transform: ${({ $isSelected }) => $isSelected ? 'rotate(0)' : 'rotate(45deg)'};
    transition: all 0.3s;
    background-size: contain;
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    width:  ${(props) => props.width || '150px'};
  }
`;
