import React from 'react';
import PropTypes from 'prop-types';
import { getCarPlaceholderImageUrl } from '../../../../../utils/assetsUtils';
import ModelImage from '../../ModelImage/ModelImage';
import * as Styled from './HeaderDesktop.styles';

const HeaderDesktop = ({
    imageAlt, imageSrc, brand, figCaption, href, onClick,
}) => {
    return (
        <Styled.HeaderDesktop to={ href } onClick={ onClick }>
            <Styled.Header>
                <ModelImage
                    alt={ imageAlt }
                    src={ imageSrc }
                    fallbackSrc={ getCarPlaceholderImageUrl(brand) }
                    figcaption={ figCaption }
                />
            </Styled.Header>
        </Styled.HeaderDesktop>
    );
};

HeaderDesktop.defaultProps = {
    onClick: () => { },
};

HeaderDesktop.propTypes = {
    imageAlt: PropTypes.string.isRequired,
    imageSrc: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    figCaption: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

export default HeaderDesktop;
