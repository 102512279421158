import styled, { css } from 'styled-components';
import { pxToRem } from '../../utils/commonUtils';
import { nisCurrencySign, lineThrough, configurationLeftScrollBar } from '../common/styles';
import CONFIG from '../../config';

const { DEVICE_SIZE } = CONFIG;

export const PaymentDetails = styled.div`
  ${({ $isInSummary }) =>  !$isInSummary && css`
    height: 100%;
  `};
`;
export const Header = styled.header`
  padding: ${({ $isAudi }) => $isAudi ? '20px 0 20px 30px' : '20px 30px'};
  display: ${({ $isAudi }) => $isAudi ? 'static' : 'flex'};
  margin-bottom: ${({ $isAudi }) => $isAudi ? '40px' : '0'};
  align-items: center;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    padding-left: 0;
    margin-bottom: 0;
    }
`;
export const Title = styled.h2`
  font-weight: 700;
  font-size: ${pxToRem(20)};
  margin: 0;
  line-height: 1;
  ${({ $isAudi }) => $isAudi && css`
    padding-right: 20px;
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
      display: none;
    }
    `};
`;
export const SubTitle = styled.p`
  margin: 0;
  color: #7c7c7c;
  font-weight: 700;
  font-size: ${pxToRem(13)};
`;
export const CarTitle = styled.h4`
  margin: 0;
  font-weight: normal;
  font-size: ${pxToRem(33)};
  padding: ${({ $isAudi }) => $isAudi ? '20px 20px' : '20px 30px'};
  text-align: right;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
  text-align: ${({ $isAudi }) => $isAudi ? 'center' : 'right'};
}
${({ $isInSummary, $isAudi }) =>  $isInSummary && $isAudi && css`
    padding: 20px 20px 40px 20px
  `};
`;
export const CarTitleText = styled.span`
  font-weight: ${({ $isBold }) => ($isBold ? 700 : '400')};
  ${({ $isAudi }) => $isAudi && css`
    font-size: 18px;
    font-weight: 400;
  `};
`;

export const AudiCarTitleText = styled.div`
  font-size: ${pxToRem(40)};
  font-weight: 700;
  line-height: 60px;
  ${({ $isInSummary }) =>  $isInSummary && css`
    font-size: ${pxToRem(50)};
  `};
`;

export const CloseButton = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  display: block;
  border: ${({ $isAudi }) => $isAudi ? 'none' : '1px solid black'};
  margin-left: ${({ $isAudi }) => $isAudi ? '0' : '15px'};
  margin-right: ${({ $isAudi }) => $isAudi ? 'auto' : '0px'};
  font-size: ${({ $isAudi }) => $isAudi ? '18px' : '13px'};
  ${({ $isAudi }) => $isAudi && css`
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
      padding-left: 30px;
    }
  `};

`;

export const Content = styled.div`
${configurationLeftScrollBar}
height: 90%;
overflow-y: auto;
${({ $isInSummary }) => $isInSummary && css`
height: 100%;
`}
`;

export const Body = styled.div`
  padding: 20px;
  ${({ $isAudi }) => $isAudi && css`
     padding-top: 5px;
     padding-left: 60px;
     /*padding-bottom: 0;*/
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
      padding-bottom: 10px;
      padding-left: 20px;
    }
  `};
<<<<<<< HEAD
  ${({ $isInSummary }) => $isInSummary && css`
    height: auto;
  `};
=======
>>>>>>> origin/new-additional-models-design
`;

export const TotalSum = styled.div`
  padding: ${({ $isAudi }) => $isAudi ? '20px 0' : '10px 0'};
  display: flex;
  justify-content: space-between;
`;
export const TotalString = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: ${pxToRem(20)};
`;
export const TotalPriceContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const TotalPrice = styled.p`
  margin: 0;
  font-weight: 800;
  font-size: ${pxToRem(20)};
  ${nisCurrencySign}
`;
export const TotalPriceBeforeDiscount = styled(TotalPrice)`
  margin:0 0 0 10px;
  font-size: ${pxToRem(17)};
  position: relative;
  ${lineThrough}
`;

export const CustomLoader = styled.div`
  height:100%;
  padding: 40px;
  display:flex;
  justify-content:center;
  align-items:center;
`;
