import styled from 'styled-components';
import {
    InputFormGroup,
    Label as InputFormGroupLabel,
} from '../InputFormGroup/InputFormGroup.styles';

export const TextAreaGroup = styled(InputFormGroup)``;
export const Label = styled(InputFormGroupLabel)`
  margin-bottom: 18px;
`;

export const TextAreaContainer = styled.div`
  border:1px solid;
  height:113px;
  padding:12px;
  & textarea{
      width:100%;
      height:100%;
      max-width:100%;
      max-height:100%;
      border:none;
      resize:none;
      overflow-y: hidden;
      background-color:transparent;
  }
`;
