/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const HowToBuyAVehicle = ({ email }) => {
    return (
        <>
            <p className="m_-604085057046575384gmail-MsoNormalCxSpFirst" dir="RTL"><span lang="HE">בשלב הראשון יש לאפיין את הרכב אותו מעוניינים לרכוש, כלומר לבחור צבע</span><span lang="HE">&nbsp;</span><span lang="HE">ו</span><span lang="HE">גימור פנים</span><span lang="HE">.&nbsp;</span><span lang="HE">כמו כן בשלב זה</span><span lang="HE">&nbsp;</span><span lang="HE">ניתן לרכוש אבזור נוסף בתשלום. עם סיום השלב הראשון יוצג מחיר הרכב המלא לתשלום.</span></p>
            <p className="m_-604085057046575384gmail-MsoNormalCxSpMiddle" dir="RTL"><span lang="HE">בשלב השני יש לבצע הרשמה משמע למלא פרטים אישים לצורך ביצוע ההזמנה.</span></p>
            <p className="m_-604085057046575384gmail-MsoNormalCxSpMiddle" dir="RTL"><span lang="HE">השלב השלישי הינו שלב ביצוע ההזמנה, השלב מתחיל במספר מסכים מקדימים בו תעשה בחירת אופן התשלום המלא, בחירת מקום קבלת הרכב ולאחר מכן תשלום מקדמה באמצעות כרטיס אשראי</span><span lang="HE">.</span></p>
            <p className="m_-604085057046575384gmail-MsoNormalCxSpMiddle" dir="RTL"><span lang="HE">השלב הרביעי&nbsp;</span><span lang="HE">&nbsp;הוא לאחר תשלום המקדמה בו יישלח מייל עם טפסים הנדרשים להמשך התהליך וכן הסברים והוראות מתאימים. יש להסדיר את מלוא התשלום על הרכב או בהעברה בנקאית רגילה או בהעברה בסיום תהליך המימון.</span><span lang="HE">&nbsp;</span><span lang="HE">מספר ההזמנה צריך להיות מצוין בהערות של ההעברה הבנקאית</span><span lang="HE">&nbsp;(</span><span lang="HE">באזור האישי ניתן למצוא את מספר ההזמנה</span><span lang="HE">)</span><span lang="HE">.</span><span lang="HE">&nbsp;</span><span lang="HE">על הלקוח לשלוח</span><span lang="HE">&nbsp;</span><span lang="HE">העתק מהמסמכים המלאים והחתומים במייל חוזר לכתובת&nbsp;</span><a href={ `mailto:${email}` } target="_blank" rel="noreferrer"><span dir="LTR">{email}</span></a>&nbsp;<span lang="HE">(ניתן לצלם בצורה ברורה בסלולרי או לסרוק למחשב ולאחר מכן לשלוח אלינו במייל</span></p>
            <p className="m_-604085057046575384gmail-MsoNormalCxSpMiddle" dir="RTL"><span lang="HE">השלב החמישי והאחרון הוא כמובן קבלת הרכב</span><span lang="HE">.&nbsp;</span><span lang="HE">בעת קבלת הרכב יש למסור לנציגנו עותק מקורי של המסמכים שמולאו ונחתמו על ידי הלקוח.</span></p>
        </>
    );
};

HowToBuyAVehicle.propTypes = {
    email: PropTypes.string.isRequired,
};

export default HowToBuyAVehicle;
