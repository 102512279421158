import styled from 'styled-components';
import CONFIG from '../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const Image = styled.div`
  flex: 1 1 50%;
  background-size: ${({ $isDefaultImage }) => $isDefaultImage ? 'auto' : 'cover'};
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ $image }) => $image});

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: none;
  }
`;
