import { isObjectEmpty } from '../../../utils/commonUtils';
import { parseInputFieldsToState } from '../../../utils/formUtils';
import {
    REVEAL_UNIQUE_ONLINE_PRICE,
    SPEAK_WITH_REPRESENTATIVE,
} from './ModalForms/ModalForm/ModalForm.fields';

/**
 * 
 * @param {Object} selectedAddOns in the following structure :
 {
    "1": {
        "title": "Connect it+אית' פעיל 7002",
        "price": 3640,
        "image": "http://champ-media.champion.co.il/VW/Proceed_online/add_on/55.jpg",
        "local": "CIK"
    },
    "5": {
        "title": "איתוראן פעיל 7002",
        "price": 2650,
        "image": "http://champ-media.champion.co.il/VW/Proceed_online/add_on/25.jpg",
        "local": "GSP"
    },
    "15": {
        "title": "מצלמת דרך+ח'אחורי+מעמד נ'",
        "price": 1990,
        "image": "http://champ-media.champion.co.il/VW/Proceed_online/add_on/21.jpg",
        "local": "CGA"
    }
}
 * @returns {String}  concat of local property - as follows: CIKGSPCGA
 */
export const parseSelectedAddOnsToString = (selectedAddOns) => {
    if (isObjectEmpty(selectedAddOns)) {
        return '';
    }
    
    return Object.values(selectedAddOns).reduce((acc, item) => {
        acc += item.local;
        
        return acc;
    }, '');
};
/*
 * @returns {String}  concat of local property - as follows: [CIK, GSP, CGA]
 */
export const parseSelectedAddOnsToArray = (selectedAddOns) => {
    if (isObjectEmpty(selectedAddOns)) {
        return [];
    }
    
    return Object.values(selectedAddOns).reduce((acc, item) => {
        acc.push(item.local);
        
        return acc;
    }, []);
};
/**
 * @param  {object} selectedAddOns in the structure that mentioned above
 * @returns {String}  concat of title property - as follows: יתוראן פעיל 7002 צלמת דרך+ח'אחורי+מעמד נ
 */
export const parseSelectedAddOnsGroupDescriptionToString = (selectedAddOns) => {
    if (isObjectEmpty(selectedAddOns)) {
        return '';
    }

    return Object.values(selectedAddOns).reduce((acc, item) => {
        acc += `${item.groupDescription} `;
        
        return acc;
    }, '');
};

/**
 * 
 * @param {array} @of './ModalForm/ModalForm.fields 
 * @param {array} formNames 
 * @param {bool} withNoEmail 
 * @returns [inputs,setInputs] 
 * inputs in following structure : 
  { 
    [formName] : inputFields,
    [formName] : inputFields, 
  }
 */

export const parseMultipleFormsToState = (inputFields, formNames, withNoEmail) => {
    const initialInputFieldsState = formNames.reduce((acc, formName) => {
        acc[formName] = parseInputFieldsToState(inputFields);

        return acc;
    }, {});

    if (withNoEmail) {
        delete initialInputFieldsState[REVEAL_UNIQUE_ONLINE_PRICE].email;
        delete initialInputFieldsState[REVEAL_UNIQUE_ONLINE_PRICE].firstName;
        delete initialInputFieldsState[REVEAL_UNIQUE_ONLINE_PRICE].lastName;
        delete initialInputFieldsState[SPEAK_WITH_REPRESENTATIVE].fullName;
    }

    return initialInputFieldsState;
};
