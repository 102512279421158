import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCurrentAppCommonData } from '../../../../hooks';
import * as Styled from './ModelImage.styles';

const ModelImage = ({
    src, fallbackSrc, figcaption, alt,
}) => {
    const { isAudi } = useCurrentAppCommonData();
    const [_src, setSrc] = useState(src);
    const [hasError, setHasError] = useState(false);

    const loadImgErrorHandler = () => {
        setHasError(true);
    };

    const renderFigCaption = () => {
        if (!figcaption) {
            return null;
        }
        
        return (
            <Styled.FigCaption>
                <Styled.FigCaptionText>
                    {figcaption}
                </Styled.FigCaptionText>
            </Styled.FigCaption>
        );
    };

    useEffect(() => {
        if (hasError) {
            setSrc(fallbackSrc);
        }
    }, [hasError]);
    
    return (
        <Styled.Figure>
            <Styled.ImageBox
                $isAudi={ isAudi }
            >
                <Styled.Image
                    alt={ alt }
                    src={ _src }
                    onError={ loadImgErrorHandler }
                />
            </Styled.ImageBox>
            {renderFigCaption()}
        </Styled.Figure>
    );
};

ModelImage.defaultProps = {
    figcaption: null,
};

ModelImage.propTypes = {
    src: PropTypes.string.isRequired,
    fallbackSrc: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    figcaption: PropTypes.string,
};

export default ModelImage;
