import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { pxToRem } from '../../../utils/commonUtils';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const NavigationItem = styled.li`
   padding-bottom:15px;
   page-break-inside: avoid;
    break-inside: avoid;
    -webkit-column-break-inside: avoid;

    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
      padding-bottom:10px;
  }
`;
export const NavigationLink = styled(Link)`
  color:${({ theme }) => theme.footerTopNavItemColor};
  font-weight:700;
  font-size: ${pxToRem(14)};
  text-decoration:none;
  &:hover{
      text-decoration:underline;
  }
`;
