import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLORS } from '../../../../../../constants';
import { pxToRem } from '../../../../../../utils/commonUtils';
import { InlineText } from '../../../../../common/styles';
import {
    ImageBox,
    FigCaptionText,
} from '../../../../../Steps/Model/ModelImage/ModelImage.styles';
import CONFIG from '../../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const OnlineCarLink = styled(Link)`
  text-decoration:none;
  ${ImageBox}{
    width: 100px;
    margin:auto;
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        width:100%;
        height:auto;
    }
  }
  ${FigCaptionText}{
    margin-top:20px;
    width: 100%;
    text-align:center;
    font-size:${pxToRem(17)};
    font-weight:700;
    color: ${COLORS.FIG_CAPTION_COLOR_NAV_CAR_ONLINE};
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        margin-top:0;
    }
    }
`;

export const OnLineCar = styled.div`
    min-height: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d8d8d8;
    &:hover{
        background-color:${COLORS.HOVER_COLOR_NAV_CAR_ONLINE};
    }
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        min-height:auto;
    }
`;

export const ModelName = styled.p`
    margin: 0;
    font-size: 26px;
    font-weight: bold;
    color: black;
    margin-left: 1rem;
`;

export const OnlineCarItem = styled.li`
    flex: 1 0 50%;
    min-height: 60px;
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        flex:1 1 50%;
        max-width:50%;
        padding:10px;
    }
    
`;

export const ToPurchase = styled.div`
      margin-top: 30px;
   text-align:center;
   @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        display:none;
    }
`;

export const Text = styled(InlineText)`
   
    border-bottom: 2px solid #000;
    padding-bottom: 6px;
    font-size:${pxToRem(16)};
    color:${COLORS.INFO_LINK_COLOR_NAV_CAR_ONLINE};
`;

export const MoreInfoLink = styled(Link)`
  color:${COLORS.INFO_LINK_COLOR_NAV_CAR_ONLINE};
  font-weight:400;
  font-size:${pxToRem(14)};
  text-decoration:none;
  display: block;
    margin: 10px;
    text-align: center;
  &:hover{
    text-decoration:underline;
  }
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        display:none;
    }
`;
