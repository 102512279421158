import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../../language';
import {
    getBgColorForRuler,
    renderUniqueSafetyAndPollutionTableRows,
} from '../../../../../utils/commonUtils';
import TableData from '../../../../../components/TableData/TableData';
import TableHeadData from '../../../../../components/TableHeadData/TableHeadData';
import { COLORS, DASH_SIGN } from '../../../../../constants';
import * as Styled from './SafetyModel.styles';

const SafetyModel = ({
    cars,
}) => {
    const displayedModelsArray = [];

    const renderModelTr = () => {
        return cars.map((car) => {
            const {
                modelCode, modelDescription, safetyEquipmentLevel, isBeforeManufacture,
            } = car;
            
            return safetyEquipmentLevel.map((_safetyEquipmentLevel) => {
                const modelToDisplay = { modelCode, safetyEquipmentLevel: _safetyEquipmentLevel };

                if (!renderUniqueSafetyAndPollutionTableRows(
                    displayedModelsArray, modelToDisplay,
                )) {
                    displayedModelsArray.push(modelToDisplay);
                    
                    return (
                        <Styled.ModelTr key={ `${modelCode}-${_safetyEquipmentLevel}` }>
                            <TableData data={ isBeforeManufacture ? DASH_SIGN : modelCode } />
                            <TableData data={ modelDescription } />
                            <TableData
                                fontColor={ COLORS.COLOR_TEXT_SAFETY_LEVEL_EQUIPMENT_CELL }
                                bg={ getBgColorForRuler(isBeforeManufacture ? DASH_SIGN : _safetyEquipmentLevel) }
                                data={ isBeforeManufacture ? DASH_SIGN : _safetyEquipmentLevel }
                            />
                        </Styled.ModelTr>
                    );
                }

                return null;
            });
        });
    };
    
    return (
        <Styled.SafetyModel>
            <Styled.ModelTable>
                <Styled.ModelThead>
                    <Styled.ModelTr>
                        <TableHeadData data={ STRINGS.MODEL_CODE } />
                        <TableHeadData data={ STRINGS.MODEL_DESCRIPTION } />
                        <TableHeadData data={ STRINGS.SAFETY_EQUIPMENT_LEVEL } />
                    </Styled.ModelTr>
                </Styled.ModelThead>
                <Styled.ModelTbody>{ renderModelTr() }</Styled.ModelTbody>
            </Styled.ModelTable>
        </Styled.SafetyModel>
    );
};

SafetyModel.propTypes = {
    cars: PropTypes.array.isRequired,
};

export default SafetyModel;
