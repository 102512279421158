import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CONFIG from '../../../../../config';
import { pxToRem } from '../../../../../utils/commonUtils';
import { COLORS } from '../../../../../constants';
import { ImageBox, FigCaption } from '../../../../Steps/Model/ModelImage/ModelImage.styles';
import {
    HeaderDesktop,
} from '../../../../Steps/Model/ModelItemCollapsible/HeaderDesktop/HeaderDesktop.styles';
import {
    FullPriceDescription,
    FullPriceAndTooltip,
    PriceTitle,
} from '../../../../Steps/Model/ModelItemCollapsible/FullPriceAndTooltip/FullPriceAndTooltip.styles';

const { DEVICE_SIZE } = CONFIG;

export const ModelItem = styled.li`
  flex: 0 0 25%;
  max-width: 400px;
  min-width: 400px;

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex: 1 1 100%;
    max-width: initial;
    min-width: initial;
    padding-top: 2rem;
    background-color: #EFEFEF;

    ${HeaderDesktop}{
      display: block;
      & header{
        max-width:100%;
      }
    }
    ${FullPriceAndTooltip}{
      display: flex;
      justify-content: center;
      margin-top:10px;
    }
    ${ImageBox}{
      max-width:200px;
    }
    ${FigCaption}{
      justify-content: center;
    }
    ${PriceTitle}{
      font-size: ${pxToRem(26)};
      margin-bottom: 1.5rem;
    }

  }

`;

export const Car = styled.div`
  padding: 35px 25px;
  min-height: 680px;
  a > header > figure > div {
    height: 170px;
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
      height: auto;
      padding: 0;
    }
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    min-height: auto;
    }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Body = styled.div`
  max-width: 250px;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    max-width: 100%;
  }
`;

export const SelectButton = styled(Link)`
  width: 100%;
  min-width: 108px;
  max-width: 280px;
  height: 40px;
  font-weight: 500;
  line-height: 1;
  border-radius: ${({ theme }) => theme.buttonButtonRadius};
  border: none;
  outline: none;
  padding: 0 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.buttonTextColor};
  font-size: ${pxToRem(24)};
  white-space: nowrap;
  background-color: ${({ theme }) => theme.buttonColor};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-bottom: 30px;

  @media (min-width: ${DEVICE_SIZE.LARGE}px) {
    &:hover{
      color: ${COLORS.BLACK};
      background-color: ${COLORS.WHITE};
      border: 2px solid ${COLORS.BLACK};
      transition: background-color 0.5s;
    }
  }

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    min-width: auto;
    max-width: 70%;
    height: 50px;
    margin:0 auto 25px auto;
  }
`;

export const Description = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: ${pxToRem(13)};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    text-align:center;
  }
`;

export const ArrivelDate = styled.p`
  font-weight: 500;
  font-size: ${pxToRem(13)};
  margin: 0;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const FullPriceDescriptionMobileOnly = styled(FullPriceDescription)`
  font-size: ${pxToRem(16)};
  margin-bottom: 9px;
  display: none;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: block;
  }
`;

export const Footer = styled.footer`
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    text-align: center;
  }
`;
