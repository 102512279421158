import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHECKOUT_PAGE_FORMS } from '../../../../constants';
import { setCurrentIdentificationForm } from '../../../../store/actions';
import IdentificationForm from './IdentificationForm/IdentificationForm';
import RegistrationForm from './RegistrationForm/RegistrationForm';
import OtpForm from './OtpForm/OtpForm';
import * as Styled from './IdentificationAndRegistration.styles';

const { IDENTIFICATION_FORM, REGISTRATION_FORM, OTP_FORM } = CHECKOUT_PAGE_FORMS;

const IdentificationAndRegistration = () => {
    const { identification } = useSelector(({ checkoutReducer }) => checkoutReducer);
    const { currentIdentificationForm } = identification;
    const dispatch = useDispatch();

    const handleSelectForm = (form) => {
        dispatch(setCurrentIdentificationForm(form));
    };

    const renderForm = () => {
        switch (currentIdentificationForm) {
            case IDENTIFICATION_FORM:
                return (
                    <IdentificationForm
                        onSelectForm={ handleSelectForm }
                    />
                );
            case REGISTRATION_FORM:
                return <RegistrationForm />;
            case OTP_FORM:
                return <OtpForm onSelectForm={ handleSelectForm } />;
            default:
                return <IdentificationForm />;
        }
    };

    return (
        <Styled.IdentificationAndRegistration className="CheckOutScreenTab">
            {renderForm()}
        </Styled.IdentificationAndRegistration>
    );
};

export default IdentificationAndRegistration;
