import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AudiPaymentDetailsModal from '../../AudiPaymentDetails/AudiPaymentDetailsModal';
import { getIconFromAssets } from '../../../../utils/assetsUtils';
import { ICONS_NAMES, CONFIGURATOR_STEPS } from '../../../../constants';
import { getAudiStepContinueBtnTitle } from '../../../../utils/configuratorUtils';
import STRINGS from '../../../../language';
import * as Styled from './ConfiguratorFooter.style';

const ConfiguratorFooter = ({
    modelGroup,
    thumbnailCarImage,
    fullPrice,
    showCarImage,
    isContinueButtonDisabled,
    onContinue,
    nextStep,
}) => {
    const leftArrowIcon = getIconFromAssets(ICONS_NAMES.ARROW_LEFT);
    const [isPaymentDetailsVisible, setIsPaymentDetailsVisible] = useState(false);

    return (
        <Styled.Footer>
            { showCarImage && <Styled.CarImage src={ thumbnailCarImage } /> }
            <Styled.FooterRightWrapper>
                <Styled.ModelGroupText>
                    { modelGroup }
                </Styled.ModelGroupText>
                <Styled.Separator />
                <Styled.PriceAndPaymentDetailsButtonContainer
                    isPaymentDetailsVisible={ isPaymentDetailsVisible }
                >
                    <Styled.PriceContainer>
                        <Styled.PriceDescription>
                            {STRINGS.FOOTER_PRICE_INCLUDES_VAR_AND_LICENSING_FEE}
                        </Styled.PriceDescription>
                        <Styled.FullPrice>{fullPrice} &#8362;</Styled.FullPrice>
                    </Styled.PriceContainer>
                    <Styled.PaymentDetailsButton
                        onClick={ () => setIsPaymentDetailsVisible(!isPaymentDetailsVisible) }
                    >
                        {STRINGS.DETAILED_PRICE}
                    </Styled.PaymentDetailsButton>
                </Styled.PriceAndPaymentDetailsButtonContainer>
            </Styled.FooterRightWrapper>
            <Styled.ContinueButton
                $icon={ leftArrowIcon }
                $isDisabled={ isContinueButtonDisabled }
                onClick={ onContinue }
            >
                {getAudiStepContinueBtnTitle(nextStep, CONFIGURATOR_STEPS)}
            </Styled.ContinueButton>
            <AudiPaymentDetailsModal
                isPaymentDetailsVisible={ isPaymentDetailsVisible }
                setIsPaymentDetailsVisible={ setIsPaymentDetailsVisible }
            />
        </Styled.Footer>
    );
};

ConfiguratorFooter.propTypes = {
    modelGroup: PropTypes.string.isRequired,
    thumbnailCarImage: PropTypes.string.isRequired,
    fullPrice: PropTypes.string.isRequired,
    showCarImage: PropTypes.bool.isRequired,
    isContinueButtonDisabled: PropTypes.bool.isRequired,
    onContinue: PropTypes.func.isRequired,
    nextStep: PropTypes.string.isRequired,
};

export default ConfiguratorFooter;
