import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SpecificationsModal from '../../InnerPages/Specifications/SpecificationsModal';
import { useIsSpecificationsModalVisible, useActionDispatch } from '../../../hooks';
import { CONFIGURATOR_STEPS } from '../../../constants';
import ConfigurationalLeft from './ConfigurationalLeft/ConfigurationalLeft';
import PresentationalRight from './PresentationalRight/PresentationalRight';
import * as Styled from './ConfiguratorLayout.styles';

const { MODEL, ENGINE_AND_TRIM_LEVEL, EQUIPMENT_PACKAGES } = CONFIGURATOR_STEPS;

const  ConfiguratorLayout = ({
    footerComponent,
    presentationalComponent,
    configurationComponent,
    nextStep,
    currentStep,
    configOptions,
    onContinue,
    onGoBack,
}) => {
    const FooterComponent =  footerComponent || (() => null);
    const isSpecificationsModalVisible = useIsSpecificationsModalVisible();
    const { toggleSpecificationsModal } = useActionDispatch();

    useEffect(() => {
        if (isSpecificationsModalVisible) {
            document.body.style.overflow = 'hidden';
            document.body.dataset.moreInfo = isSpecificationsModalVisible;
        } else {
            document.body.style.overflow = 'visible';
            document.body.dataset.moreInfo = isSpecificationsModalVisible;
        }
    }, [isSpecificationsModalVisible]);

    const renderConfigurationalLeft = () => {
        const doNotshowConfigurationLeft = [MODEL, ENGINE_AND_TRIM_LEVEL, EQUIPMENT_PACKAGES]
            .find((step) => step === currentStep);

        if (doNotshowConfigurationLeft) {
            return null;
        }

        return (
            <ConfigurationalLeft
                configOptions={ configOptions }
                nextStep={ nextStep }
                currentStep={ currentStep }
                onContinue={ onContinue }
                component={ configurationComponent }
            />
        );
    };

    renderConfigurationalLeft();
    
    return (
        <Styled.ConfiguratorLayout>
            <SpecificationsModal
                isVisible={ isSpecificationsModalVisible }
                onClose={ () => toggleSpecificationsModal() }
            />
            <Styled.ConfiguratorContent>
                <PresentationalRight
                    onGoBack={ onGoBack }
                    currentStep={ currentStep }
                    configOptions={ configOptions }
                    component={ presentationalComponent }
                />
                {renderConfigurationalLeft()}
            </Styled.ConfiguratorContent>
            <FooterComponent configOptions={ configOptions } />
        </Styled.ConfiguratorLayout>
    );
};

ConfiguratorLayout.defaultProps = {
    footerComponent: null,
    configurationComponent: null,
    presentationalComponent: null,
    currentStep: null,
    nextStep: null,
    onContinue: () => {},
    onGoBack: () => {},
};

ConfiguratorLayout.propTypes = {
    footerComponent: PropTypes.elementType,
    presentationalComponent: PropTypes.elementType,
    configurationComponent: PropTypes.elementType,
    configOptions: PropTypes.object.isRequired,
    currentStep: PropTypes.number,
    nextStep: PropTypes.number,
    onContinue: PropTypes.func,
    onGoBack: PropTypes.func,
};

export default React.memo(ConfiguratorLayout);
