import styled from 'styled-components';
import {
    ReactComponent as CheckMark,
} from '../../../../../assets/icons/svg-check-mark.svg';
import {
    ReactComponent as CloseBlack,
} from '../../../../../assets/icons/close-black.svg';
import CONFIG from '../../../../../config';
import { Divider } from '../Divider/Divider.styles';

const { DEVICE_SIZE } = CONFIG;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    border: none;
    ${Divider} {
      display: none;
    }
  }
`;

const CheckMarkIcon = styled(CheckMark).attrs(({ theme }) => ({
    stroke: theme.primaryColor,
    width: 30,
    height: 30,
}))`
  padding: 4px;
  color: ${({ theme }) => theme.primaryColor};
  & > g > path {
    stroke: ${({ theme }) => theme.primaryColor};
  }
`;

const CloseIcon = styled(CloseBlack).attrs({
    width: 36,
    height: 36,
    fill: '#9B9B9B',
})``;

const Dummy = styled.div`
  height: 1px;
`;

const Styled = {
    Container,
    CheckMarkIcon,
    CloseIcon,
    Dummy,
};

export default Styled;
