/* eslint-disable camelcase */
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormSubTitle from '../FormSubTitle/FormSubTitle';
import FormTitle from '../FormTitle/FormTitle';
import Form from '../Form/Form';
import ContinueThemeButton from '../../../../../components/ContinueThemeButton/ContinueThemeButton';
import ErrorMessage  from '../../../../../components/ErrorMessage/ErrorMessage';
import CheckBoxFormGroup from '../../../../../components/CheckBoxFormGroup/CheckBoxFormGroup';
import Loader from '../../../../../components/Loader';
import TermsBody from '../../../../InnerPages/Terms/TermsBody/TermsBody';
import InputFormGroup from '../InputFormGroup/InputFormGroup';
import {
    formatPhoneNumber,
    getFullManufacturer,
    getManufacturerContactInfo,
    isArrayEmpty,
    replacePlaceholdersInTemplate,
} from '../../../../../utils/commonUtils';
import { checkOtpSuccess, isAuthenticatedSuccess } from '../../../../../store/actions';
import { useActionDispatch, useCurrentAppCommonData, useCurrentBrand } from '../../../../../hooks';
import { parseInputFieldsToState } from '../../../../../utils/formUtils';
import {
    ICONS_NAMES,
    IS_VERIFIED_AUTHORIZATION,
    LANGUAGE,
} from '../../../../../constants';
import STRINGS from '../../../../../language';
import CustomTextInput from '../../../../../components/CustomTextInput';
import IconAndSubTitle from '../../../IconAndSubTitle';
import { getIconFromAssets } from '../../../../../utils/assetsUtils';
import SmsCodeForm from '../../../../../components/Steps/Summary/SmsCodeForm/SmsCodeForm';
import {
    UNIQUE_ONLINE_PRICE_SMS_CODE,
    INPUT_FIELDS as SMS_INPUT_FIELD,
} from '../../../../../components/Steps/Summary/SmsCodeForm/SmsCodeForm.fields';
import { parseMultipleFormsToState } from '../../../../../components/Steps/Summary/Summary.utils';
import { useAuthentication } from '../../../../../hooks/authentication';
import { parseRegInputFieldsToState } from './RegistrationForm.utils';
import { INPUT_FIELDS, CHECKBOX_FIELDS, AUDI_CHECKBOX_FIELDS } from './RegistrationForm.fields';
import TermsOfUseModal from './TermsOfUseModal/TermsOfUseModal';
import * as Styled from './RegistrationForm.styles';

const { IDENTIFY_ICON, IDENTIFY_ICON_AUDI } = ICONS_NAMES;

const OPEN_TERMS_OF_USE_ID = 'openTermsOfUse';
const TERMS_OF_USE_MODAL_BACK_DROP_ID = 'TermsOfUseModalBackDrop';
const TERMS_OF_USE_MODAL_CLOSE_BUTTON_ID = 'TermsOfUseModalCloseButton';
const ID_NUMBER_INPUT_NAME = 'id_number';
const PHONE_NUMBER_INPUT_NAME = 'mobilePhoneNumber';

const TERMS_OF_USE_ELEMENTS_IDS  = [
    OPEN_TERMS_OF_USE_ID, TERMS_OF_USE_MODAL_BACK_DROP_ID,
    TERMS_OF_USE_MODAL_CLOSE_BUTTON_ID,
];

const RegistrationForm = () => {
    const {
        customerRegistrationAsync,
        sendSmsAsync,
        checkOtpAsync,
    } = useActionDispatch();
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [showTermsOfUse, setShowTermsOfUse] = useState(false);
    const { isAudi } = useCurrentAppCommonData();
    const { sms: smsState, otp: otpState } = useAuthentication();
    const CURRENT_CHECKBOX_FIELDS = isAudi ? AUDI_CHECKBOX_FIELDS : CHECKBOX_FIELDS;
    const [checkBoxFields, setCheckboxFields] = useState(
        parseInputFieldsToState(CURRENT_CHECKBOX_FIELDS),
    );
    const currentBrand = useCurrentBrand();
    const checkoutState = useSelector(({ checkoutReducer }) => checkoutReducer);
    const { identification, registration, customer } = checkoutState;
    const [inputFields, setInputFields] = useState(
        parseRegInputFieldsToState(INPUT_FIELDS(isAudi), customer),
    );
    const { isLoading: isCheckingOtp, isVerified, error: otpError } = otpState;
    const { isLoading: isSendingSms } = smsState;
    const initFormSmsInput = parseMultipleFormsToState(
        SMS_INPUT_FIELD, [UNIQUE_ONLINE_PRICE_SMS_CODE],
    );
    const [otpInput, setOtpInput] = useState(initFormSmsInput);
    const [isOtpFormVisible, setIsOtpFormVisible] = useState(false);

    const otpSmsMessage = replacePlaceholdersInTemplate(
        STRINGS.A_MESSAGE_WITH_A_VERIFICATION_CODE_HAS_BEEN_SENT_TO_VERIFICATION,
        [inputFields.mobilePhoneNumber.value],
    );

    const onChangeOtpInputHandler = useCallback((event) => {
        const $this = event.target;
        const { name: formName } = $this.closest('form');
        const { name: inputName, value } = $this;

        setOtpInput((prevState) => {
            const currentInput = prevState[formName][inputName];

            return {
                ...prevState,
                [formName]: {
                    ...prevState[formName],
                    [inputName]: {
                        ...currentInput,
                        value,
                        isErrorShown: false,
                    },
                },
            };
        });
    }, []);

    const manufacturerInHebrew = getFullManufacturer(
        currentBrand,
        LANGUAGE.HEBREW,
    );
    const { idNumber } = identification;
    const { isLoading: isRegistering } = registration;
    const formTitle = `${STRINGS.NICE_TO_MEET_YOU_WE_ARE(manufacturerInHebrew)}.`;
    const { PHONE: contactPhoneNumber } = getManufacturerContactInfo(currentBrand);
    const regErrorMsg = replacePlaceholdersInTemplate(
        STRINGS.ERRMSG_IN_REGISTRATION, [contactPhoneNumber],
    );

    const icon = isAudi ? IDENTIFY_ICON_AUDI : IDENTIFY_ICON;
    
    const onChangeInputHandler = (e) => {
        const { name, value } = e.target;

        const inputsToUpdate = {
            ...inputFields,
            [name]: {
                ...inputFields[name],
            },
        };
        const currentInput = inputsToUpdate[name];
        const { formatter } = currentInput;
        const formattedValue = formatter?.(value) ?? value;

        currentInput.value = formattedValue;
        setInputFields(inputsToUpdate);
    };

    const onCheckHandler = (e) => {
        const { id } = e.target;

        setCheckboxFields((prevState) => {
            const currentCheckBoxInput = prevState[id];
            const { isChecked, isRequired, isValid } = currentCheckBoxInput;
            
            return {
                ...prevState,
                [id]: {
                    ...currentCheckBoxInput,
                    isChecked: !isChecked,
                    isValid: isRequired ? !isChecked : isValid,
                    isErrorShown: false,
                },
            };
        });
    };

    const validateInputFields = (inputs) => {
        inputs.forEach((input) => {
            const { validator, validators, value } = input;
   
            input.isErrorShown = false;
            input.isValid = true;

            if (validators) {
                validators.some((item) => {
                    const { validator: validatorFn, errorMessage } = item;

                    if (!validatorFn(value)) {
                        input.isValid = validatorFn(value);
                        input.errorMessage = errorMessage;
                        input.isErrorShown = !validatorFn(value);
                        
                        return true;
                    }

                    return false;
                });
            }
            if (validator) {
                input.isValid = validator(value);
                input.isErrorShown = !validator(value);
            }
        });
       
        return  parseInputFieldsToState(inputs);
    };

    const validateCheckBoxFields = (checkBoxes) => {
        checkBoxes.forEach((checkBox) => {
            const { isRequired, isValid } = checkBox;
            
            if (isRequired && !isValid) {
                checkBox.isErrorShown = true;
            }
        });

        return parseInputFieldsToState(checkBoxes);
    };

    const customerRegistration = (customerRegObject) => {
        customerRegistrationAsync(customerRegObject).then((response) => {
            if (response) {
                // Indication for continue in checkout - the same logic like entering OTP.
                dispatch(checkOtpSuccess());
                sessionStorage.setItem(IS_VERIFIED_AUTHORIZATION, true);
                dispatch(isAuthenticatedSuccess());
            } else {
                setError(regErrorMsg);
            }
        });
    };

    const submitCreateNewCustomer = () => {
        setIsOtpFormVisible(false);
        const {
            firstName, lastName, email, mobilePhoneNumber,
        } = inputFields;
        
        const { accept_advertising, privacy_policy } = checkBoxFields;
        const customerIdnumber = customer.idNumber || idNumber;
        
        const customerRegObject = {
            brand: currentBrand,
            customerFirstName: firstName.value,
            customerLastName: lastName.value,
            customerPhoneNumber: mobilePhoneNumber.value,
            sIdCard: customerIdnumber?.trim(),
            customerEmail: email.value,
            isAgreeToTerms: privacy_policy.isChecked,
            isAgreeToMarketing: accept_advertising.isChecked,
        };
        
        customerRegistration(customerRegObject);
    };

    const sendSmsCodeHandler = () => {
        const {
            firstName, lastName, mobilePhoneNumber,
        } = inputFields;

        sendSmsAsync(`${firstName.value} ${lastName.value}`, mobilePhoneNumber.value);
    };

    const submitFormHandler = useCallback((e) => {
        e.preventDefault();
        const inputs = Object.values(inputFields);
        const checkBoxes = Object.values(checkBoxFields);
        const allInputsCombined = inputs.concat(checkBoxes);
        const inputFieldsAfterValidation =  validateInputFields(inputs);
        const checkBoxesAfterValidation =  validateCheckBoxFields(checkBoxes);
    
        setCheckboxFields(checkBoxesAfterValidation);
        setInputFields(inputFieldsAfterValidation);
    
        const validInputs = allInputsCombined.filter((input) => !input.isValid);
    
        if (!isArrayEmpty(validInputs)) {
            setCheckboxFields(checkBoxesAfterValidation);
            setInputFields(inputFieldsAfterValidation);
        } else if (isVerified) {
            submitCreateNewCustomer();
        } else {
            sendSmsCodeHandler();
            setIsOtpFormVisible(true);
        }
    }, [inputFields, checkBoxFields]);

    const onSubmitSmsInputHandler = (event) => {
        event.preventDefault();
        const { smsCode } = otpInput[UNIQUE_ONLINE_PRICE_SMS_CODE];
    
        checkOtpAsync(smsCode.value, submitCreateNewCustomer);
    };

    const parseInputValue = (name, value, customerId, placeholder) => {
        switch (name) {
            case ID_NUMBER_INPUT_NAME:
                return placeholder(customerId);
            case PHONE_NUMBER_INPUT_NAME:
                return formatPhoneNumber(value);
            default:
                return value;
        }
    };

    const renderInputFields = () => {
        const Input = isAudi ? CustomTextInput : InputFormGroup;
        
        return INPUT_FIELDS(isAudi).map((inputField, index) => {
            const {
                name,
                value,
                type,
                label,
                isRequired,
                isErrorShown,
                errorMessage,
                placeholder,
                isReadOnly,
                maxLength,
            } = inputFields[inputField.name];

            const customerId = customer.idNumber || idNumber;

            return (
                <Input
                    inputName={ name }
                    inputValue={ parseInputValue(name, value, customerId, placeholder) }
                    key={ name }
                    inputType={ type }
                    isReadOnly={ isReadOnly }
                    maxLength={ maxLength }
                    inputPlaceholder={ placeholder(customerId) }
                    onChange={ onChangeInputHandler }
                    label={ label }
                    isRequired={ isRequired }
                    isErrorShown={ isErrorShown }
                    errorMessage={ errorMessage }
                    isAutoFocused={ index === 0 }
                />
            );
        });
    };

    const termsOfUseModalHandler = (e) => {
        const thisId = e.target.id;
        
        const currentId = TERMS_OF_USE_ELEMENTS_IDS.find((elementId) => elementId === thisId);

        if (currentId) {
            setShowTermsOfUse((show) => !show);
        }
    };

    const renderCheckboxFields = () => {
        return CURRENT_CHECKBOX_FIELDS.map((checkBoxField) => {
            const {
                label, isRequired, isErrorShown, errorMessage, id, isChecked,
            } =   checkBoxFields[checkBoxField.id];

            return (
                <CheckBoxFormGroup
                    isChecked={ isChecked }
                    onCheck={ onCheckHandler }
                    label={ label }
                    isRequired={ isRequired }
                    isErrorShown={ isErrorShown }
                    errorMessage={ errorMessage }
                    id={ id }
                    key={ id }
                />
            );
        });
    };

    const renderLoader = () => {
        return (
            <Styled.LoaderContainer>
                <Loader />
            </Styled.LoaderContainer>
        );
    };
    
    if (error) {
        return (
            <Styled.ErrorMessageContainer>
                <ErrorMessage
                    color="red"
                    errorMessage={ error }
                />
            </Styled.ErrorMessageContainer>
        );
    }

    return (
        <Styled.RegistrationForm>
            { isRegistering && renderLoader()}
            <Styled.StepHeadContainer $isAudi={ isAudi }>
                <Styled.IconAndSubTitleWrapper>
                    <IconAndSubTitle
                        icon={ getIconFromAssets(icon) }
                        subTitle={ isAudi ? STRINGS.REGISTRATION : STRINGS.IDENTIFICATION }
                    />
                </Styled.IconAndSubTitleWrapper>
                <Styled.TitleContainer>
                    <FormTitle title={ formTitle } />
                    <FormSubTitle
                        subTitle={ isAudi
                            ? STRINGS.FAILED_TO_IDENT
                            : STRINGS.THIS_PART_IS_ALL_ABOUT_YOU }
                    />
                </Styled.TitleContainer>
            </Styled.StepHeadContainer>
            
            <Form isAudi={ isAudi } action="/">
                {renderInputFields()}
                <Styled.Terms>{renderCheckboxFields()}</Styled.Terms>
                <Styled.SubmitButtonWrapper>
                    <ContinueThemeButton
                        onClick={ submitFormHandler }
                        title={ STRINGS.CONTINUE }
                        isAudi={ isAudi }
                    />
                </Styled.SubmitButtonWrapper>
            </Form>
            <Styled.Disclaimer>
                {STRINGS.PLEASE_MAKE_SURE_THAT_THE_DETAILS_ARE_CORRECT}
            </Styled.Disclaimer>
            <TermsOfUseModal isVisible={ showTermsOfUse } onClose={ termsOfUseModalHandler }>
                <TermsBody />
            </TermsOfUseModal>
            <SmsCodeForm
                title={ STRINGS.HAVE_YOU_RECEIVED_AN_SMS_CODE }
                formName={ UNIQUE_ONLINE_PRICE_SMS_CODE }
                description={ otpSmsMessage }
                otpError={ otpError }
                isVisible={ isOtpFormVisible }
                onSubmit={ onSubmitSmsInputHandler }
                onResendSmsCode={ sendSmsCodeHandler }
                onNotMyPhone={ () => setIsOtpFormVisible(false) }
                submitButtonTitle={ STRINGS.LETS_CONTINUE }
                onClose={ () => setIsOtpFormVisible(false) }
                onChangeInput={ onChangeOtpInputHandler }
                input={ otpInput[UNIQUE_ONLINE_PRICE_SMS_CODE] }
                isLoading={ isCheckingOtp || isSendingSms }
            />
        </Styled.RegistrationForm>
    );
};

export default RegistrationForm;
