import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { getIconFromAssets } from '../../utils/assetsUtils';
import { COLORS, ICONS_NAMES } from '../../constants';
import { pxToRem } from '../../utils/commonUtils';
import { OnlineCarsMenu } from '../OnlineCarsMenu/OnlineCarsMenu.styles';

import CONFIG from '../../config';

const menuArrowIcon = getIconFromAssets(ICONS_NAMES.MENU_ARROW);

const { DEVICE_SIZE } = CONFIG;

const toggleNavigationCss = (isVisible) => {
    return isVisible
        ? css`
        max-height: 1000px;
        opacity: 1;
      `
        : css`
        max-height: 0;
        opacity: 0;
      `;
};

export const Header = styled.header`
  min-height: 68px;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    height: 54px;
    &:after {
      content: "";
      background: black;
      display: block;
      height: 100%;
      position: fixed;
      right: 0;
      top: 68px;
      width: 100%;
      z-index: 130;
      opacity: ${({ $isNavigationVisible }) => ($isNavigationVisible ? '.5' : 0)};
      transition: opacity 0.15s ease-in;
      visibility: ${({ $isNavigationVisible }) => $isNavigationVisible ? 'visible' : 'hidden'};
    }
    body[data-page-name="checkout"] & {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  min-height: inherit;
  max-width: 1500px;
  margin: auto;
  flex-wrap: wrap;
  background-color: ${COLORS.BG_COLOR_HEADER};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    /* position: fixed;
    left: 0;
    right: 0; */
    height: 54px;
    border-bottom: 1px solid ${COLORS.BORDER_BOTTOM_COLOR_MOBILE};
    z-index: 130;
  }
`;

export const Navigation = styled.nav`
  border-right: 1px solid ${COLORS.BORDER_RIGHT_NAV};

  flex: 1;
  z-index: 101;
  transition: opacity 0.5s, max-height 1s;

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    background-color: white;
    flex: 1 1 100%;
    position: fixed;
    top: 68px;
    right: 0;
    left: 0;
    ${({ $isNavigationVisible }) => toggleNavigationCss($isNavigationVisible)};
  }
`;

export const NavigationMenu = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  height: 100%;
  align-items: center;
  list-style-type: none;
  position: relative;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: ${({ $isNavigationVisible }) => $isNavigationVisible ? 'block' : 'none'};
    height: auto;
  }
`;

export const NavigationItem = styled.li`
  padding: 0 10px;
  margin: 0 10px;
  display: flex;
  height: 100%;
  font-weight: 700;
  align-items: center;
  &:first-child a.NavigationItem__Link:after {
    content: "";
    display: block;
    transform: rotate(0);
    width: 20px;
    height: 20px;
    transition: all 0.3s;
    margin-right: 5px;
    background-image: url(${menuArrowIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  &:first-child:hover a.NavigationItem__Link:after {
    transform: rotate(180deg);
  }
  @media (min-width: ${DEVICE_SIZE.MEDIUM}px) {
    &:first-child:hover ${OnlineCarsMenu} {
      justify-content: ${({ $isAudi }) => $isAudi && 'center'};
    }
  }
  @media (max-width: ${DEVICE_SIZE.LARGE}px) {
    margin: 0;
  }

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    margin: 0 20px;
    border-bottom: 1px solid ${COLORS.BODER_BOTTOM_COLOR_NAV_LINK};
    /**
      only on mobile ( < 768) handle these dynamic props 
    */
    ${({ $isActive }) => $isActive
      && css`
        &:first-child {
          flex-direction: column;
          align-items: baseline;
        }
        &:first-child:after {
          position: absolute;
          left: 20px;
        }
      `}
  }
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${COLORS.COLOR_HEADER_NAV_LINK};
  font-size: ${pxToRem(15)};
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 20px 0;
  &:hover {
    color: ${COLORS.COLOR_HEADER_NAV_LINK_HOVER};
  }
`;

export const MobileWrapper = styled.div`
  display: none;
  ${({ $isAudi }) => $isAudi && css`
    @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
      display: block;
    }
  `}
`;

export const DesktopWrapper = styled.div`
  z-index: 131;
  display: flex;
  flex: 1;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    ${({ $isAudi }) => $isAudi && css`
      display: none;
    `}
  }
`;
