export const replaceUrlParams = (url, params = {}) => {
    let replacedUrl = url;

    const entries = Object.entries(params);

    entries.forEach(([key, val]) => {
        replacedUrl = replacedUrl?.replace(`{${key}}`, val);
    });

    return replacedUrl;
};
