import { mapDealershipsToOptions } from './utils';

export const customerFirstIdentificationPending = (state) => ({
    ...state,
    identification: {
        ...state.identification,
        isLoading: true,
    },
});

export const customerFirstIdentificationSuccess = ((state, action) => {
    const { response } = action;
    const { customerId } = response;

    return {
        ...state,
        identification: {
            ...state.identification,
            isLoading: false,
        },
        customer: {
            ...state.customer,
            idNumber: customerId,
        },
    };
});

export const customerFirstIdentificationFailure = ((state, action) => {
    const { error } = action;

    return {
        ...state,
        identification: {
            ...state.identification,
            isLoading: false,
            error,
        },
    };
});

export const customerIdentificationPending = (state) => ({
    ...state,
    identification: {
        ...state.identification,
        isLoading: true,
    },
});

export const customerIdentificationSuccess = ((state, action) => {
    const { customer } = action;
    const {
        fName, lName, FullName, phone1, mobilePhone, Mail, IDCard, Address,
    } = customer;
    
    return {
        ...state,
        customer: {
            firstName: fName,
            lastName: lName,
            fullName: FullName,
            phone: phone1,
            mobilePhoneNumber: mobilePhone,
            email: Mail,
            idNumber: IDCard,
            address: Address,
        },
        identification: {
            ...state.identification,
            isLoading: false,
        },
    };
});

export const customerIdentificationFailure = ((state, action) => {
    const { error } = action;

    return {
        ...state,
        identification: {
            ...state.identification,
            isLoading: false,
            error,
        },
    };
});

export const customerRegistrationPending = (state) => ({
    ...state,
    registration: {
        ...state.registration,
        isLoading: true,
    },
});

export const customerRegistrationSuccess = ((state, action) => {
    const { customer } = action;
    
    const {
        customerFirstName, customerLastName, customerPhoneNumber, customerEmail, sIdCard,
    } = customer;
    
    return {
        ...state,
        customer: {
            firstName: customerFirstName,
            lastName: customerLastName,
            fullName: `${customerFirstName} ${customerLastName}`,
            phone: null,
            mobilePhoneNumber: customerPhoneNumber,
            email: customerEmail,
            idNumber: sIdCard,
            address: null,
        },
        registration: {
            ...state.registration,
            isLoading: false,
        },
    };
});

export const customerRegistrationFailure = ((state, action) => {
    const { error } = action;

    return {
        ...state,
        registration: {
            ...state.registration,
            isLoading: false,
            error,
        },
    };
});

export const saveCustomerIdNumber = ((state, action) => {
    const { idNumber } = action;
    
    return {
        ...state,
        identification: {
            ...state.identification,
            idNumber,
        },
    };
});

export const getDealershipsPending = (state) => ({
    ...state,
    pickUpAndFinancing: {
        ...state.pickUpAndFinancing,
        isLoading: true,
    },
});

export const getDealershipsSuccess = ((state, action) => {
    const { dealerships, isAudi } = action;

    return {
        ...state,
        pickUpAndFinancing: {
            ...state.pickUpAndFinancing,
            isLoading: false,
            dealerships: mapDealershipsToOptions(dealerships, isAudi),
        },
    };
});

export const getDealershipsFailure = ((state, action) => {
    const { error } = action;

    return {
        ...state,
        pickUpAndFinancing: {
            ...state.pickUpAndFinancing,
            isLoading: false,
            error,
        },
    };
});

export const selectDealership = (state, action) => {
    const { dealership } = action;

    return {
        ...state,
        pickUpAndFinancing: {
            ...state.pickUpAndFinancing,
            selectedDealership: dealership,
        },
    };
};

export const setIsFinancing = (state, action) => {
    const { isFinancing } = action;
    
    return {
        ...state,
        pickUpAndFinancing: {
            ...state.pickUpAndFinancing,
            isFinancing: !isFinancing,
        },
    };
};

export const setIsFinished = (state) => {
    return {
        ...state,
        isFinished: true,
    };
};

export const setIsPaymentFailed = (state) => {
    return {
        ...state,
        isPaymentFailed: true,
    };
};

export const holdCommisionPending = (state) => ({
    ...state,
    commision: {
        ...state.commision,
        isLoading: true,
    },
});

export const holdCommisionSuccess = ((state, action) => {
    const {  creditGuardUrl, totalPayment } = action;

    return {
        ...state,
        commision: {
            ...state.commision,
            isLoading: false,
            error: null,
            creditGuardUrl,
            totalPayment,
        },
    };
});

export const holdCommisionFailure = ((state, action) => {
    const { error } = action;

    return {
        ...state,
        commision: {
            ...state.commision,
            isLoading: false,
            creditGuardUrl: null,
            error,
        },
    };
});

export const setEditedOrderId = (state, action) => {
    const { orderId } = action;

    return {
        ...state,
        order: {
            orderId,
        },
    };
};

export const initializeCheckout = (initialState) => {
    return initialState;
};

export const setIsInCheckout = (state, action) => {
    const { isInCheckout } = action;
    
    return {
        ...state,
        isInCheckout,
    };
};

export const setCurrentIdentificationForm = (state, action) => {
    const { form } = action;

    return {
        ...state,
        identification: {
            ...state.identification,
            currentIdentificationForm: form,
        },
    };
};

export const saveCustomerPhoneNumber = (state, action) => {
    const { phoneNumber } = action;

    return {
        ...state,
        customer: {
            ...state.customer,
            mobilePhoneNumber: phoneNumber,
        },
    };
};

export const setRegisterReason = (state, action) => {
    const { reason } = action;

    return {
        ...state,
        registration: {
            ...state.registration,
            registerReason: reason,
        },
    };
};
