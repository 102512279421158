import styled, { css } from 'styled-components';
import { Tooltip } from 'react-tooltip';
import { motion } from 'framer-motion';
import { NoEntranceIcon } from '../common/styles';
import CONFIG from '../../config';
import { pxToRem } from '../../utils/commonUtils';
import { staggerVerticalChildVariants } from '../../utils/animationsConfig';

const { DEVICE_SIZE } = CONFIG;

export const PickerItem = styled(motion.div).attrs({
    variants: staggerVerticalChildVariants,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ $isAudi }) => $isAudi ? '20px' : ''};
  box-sizing: border-box;
`;

export const Container = styled.div`
  height: auto;
  position: relative;
  box-sizing: content-box;
  ${({ $isPackageRelated }) => $isPackageRelated
    && css`
      &.selected {
        border: ${({ $isAudi }) => $isAudi ? '' : '3px solid #fff'};
        box-shadow: ${({ $isAudi }) => $isAudi ? '' : '0 1px 8px 1px rgb(117 108 88 / 64%)'};
        border-radius: ${({ theme }) => theme.steps.color.pickerItemRadius};
      }
      &.interactive {
        cursor: pointer;
        &:hover {
          transform:  ${({ $isAudi }) => $isAudi ? '' : 'scale(1.1)'};
          transition: 0.2s all;
        }
      }
    `};
  ${NoEntranceIcon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (min-width:${DEVICE_SIZE.LARGE}px) {
    &:hover{
      ${({ $isAudi }) => $isAudi && css`
      &:after{
          content: '';
          width: 100%;
          height: 2px;
          position: absolute;
          background-color: black;
          z-index: 200;
          margin-top: 6px;
        }
      `}
    }
  }
`;

export const ToolTip = styled(Tooltip)`
  &.extraClass {
    border: 5px solid #fff;
    border-radius: 15px;
    padding: 1rem;
    text-align: center;
    box-shadow: 5px 5px 20px 0px #63636378;
    z-index: 100;
    &.place-top {
    }
  }
`;

export const TooltipTitle = styled.div`
  color: #fff;
  font-weight: 500;
  font-size: 1.05rem;
  margin: 0 0 0.7rem;
`;

export const TooltipDescription = styled.div`
  color: #eee;
  font-weight: 400;
  font-size: 0.9rem;
  margin: 0 0 0.7rem;
`;
export const TooltipInStock = styled(TooltipDescription)``;

export const PickerItemInner = styled.span`
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  width: 3.5rem;
  height: 3.5rem;
  margin: auto;
  border-radius: ${({ theme }) => theme.steps.color.pickerItemRadius};
  display: block;
  flex-shrink: 0;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    border: ${({ $isAudi }) => $isAudi ? 'none' : '3px solid #ddd'};
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: ${({ theme }) => theme.steps.color.pickerItemRadius};
    z-index: -1;
  }
  &:before {
    left: ${({ $isAudi }) => $isAudi ? '3.5rem' : '-0.5rem'};
    top: ${({ $isAudi }) => $isAudi ? '3.5rem' : '-0.5rem'};
    width: ${({ $isAudi }) => $isAudi ? '0.8rem' : '1rem'};
    height: ${({ $isAudi }) => $isAudi ? '0.8rem' : '1rem'};
    position: absolute;
    background: ${({ theme }) => theme.checkMarkBackgroundColor};
    border-radius: 50%;
    padding: 0.3rem;
    border: none;
    content: "\\e800";
    font-size:  ${({ $isAudi }) => $isAudi ? '0.8rem' : '1rem'};
    color: #fff;
    font-family: "fontello";
    text-align: center;
    vertical-align: middle;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
      top: 3.9rem;
      right: 1.5rem;
      width: 1rem;
      height: 1rem;
      font-size: 1rem;
      margin: auto;
      bottom: auto;
    }
  }
  &.selected {
    &:before {
      opacity: 1;
    }
  }
  /* box-shadow: 0 1px 8px 1px #756c58;
  box-shadow: 0 1px 8px 1px #756c58a3; */
  &.selected {
    &:before {
      @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        top: 0;
        right: 0;
        width: 1rem;
        height: 1rem;
        font-size: 1rem;
        margin: auto;
        bottom: auto;
        transform: translateY(-50%);
      }
    }
  }
    

  @media (max-width: 700px) {
    width: calc(3.5rem / 1.5);
    height: calc(3.5rem / 1.5);
    border-width: 2px;
    &.selected {
      &:before {
        width: calc(1rem / 1.5);
        height: calc(1rem / 1.5);
        font-size: calc(1rem / 1.5);
      }
    }
  }

  @media (max-width: 400px) {
    width: calc(3.5rem / 2);
    height: calc(3.5rem / 2);
    &.selected {
      &:before {
        width: calc(1rem / 2);
        height: calc(1rem / 2);
        font-size: calc(1rem / 2);
      }
    }
  }

  ${({ $isAudi }) => $isAudi
  && css`
    &.selected {
      &:before {
        @media(max-width:${DEVICE_SIZE.MEDIUM}px){
          top: 4.5rem;
          right: 1.5rem;
          width: 1rem;
          height: 1rem;
          font-size: 1rem;
          margin: auto;
          bottom: auto;
        }
      }
    }    
  `};
`;

export const PickerText = styled.span`
  word-break: break-word;
  text-align: center;
  padding: 0.5rem;
  text-align: center;
  width:100%;
  display:inline-block;
  font-size: ${pxToRem(14)};
  max-width:${({ theme }) => theme.steps.color.pickerItemTextWidth ?? theme.steps.color.pickerItemWidth};
  min-width:${({ theme }) => theme.steps.color.pickerItemWidth};
`;

export const NoPackageTooltipContainer = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  ${NoEntranceIcon} {
    position: static;
    margin-left: 10px;
    transform: translate(0);
  }
  ${TooltipDescription}{
    margin:0;
  }
`;
