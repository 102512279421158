/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { getWarrentyLink } from '../../../../utils/assetsUtils';
import { spreadAllManufacturingDates } from '../../../../utils/commonUtils';

// todo : replace the following content  with real data from the server
const CarInfoSkoda = ({ brand, ManufacturingDates }) => {
    return (
        <>
            {spreadAllManufacturingDates(ManufacturingDates)}
            <p>מצב מכני של הרכב - מצב חדש ללא נזק בטיחותי</p>
            <p>הרכב לא נרשם במדינת חוץ</p>
            <p> ללא תקלות בטיחותיות סדרתיות </p>
            <p>מספר הקילומטרים של כלל נסיעות הרכב ממועד ייצורו לא עולה על 150 ק"מ</p>
            <p>תקופת האחריות: שנתיים. פירוט תנאי האחריות תוכל למצוא <a rel="noopener noreferrer" href={ getWarrentyLink(brand) } target="_blank">כאן</a></p>
        </>
    );
};

CarInfoSkoda.propTypes = {
    brand: PropTypes.string.isRequired,
    ManufacturingDates: PropTypes.array.isRequired,
};

export default CarInfoSkoda;
