import styled from 'styled-components';
import { motion } from 'framer-motion';
import CONFIG from '../../../../config';
import { pxToRem } from '../../../../utils/commonUtils';
import { presentationalFadeIn } from '../../../../utils/animationsConfig';

const { DEVICE_SIZE } = CONFIG;

const PresentationalLeft = styled(motion.div).attrs({
    ...presentationalFadeIn,
})`
  flex: 1;
  position: relative;
  max-width: ${({ isFullScreen }) => isFullScreen ? '100%' : '60%'};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    width: 100%;
    max-width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
`;

const StepTitle = styled.h2`
  display: none;
  text-align: right;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin: 50px 30px 0 0;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: block;
    margin-top: 25px;
    margin-inline-start: 20px;
    margin-bottom: 25px;
  }
`;

const ModelDescription = styled.p`
  display: none;
  max-width: 30%;
  margin: 0;
  margin-inline-end: 20px;
  align-items: center;
  color: ${({ theme }) => theme.primaryColor};
  font-weight: 500;
  font-size: ${pxToRem(14)};
  text-align: center;
  @media(max-width:${DEVICE_SIZE.LARGE}px){
    display: flex;
  }
`;

const Styled = {
    PresentationalLeft,
    Header,
    StepTitle,
    ModelDescription,
};

export default Styled;
