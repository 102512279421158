import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './SystemDetails.styles';
import SystemDetailItem from './SystemDetailItem/SystemDetailItem';

const SystemDetails = ({ safetySystemDetails }) => {
    return (
        <Styled.SystemDetails>
            <Styled.SystemDetailsList>
                {safetySystemDetails.map((detail) => {
                    const { description, value, installationStatus } = detail;
                    
                    return (
                        <SystemDetailItem
                            key={ description }
                            name={ description }
                            value={ value }
                            valueType={ installationStatus }
                        />
                    );
                })}
            </Styled.SystemDetailsList>
        </Styled.SystemDetails>
    );
};

SystemDetails.propTypes = {
    safetySystemDetails: PropTypes.array.isRequired,
};

export default SystemDetails;
