import styled, { css } from 'styled-components';
import { configurationLeftScrollBar } from '../../../components/common/styles';
import CONFIG from '../../../config';
import { IconAndSubTitle } from '../IconAndSubTitle/IconAndSubTitle.styles';

const { DEVICE_SIZE } = CONFIG;

export const ProgressScreenSteps = styled.div`
 display:flex;
 flex-direction: column;
 overflow-y: auto;
 max-height: 100%;
 height: 758px ;
 border-bottom: 1px solid lightgray;
 ${configurationLeftScrollBar};
 .CheckOutScreenTab{
   flex:1;
 }
 @media(max-width:${DEVICE_SIZE.MEDIUM}px){
  padding-top: 0;
  height: ${() => window.innerHeight - 150}px;
 }
`;

export const StepContainer = styled.div`
  max-width: 800px;
  display: ${({ $isVisible }) => $isVisible ? 'flex' : 'none'};
  padding-top: 30px ;
  opacity: 1;
  transition: 0.5s linear ;
  & > ${IconAndSubTitle} {
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
      display: none;
    }
  }
  ${({ $isDisabled }) => $isDisabled
  && css`
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
  `}
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    padding: 35px 25px 35px 25px;
    flex-direction: column;
  }
`;
