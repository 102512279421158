import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../language';
import Image from '../../Image';
import { getCarPlaceholderImageUrl } from '../../../utils/assetsUtils';
import { getCurrentBrandFromEnv } from '../../../utils/commonUtils';
import * as Styled from './Car360.styles';

const currentBrand = getCurrentBrandFromEnv();

const Car360 = ({ src, fallbackSrc }) => {
    if (!src) {
        return (
            <Styled.ImageWrapper>
                <Image
                    fallbackSrc={ fallbackSrc }
                    src={ fallbackSrc }
                    alt={ STRINGS.CAR_IMAGE_ALT }
                />
            </Styled.ImageWrapper>
        );
    }

    return (
        <Styled.Car360>
            <Styled.Iframe src={ src } />
        </Styled.Car360>
    );
};

Car360.defaultProps = {
    fallbackSrc: getCarPlaceholderImageUrl(currentBrand),
};

Car360.propTypes = {
    src: PropTypes.string.isRequired,
    fallbackSrc: PropTypes.string,
};

export default Car360;
