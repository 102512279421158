import styled from 'styled-components';
import { ReactComponent as Info } from '../../../assets/icons/info.svg';
import { ReactComponent as Close } from '../../../assets/icons/close-thin.svg';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 201;
  background-color: rgba(0, 0, 0, 0.5);
  
  &.show-up-enter .portal-content {
    transform: translateY(100%);
  }
  
  &.show-up-enter-active .portal-content {
    transform: translateY(0);
    transition: all 400ms;
  }
  
  &.show-up-exit-active .portal-content {
    transform: translateY(100%);
    transition: all 400ms;
  }
  &.show-up-exit-active {
    transition: all 500ms;
  }
`;

export const PaymentDetailsContainer = styled.div`
  position: fixed;
  bottom: 0;
  max-height: 85%;
  width: 50%;
  padding: 0;
  z-index: 130;
  background-color: white;
  box-shadow: rgb(0 0 0 / 50%) 3px 5px 14px;
  overflow-y: auto;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    position: fixed;
    width: 95%;
    height: 75%;
    border-radius: 10px 10px 0 0;
  }
`;

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  padding: 10px 0;
`;

export const Header = styled.header`
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;
  padding: 0 10px;
  gap: 8px;
`;

export const Title = styled.h2`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
`;

export const InfoIcon = styled(Info)``;

export const CloseButton = styled(Close).attrs({
    role: 'button',
})`
  width: 26px;
  height: 26px;
  display: block;
  margin: 10px;
  outline: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
`;

export const Container = styled.div`
  overflow-y: auto;
`;
