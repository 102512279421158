import styled from 'styled-components';

const Container = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Styled = {
    Container,
    Content,
};

export default Styled;
