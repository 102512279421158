import styled from 'styled-components';
import CONFIG from '../../../../../config';
import { Divider } from '../Divider/Divider.styles';

const { DEVICE_SIZE } = CONFIG;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    border: none;
    padding: 0 12px;
    ${Divider} {
      display: none;
    }
  }
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-align: start;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    margin: 0 0 8px 0;
  }
`;

const DetailsList = styled.ul`
  margin: 0;
  padding: 0 14px 0 0;
  margin-bottom: 1em;
`;

const DetailItem = styled.li.attrs({
    dir: 'rtl',
})`
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: start;
`;

const Detail = styled.span.attrs({
    dir: 'rtl',
})`
`;

const Styled = {
    Container,
    Title,
    Detail,
    DetailsList,
    DetailItem,
};

export default Styled;
