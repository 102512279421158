import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { useCurrentBrand, useCurrentCar, useSelectedCurrentData } from '../../../hooks';
import { getFullManufacturer } from '../../../utils/commonUtils';
import STRINGS from '../../../language';
import {
    useDataForPaymentDetails,
} from '../../PaymentDetails/PaymentDetails.hooks';
import PaymentDetails from '../PaymentDetails/PaymentDetails';
import AdditionalInformation from '../PaymentDetails/AdditionalInformation/AdditionalInformation';
import * as Styled from './PaymentDetailsModal.style';

const PaymentDetailsModal = ({
    isPaymentDetailsVisible,
    onClose,
}) => {
    const currentBrand = useCurrentBrand();
    const backdrop = React.useRef(null);
    const clickHandler = (e) => e.target === backdrop.current && onClose();

    const {
        specifications,
        addOns,
        originalAdditions,
        equipmentPackage,
        carPrices,
        licenseFeePrices,
        totalPrices,
    } = useDataForPaymentDetails();
    const currentCar = useCurrentCar();
    const {
        modelDescription,
        priceList,
        priceListDate,
    } = currentCar;

    const {
        selectedExternalColor,
        selectedInternalColor,
    } = useSelectedCurrentData();
    const { inStockString: inStockExternalString } = selectedExternalColor;
    const { inStockString: inStockInternalString } = selectedInternalColor;
    const inStockString = inStockInternalString ?? inStockExternalString;
    const manufacturerInHebrew = getFullManufacturer(currentBrand, 'hebrew');

    useEffect(() => {
        if (isPaymentDetailsVisible) {
            document.body.style.overflow = 'hidden';
            document.body.dataset.moreInfo = isPaymentDetailsVisible;
            
            return;
        }
        
        document.body.style.overflow = 'visible';
        document.body.dataset.moreInfo = isPaymentDetailsVisible;
    }, [isPaymentDetailsVisible]);

    const portal = (
        <CSSTransition
            in={ isPaymentDetailsVisible }
            timeout={ 500 }
            classNames="show-up"
            unmountOnExit
        >
            <Styled.Overlay ref={ backdrop } onClick={ clickHandler }>
                <Styled.PaymentDetailsContainer
                    className="portal-content"
                    isPaymentDetailsVisible={ isPaymentDetailsVisible }
                >
                    <Styled.HeaderContainer>
                        <Styled.CloseButton onClick={ onClose }>X</Styled.CloseButton>
                        <Styled.Header>
                            <Styled.InfoIcon />
                            <Styled.Title>{ STRINGS.PAYMENT_DETAILS }</Styled.Title>
                        </Styled.Header>
                    </Styled.HeaderContainer>
                    <Styled.Container>
                        <PaymentDetails
                            specifications={ specifications }
                            addOns={ addOns }
                            originalAdditions={ originalAdditions }
                            equipmentPackage={ equipmentPackage }
                            carPrices={ carPrices }
                            licenseFeePrices={ licenseFeePrices }
                            totalPrices={ totalPrices }
                            currentBrand={ currentBrand }
                            onClose={ onClose }
                        />
                        <AdditionalInformation
                            priceList={ priceList }
                            manufacturerInHebrew={ manufacturerInHebrew }
                            modelDescription={ modelDescription }
                            priceListDate={ priceListDate }
                            inStockString={ inStockString }
                        />
                    </Styled.Container>
                </Styled.PaymentDetailsContainer>
            </Styled.Overlay>
        </CSSTransition>
    );

    return ReactDOM.createPortal(portal, document.body);
};

PaymentDetailsModal.propTypes = {
    isPaymentDetailsVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PaymentDetailsModal;
