import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/Loader';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import {
    useActionDispatch,
    useCurrentAppCommonData,
    useCurrentCar,
    useCurrentExternalColor,
    useCurrentHoop,
    useCurrentInternalColor,
    useCreditGuardUrl,
    useSelectedCurrentData,
} from '../../../../hooks';
import { useGA4Gtm } from '../../../../utils/gtm.hooks';
import {
    getManufacturerContactInfo,
    isArrayEmpty,
    replacePlaceholdersInTemplate,
} from '../../../../utils/commonUtils';
import {
    CHECKOUT_PAGE_PROGRESS_STEPS,
    GTM_EVENT_TYPES,
} from '../../../../constants';
import STRINGS from '../../../../language';
import { parseSelectedAddOnsToArray } from '../../../../components/Steps/Summary/Summary.utils';
import { useAuthentication } from '../../../../hooks/authentication';
import Financing from './Financing/Financing';
import PickUp from './PickUp/PickUp';
import * as Styled from './PickUpAndFinancing.styles';

const { PAYMENT_AND_DOWN_PAYMENT } = CHECKOUT_PAGE_PROGRESS_STEPS;

const PickUpAndFinancing = ({ scrollHandler }) => {
    const {
        getDealershipsAsync,
        holdCommisionAsync,
        selectDealership,
        setIsFinancing,
    } = useActionDispatch();
    const [error, setError] = useState(null);
    const {
        pickUpAndFinancing,
        commision,
    } = useSelector(
        ({ checkoutReducer }) => checkoutReducer,
    );
    const {
        auth,
    } = useAuthentication();
    const { selectedAddOns } = useSelectedCurrentData();
    const { currentBrand, isAudi } = useCurrentAppCommonData();
    const { modelCode, carVersion } = useCurrentCar();
    const { value: outColor } = useCurrentExternalColor();
    const { value: inColor } = useCurrentInternalColor();
    const selectedHoop = useCurrentHoop();
    const creditGuardUrl = useCreditGuardUrl();
    const gtmPickUpAndFinancing = useGA4Gtm({ eventType: GTM_EVENT_TYPES.BEGIN_CHECKOUT });
    const { isAuthenticated } = auth;
    const {
        dealerships,
        isLoading: isLoadingDealerships,
        selectedDealership,
        isFinancing,
    } = pickUpAndFinancing;
    const selectedHoopsString = selectedHoop.value;
    const selectedAddOnsArray = parseSelectedAddOnsToArray(selectedAddOns);
    const { isLoading: isLoadingCommision } = commision;
    const { PHONE: contactPhoneNumber } = getManufacturerContactInfo(currentBrand);
    const contactUsForTradeIn = replacePlaceholdersInTemplate(
        STRINGS.CONTACT_US_FOR_TRADE_IN,
        [contactPhoneNumber],
    );
 
    useEffect(() => {
        if (isAuthenticated) {
            getDealershipsAsync(currentBrand);
        }
    }, [isAuthenticated]);

    const setIsFinancingHandler = () => {
        setIsFinancing(isFinancing);
    };

    const holdCommisionHandler = async () => {
        const response = await
        holdCommisionAsync(
            modelCode,
            outColor,
            inColor,
            carVersion,
            selectedAddOnsArray,
            selectedHoopsString,
        );

        if (response.isSuccess) {
            /**
             * 1. fire gtm event - step 3 
             * continue to Payment and down payment step
             */
            gtmPickUpAndFinancing();
            scrollHandler(PAYMENT_AND_DOWN_PAYMENT);
            setError(null);
        } else {
            const screenErrMsg = replacePlaceholdersInTemplate(
                response?.error,
                [contactPhoneNumber],
            );

            setError(screenErrMsg);
        }
    };

    const selectDealershipHandler = (event) => {
        const { value } = event.target;
        const dealership = dealerships.find(
            (dealershipItem) => dealershipItem.value === parseInt(value),
        );

        const isSelectedValue = !!parseInt(value);

        selectDealership(dealership);
        if (!creditGuardUrl && isSelectedValue) {
            holdCommisionHandler();
        } else if (isSelectedValue) {
            scrollHandler(PAYMENT_AND_DOWN_PAYMENT);
        }
    };

    const renderLoader = () => {
        return (
            <Styled.LoaderContainer>
                <Loader />
            </Styled.LoaderContainer>
        );
    };

    if (isArrayEmpty(dealerships) && !(isLoadingDealerships || isLoadingCommision)) {
        return <ErrorMessage errorMessage={ STRINGS.NO_DATA_TO_DISPLAY } />;
    }

    return (
        <Styled.PickUpAndFinancing>
            { (isLoadingDealerships || isLoadingCommision) && renderLoader() }
            <Financing onCheck={ setIsFinancingHandler } isFinancing={ isFinancing } />
            <PickUp
                onSelectDealership={ selectDealershipHandler }
                selectedDealership={ selectedDealership }
                dealerships={ dealerships }
            />
            { !isAudi && (
                <Styled.ContactUsForTradeIn>{contactUsForTradeIn}</Styled.ContactUsForTradeIn>
            ) }
            {error && <Styled.Error>{error}</Styled.Error>}
        </Styled.PickUpAndFinancing>
    );
};

PickUpAndFinancing.defaultProps = {
    scrollHandler: () => {},
};
PickUpAndFinancing.propTypes = {
    scrollHandler: PropTypes.func,
};
export default PickUpAndFinancing;
