import styled, { css } from 'styled-components';
import { COLORS } from '../../../constants';
import {
    Body,
    CarTitle,
    PaymentDetails,
} from '../../../components/PaymentDetails/PaymentDetails.styles';
import { AdditionalInformationList }
    from '../../../components/PaymentDetails/AdditionalInformation/AdditionalInformation.styles';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const CheckoutSiderBar = styled.aside`
  flex: 0 1 450px;
  padding-right: 25px;

  ${({ $isAudi }) => $isAudi && css`
    flex: 0 1 600px;
    ${PaymentDetails} {
      padding: 0;
    }
    ${Body} {
      padding: 0;
    }
    ${AdditionalInformationList} {
      margin-bottom: 0;
      padding-right: 0;
      list-style-type: none;
      li {
        font-size: 15px;
        margin-bottom: 0;
      }
    }
  `}

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex: auto;
    body[data-page-name="audi-checkout"] & {
      display: none;
    }
    ${PaymentDetails} {
      padding-top: 25px;
    }
    ${Body} {
      padding-bottom: 0;
    }
    ${CarTitle} {
      display: none;
    }
    ${AdditionalInformationList} {
      margin-bottom: 0;
      padding-right: 0;
      list-style-type: none;
      li {
        font-size: 15px;
        margin-bottom: 0;
      }
    }
  }
`;

export const ImageWrapper = styled.div`
  max-width: 400px;
  max-height: ${({ $isAudi }) => $isAudi && '250px'};
  margin: auto;
  img {
    height: 100%;
    object-fit: cover;
  }
`;

export const InnerContent = styled.div`
  max-width: 402px;
  margin-right: auto;
  border: 1px solid ${COLORS.BORDER_COLOR_CHECKOUT_SIDER_BAR};
  background-color: ${COLORS.BG_COLOR_CHECKOUT_SIDER_BAR};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    background-color: ${COLORS.BG_COLOR_CHECKOUT_SIDER_BAR_MOBILE};
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 150;
    max-width: 100%;
    max-height:calc(100% - 90px);
    overflow: scroll;
    transform: ${({ isToggled }) => {
        const yPercentage = !isToggled ? '-100%' : '0%';

        return `translate3d(0, calc(${yPercentage} + 60px), 0)`;
    }};
    transition: transform 0.35s ease-in-out;
    img.CarImage {
      display: none;
    }
    ${CarTitle} {
      display: none;
    }
  }
  ${({ $isAudi }) => $isAudi && css`
    max-width: none;
    margin-right: none;
    border: none;
    background-color: transparent;
    
  `}
`;
