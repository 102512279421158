/* eslint-disable max-len */
import React from 'react';
import { getManufacturerContactInfo } from '../../../../utils/commonUtils';
import FaqParagraph from './FaqParagraph/FaqParagraph';
import HowToBuyAVehicle from './HowToBuyAVehicle/HowToBuyAVehicle';
import NessaryDocuments from './NessaryDocuments/NessaryDocuments';
import WhatShouldIBringWithMe from './WhatShouldIBringWithMe/WhatShouldIBringWithMe';
import WhereToSeeTheVehicle from './WhereToSeeTheVehicle/WhereToSeeTheVehicle';

const faqItems = (brand) => {
    const { EMAIL: email, PHONE: phone } = getManufacturerContactInfo(brand);
    
    return [
        {
            q: 'מה זמינות הרכב?',
            a: (
                <FaqParagraph text={ <p>לכל צבע רכב מופיע המידע האם הוא קיים במלאי או צפי התאריך שהוא צפוי להגיע לארץ</p> } />
            ),
        },
        {
            q: 'איך קונים רכב באתר?',
            a: <HowToBuyAVehicle email={ email } />,
        },
        {
            q: 'מה קורה אחרי תשלום המקדמה?',
            a: (
                <FaqParagraph
                    text={ (
                        <p>
                            אחרי תשלום המקדמה יישלח ללקוח מייל מנציגנו עם מסמכים שעליו למלא ולחתום להמשך התהליך. כמו כן, המייל שיישלח מכיל גם הסברים והוראות מפורטות להמשך. אם לא קיבלת מייל בתוך 48 שעות, צור עמנו קשר באחת הדרכים המופיעות בהמשך.
                        </p>
                    ) }
                />
            ),
        },
        {
            q: 'על שם מי יירשם הרכב?',
            a: (
                <FaqParagraph
                    text={ (
                        <p>
                            השם איתו נרשמים לאתר הוא השם עליו ירשם הרכב במסמכי הרישוי. במידה ומעוניינים ששם אחר יופיע במסמכי הרישוי יש ליצור איתנו קשר בהקדם. שינוי יתאפשר עד לשלב העברת מלוא התשלום עבור הרכב
                        </p>
                    ) }
                />
            ),
        },
        {
            q: 'האם ניתן להעביר את התשלום עבור הרכב מכל חשבון בנק?',
            a: (
                <FaqParagraph
                    text={ (
                        <p>
                            חשבון הבנק ממנו תועבר יתרת התשלום עבור הרכב נדרש להיות על שם אותו הלקוח שעל שמו גם בוצעה הזמנת הרכב. אם הלקוח מעונין להעביר את יתרת התשלום מחשבון אחר יש ליצור אתנו קשר בהקדם.
                        </p>
                    ) }
                />
            ),
        },
        {
            q: 'כיצד ניתן ליצור אתנו קשר?',
            a: (
                <FaqParagraph text={ <p>ניתן ליצור אתנו קשר בכל עת במייל <a href={ `mailto:${email}` }>{email}</a> או דרך טופס צור קשר באתר או דרך הצ'ט ובמספר טלפון <a href={ `tel:${phone}` }>{phone}</a> (בשעות הפעילות).</p> } />
            ),
        },
        {
            q: 'מה הם הטפסים הנדרשים לרכישת רכב? וכיצד יש למלא אותם?',
            a: <NessaryDocuments />,
        },
        {
            q: 'מה כולל טופס הפנייה לקבלת מימון?',
            a: (
                <FaqParagraph text={ <p>טופס הפנייה למימון כולל את פרטי הרכב, פרטיך, פרטי הסכם הזמנת הרכב לרבות דגם הרכב ומחירו וכן פירוט תנאי ההלוואה ואמצעי התשלום.</p> } />
            ),
        },
        {
            q:
      'איך משלמים את מלוא התשלום על הרכב ותוך כמה זמן נדרש להעביר את מלוא התשלום?',
            a: (
                <FaqParagraph
                    text={ (
                        <p>
                            לאחר תשלום המקדמה יש להעביר את מלוא התשלום על הרכב. בין אם הלקוח מעביר את יתרת התשלום בהעברה בנקאית רגילה או בהעברה בסיום תהליך המימון,  יש לכתוב את מספר ההזמנה בהערות של ההעברה הבנקאית (באזור האישי שבאתר ניתן למצוא את מספר ההזמנה) וזאת על מנת שנוכל לשייך את הכספים שהועברו להזמנה שבוצעה.
                            להשלמת מלוא התשלום עבור ההזמנה, יעמדו לרשות הלקוח 7 ימים, בתום 7 ימים ההזמנה תבוטל.
                        </p>
                    ) }
                />
            ),
        },
        {
            q: 'מה נדרש להביא במועד קבלת הרכב?',
            a: <WhatShouldIBringWithMe />,
        },
        {
            q: 'האם אפשר לבטל את ההזמנה?',
            a: (
                <FaqParagraph
                    text={ <p>ביטול הזמנה יכול להיעשות בתנאים המפורטים בסעיף 6 להסכם הזמנת הרכב, כלומר: בתוך 14 ימים ממועד ההזמנה, אם הרכב טרם נרשם על שמך וטרם הרכבת התוספות המיוחדות, אם הזמנת תוספות מיוחדות. אם ברצונך לבטל את הזמנתך, אנא שלח לנו פנייה בצ'ט או בדף צור קשר או בכתובת המייל <a href={ `mailto:${email}` }>{email}</a></p> }
                />
            ),
        },
        {
            q: 'האם אפשר לבצע שינויים בהזמנה?',
            a: (
                <FaqParagraph text={ <p>לכל שינוי בהזמנה עליך ליצור עמנו קשר בהקדם האפשרי בצ'אט, בדף צור קשר ובכתובת המייל: <a href={ `mailto:${email}` }>{email}</a></p> } />
            ),
        },
        {
            q: 'האם ניתן לראות את הרכב?',
            a: (
                <FaqParagraph text={ <WhereToSeeTheVehicle brand={ brand } /> } />
            ),
        },
        {
            q: 'כיצד ניתן לבצע Trade In?',
            a: (
                <FaqParagraph text={ <p>לשירותי טרייד אין וכל מידע נוסף אנא פנו למספר הטלפון <a href={ `tel:${phone}` }>{phone}</a></p> } />
            ),
        },
    ];
};

export default faqItems;
