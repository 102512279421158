import styled from 'styled-components';
import { ReactComponent as Specs } from '../../../../../assets/icons/specs.svg';
import CONFIG from '../../../../../config';
import { COLORS } from '../../../../../constants';

const { DEVICE_SIZE } = CONFIG;

const ModelItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  max-width: 480px;
  min-height: 400px;
  padding: 10px;
  gap: 24px;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex: 1 1 100%;
    max-width: initial;
    min-width: initial;
  }
`;

const ModelTitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const ModelName = styled.p`
  margin: 0;
  color: ${COLORS.BLACK};
  text-align: right;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`;

const Image = styled.img`
  object-fit: contain;
  height: 208px;
  width: 100%;
`;

const PriceTitle = styled.h4`
  font-size: 14px;
  font-weight: 700;
  margin: 0;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
`;

const PriceWrapper = styled.div`
  font-size: 26px;
  font-weight: 700;
  color: ${COLORS.BLACK};
  display: flex;
  align-items: baseline;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    margin-inline-start: 8px;
  }
`;

const Price = styled.p`
  margin: 0;
  line-height: 1;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    line-height: 1.5;
  }
`;

const NisSymbol = styled.span`
  font-size: 14px;
`;

const SelectButtonContainer = styled.div.attrs({
    role: 'link',
})`
  width: fit-content;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: flex;
    align-self: flex-start;
  }
`;

const MainCharacteristicsAndSpecificationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  padding-bottom: 17px;
  border-bottom: 1px solid #DFE4E8;
`;

const MainCharacteristicsTitle = styled.p`
  margin: 0;
  color: ${COLORS.BLACK};
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const SpecsButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  font-family: ${({ theme }) => theme['font-family']};
  color: ${({ theme }) => theme.primaryColor};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const SpecsIcon = styled(Specs)``;

const MainCharacteristicsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const MainCharacteristicItem = styled.li`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  &::before {
    content: '✓';
    margin-left: 8px;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Styled = {
    ModelItem,
    ModelTitlesContainer,
    ModelName,
    Image,
    PriceTitle,
    PriceContainer,
    PriceWrapper,
    Price,
    NisSymbol,
    SelectButtonContainer,
    MainCharacteristicsAndSpecificationContainer,
    MainCharacteristicsTitle,
    SpecsButton,
    SpecsIcon,
    MainCharacteristicsList,
    MainCharacteristicItem,
    Footer,
};

export default Styled;
