import styled, { css } from 'styled-components';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const TogglePresentationBtn = styled.button`
  background-color: #fafaf9;
  box-shadow: 0 -1px 8px 1px rgba(117, 108, 88, 0.32);
  border-radius: 5rem;
  padding: 0.5rem;
  outline: none;
  position: absolute;
  width: 95px;

  border:none;
  top: 30px;
  left: 30px;
  cursor: pointer;
  z-index: 10;
  display:flex;
  justify-content: space-between;
 
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    top: 10px;
  }
`;

export const IconBox = styled.div`
  width:38px;
  height:38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $isActive }) => $isActive && css`
  background-color:${({ theme }) => theme.primaryColor};
  `}
`;
export const Icon = styled.img`
    width: 26px;
    height: 26px;
`;
