import React from 'react';
import PropTypes from 'prop-types';
import { useCurrentAppCommonData } from '../../hooks';
import * as Styled from './SubmitButton.styles';

const SubmitButton = ({ title, onSubmit }) => {
    const { isCupra } = useCurrentAppCommonData();

    return <Styled.SubmitButton onClick={ onSubmit } isCupra={ isCupra }>{title}</Styled.SubmitButton>;
};

SubmitButton.defaultProps = {
    onSubmit: () => {},
};

SubmitButton.propTypes = {
    title: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
};

export default SubmitButton;
