import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import PaymentDetails from '../../PaymentDetails/PaymentDetails';
import {
    useDataForPaymentDetails,
} from '../../PaymentDetails/PaymentDetails.hooks';
import { useCurrentBrand } from '../../../hooks';
import * as Styled from './AudiPaymentDetailsModal.style';

const AudiPaymentDetailsModal = ({
    isPaymentDetailsVisible,
    setIsPaymentDetailsVisible,
}) => {
    const currentBrand = useCurrentBrand();
    const backdrop = React.useRef(null);
    const clickHandler = (e) => e.target === backdrop.current && setIsPaymentDetailsVisible(false);

    const {
        specifications,
        addOns,
        originalAdditions,
        equipmentPackage,
        carPrices,
        licenseFeePrices,
        totalPrices,
    } = useDataForPaymentDetails();

    const portal = (
        <CSSTransition
            in={ isPaymentDetailsVisible }
            timeout={ 500 }
            classNames="show-up"
            unmountOnExit
        >
            <Styled.Overlay ref={ backdrop } onClick={ clickHandler }>
                <Styled.PaymentDetailsContainer
                    className="portal-content"
                    isPaymentDetailsVisible={ isPaymentDetailsVisible }
                >
                    <PaymentDetails
                        specifications={ specifications }
                        addOns={ addOns }
                        originalAdditions={ originalAdditions }
                        equipmentPackage={ equipmentPackage }
                        carPrices={ carPrices }
                        licenseFeePrices={ licenseFeePrices }
                        totalPrices={ totalPrices }
                        currentBrand={ currentBrand }
                        onClose={ () => { setIsPaymentDetailsVisible(false); } }
                    />
                </Styled.PaymentDetailsContainer>
            </Styled.Overlay>
        </CSSTransition>
    );

    return ReactDOM.createPortal(portal, document.body);
};

AudiPaymentDetailsModal.propTypes = {
    isPaymentDetailsVisible: PropTypes.bool.isRequired,
    setIsPaymentDetailsVisible: PropTypes.func.isRequired,
};

export default AudiPaymentDetailsModal;
