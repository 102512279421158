import React, { useState, useEffect, useContext } from 'react';
import PageContainer from '../../components/PageContainer';
import STRING from '../../language';
import actions from '../../actions';
import { fetchLogFailure } from '../../fetch/fetch';

import Loader from '../../components/Loader/Loader';
import useIsMobile from '../../hooks/useWindowSize';
import { parsePreSaleResponseData, isCostValid } from '../../utils';
import ThemeContext from '../../ThemeProvider';
import { FAIL_CODES, TYPES } from '../../constants';
import FailureScreen from '../FailureScreen';
import { AppError } from '../../constants/errors';
import * as Styled from './Main.style';
import ProceedOnline from './ProceedOnline/ProceedOnline';

const Main = () => {
    const [appData, setAppData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const isMobile = useIsMobile(1100);
    const { setTheme } = useContext(ThemeContext);

    // get current orderId
    const urlParams = new URLSearchParams(window.location.search);
    const preSaleId = urlParams.get('id');

    // set avialbale color & selected color
    const colors = [];
    // const [colors, setcolors] = useState([]);
    const [selectedOutColor, setSelectedOutColor] = useState('');

    useEffect(() => {
        setIsLoading(true);

        if (!preSaleId) {
            setError(new Error(STRING.ERRMSG_INVALID_PARAMS));
            setIsLoading(false);
        } else {
            actions
                .GetPreSaleData({ id: preSaleId })
                .then((res) => {
                    const parsedReponse = parsePreSaleResponseData(res);

                    if (!parsedReponse
                        || !parsedReponse.carName
                        || !parsedReponse.carManufacturer
                        || !parsedReponse.brand) {
                        fetchLogFailure(preSaleId, FAIL_CODES.INVALLID_OBJECT_DATA);
                        throw new AppError(STRING.ERRMSG_INVALID_OBJECT_DATA);
                    }

                    if (!isCostValid(parsedReponse)) {
                        fetchLogFailure(preSaleId, FAIL_CODES.INVALLID_OBJECT_DATA);
                        throw new AppError(STRING.ERRMSG_INVALID_CALCULATION);
                    }
                    if (parsedReponse.totalPayment.prices.total <= 0) {
                        fetchLogFailure(preSaleId, FAIL_CODES.INVALLID_OBJECT_DATA);
                        throw new AppError(STRING.ERRMSG_LOW_PRICE);
                    }

                    // set the (default) selected color from obj
                    const _outColor = res.find(({ Type }) => Type === TYPES.COLOR_OUT)?.Value;

                    if (_outColor) {
                        setSelectedOutColor(_outColor);
                    }

                    // fetchOutColors({ preSaleId }).then(
                    //     (_colors) => {
                    //         setcolors(_colors);
                    //     },
                    // ).catch(console.warn);

                    setAppData(parsedReponse);
                    setTheme(parsedReponse.brand);
                })
                .catch((_error) => {
                    // show error when no data from server
                    console.warn('error GetPreSaleData', _error);

                    let errorMessage = STRING.ERRMSG_UNKNOWN_ORDER;

                    if (_error instanceof AppError && _error?.message) {
                        errorMessage = _error.message;
                    }

                    setError(new Error(errorMessage));
                }).finally(() => {
                    setIsLoading(false);
                });
        }
    }, [preSaleId, setTheme]);

    // change selected color in appData
    // useEffect(() => {
    //     if (appData && outColor && colors) {
    //         const COLOR_OUT_INDEX = appData.specification?.items?.findIndex(
    //             ({ type }) => type === TYPES.COLOR_OUT,
    //         ) ?? -1;
    //         const newOutColor = colors.find(({ value }) => outColor === value);

    //         if (
    //             (COLOR_OUT_INDEX !== -1)
    //             && newOutColor
    //             && (newOutColor.description
    //             !== appData.specification.items[COLOR_OUT_INDEX].title)
    //         ) {
    //             /** @type {import('../../types').Item} */
    //             const newColorOutItem = {
    //                 ...appData.specification.items[COLOR_OUT_INDEX],
    //                 icon: null,
    //                 title: newOutColor.description,
    //             };

    //             const newItems = appData.specification.items;

    //             newItems[COLOR_OUT_INDEX] = newColorOutItem;

    //             /** @type {import('../../types').Specification} */
    //             const newSpecification = {
    //                 ...appData.specification,
    //                 items: newItems,
    //             };

    //             /** @type {import('../../types').ParsedResponse} */
    //             const newAppData = {
    //                 ...appData,
    //                 specification: newSpecification,
    //             };

    //             setAppData(newAppData);
    //         }
    //     }
    // }, [appData, colors, outColor]);

    const defineContent = () => {
        if (isLoading) {
            return (
                <Styled.LoadingWrapper>
                    <Loader loadingMessage={ STRING.LOADING } />
                </Styled.LoadingWrapper>
            );
        }
        
        if (error || !appData || appData.ErrorMessage) {
            return (
                <FailureScreen
                    error={ error ?? new Error(appData?.ErrorMessage ?? STRING.NO_INFO) }
                />
            );
        }

        return (
            <ProceedOnline
                orderId={ preSaleId }
                onPickOutColor={ setSelectedOutColor }
                colors={ colors }
                outColor={ selectedOutColor }
                data={ appData }
                isMobile={ isMobile }
            />
        );
    };

    return <PageContainer>{defineContent()}</PageContainer>;
};

export default Main;
