import { NEW_API_METHODS } from '../../constants';
import { replaceUrlParams } from '../../utils/fetchUtils';
import axios from '../axios';

const { GET_EQUIPMENT_PACKAGE } = NEW_API_METHODS.CONFIGURATOR;

/**
 * @POST request
 * @param {string} brand (champion equivalent - sMutag)
 * @param {number} trimLevelCode (champion equivalent - sStyleLevelCode) 
 * @param {string} modelCode (champion equivalent - sModel) 
 * @returns {promise}
 */

export const getEquipmentPackages = async (brand, trimLevelCode, modelCode, OriginalAdditions) => {
    const getEquipmentPackagesUrl = replaceUrlParams(GET_EQUIPMENT_PACKAGE, {
        modelCode,
    });

    const result  = await axios.get(getEquipmentPackagesUrl, {
        params: {
            StyleLevelCode: trimLevelCode,
            OriginalAdditions,
        },
    });

    return result;
};
