import styled from 'styled-components';
import { pxToRem } from '../../../../../utils/commonUtils';
import { Label } from '../../../../../components/CheckBoxFormGroup/CheckBoxFormGroup.styles';
import CONFIG from '../../../../../config';
import { MENUFACTURS } from '../../../../../constants';

const { DEVICE_SIZE, brand } = CONFIG;

const MARGIN_RIGHT = brand === MENUFACTURS.VOLKSWAGEN_COMMERCIAL ? '0' : '5.5rem';

export const Financing = styled.div`
 max-width: 600px;
 display:flex;
 flex-direction: column;
 padding: 30px 0;
 border-bottom: 1px solid #e1e1e1;
 @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    padding: 15px 0;
  }
`;
export const Content = styled.div`
  margin-right: ${({ $isAudi }) => $isAudi ? '7.5rem' : MARGIN_RIGHT};
  ${Label}{
    font-weight: 500;
    font-size: ${pxToRem(17)};
    line-height:25px;
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    margin-right: 0;
  }
`;
export const Title = styled.h2`
  margin: 0 0 8px 0;
  font-weight: 700;
  font-size: ${pxToRem(24)};
  line-height:26px;
`;
export const SubTitle = styled.p`
  margin: 0 0 12px 0;
  font-weight: 400;
  font-size: ${pxToRem(19)};
  line-height:27px;
`;

export const Disclaimer = styled.p`
  margin: 0 0 5px 0;
  font-weight: 400;
  font-size: ${pxToRem(16)};
  line-height:23px;
`;

export const StepHeadContainer = styled.div`
  display: inline-flex;
  @media(max-width: ${DEVICE_SIZE.SMALL}px) {
    flex-direction: ${({ $isAudi }) => !$isAudi && 'column'};
  }
`;

export const TitleContainer = styled.div`
  /* display: none;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: block;
  } */
`;
