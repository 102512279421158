import styled, { css } from 'styled-components';
import { COLORS } from '../../../../constants';
import { pxToRem } from '../../../../utils/commonUtils';
import CONFIG from '../../../../config';
import {
    StickyConfigLeftFooterOnMobile,
} from '../../../common/styles';

const { DEVICE_SIZE } = CONFIG;

export const Footer = styled.footer`
  position: relative;
  padding: 20px 160px;
  background-color: ${COLORS.AUDI_CONFIGURATOR_FOOTER_BG};
  margin-top: auto;
  display: flex;
  align-items: center;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    ${StickyConfigLeftFooterOnMobile};
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-top: none;
    height: 120px;
  }
`;

export const FooterRightWrapper = styled.div`
  flex: 1;
  height: 100%;
  display: inline-flex;
  align-items: center;
  text-align: center;
  color: ${COLORS.WHITE};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    color: ${COLORS.BLACK};
    width: 100%;
  }
`;

export const PaymentDetailsContainer = styled.div`
   position: fixed;
   background-color: white;
   box-shadow: rgb(0 0 0 / 50%) 3px 5px 14px;
   @media (min-width: ${DEVICE_SIZE.MEDIUM}px) {
    z-index: 130;
    bottom: 0;
    right: 0;
    width: 30%;
    height: 100%;
    padding: 0;
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
   bottom: 64px;
   position: fixed;
   width: 95%;
   height: 75%;
   border-radius: 10px 10px 0 0;
  }
`;

export const PriceDescription = styled.p`
  font-size: ${pxToRem(11.5)};
  font-weight: 500;
  margin: 0;
`;

export const FullPrice = styled.p`
  margin: 0;
  font-size: ${pxToRem(24)};
  font-weight: 800;
  white-space: nowrap;
  line-height: 1.5;
  width: 100%;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    font-size: ${pxToRem(28)};
    }
`;

export const Separator = styled.div`
    width: 1px;
    height: 60px;
    background-color: #4C4C4C;
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
     display: none;
    }
`;

export const ModelGroupText = styled.p`
    margin: 0;
    font-size: ${pxToRem(24)};
    color: ${COLORS.WHITE};
    margin-left: 60px;
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: none;
  }
`;

export const PriceAndPaymentDetailsButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-right: 20px;
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
      background-color: whitesmoke;
      width: 95%;
      margin: 0 auto;
      box-shadow: 0 1px 8px 0.12px rgb(117 108 88 / 64%);
      border-radius:  5px 5px 0 0;
      padding: 10px;
      transition: 400ms opacity ease-in-out;
      opacity: ${({ isPaymentDetailsVisible }) => isPaymentDetailsVisible ? 0 : 1};
      pointer-events: ${({ isPaymentDetailsVisible }) => isPaymentDetailsVisible ? 'none' : 'auto'};
  }
`;

export const PriceContainer = styled.div`
    display: block;
    margin-left: 40px;
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
     display: flex;
     align-items: center;
     margin-left: 0px;
  }
`;

export const PaymentDetailsButton = styled.button`
  border: none;
  outline: none;
  font-weight: bold;
  line-height: 19px;
  text-decoration: underline;
  font-family: ${({ theme }) => theme['font-family']};
  font-size: ${pxToRem(14)};
  color: ${COLORS.WHITE};
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    color: ${COLORS.BLACK};
    margin-right: auto;
    margin-left: 5px;
  }
`;

export const CarImage = styled.img`
    width: 300px;
    height: 175px;
    position: absolute;
    bottom: 35px;
    right: 80px;
    object-fit: contain;
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: none;
  }
`;

export const ContinueButton = styled.button.attrs(({ $isDisabled }) => ({
    disabled: $isDisabled,
}))`
  flex: 1.5;
  width: 100%;
  max-width: 265px;
  padding: 0 23px;
  font-family: ${({ theme }) => theme['font-family']};
  font-weight: 700;
  font-size: ${pxToRem(15)};
  border: none;
  border-radius: ${({ theme }) => theme.buttonButtonRadius};
  outline: none;
  background-color: ${COLORS.WHITE};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: auto;
  color: ${COLORS.BLACK};
  &:after {
    content: "";
    display: inline-block;
    background-image:url(${({ $icon })  => $icon});
    background-repeat: no-repeat;
    background-position: center;
    width: 25px;
    height: 25px;
  }

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    max-width: 100%;
    height: 60px;
    justify-content: space-evenly;
    background-color:${COLORS.BLACK};
    color: ${COLORS.WHITE};
    font-size: ${pxToRem(20)};
    position: relative;
    z-index: 200;
  }
  ${({ $isDisabled }) => $isDisabled
    && css`
      opacity: 0.5;
      user-select: none;
      cursor:not-allowed;
    `}
`;
