import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useCurrentBrand, useCurrentAppCommonData } from '../../../../hooks';
import { getMetaDataByModelGroup } from '../../../../utils/seoUtils';
import { isArrayAndNotEmpty } from '../../../../utils/commonUtils';
import STRINGS from '../../../../language';
import HelmetTitleDescription from '../../../HelmetTitleDescription/HelmetTitleDescription';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import ModelsSlider from './ModelsSlider/ModelsSlider';
import * as Styled from './EngineAndTrimLevel.style';

const EngineAndTrimLevel = () => {
    const { search } = useLocation();
    const {  modelGroupCode: modelGroupCodeFromParams, modelGroup } = useParams();
    const { onlineCars } = useCurrentAppCommonData();
    const currentBrand = useCurrentBrand();
    const urlParams = new URLSearchParams(search);
    const modelGroupCode =  urlParams.get('modelGroupCode') ?? modelGroupCodeFromParams;
    const carItems = onlineCars
        .filter((car) => car.modelGroup.toLowerCase().replaceAll(' ', '-') === modelGroup);
    const { title, description } = getMetaDataByModelGroup(currentBrand, modelGroupCode);

    const renderModelItems = () => {
        if (!isArrayAndNotEmpty(carItems)) {
            return (
                <Styled.ErrorWrapper>
                    <ErrorMessage errorMessage={ STRINGS.NO_CARS_TO_DISPLAY } />
                </Styled.ErrorWrapper>
            );
        }

        return (
            <ModelsSlider
                models={ carItems }
                brand={ currentBrand }
            />
        );
    };

    return (
        <Styled.EngineAndTrimLevel>
            <HelmetTitleDescription
                title={ title }
                description={ description }
                canonical={ window.location.href }
            />
            { renderModelItems() }
        </Styled.EngineAndTrimLevel>
    );
};

export default EngineAndTrimLevel;
