import { useSelector } from 'react-redux';

export const useCheckout = () => {
    return useSelector(({ checkoutReducer }) => checkoutReducer);
};

export const useCheckoutCustomer = () => {
    const { customer } = useCheckout();

    return customer;
};

export const useCheckoutPickUpAndFinancing = () => {
    const { pickUpAndFinancing } = useCheckout();

    return pickUpAndFinancing;
};

export const useCheckoutCommision = () => {
    const { commision } = useCheckout();

    return commision;
};

export const useCheckoutIsFinished = () => {
    const { isFinished } = useCheckout();

    return isFinished;
};

export const useIsInCheckout = () => {
    const { isInCheckout } = useCheckout();
    
    return isInCheckout;
};

// -------------------------- //

export const useCustomerIdNumber = () => {
    const { idNumber } = useCheckoutCustomer();

    return idNumber;
};

export const useSelectedDealership = () => {
    const { selectedDealership } = useCheckoutPickUpAndFinancing();
    const { value } = selectedDealership;

    return value;
};

export const useCreditGuardUrl = () => {
    const { creditGuardUrl } = useCheckoutCommision();

    return creditGuardUrl;
};
