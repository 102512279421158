import styled from 'styled-components';
import { COLORS } from '../../constants';

const Container = styled.div`
  text-align: center;
`;

const Loader = styled.div`
    display: inline-block;
    position: relative;
    width: ${({ size }) => size + 5}px;
    height: ${({ size }) => size + 5}px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    margin: 6px;
    border: 6px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${COLORS.BLUE_CHAMPION} transparent transparent transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }

  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Message = styled.p`
  font-size: 20px;
  color: ${({ theme }) => theme.spinner};
  animation: fadeIn 3s both infinite;
  margin: 5px auto 0 auto;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;

const Style = {
    Container,
    Loader,
    Message,
};

export default Style;
