import styled from 'styled-components';
import { ConfiguratorFooterTitle } from '../../../../components/common/styles';
import { pxToRem } from '../../../../utils/commonUtils';
import CONFIG from '../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const Pollution = styled.div`
  //
`;

export const PollutionModelAndRange = styled.div`
  display: flex;
  padding: 10px 0;
  
  @media(max-width:${DEVICE_SIZE.VERY_LARGE}px){
    flex-direction:column;
  }
  
`;

export const Title = styled(ConfiguratorFooterTitle)``;

export const Text = styled.p`
  margin:0;
  font-size:${pxToRem(14)};
  padding:10px;
  width: 98%;
  
`;
