import styled from 'styled-components';
import { pxToRem } from '../../../../../utils/commonUtils';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 10px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  padding: 0 20px;
`;

const ArrivalDate = styled.p`
  font-weight: ${({ $isBold }) => $isBold ? '700' : '500'};
  font-size: ${pxToRem(13)};
  margin: 0;
  margin: 0.75rem 0;
  display: flex;
  align-items: center;
`;

const Styled = {
    Container,
    ArrivalDate,
};

export default Styled;
