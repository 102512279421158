import React from 'react';
import PropTypes from 'prop-types';
import AddOnGroupTitleAndIcon from '../Components/AddOnGroupTitleAndIcon/AddOnGroupTitleAndIcon';
import { getStepTitle } from '../../../../../utils/configuratorUtils';
import { CONFIGURATOR_STEPS } from '../../../../../constants';
import * as Styled from './AddOnsMenu.styles';

const AddOnsMenu = ({
    modelLocalGroups,
    hoopCode,
    scrollToAddOnsGroup,
}) => {
    const { addOns } = modelLocalGroups;

    const renderAddOnGroupTitles = () => {
        if (addOns[hoopCode]) {
            return addOns[hoopCode].map((AddOnGroup) => {
                const { groupCode, listLocalPackages, groupDescription } = AddOnGroup;
                const { image } = listLocalPackages[0];
                   
                return (
                    <AddOnGroupTitleAndIcon
                        key={ groupCode }
                        groupDescription={ groupDescription }
                        IconUrl={ image }
                        scrollToAddOnsGroup={ () => scrollToAddOnsGroup(groupCode) }
                        groupCode={ groupCode }
                    />
                );
            });
        }
        
        return null;
    };

    return (
        <Styled.AddOnsMenu>
            <Styled.AddOnsStepTitle>{
                getStepTitle(CONFIGURATOR_STEPS.ADD_ONS, true)
            }
            </Styled.AddOnsStepTitle>
            <Styled.AddOnsGroupTitlesContainer>
                {renderAddOnGroupTitles()}
            </Styled.AddOnsGroupTitlesContainer>
        </Styled.AddOnsMenu>
    );
};

AddOnsMenu.propTypes = {
    modelLocalGroups: PropTypes.object.isRequired,
    hoopCode: PropTypes.string.isRequired,
    scrollToAddOnsGroup: PropTypes.func.isRequired,
};

export default AddOnsMenu;
