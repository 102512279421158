import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../language';
import Equipment from '../Equipment/Equipment';
import Engine from '../Engine/Engine';
import Measurements from '../Measurements/Measurements';
import * as Styled from './SpecificationsData.styles';

const { EQUIPMENT, ENGINE, MEASUREMENTS } = STRINGS;

const SpecificationsData = ({ selectedTab, specifications }) => {
    const { equipment, engine, measurements } = specifications;
        
    const renderTabScreen = () => {
        switch (selectedTab) {
            case EQUIPMENT:
                return <Equipment equipment={ equipment } />;
            case ENGINE:
                return <Engine engine={ engine } />;
            case MEASUREMENTS:
                return <Measurements measurements={ measurements } />;
            default:
                return null;
        }
    };
    
    return (
        <Styled.SpecificationsData>
            {renderTabScreen()}
        </Styled.SpecificationsData>
    );
};

SpecificationsData.defaultProps = {
    selectedTab: null,
};
SpecificationsData.propTypes = {
    selectedTab: PropTypes.string,
    specifications: PropTypes.object.isRequired,
};

export default SpecificationsData;
