import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useCurrentAppCommonData, useSelectedCurrentData } from '../../../../hooks';
import Car360 from '../../../Configurator/Car360/Car360';
import { ImageSwiper } from '../../../Configurator/ImageSwiper';
import STRINGS from '../../../../language';
import ColorPresentation from '../../../Configurator/Presentation/Presentation';
import {
    NoPreviewWrapper,
    LoaderWrapper,
} from '../../../Configurator/Presentation/Presentation.styles';
import { isArrayAndNotEmpty, isObjectEmpty } from '../../../../utils/commonUtils';
import { useCarPlaceHolderImageUrl, useOutCarImageUrl } from '../../../../utils/assets.hooks';
import Loader from '../../../Loader';
import Image from '../../../Image';

const Presentation = ({ configOptions }) => {
    const [isSlider, setIsSlider] = useState(false);
    const { selectedExternalColor, selectedCar } = useSelectedCurrentData();
    const { modelGroupCode, trimLevelCode } = selectedCar;
    const {
        carImages,
        link360,
        value: selectedColorValue,
    } = selectedExternalColor;
    const { currentBrand } = useCurrentAppCommonData();
    const carImageUrl = useOutCarImageUrl(
        currentBrand,
        modelGroupCode,
        trimLevelCode,
        selectedColorValue);
    const fallbackSrc = useCarPlaceHolderImageUrl(currentBrand);
    const { externalColors } = configOptions;
    const carHasMedia = isArrayAndNotEmpty(carImages) || !!link360;
    const carHasBoth360AndMedia = isArrayAndNotEmpty(carImages) && !!link360;

    const toggleIframeOrSliderHandler = () => {
        setIsSlider((shown) => !shown);
    };

    const renderContent = () => {
        if (!carHasMedia) {
            return (
                <Image
                    showLoading
                    fallbackSrc={ fallbackSrc }
                    src={ carImageUrl }
                    alt={ STRINGS.CAR_IMAGE_ALT }
                />
            );
        }

        if ((link360 && !isSlider) || !isArrayAndNotEmpty(carImages)) {
            return <Car360 src={ link360 } />;
        }
        
        return <ImageSwiper images={ carImages } />;
    };

    if (!isArrayAndNotEmpty(externalColors)) {
        return (
            <NoPreviewWrapper>
                <ErrorMessage errorMessage={ STRINGS.NO_PREVIEW_IS_AVAILABLE } />
            </NoPreviewWrapper>
        );
    }

    if (isObjectEmpty(selectedExternalColor)) {
        return <LoaderWrapper><Loader /></LoaderWrapper>;
    }
    
    return (

        <ColorPresentation
            isMediaVisible={ isSlider }
            isButtonShown={ carHasBoth360AndMedia }
            isSingleImage={ !carHasMedia }
            onToggle={ toggleIframeOrSliderHandler }
        >
            {renderContent()}
        </ColorPresentation>
    );
};

Presentation.propTypes = {
    configOptions: PropTypes.object.isRequired,
};

export default Presentation;
