import PropTypes from 'prop-types';

export const ColorPickerItemType = PropTypes.shape({
    value: PropTypes.string.isRequired,
    description: PropTypes.string,
});

export const InteralColorItemType = PropTypes.shape({
    value: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    additionalPayment: PropTypes.string.isRequired,
    inStock: PropTypes.string.isRequired,
});

export const NumberLikeType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const PricesType = PropTypes.shape({
    price: NumberLikeType,
    discount: NumberLikeType,
    total: NumberLikeType,
});

export const RequestedSubjectType = PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
});

export const ModelPriceType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    prices: PricesType.isRequired,
});

export const ItemType = PropTypes.shape({
    type: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    prices: PricesType,
    typeDescription: PropTypes.string,
});

export const SpecificationsInnerTabItem = PropTypes.shape({
    headerCode: PropTypes.string.isRequired,
    headerDescription: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
});

export const SpecificationType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(ItemType).isRequired,
});

export const SpecificationEngineData = PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
});
export const SpecificationMeasurementType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
});
export const EquipmentScreenItemType = PropTypes.shape({
    key: PropTypes.string.isRequired,
    lineCode: PropTypes.string.isRequired,
    lineDescription: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
});

export const ExtrasType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(ItemType).isRequired,
});

export const CheckOutProgressStepType = PropTypes.shape({
    step: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    subTitle: PropTypes.string,
    ScreenTab: PropTypes.elementType,
});

export const PaymentDetail = PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
}).isRequired;

export const LicenceFeeType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    prices: PricesType.isRequired,
});

export const OnlineDiscountType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    prices: PricesType.isRequired,
});

export const TotalPaymentType = PropTypes.shape({
    title: PropTypes.string,
    prices: PricesType,
    isTotalPrice: PropTypes.bool,
});

export const ExtraDataItemType = PropTypes.shape({
    text: PropTypes.string,
});

export const hoopItem = PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
}).isRequired;

export const ExtraDataType = PropTypes.arrayOf(ExtraDataItemType);

export const ParsedDataType = PropTypes.shape({
    carName: PropTypes.string.isTotalPrice,
    carManufacturer: PropTypes.string.isRequired,
    modelPrice: ModelPriceType,
    specification: SpecificationType,
    extras: ExtrasType,
    licenceFee: LicenceFeeType,
    onlineDiscount: OnlineDiscountType,
    totalPayment: TotalPaymentType,
    extraData: ExtraDataType,
});

export const DealershipType = PropTypes.shape({
    value: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
});

export const EquipmentPackageColorType = PropTypes.shape({
    value: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    link360: PropTypes.string,
    isPackageRelated: PropTypes.bool.isRequired,
});

export const EquipmentPackageHoopType = PropTypes.shape({
    code: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    price: PropTypes.number,
    isPackageRelated: PropTypes.bool.isRequired,
});

export const EquipmentPackageItem = {
    id: PropTypes.string.isRequired,
    colors: PropTypes.arrayOf(EquipmentPackageColorType).isRequired,
    hoops: PropTypes.arrayOf(hoopItem).isRequired,
    properties: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
    price: NumberLikeType,
};

export const EquipmentPackageItemType = PropTypes.shape(EquipmentPackageItem);
