import styled from 'styled-components';
import {
    LoaderWrapper as LW,
    ErrorWrapper as EW,
}
    from '../../InternalColor/Configuration/Configuration.styles';

export const AddOnsConfiguration = styled.div`
  /* border:10px solid red; */
`;
export const AddOns = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const LoaderWrapper = styled(LW)``;

export const ErrorWrapper = styled(EW)``;
