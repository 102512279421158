import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import SalesDetails from '../../../components/SalesDetails';
import * as CustomTypes from '../../../constants/propTypes';
import STRINGS from '../../../language';
import Payment from '../Payment';
import BrandImageWithSocial from '../BrandImageWithSocial';
import { fetchStartPayment, fetchLogFailure } from '../../../fetch/fetch';
import {
    IFRAME_MESSAGES,
    ColorPickerItemType,
    PAYMENT_STEPS,
    FAIL_CODES,
} from '../../../constants';
import CarImage from '../../../components/CarImage/CarImage';
import Success from '../../Success/Success';
import FailureScreen from '../../FailureScreen';
import { htmlDecode } from '../../../utils';
import { AppError } from '../../../constants/errors';
import Styled from './ProceedOnline.style';

const ProceedOnline = ({
    orderId, data, outColor, onPickOutColor, colors, isMobile,
}) => {
    const [currentStep, setCurrentStep] = useState(PAYMENT_STEPS.INITIAL);
    const [paymentUrl, setPaymentUrl] = useState(null);
    const [isloadingPayment, setLoadingPayment] = useState(false);
    const [isPaymentError, setIsPaymentError] = useState(false);
    const [error, setError] = useState({});
    const [successData, setSuccessData] = useState({});
    
    const handleKeepToPayment = (event) => {
        setLoadingPayment(true);
        fetchStartPayment(orderId)
            .then((result) => {
                if (result?.Value) {
                    setPaymentUrl(result.Value);
                    setCurrentStep(PAYMENT_STEPS.PAYMENT);
                } else {
                    throw new AppError(STRINGS.ERRMSG_INVALID_PAYMENT_URL);
                }
            })
            .catch((_error) => {
                console.warn('error fetchStartPayment', _error);

                fetchLogFailure(orderId, FAIL_CODES.LOADING_PAYMENT_URL_FAILED);
                
                let errorMessage = STRINGS.ERRMSG_INVALID_PAYMENT_URL;

                if (_error instanceof AppError && _error?.message) {
                    errorMessage = _error.message;
                }

                setError(new Error(errorMessage));
                setCurrentStep(PAYMENT_STEPS.FAILURE);
            })
            .finally(() => {
                setLoadingPayment(false);
            });
    };

    const handlePaymentFailure = useCallback((failureData) => {
        console.log('failure data', failureData);

        const decodedErrorText = htmlDecode(failureData?.error);
        const decodedErrorcCode = htmlDecode(failureData?.errorCode);

        const errorMessage = `${decodedErrorText} (${decodedErrorcCode})`;

        fetchLogFailure(orderId, `CRG_${decodedErrorcCode}`);
        setError(new Error(errorMessage));
        setIsPaymentError(true);
        setCurrentStep(PAYMENT_STEPS.FAILURE);
    }, [orderId]);

    const handlePaymentSuccess = useCallback((sData) => {
        console.log('success data', sData);

        setSuccessData({
            cardToken: sData.cardToken,
            cardExp: sData.cardExp,
            authNumber: sData.authNumber,
        });

        setCurrentStep(PAYMENT_STEPS.SUCCESS);
    }, []);

    /**
   *
   * @param {MessageEvent<any>} e
   */
    const onMessage = useCallback((e) => {
        // todo: set origin in .jsp files

        // only listem to message events
        if (e?.type !== 'message') {
            return;
        }

        // only listen to known messages types (from iframe)
        if (!Object.values(IFRAME_MESSAGES).includes(e?.data?.message)) {
            return;
        }

        // only listen to events from credit-guard iframe
        if (!e?.origin?.toString().includes('creditguard.co.il')) {
            fetchLogFailure(orderId, FAIL_CODES.UNKNOWN);
            console.log('invalid message origin', e?.origin?.toString());

            return;
        }

        switch (e.data.message) {
            case IFRAME_MESSAGES.FAIL: {
                handlePaymentFailure(e?.data?.payload);
                break;
            }
            case IFRAME_MESSAGES.SUCCESS:
                handlePaymentSuccess(e?.data?.payload);
                break;
            default:
                break;
        }
    }, [handlePaymentFailure, handlePaymentSuccess, orderId]);

    useEffect(() => {
        window.addEventListener('message', onMessage);

        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, [onMessage]);

    const defineStepRouter = () => {
        switch (currentStep) {
            case PAYMENT_STEPS.PAYMENT:
                return  <Payment iframeUrl={ paymentUrl } />;
            case PAYMENT_STEPS.SUCCESS:
                return (
                    <Success
                        orderId={ orderId }
                        cardToken={ successData.cardToken }
                        cardExp={ successData.cardExp }
                        authNumber={ successData.authNumber }
                        salesMan={ data.salesMan }
                        saleCenter={ data.saleCenter }
                    />
                );
            case PAYMENT_STEPS.FAILURE:
                return <FailureScreen isPaymentError={ isPaymentError } error={ error } />;
            case PAYMENT_STEPS.INITIAL:
            default:
                return (
                    <BrandImageWithSocial
                        brand={ data.brand }
                        outColor={ outColor }
                        onPickOutColor={ onPickOutColor }
                        colors={ colors }
                        carName={ data.carName }
                        modelGroup={ data.modelGroup }
                        trimLevel={ data.trimLevel }
                    />
                );
        }
    };

    const defineCarTitle = () => {
        return (
            <Styled.CarTitleContainer>
                <Styled.CarTitleBrand>
                    {`${STRINGS.PROCEED_ONLINE_MY} ${data.carManufacturer}`}
                </Styled.CarTitleBrand>
                <Styled.CarTitleModel>
                    {data.carName}
                </Styled.CarTitleModel>
            </Styled.CarTitleContainer>
        );
    };

    return (
        <Styled.Container step={ currentStep }>

            {isMobile && (
                <Styled.CarTitleMobileWrapper step={ currentStep }>
                    {defineCarTitle()}
                </Styled.CarTitleMobileWrapper>
            )}
                
            {defineStepRouter()}

            <Styled.SaleDetails step={ currentStep }>
                {currentStep !== PAYMENT_STEPS.INITIAL && (
                    <Styled.CarImageContainer>
                        <CarImage
                            brand={ data.brand }
                            outColor={ outColor }
                            modelGroup={ data.modelGroup }
                            trimLevel={ data.trimLevel }
                        />
                    </Styled.CarImageContainer>
                )}
                
                {!isMobile && (
                    <Styled.CarTitleDesktopWrapper>
                        {defineCarTitle()}
                    </Styled.CarTitleDesktopWrapper>
                )}

                <Styled.SaleDetailsSubTitle>
                    {STRINGS.PAYMENT_DETAILS}
                </Styled.SaleDetailsSubTitle>
                <SalesDetails
                    { ...data }
                    outColor={ outColor }
                    colors={ colors }
                    showPaymentButton={ currentStep === PAYMENT_STEPS.INITIAL }
                    isloadingPayment={ isloadingPayment }
                    onPaymentButtonClick={ handleKeepToPayment }
                />
            </Styled.SaleDetails>
        </Styled.Container>
    );
};

ProceedOnline.propTypes = {
    orderId: PropTypes.string.isRequired,
    data: CustomTypes.ParsedDataType.isRequired,
    isMobile: PropTypes.bool,
    outColor: PropTypes.string.isRequired,
    onPickOutColor: PropTypes.func.isRequired,
    colors: PropTypes.arrayOf(ColorPickerItemType),
};

ProceedOnline.defaultProps = {
    isMobile: false,
    colors: [],
};

export default ProceedOnline;
