import styled from 'styled-components';
import { COLORS } from '../../constants/constants';

const { LOADER_BACKGROUND_COLOR } = COLORS;

export const Configurator = styled.div`
position: relative;
`;

export const CustomLoaderWrapper = styled.div`
position: relative;
`;

export const CustomLoader = styled.div`
  background-color: ${LOADER_BACKGROUND_COLOR};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 100;
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
`;
export const ErrorWrapper = styled.div`
  height:400px;
  display:flex;
  justify-content:center;
  align-items:center;
`;
