import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './ConfiguratorStep.style';

const ConfiguratorStep = ({
    name,
    isActive,
    isFinished,
    onSelectStep,
    id,
    isVisible,
    stepRef,
}) => {
    return (
        <Styled.ConfiguratorStep
            ref={ stepRef }
            id={ id }
            onClick={ isFinished ? onSelectStep : null }
            $isFinished={ isFinished }
            $isActive={ isActive }
            $isVisible={ isVisible }
        >
            <Styled.Label $isActive={ isActive }>
                {name}
            </Styled.Label>
            {isActive && (
                <Styled.BackgroundChip
                    $isActive={ isActive }
                    id={ id }
                    layoutId="backgroundChip"
                />
            )}
        </Styled.ConfiguratorStep>
    );
};

ConfiguratorStep.defaultProps = {
    isActive: false,
    isFinished: false,
    onSelectStep: () => {},
};

ConfiguratorStep.propTypes =  {
    name: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    isFinished: PropTypes.bool,
    onSelectStep: PropTypes.func,
    id: PropTypes.number.isRequired,
    isVisible: PropTypes.bool.isRequired,
    stepRef: PropTypes.object.isRequired,
};

export default ConfiguratorStep;
