import styled from 'styled-components';
import { COLORS } from '../../../constants';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const OrderAndCancelTerms = styled.div`
  padding:10px  0 40px;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    background-color: ${COLORS.BG_COLOR_INNER_PAGE_MOBILE}
  }

  
`;
export const Body = styled.div`
    direction: rtl;
  p,.dirright{
    text-align: right;
    direction:rtl;
  }
  .fw400{
    font-weight:400;
  }
  .textright{
    text-align:right;
  }
  .title{
    direction: rtl; 
    color: #5e9ca0; 
    text-align: right;
  }
  ol{
    padding: 12px;
  }

`;
