import React from 'react';
import InnerPagesHeader from '../../../components/InnerPages/InnerPagesHeader/InnerPagesHeader';
import InnerPageContainer from '../../../components/InnerPages/InnerPageContainer';
import STRINGS from '../../../language';
import { useCurrentBrand } from '../../../hooks';
import { MENUFACTURS } from '../../../constants';
import OrderAndCancelTermsVolkswagen
    from './OrderAndCancelTermsVolkswagen/OrderAndCancelTermsVolkswagen';
import OrderAndCancelTermsVolkswagenCommercial
    from './OrderAndCancelTermsVolkswagenCommercial/OrderAndCancelTermsVolkswagenCommercial';
import OrderAndCancelTermsSkoda from './OrderAndCancelTermsSkoda/OrderAndCancelTermsSkoda';
import OrderAndCancelTermsSeat from './OrderAndCancelTermsSeat/OrderAndCancelTermsSeat';
import OrderAndCancelTermsCupra from './OrderAndCancelTermsCupra/OrderAndCancelTermsCupra';
import OrderAndCancelTermsAudi from './OrderAndCancelTermsAudi/OrderAndCancelTermsAudi';
import * as Styled from './OrderAndCancelTerms.styles';

const OrderAndCancelTerms = () => {
    const currentBrand = useCurrentBrand();

    const renderCarInfo = (brand) => {
        switch (brand) {
            case MENUFACTURS.SEAT:
                return <OrderAndCancelTermsSeat />;
            case MENUFACTURS.SKODA:
                return <OrderAndCancelTermsSkoda />;
            case MENUFACTURS.VOLKSWAGEN:
                return <OrderAndCancelTermsVolkswagen />;
            case MENUFACTURS.VOLKSWAGEN_COMMERCIAL:
                return <OrderAndCancelTermsVolkswagenCommercial />;
            case MENUFACTURS.CUPRA:
                return <OrderAndCancelTermsCupra />;
            case MENUFACTURS.AUDI:
                return <OrderAndCancelTermsAudi />;
            default:
                return null;
        }
    };

    return (
        <Styled.OrderAndCancelTerms>
            <InnerPageContainer>
                <InnerPagesHeader title={ STRINGS.CAR_BOOKING_AGREEMENT_AND_CANCELLATION_POLICY } />
                <Styled.Body>
                    {renderCarInfo(currentBrand)}
                </Styled.Body>
            </InnerPageContainer>
        </Styled.OrderAndCancelTerms>

    );
};

export default OrderAndCancelTerms;
