import React from 'react';
import PropTypes from 'prop-types';
import { useCurrentAppCommonData } from '../../hooks';
import Loader from '../Loader';
import * as Styled from './ContinueThemeButton.styles';

const ContinueThemeButton = ({
    title, icon, isDisabled, height, onClick, isSubmitting,
}) => {
    const { isCupra, isAudi } = useCurrentAppCommonData();

    return (
        <Styled.ContinueThemeButton
            onClick={ onClick }
            height={ height }
            $icon={ icon }
            $isSubmitting={ isSubmitting }
            $isDisabled={ isDisabled }
            $isCupra={ isCupra }
            $isAudi={ isAudi }
        >
            {title}
            <Styled.LeftArrowIcon />
            {isSubmitting && <Loader loadingMessage="" size={ 35 } />}
        </Styled.ContinueThemeButton>
    );
};

ContinueThemeButton.defaultProps = {
    icon: null,
    isDisabled: false,
    isSubmitting: false,
    title: '',
    height: 52,
    onClick: () => {},
    isCupra: false,
};

ContinueThemeButton.propTypes = {
    icon: PropTypes.string,
    isDisabled: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    title: PropTypes.string,
    height: PropTypes.number,
    onClick: PropTypes.func,
    isCupra: PropTypes.bool,
};

export default ContinueThemeButton;
