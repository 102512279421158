import React from 'react';
import PropTypes from 'prop-types';
import ImageBlockWithTextInTheMiddle from '../../ImageBlockWithTextInTheMiddle';
import { getInnerPageHeaderImage } from '../../../utils/assetsUtils';
import { useCurrentAppCommonData, useCurrentBrand } from '../../../hooks';
import { TITLE_POSITION } from '../../../constants';
import * as Styled from './InnerPagesHeader.styles';

const { BOTTOM, CENTER, TOP } = TITLE_POSITION;

const InnerPagesHeader = ({ title, position }) => {
    const currentBrand = useCurrentBrand();
    const { isAudi } = useCurrentAppCommonData();
    const image = getInnerPageHeaderImage(currentBrand);
 
    return (
        <Styled.InnerPagesHeader>
            <ImageBlockWithTextInTheMiddle
                brand={ currentBrand }
                text={ title }
                image={ image }
                position={ isAudi ? TOP : position }
            />
        </Styled.InnerPagesHeader>
    );
};

InnerPagesHeader.defaultProps = {
    title: '',
    position: CENTER,
};

InnerPagesHeader.propTypes = {
    title: PropTypes.string,
    position: PropTypes.oneOf([BOTTOM, CENTER, TOP]),
};

export default InnerPagesHeader;
