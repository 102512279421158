import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../Modal/Modal';
import InputFormGroup from '../../../InputFormGroup/InputFormGroup';
import SubmitButton from '../../../SubmitButton/SubmitButton';
import STRINGS from '../../../../language';
import  ErrorMessage  from '../../../ErrorMessage/ErrorMessage';
import { INPUT_FIELDS } from './SmsCodeForm.fields';
import * as Styled from './SmsCodeForm.styles';

const smsCodeFormHeight = 440;
const smsCodeFormWidth = 500;

const SmsCodeForm = ({
    title,
    description,
    onChangeInput,
    input,
    submitButtonTitle,
    onSubmit,
    isVisible,
    formName,
    onClose,
    onResendSmsCode,
    onNotMyPhone,
    isLoading,
    otpError,
    isMaxAttempts,
}) => {
    const renderInputFormGroups = () => {
        return INPUT_FIELDS.map((inputField, index) => {
            const {
                name, label, errorMessage, type, isRequired,
            } = inputField;
            
            return (
                <InputFormGroup
                    key={ name }
                    inputName={ name }
                    label={ otpError?.message ?? label }
                    errorMessage={ errorMessage }
                    inputType={ type }
                    isRequired={ isRequired }
                    inputValue={ input[name]?.value ?? '' }
                    isErrorShown={ input[name]?.isErrorShown }
                    onChange={ onChangeInput }
                    isAutoFocused={ index === 0 }
                />
            );
        });
    };

    const renderMaxAttemptsError = () => {
        return (
            <Styled.ErrorContainer>
                <ErrorMessage
                    color="red"
                    errorMessage={ STRINGS.ERRMSG_YOU_HAVE_EXCEEDED_THE_NUMBER_OF_ATTEMPTS }
                />
            </Styled.ErrorContainer>
        );
    };
    const renderForm = () => {
        return (
            <>
                <Styled.Title>{title}</Styled.Title>
                <Styled.Description>{description}</Styled.Description>
                <Styled.Form name={ formName } action="/">
                    {renderInputFormGroups()}
                    <SubmitButton onSubmit={ onSubmit } title={ submitButtonTitle } />
                </Styled.Form>
            </>
        );
    };

    return (
        <Modal
            isLoading={ isLoading }
            height={ smsCodeFormHeight }
            onClose={ onClose }
            isVisible={ isVisible }
            width={ smsCodeFormWidth }
        >
            <Styled.FormContainer isError={ otpError?.message.length > 0 }>
                {isMaxAttempts ? renderMaxAttemptsError() : renderForm()}
                <Styled.Footer>
                    <Styled.NotMyPhoneBtn onClick={ onNotMyPhone }>
                        {STRINGS.THIS_IS_NOT_MY_PHONE_NUMBER}
                    </Styled.NotMyPhoneBtn>
                    <Styled.ResendSmsCodeAgainBtn onClick={ onResendSmsCode }>
                        {STRINGS.I_HAVE_NOT_RECEIVED_THE_CODE_PLEASE_SEND_AGAIN}
                    </Styled.ResendSmsCodeAgainBtn>
                </Styled.Footer>
            </Styled.FormContainer>
        </Modal>
    );
};

SmsCodeForm.defaultProps = {
    description: null,
    onChangeInput: () => {},
    onSubmit: () => {},
    onClose: () => {},
    onResendSmsCode: () => {},
    onNotMyPhone: () => {},
    input: {},
    isVisible: false,
    submitButtonTitle: STRINGS.SEND,
    isLoading: false,
    otpError: null,
    isMaxAttempts: false,
};

SmsCodeForm.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    onChangeInput: PropTypes.func,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    onResendSmsCode: PropTypes.func,
    onNotMyPhone: PropTypes.func,
    input: PropTypes.object,
    submitButtonTitle: PropTypes.string,
    isVisible: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    otpError: PropTypes.object,
    isMaxAttempts: PropTypes.bool,
};

export default React.memo(SmsCodeForm);
