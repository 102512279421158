import React from 'react';
import PropTypes from 'prop-types';
import { DealershipType, ICONS_NAMES } from '../../../../../constants';
import STRINGS from '../../../../../language';
import { getIconFromAssets } from '../../../../../utils/assetsUtils';
import Select from '../../../../../components/Select/Select';
import IconAndSubTitle from '../../../IconAndSubTitle';
import { useCurrentAppCommonData } from '../../../../../hooks';
import * as Styled from './PickUp.styles';

const { LOCATION, LOCATION_AUDI } = ICONS_NAMES;
const SelectName = 'dealerships';
const dealershipsForScroller = 20;

const PickUp = ({ dealerships, selectedDealership, onSelectDealership }) => {
    const { value } = selectedDealership;
    const dealershipsLength = dealerships.length;
    const { isAudi } = useCurrentAppCommonData();
    const pickUpIcon = isAudi ? LOCATION_AUDI : LOCATION;
    
    return (
        <Styled.PickUp>
            <Styled.StepHeadContainer $isAudi={ isAudi }>
                <IconAndSubTitle
                    icon={ getIconFromAssets(pickUpIcon) }
                    subTitle={ STRINGS.PICK_UP }
                />
                <Styled.TitleContainer>
                    <Styled.Title>
                        { STRINGS.PICK_UP }
                    </Styled.Title>
                    <Styled.Label>
                        { STRINGS.CHOOSE_YOUR_SHOW_ROOM }
                    </Styled.Label>
                </Styled.TitleContainer>
            </Styled.StepHeadContainer>

            <Styled.Content
                isScroller={ dealershipsLength > dealershipsForScroller }
                $isAudi={ isAudi }
            >
                <Select
                    name={ SelectName }
                    value={ value  }
                    onChange={ onSelectDealership }
                    options={ dealerships }
                />
                <Styled.Disclaimer>
                    {STRINGS.VEHICLE_DELIVERING_WILL_BE_DONE_WITHIN_15_DAYS}
                </Styled.Disclaimer>
            </Styled.Content>
        </Styled.PickUp>
    );
};

PickUp.defaultProps = {
    dealerships: [],
    onSelectDealership: () => {},
};

PickUp.propTypes = {
    dealerships: PropTypes.arrayOf(DealershipType),
    selectedDealership: PropTypes.object.isRequired,
    onSelectDealership: PropTypes.func,
};

export default PickUp;
