import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { pxToRem } from '../../../../utils/commonUtils';

export const Comparison = styled.div`
 padding-top: 20px;
`;
export const LinkToComparison = styled(Link)`
  text-decoration: none;
  font-weight: 500;
  font-size: ${pxToRem(16)};
  color:${({ theme }) => theme.configuratorFooterLinksColor};
  &:hover{
      text-decoration: underline;
  }
`;

export const List = styled.ul`
  padding: 0;
  margin: 15px 0 30px 0;
  padding-right: 40px;
`;

export const Item = styled.li`
  font-size: ${pxToRem(16)};
  font-weight: 500;
  line-height: 22.5px;
  margin-bottom: 12px;
  white-space: pre-wrap;
`;
