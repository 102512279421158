import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './AfterDiscountPriceItem.styles';

const AfterDiscountPriceItem = ({ name, prices }) => {
    const { price, total } = prices;
    const priceAfterDiscount = parseFloat(total - price).toLocaleString();
  
    return (
        <Styled.AfterDiscountPriceItem>
            <Styled.Name>{name}</Styled.Name>
            <Styled.PriceContainer>

                <Styled.DiscountPrice>
                    {priceAfterDiscount}
                </Styled.DiscountPrice>

            </Styled.PriceContainer>
        </Styled.AfterDiscountPriceItem>
    );
};

AfterDiscountPriceItem.propTypes = {
    name: PropTypes.string.isRequired,
    prices: PropTypes.object.isRequired,
};

export default AfterDiscountPriceItem;
