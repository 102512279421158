import styled from 'styled-components';
import CONFIG from '../../config';

const { DEVICE_SIZE } = CONFIG;

export const VideoPlayer = styled.video`
    border: 0;
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : 'auto')};
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    height:${({ theme }) => theme.presentation.mobile.height};
    margin-top: 30px;
    margin-bottom: -10px;
    width: 100vw;
  }
`;
