/** ********************** Send SMS ***************** */
export const SEND_SMS_PENDING = 'SEND_SMS_PENDING';
export const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS';
export const SEND_SMS_FAILURE = 'SEND_SMS_FAILURE';

/** ********************** isAuthenticated ***************** */

export const IS_AUTHENTICATED_PENDING = 'IS_AUTHENTICATED_PENDING';
export const IS_AUTHENTICATED_SUCCESS = 'IS_AUTHENTICATED_SUCCESS';
export const IS_AUTHENTICATED_FAILURE = 'IS_AUTHENTICATED_FAILURE';

/** ********************** Check Otp ***************** */

export const CHECK_OTP_PENDING = 'CHECK_OTP_PENDING';
export const CHECK_OTP_SUCCESS = 'CHECK_OTP_SUCCESS';
export const CHECK_OTP_FAILURE = 'CHECK_OTP_FAILURE';

/** ********************** Initialize ***************** */
export const INITIALIZE_AUTHENTICATION = 'INITIALIZE_AUTHENTICATION';
export const INITIALIZE_OTP = 'INITIALIZE_OTP';
export const INITIALIZE_SMS = 'INITIALIZE_SMS';
export const INITIALIZE_OTP_VERIFICATION = 'INITIALIZE_OTP_VERIFICATION';
export const OTP_VERIFICATION_SUCCESS = 'OTP_VERIFICATION_SUCCESS';
export const SET_MASKED_PHONE_NUMBER = 'SET_MASKED_PHONE_NUMBER';
export const SAVE_TOKEN = 'SAVE_TOKEN';
