import React from 'react';
import { PHONE_NUMBER_AUTHORIZATION, ROUTES } from '../../../../../constants';
import STRINGS from '../../../../../language';
import {
    isEmail, isPhonenumber, formatPhoneNumber,
} from '../../../../../utils/commonUtils';

export const INPUT_FIELDS = (isAudi) => [
    {
        name: 'firstName',
        type: 'text',
        value: '',
        label: STRINGS.FIRST_NAME,
        isRequired: true,
        isValid: false,
        validator: (value) => value.trim().length > 0,
        errorMessage: `${STRINGS.MUST_BE_FILLED_OUT} ${STRINGS.FIRST_NAME}`,
        isErrorShown: false,
        placeholder: () => {
            return isAudi
                ? STRINGS.AUDI_NAME_OF_THE_BANK_ACCOUNT_OWNER_ON_WHICH_THE_VEHICLE_WILL_BE_REGISTERED
                : STRINGS.NAME_OF_THE_BANK_ACCOUNT_OWNER_ON_WHICH_THE_VEHICLE_WILL_BE_REGISTERED;
        },
        maxLength: null,
        isReadOnly: false,
    },
    {
        name: 'lastName',
        type: 'text',
        value: '',
        label: STRINGS.LAST_NAME,
        isRequired: true,
        isValid: false,
        validator: (value) => value.trim().length > 0,
        errorMessage: `${STRINGS.MUST_BE_FILLED_OUT} ${STRINGS.LAST_NAME}`,
        isErrorShown: false,
        placeholder: () => null,
        maxLength: null,
        isReadOnly: false,
    },
    {
        name: 'email',
        type: 'email',
        value: '',
        label: STRINGS.EMAIL_ADDRESS,
        isRequired: true,
        isValid: false,
        validators: [
            {
                validator: (value) => value.trim().length > 0,
                errorMessage: `${STRINGS.MUST_BE_FILLED_OUT} ${STRINGS.EMAIL_ADDRESS}`,
            },
            {
                validator: (value) => isEmail(value),
                errorMessage: STRINGS.ENTER_VALID_EMAIL_ADDRESS_FOR_EXAMPLE,
            },
        ],
        isErrorShown: false,
        placeholder: () => null,
        maxLength: null,
        isReadOnly: false,
    },
    {
        name: 'mobilePhoneNumber',
        type: 'tel',
        value: sessionStorage.getItem(PHONE_NUMBER_AUTHORIZATION, '') || '',
        validators: [
            { validator: (value) => value.trim().length > 0, errorMessage: `${STRINGS.MUST_BE_FILLED_OUT} ${STRINGS.MOBILE_PHONE}` },
            { validator: (value) => isPhonenumber(value), errorMessage: STRINGS.PHONE_NUM_FIELD_MUST_BE_VALID_AND_10_CHARACTERS },
        ],
        formatter: (value) => formatPhoneNumber(value),
        label: STRINGS.MOBILE_PHONE,
        placeholder: () => null,
        isRequired: true,
        isValid: false,
        isErrorShown: false,
        maxLength: 11,
        encodedValue: '',
        isReadOnly: !!sessionStorage.getItem(PHONE_NUMBER_AUTHORIZATION, ''),
    },
    {
        name: 'id_number',
        type: 'text',
        value: '',
        validator: null,
        formatter: null,
        encoder: null,
        label: STRINGS.ID,
        isRequired: false,
        isValid: true,
        errorMessage: null,
        isErrorShown: false,
        placeholder: (text) => text,
        maxLength: null,
        encodedValue: '',
        isReadOnly: true,
    },

];

const privacyPolicyLabel = (
    <span>
        {`${STRINGS.ACCEPT_USE_DETAILS} `}
        <a target="_blank"
            rel="noreferrer"
            href={ ROUTES.PRIVACY_POLICY }
        >
            {STRINGS.FOR_PRIVACY_POLICY}
        </a> {STRINGS.OF_THE_COMPANY}
    </span>
);

const acceptAdvertisingLabel = (
    <span>
        {`${STRINGS.ACCEPT_ADVERTISING} `}
        <a target="_blank"
            rel="noreferrer"
            href={ ROUTES.PRIVACY_POLICY }
        >
            {STRINGS.FOR_PRIVACY_POLICY}
        </a> {STRINGS.OF_CHAMPION_MOTORS}
    </span>
);

export const CHECKBOX_FIELDS = [
    {
        id: 'privacy_policy',
        name: 'privacy_policy',
        type: 'checkbox',
        label: privacyPolicyLabel,
        isRequired: true,
        isValid: true,
        isChecked: true,
        errorMessage: STRINGS.YOU_MUST_ACCEPT_PRIVACY_POLICY,
        isErrorShown: false,
    },
    {
      
        id: 'accept_advertising',
        name: 'accept_advertising',
        type: 'checkbox',
        label: acceptAdvertisingLabel,
        isRequired: false,
        isValid: true,
        isChecked: false,
        errorMessage: null,
        isErrorShown: false,
    },
];

export const AUDI_CHECKBOX_FIELDS = [
    {
        id: 'privacy_policy',
        name: 'privacy_policy',
        type: 'checkbox',
        label: privacyPolicyLabel,
        isRequired: true,
        isValid: true,
        isChecked: true,
        errorMessage: STRINGS.YOU_MUST_ACCEPT_PRIVACY_POLICY,
        isErrorShown: false,
    },
    {
      
        id: 'accept_advertising',
        name: 'accept_advertising',
        type: 'checkbox',
        label: STRINGS.AUDI_ACCEPT_ADVERTISING,
        isRequired: false,
        isValid: true,
        isChecked: false,
        errorMessage: null,
        isErrorShown: false,
    },
];
