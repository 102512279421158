import styled, { css } from 'styled-components';
import IMAGES from '../../../assets';

export const PaymentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 2rem;

    &:before {
        content: "";
        display: block;
        width: 96px;
        height: 96px;
        background: url('${IMAGES.CREDIT_CARD}') no-repeat center center;
        background-size: contain;
        margin-left: 3rem;
        margin-top: -7px;   /* remove image padding to line up with title */
    }

    @media (max-width: 700px) {
        display: block;

        &:before {
            float: left;
            margin-left: 0;
            margin-right: 2rem;
        }
    }

    @media (max-width: 400px) {
        &:before {
            float: none;
            margin: 0 auto 1rem auto;
        }
    }

    ${({ isExtended }) => isExtended && css`
        display: flex !important;
        width: 100%;
        height: 100%;
        padding: 0;

        &:before {
            display: none;
        }
    `}
`;

export const InnerContent = styled.div`
    flex: 1;
`;

export const Header = styled.div`
    display: block;
    margin-bottom: 2rem;

    ${({ isHidden }) => isHidden && css`
        display: none;
    `}
`;

export const HeaderText = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
`;

export const Text = styled.div`
    font-size: 1.1rem;
`;

export const PaymentIframe = styled.iframe`
    width: 100%;
    max-width: 600px;   /* don't want it to change the size on screen animations */
    height: 400px;  /* max-size for mobile too */
    border: 0;
    margin-right: -7px; /* make 1 line with the header text */

    @media (max-width: 700px) {
        max-width: 100%;
    }

    ${({ isExtended }) => isExtended && css`
        display: block;
        max-width: 100%;
        height: 100%;
        margin-right: 0;
    `}
`;

const Style = {
    PaymentContainer,
    HeaderText,
};

export default Style;
