import styled from 'styled-components';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const ToolTip = styled(ReactTooltip)`
  &.extraClass {
    border-radius: 8px;
    padding: 0.25rem;
    text-align: center;
    box-shadow: 5px 5px 20px 0px #63636378;
    opacity: 1 !important;
    z-index: 10;
  }
`;

const Colors = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
`;

const Color = styled.li`
  width: 24px;
  height: 24px;
  margin: 4px 0;
  background-image: url(${({ $icon }) => $icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 0.5px solid #F5F5F5;

  &:not(:last-child){
    margin-left: 4px;
  };
`;

const Styled = {
    ToolTip,
    Colors,
    Color,
};

export default Styled;
