import { parseSpecificationsData } from './utils';

export const getSpecificationsPending = (state, specificationType) => ({
    ...state,
    [specificationType]: {
        ...state[specificationType],
        isLoading: true,
    },
});

export const getEquipmentSpecificationsSuccess = (state, action) => {
    const { data } = action;
    const { specifications: equipmentData } = data;
    
    return {
        ...state,
        equipment: {
            ...state.equipment,
            data: parseSpecificationsData(equipmentData),
            isLoading: false,
        },
    };
};
export const getEngineSpecificationsSuccess = (state, action) => {
    const { data: engineData } = action;

    return {
        ...state,
        engine: {
            ...state.engine,
            data: parseSpecificationsData(engineData),
            isLoading: false,
        },
    };
};
export const getMeasurementsSpecificationsSuccess = (state, action) => {
    const { data } = action;
    
    const mappedData = data.map((image) => {
        return {
            title: null,
            image,
        };
    });

    return {
        ...state,
        measurements: {
            ...state.measurements,
            data: mappedData,
            isLoading: false,
        },
    };
};

export const getSpecificationsInitSuccess = (state, action) => {
    const { data, originalAdditionsCode = '' } = action;

    const {
        CatalogLink,
        ModelDescription,
        ShowTabEngine,
        ShowTabEquipment,
        ShowTabSizes,
        trimLevelDescription,
    } = data;
    
    return {
        ...state,
        init: {
            ...state.init,
            modelDescription: ModelDescription,
            trimLevelDescription,
            catalogLink: CatalogLink,
            showTabEquipment: ShowTabEquipment,
            showTabEngine: ShowTabEngine,
            showTabSizes: ShowTabSizes,
            isLoading: false,
            error: null,
        },
        originalAdditionsCode,
    };
};
export const getSpecificationsFailure = (state, action, specificationType) => {
    const { error } = action;

    return {
        ...state,
        [specificationType]: {
            ...state[specificationType],
            isLoading: false,
            error,
        },
    };
};

export const initializeSpecifications = (initialState) => {
    return initialState;
};

export const setSpecificationParams = (state, action) => {
    const { data } = action;
    const {
        groupModelCode,
        modelCode,
        modelDescription,
        originalAdditionsCode = '',
        version,
        trimLevelCode,
        id,
    } = data;

    return {
        ...state,
        groupModelCode,
        modelCode,
        modelDescription,
        originalAdditionsCode,
        version,
        trimLevelCode,
        id,
    };
};

export const ToggleSpecificationsModal = (state) => {
    return {
        ...state,
        isModalVisible: !state.isModalVisible,
    };
};
