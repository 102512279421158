import React from 'react';
import PropTypes from 'prop-types';
import  ContinueThemeButton from '../../../../components/ContinueThemeButton/ContinueThemeButton';
import STRINGS from '../../../../language';
import {
    getManufacturerContactInfo,
    replacePlaceholdersInTemplate,
} from '../../../../utils/commonUtils';
import { useCurrentAppCommonData, useCurrentBrand } from '../../../../hooks';
import * as Styled from './ContactResponse.styles';

const ContactResponse = ({ onGoBack, isContactSuccessfull }) => {
    const currentBrand = useCurrentBrand();
    const { isAudi } = useCurrentAppCommonData();
    const { PHONE: contactPhoneNumber } = getManufacturerContactInfo(currentBrand);
    const errorMsg = replacePlaceholdersInTemplate(
        STRINGS.ERRMSG_PROBLEM_IN_CONTACT,
        [contactPhoneNumber],
    );
    
    const renderResponse = () => {
        if (isContactSuccessfull) {
            return (
                <>
                    <Styled.Text>{STRINGS.WE_GOT_IT}!</Styled.Text>
                    <Styled.Text>{STRINGS.WE_HAVE_SENT_YOUR_MESSAGE}</Styled.Text>
                    <Styled.Text>{STRINGS.SHOULD_WE_RETURN_TO_CONTACT_US_PAGE}?</Styled.Text>
                </>
            );
        }
        
        return (
            <Styled.Text>
                {errorMsg}
            </Styled.Text>
        );
    };

    return (
        <Styled.ContactResponseContainer>
            <Styled.Title>{STRINGS.TALK_TO_US}</Styled.Title>
            {renderResponse()}
            <ContinueThemeButton
                onClick={ onGoBack }
                title={ STRINGS.SEND_ME_BACK }
                isAudi={ isAudi }
            />
        </Styled.ContactResponseContainer>
    );
};

ContactResponse.defaultProps = {
    onGoBack: () => {},
    isContactSuccessfull: null,
};

ContactResponse.propTypes = {
    onGoBack: PropTypes.func,
    isContactSuccessfull: PropTypes.bool,
};

export default ContactResponse;
