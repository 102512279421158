import { getOnelineCarMenuThumbail } from '../../../utils/assetsUtils';
import { getCurrentBrandFromEnv, isArrayAndNotEmpty } from '../../../utils/commonUtils';
import { FILTER_BY } from '../../../constants';

export const parseOnlineCarsByModelGroups = (onlineCars, filterBy = FILTER_BY.BY_MODEL) => {
    if (!isArrayAndNotEmpty(onlineCars)) {
        return [];
    }

    const brand = getCurrentBrandFromEnv();

    const modelGroupsItems = onlineCars.reduce((acc, car) => {
        const {
            modelGroupCode, modelType, hebrewModelGroup, price, description, totalPrice,
        } = car;
        const paramName = filterBy === FILTER_BY.BY_MODEL ? modelGroupCode : modelType;

        if (!acc[paramName]) {
            acc[paramName] = {
                id: `modelGroupCode-${modelGroupCode}`,
                manufacturer: hebrewModelGroup,
                price,
                totalPrice,
                description,
                brand,
                image: getOnelineCarMenuThumbail(brand, modelGroupCode),
                imageAlt: hebrewModelGroup,
                modelType,
                cars: [car],
            };
        } else {
            acc[paramName].cars.push(car);
        }

        return acc;
    }, {});
    
    // TODO: check Vladi function
    return Object.values(modelGroupsItems).sort((a, b) => a.price - b.price);
};

export const isShowOvarlay = (modelGroupCars, selectedModelGroup) => {
    let modelIndex = modelGroupCars.length;
    const isMoreThanOneModel = modelGroupCars.find((car, idx) => {
        if (selectedModelGroup === car.id) {
            const { cars } = car;
            
            modelIndex = idx;

            return cars.length > 1;
        }

        return false;
    });

    // If the selected model group has more than one car,
    // /Or/
    // If the selected model group is not the last model group,
    // We will show the overlay
    return !!isMoreThanOneModel || modelIndex < modelGroupCars.length - 1;
};
