import styled from 'styled-components';
import { COLORS } from '../../constants';
import { pxToRem } from '../../utils/commonUtils';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`;

export const ErrorMessage = styled.label`
    margin-top: 1rem;
    padding: 5px 0;
    font-size: ${pxToRem(19)};
    font-weight: 400;
    color: ${COLORS.COLOR_ERROR_LABEL_INPUT_FIELD_CHECKOUT};
    font-weight:400;
`;

export const ContainerStyle = {
    direction: 'ltr',
    justifyContent: 'center',
};

export const InputStyle = {
    width: '2.5rem',
    height: '3rem',
    marginLeft: '0.75rem',
    fontSize: '18px',
};
