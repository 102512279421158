import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

const SelectButton = styled(Link)`
  display: flex;
  width: 100%;
  height: 44px;
  padding: 0px 48px;
  margin: auto;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 500px;
  border: ${({ theme }) => `2px solid ${theme.primaryColor}`};
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor};
  font-family: ${({ theme }) => theme['font-family']};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
  text-decoration: none;
  background-color: transparent;
  transition: all .2s ease-in-out;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    min-width: auto;
    max-width: 50%;
    height: 50px;
    margin:0 auto 25px auto;
  }
  @media (hover: hover){
    &:hover{
      background-color: ${({ theme }) => theme.footerContentTopBackgroundColor};
    }
  }
`;

const Styled = {
    SelectButton,
};

export default Styled;
