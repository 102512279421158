import React from 'react';
import PropTypes from 'prop-types';
import { useIconFromAssets } from '../../hooks';
import { ICONS_NAMES } from '../../constants';
import * as Styled from './CheckBox.styles';

const CheckBox = ({
    onCheck, isChecked, id, isRequired, isErrorShown,
}) => {
    const checkMarkIcon = useIconFromAssets(ICONS_NAMES.CHECK_MARK);
    
    return (
        <Styled.CheckBox
            id={ id }
            $isErrorShown={ isErrorShown }
            $isRequired={ isRequired }
            $checkMarkIcon={ isChecked ? checkMarkIcon : null }
            onClick={ onCheck }
            $isChecked={ isChecked }
        />
    );
};

CheckBox.defaultProps = {
    onCheck: () => {},
    isChecked: false,
    isRequired: false,
    isErrorShown: false,
};

CheckBox.propTypes = {
    onCheck: PropTypes.func,
    isChecked: PropTypes.bool,
    id: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    isErrorShown: PropTypes.bool,
};

export default CheckBox;
