import styled, { css } from 'styled-components';
import { coolSlideLeft, coolSlideRight } from '../../../components/common/styles';
import { PAYMENT_STEPS } from '../../../constants';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    transition: 2s all;

    ${({ step }) => step !== PAYMENT_STEPS.INITIAL && css`
        grid-template-columns: 6fr 4fr;
    `}

    @media (max-width: 1100px) {
        grid-template-columns: 1fr;
    }
`;

export const CarImageContainer = styled.div`
    width: 98%;
    max-width: 350px;
    margin: 0 auto;
    text-align: center;
    // animation: ${coolSlideLeft} 0.8s ease-in-out 1 both;
`;

export const SaleDetails = styled.div`
    display: block;
    background-color: #FFFFFF;
    padding: 3rem 5rem 0 5rem;
    border-right: 1px solid #e6dddd;
    transition: 2s background;
    overflow: auto;

    & > :last-child {
        // fix padding bottom with overflow
        // https://stackoverflow.com/a/38177668
        margin-bottom: 3rem;
    }

    ${({ step }) => step !== PAYMENT_STEPS.INITIAL && css`
        padding: 1rem 5rem 0 5rem;
        background-color: #FFFFFF;
        border: 1px solid #d6d6d6;
    `}

    @media (max-width: 1100px) {
        padding: 1rem 1.2rem;
        border-right: 0;
        overflow: visible;

        & > :last-child {
            // fix padding bottom with overflow
            // https://stackoverflow.com/a/38177668
            margin-bottom: 1rem;
        }

        ${({ step }) => step !== PAYMENT_STEPS.INITIAL && css`
            display: none;
        `}
    }
`;

const CarTitleDesktopWrapper = styled.div`
    display: block;
    font-size: 2rem;
    animation: ${coolSlideRight} 0.8s ease-in-out 1 both;

    @media (max-width: 1100px) {
        display: none;
    }
`;

const CarTitleMobileWrapper = styled.div`
    display: none;
    font-size: 2rem;
    line-height: 110%;
    padding: 1em 0.5em;
    text-align: center;
    background: #ffffff;
    border-bottom: 1px solid #e6dddd;

    @media (max-width: 1100px) {
        display: ${({ step }) => step === PAYMENT_STEPS.INITIAL ? 'block' : 'none'};
    }
`;

export const SaleDetailsSubTitle = styled.div`
    font-size: 1rem;
    color: gray;
    font-weight: bold;
    animation: ${coolSlideRight} 0.8s ease-in-out 1 both;
`;

const CarTitleContainer = styled.span`
    display: inline-block;
    direction: ltr;
`;

const CarTitleBrand = styled.span`
    display: inline-block;
    margin-right: 7px;
    vertical-align: middle;
`;

const CarTitleModel = styled.span`
    display: inline-block;
    font-weight: bold;
    vertical-align: middle;
`;

const Style = {
    Container,
    SaleDetails,
    SaleDetailsSubTitle,
    CarImageContainer,
    CarTitleDesktopWrapper,
    CarTitleMobileWrapper,
    CarTitleContainer,
    CarTitleBrand,
    CarTitleModel,
};

export default Style;
