import styled, { css } from 'styled-components';
import CONFIG from '../../../../../../config';
import { COLORS, ICONS_NAMES } from '../../../../../../constants';
import { getIconFromAssets } from '../../../../../../utils/assetsUtils';
import { nisCurrencySign } from '../../../../../common/styles';

const checkMarkIcon = getIconFromAssets(ICONS_NAMES.CHECK_MARK_WHITE);

const { AUDI_ADDON_SEPERATOR } = COLORS;

const { DEVICE_SIZE } = CONFIG;

export const AddOnItem = styled.div`
    display: flex;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid;
    border-color: ${AUDI_ADDON_SEPERATOR};
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
        margin-right: 20px;
        padding-bottom: 30px;
        margin-top: ${({ $isGroupSelected }) => !$isGroupSelected ? '0px' : '20px'};
    }
`;

export const CheckBox = styled.span`
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid lightgray;
    display: inline-block;
    position: relative;
    &:hover{
        cursor: pointer;
    }
    ${({ $isSelected }) => $isSelected && css`
        background: ${({ theme }) => theme.checkMarkBackgroundColor};
        background-image: url(${checkMarkIcon});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 70%;
    `}
`;

export const InformationContainer = styled.div`
    width: 90%;
    margin-right: 5px;
`;

export const Title = styled.h4`
    margin: 0;
`;

export const MoreInfoContainer = styled.div`
    margin: 0;
`;

export const MoreInfo = styled.div`
    margin: 0;
    max-width: 75ch;
    .description {
        font-weight: 400;
    }
    ${({ $isReadMoreVisible }) => !$isReadMoreVisible && css`
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    `}
`;

export const MoreInfoButton = styled.button`
    border: none;
    background-color: transparent;
    text-decoration: underline;
    font-weight: 700;
    margin-bottom: 35px;
    font-size: 16px;
    padding: 0;
    ${({ $isReadMoreVisible }) => $isReadMoreVisible && css`
        margin: 20px 0 35px 0;
    `}
`;

export const Price = styled.p`
    margin: 0;
    margin-bottom: 5px;
    font-size: 20px;
    ${nisCurrencySign};
`;
