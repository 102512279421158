import styled from 'styled-components';
import CONFIG from '../../../../../config';
import { COLORS } from '../../../../../constants';
import { pxToRem } from '../../../../../utils/commonUtils';
import { Figure } from '../../ModelImage/ModelImage.styles';
import {
    PriceTitle as PT,
    Price as P,
    FullPriceWrapper as FPW,
} from '../FullPriceAndTooltip/FullPriceAndTooltip.styles';

const { DEVICE_SIZE } = CONFIG;

export const HeaderMobile = styled.header.attrs(() => ({
    role: 'button',
}))`
display:none;
border-bottom: 1px solid #d8d8d8;
padding-bottom: 10px;
text-decoration: none;
cursor: pointer;
display: none;

${Figure}{
  width:45%;
  display: flex;
}



@media(max-width:${DEVICE_SIZE.MEDIUM}px){
  display:flex;
}
`;
export const Information = styled.div`
  flex:1;
  padding:0 10px;
`;

export const Title = styled.p`
  margin:0;
  font-size:${pxToRem(18)};
  font-weight:bold;
  color:${COLORS.COLOR_MODEL_ITEM_TITLE};
  line-height:1.5;
`;
export const Description = styled.p`
  margin:0;
  font-size:${pxToRem(15)};
  line-height:1.5;
`;

export const PriceTitle = styled(PT)``;

export const Price = styled(P)`
    font-size:${pxToRem(21)};
    line-height:1.5;
`;

export const FullPriceWrapper = styled(FPW)``;

export const NisSymbol = styled.span`
font-size: 22px;
`;
