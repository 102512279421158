import styled from 'styled-components';
import { COLORS } from '../../constants';
import { pxToRem } from '../../utils/commonUtils';
import { CheckBox } from '../CheckBox/CheckBox.styles';

export const CheckBoxFormGroup = styled.div`
  display:flex;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  &:not(:last-child){
    margin-bottom:14px;
  }
  ${CheckBox} {
    margin-top: 2px;
  }
`;
export const Label = styled.label`
  font-size:${pxToRem(15)};
  margin: 0 15px;
  flex:1;
  color:${({ $isErrorShown }) =>  $isErrorShown ? COLORS.COLOR_ERROR_CONTACT_US_FIELD  : COLORS.BLACK};
  span a {
  color:${({ $isErrorShown }) =>  $isErrorShown ? COLORS.COLOR_ERROR_CONTACT_US_FIELD  : COLORS.BLACK};
 }
`;
export const ErrorMessage = styled.p`
  font-size:${pxToRem(14)};
  font-weight:500;
  margin: 7px 0 0 0;
  color:${COLORS.COLOR_ERROR_CONTACT_US_FIELD};
  flex: 1 1 100%;
`;

export const MoreInfoButton = styled.button.attrs({
    type: 'button',
})`
  cursor: pointer;
  margin-right: 0.5rem;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  font-weight: 700;
  margin-bottom: 35px;
  font-size: 16px;
  padding: 0;
`;
