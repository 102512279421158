import styled from 'styled-components';
import { COLORS } from '../../../constants';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const ContactUs = styled.div`
  background-color:${COLORS.BG_COLOR_CONTACT_US_PAGE};
  padding:60px 0;
`;
export const ContentContainer = styled.div`
  width: 80%;
  min-height: 716px;
  display: flex;
  background-color: ${COLORS.WHITE};
  box-shadow: 0 1px 40px rgb(0 0 0 / 10%), 0 1px 52px rgb(0 0 0 / 20%);
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    width: auto;
    background-color: #cccccc;
    box-shadow: none;
  }
`;

export const LoaderContainer = styled.div`
width: 80%;
height: 716px;
display: flex;
justify-content: center;
align-items: center;
background-color: ${COLORS.WHITE};
box-shadow: 0 1px 40px rgb(0 0 0 / 10%), 0 1px 52px rgb(0 0 0 / 20%);
`;
