import PropTypes from 'prop-types';

const onlineCarItemShape = {
    modelGroup: PropTypes.string.isRequired,
    modelDescription: PropTypes.string.isRequired,
    trimLevel: PropTypes.string.isRequired,
    trimLevelCode: PropTypes.number.isRequired,
    hebrewModelGroup: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string,
    mainCharacteristics: PropTypes.array.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    licenseFee: PropTypes.number.isRequired,
    pollutionLevel: PropTypes.array.isRequired,
    safetyEquipmentLevel: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
};

export default onlineCarItemShape;
