/* eslint-disable max-len */

import React, { useEffect } from 'react';
import InnerPagesHeader from '../../../components/InnerPages/InnerPagesHeader/InnerPagesHeader';
import InnerPageContainer from '../../../components/InnerPages/InnerPageContainer';
import FaqAccordion from '../../../components/FaqAccordion/FaqAccordion';
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer';
import HelmetTitleDescription from '../../../components/HelmetTitleDescription/HelmetTitleDescription';
import { useCurrentAppCommonData, useActionDispatch } from '../../../hooks';

import STRINGS from '../../../language';
import { TITLE_POSITION } from '../../../constants';
import HowToBuyAVehicle from './HowToBuyAVehicle/HowToBuyAVehicle';
import StepCards from './HowToBuyAVehicle/StepCards/StepCards';
import StepsSlider from './HowToBuyAVehicle/StepsSlider/StepsSlider';
import { CARDS_CONFIG } from './HowToBuyAVehicle/StepCards/StepCard/stepCards.config';
import faqItems from './FaqItems';
import * as Styled from './Faq.styles';

const Faq = () => {
    const { getMutagVideoForFAQ } = useActionDispatch();
    const { currentBrand, videoForFaq } = useCurrentAppCommonData();

    useEffect(() => {
        getMutagVideoForFAQ(currentBrand);
    }, []);
    
    return (
        <Styled.Faq>
            <HelmetTitleDescription canonical={ window.location.href } />
            <InnerPagesHeader title={ STRINGS.HOW_DOES_IT_WORKS } position={ TITLE_POSITION.BOTTOM } />
            <InnerPageContainer>
                <HowToBuyAVehicle />
                <StepCards data={ CARDS_CONFIG } />
                <StepsSlider cards={ CARDS_CONFIG } />
                <VideoPlayer videoSource={ videoForFaq } />
                <Styled.QuestionsContainer>
                    <Styled.Text>
                        <Styled.Title>{STRINGS.YOU_HAVE_A_QUESTION}</Styled.Title>
                        <Styled.Paragraph>
                            {STRINGS.WE_HAVE_ANSWERS}
                        </Styled.Paragraph>
                    </Styled.Text>
                    <FaqAccordion faqItems={ faqItems(currentBrand) } />
                </Styled.QuestionsContainer>
            </InnerPageContainer>
        </Styled.Faq>
    );
};

export default Faq;
