import styled, { css } from 'styled-components';
import { COLORS, ICONS_NAMES } from '../../../../../../constants';
import { lineThrough, nisCurrencySign } from '../../../../../common/styles';
import { pxToRem } from '../../../../../../utils/commonUtils';
import { getIconFromAssets } from '../../../../../../utils/assetsUtils';

const checkMarkIcon = getIconFromAssets(ICONS_NAMES.CHECK_MARK_WHITE);

export const AddOnItem = styled.li`
  transform: scale(1, 0);
  transition: all 0.3s;
  padding: 0;
  max-height: 0;
  border-bottom: 1px solid ${COLORS.INTERNAL_COLOR_ITEM_BORDER_BOTTOM};
  ${({ $isActive }) => $isActive
    && css`
      max-height: 500px;
      padding: 30px 0;
      transform: scale(1, 1);
      &:first-child {
        padding-top: 0;
      }
    `}
  ${({ $isDisabled }) => $isDisabled
    && css`
      opacity: 0.3;
    `}
`;

export const AddOn = styled.div`
  display: flex;
  align-items: center;
`;

export const Picker = styled.div`
  width: 85px;
  height: 85px;
  flex: 0 0 85px;

  background-color: transparent;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: ${({ theme }) => theme.configuratorStepItemBorderRadius};
  background-image: url(${({ $image }) => $image});
  position: relative;
  cursor: pointer;
  ${({ $isDisabled }) => $isDisabled
    && css`
      cursor: not-allowed;
    `}
  ${({ $isSelected }) => $isSelected
    && css`
      box-shadow: 0 1px 8px 1px rgb(117 108 88 / 64%);
      border: 3px solid ${COLORS.ADD_ON_ITEM_COLOR_BORDER};
      &:after {
        content: "";
        width: 24px;
        height: 24px;
        position: absolute;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.primaryColor};
        background-image: url(${checkMarkIcon});
        background-repeat: no-repeat;
        background-position: center;
        /***************************************/
        ${({ theme }) => {
        const { configuratorStepItemBorderRadius: radius } = theme;

        let translate = 0;

        let left = '-8px';

        if (radius !== '50%') {
            left = 0;
            translate = '-45%, -45%';
        }

        return css`
            transform: translate(${translate});
            left: ${left};
          `;
    }};

        /**************************************/
      }
    `}
`;

export const Details = styled.div`
  margin-right: 30px;
`;

const Text = styled.p`
  margin: 0;
  font-weight: 700;
  padding: 5px;
`;

export const Title = styled(Text)`
  font-size: ${pxToRem(16)};
  direction: ltr;
`;
export const MoreInformation = styled.button`
  font-size: ${pxToRem(13)};
  text-decoration: underline;
  padding: 0 5px 5px 5px;
  font-weight: 700;
  background-color: transparent;
  border: none;
  margin: 0;
  outline: none;
  color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;
`;

export const Price = styled(Text)`
  font-size: ${pxToRem(13)};
  position: relative;
  ${nisCurrencySign};
  ${({ $showDiscount }) => $showDiscount
    && css`
      ${lineThrough}
    `};
`;

export const PricesContainer = styled.div`
  display: flex;
`;
