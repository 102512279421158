import defaultAxios from 'axios';
import { NEW_API_METHODS } from '../constants';
import { getAddOnsMoreInfoHtmlUri } from '../utils/assetsUtils';
import { replaceUrlParams } from '../utils/fetchUtils';
import axios from './axios';

const {
    GET_INTERNAL_COLORS,
    GET_RIMS,
    GET_LOCAL_GROUPS,
    GET_FINAL_PRICE,
    GET_EXTERNAL_COLORS,
} = NEW_API_METHODS.CONFIGURATOR;

const {
    GET_MODELS_ADDITIONAL_OPTIONS,
    GET_POLLUTION_AND_SAFETY_DETAILS_V2, // TODO: will be deprecated!,
    GET_GROUP_POLLUTION_AND_SAFETY_DETAILS_V2, // TODO: will be deprecated!,
} = NEW_API_METHODS.VEHICLE;

/**
 * 1. responsible for getting configurationData 
 * 2. equivalent (Champion) api call is getModelDetails
 */

/**
 * @GET request
 * @param {string} modelCode (champion equivalent - sModel) 
 * @param {number} styleLevelCode (champion equivalent - sStyleLevelCode)
 * @param {string} OriginalAdditions
 * @returns car{}
 */

export const getExternalColors = async (
    modelCode,
    trimLevelCode,
    OriginalAdditions,
) => {
    const getModelDetailsUrl = replaceUrlParams(GET_EXTERNAL_COLORS, { modelCode });

    const result = await axios.get(getModelDetailsUrl, {
        params: {
            StyleLevelCode: trimLevelCode,
            OriginalAdditions,
        },
    });

    return result;
};

/**
 * 
 * @param {string} modelCode (champion equivalent - sModel) 
 * @param {string} externalColorCode (champion equivalent - sExternalColor) 
 * @param {number} trimLevelCode (champion equivalent - sStyleLevelCode) 
 * @param {string} sOriginalAdditions (unique identifier/id of the chosen package
 */

export const getInternalColors = async (
    modelCode,
    externalColorCode,
    trimLevelCode,
    sOriginalAdditions,
) => {
    const getInternalColorsUrl = replaceUrlParams(GET_INTERNAL_COLORS, {
        modelCode,
    });

    const result = await axios.get(getInternalColorsUrl, {
        params: {
            colorCode: externalColorCode,
            styleLevelCode: trimLevelCode,
            originalAdditions: sOriginalAdditions,
        },
    });

    return result;
};

/**
 * @param {string} modelCode (champion equivalent - sModel) 
 * @param {number} trimLevelCode (champion equivalent - sStyleLevelCode) 
 * @param {string} colorCode (champion equivalent - sColor) 
 */

export const getOriginalPackages = async (
    modelCode,
    trimLevelCode,
    externalColorCode,
    internalColorCode,
    originalAdditions,
) => {
    const getOriginalPackagesUrl = replaceUrlParams(GET_RIMS, {
        modelCode,
    });

    const result = await axios.get(getOriginalPackagesUrl, {
        params: {
            externalColorCode,
            UpholsteryColorCode: internalColorCode,
            styleLevelCode: trimLevelCode,
            originalAdditions,
        },
    });

    return result;
};

/**
 * @param {string} modelCode (champion equivalent - sModel) 
 * @param {string} sOriginalAdditions
 * @param {number} carVersion (champion equivalent - sVersion) 
 */

export const getModelLocalGroups = async (modelCode, sOriginalAdditions, carVersion) => {
    const getModelLocalGroupsUrl = replaceUrlParams(GET_LOCAL_GROUPS, {
        modelCode,
    });

    const result = await axios.get(getModelLocalGroupsUrl, {
        params: {
            originalAdditions: sOriginalAdditions,
            version: carVersion,
        },
    });

    return result;
};

/**
 * champion equivalent - @api call: GetCash)
 * @param {string} brand (champion equivalent - sMutag)
 * @param {string} modelCode (champion equivalent - sModel) 
 * @param {string} internalColorCode (champion equivalent - sColor) 
 * @param {number} carVersion (champion equivalent - sVersion) 
 * @param {string} chosenHoopsString  (champion equivalent - sTosMkr) 
 * @param {string} chosenAddOnsArray  (champion equivalent - sTosMkm) 
 */

export const getFinalDiscountPrice = async (
    modelCode,
    externalColorCode,
    internalColorCode,
    carVersion,
    chosenHoopsString,
    chosenAddOnsArray,
) => {
    const getFinalDiscountPriceUrl = replaceUrlParams(GET_FINAL_PRICE, {
        modelCode,
    });

    const data = {
        externalColorCode,
        upholsteryColorCode: internalColorCode,
        version: carVersion,
        originalAdditions: [chosenHoopsString],
        localAdditions: chosenAddOnsArray,
    };
    
    const result = await axios.post(getFinalDiscountPriceUrl, data);

    return result;
};

export const getAddOnMoreInformationHtml = async (brand, addOnLocalCode) => {
    const addOnHtmlUri = getAddOnsMoreInfoHtmlUri(brand, addOnLocalCode);
    // const options = { headers: { ContentType: 'text/plain' } };
    const result = await defaultAxios.get(addOnHtmlUri);

    return result;
};

export const getOriginalAdditionDetails = async (dataLink) => {
    const { data } = await defaultAxios.get(dataLink);

    return data;
};

/**
 * @GET request
 * @param {string} brand (champion equivalent - sMutag)
 * @param {string} modelCode (champion equivalent - sModel)
 * @param {string} StyleLevelCode (champion equivalent - sStyleLevelCode)
 * @returns Object { groupModelDescription, styleLevelDescription, standard, safetySystems: [ ] }
 */
export const GetPollutionAndSafetyDetails = async (
    brand,
    modelCode,
    StyleLevelCode,
) => {
    const getPollutionAndSafetyUrl = replaceUrlParams(
        GET_POLLUTION_AND_SAFETY_DETAILS_V2,
        { modelCode },
    );

    const result = await axios.get(`${getPollutionAndSafetyUrl}`, {
        params: {
            StyleLevelCode,
        },
    });

    return result;
};

/**
 *  @GET request
 * @param {string} brand (champion equivalent - sMutag)
 * @param {string} groupModelCode (champion equivalent - sModel)
 * @returns Object { groupModelDescription, styleLevelDescription, standard, safetySystems: [ ] } To Do: update the return object
 */
export const GetGroupPollutionAndSafetyDetails = async (
    groupModelCode,
) => {
    const getPollutionAndSafetyUrl = replaceUrlParams(
        GET_GROUP_POLLUTION_AND_SAFETY_DETAILS_V2,
        { groupModelCode },
    );

    const result = await axios.get(`${getPollutionAndSafetyUrl}`);

    return result;
};

/**
 *  @GET request
 * @param {string} groupModelCode (champion equivalent - sModel)
 * @param {string} modelCode (champion equivalent - modelCode)
 * @param {string} styleLevelCode (champion equivalent - trimLevel)
 * @returns Object TODO: update the return object
 */
export const getModelsAdditionalOptions = async (
    groupModelCode,
    modelCode,
    styleLevelCode,
) => {
    const getModelsAdditionalOptionsUrl = replaceUrlParams(
        GET_MODELS_ADDITIONAL_OPTIONS,
        { groupModelCode, modelCode },
    );

    const result = await axios.get(getModelsAdditionalOptionsUrl, {
        params: {
            styleLevelCode,
        },
    });

    return result;
};
