/* eslint-disable no-shadow */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router';
import {
    useCurrentAppCommonData,
    useCurrentCar,
    useCurrentBrand,
    useActionDispatch,
    useSelectedCurrentData,
    useCurrentConfigOptions,
} from '../../../hooks';
import {
    getManufacturerContactInfo,
    replacePlaceholdersInTemplate,
} from '../../../utils/commonUtils';
import { CONFIGURATOR_STEPS } from '../../../constants';
import STRINGS from '../../../language';
import Safety from './Safety/Safety';
import Pollution from './Pollution/Pollution';
import Specifications from './Specifications/Specifications';
import Comparison from './Comparison/Comparison';
import * as Styled from './ConfiguratorFooter.styles';

const {
    ENGINE_AND_TRIM_LEVEL,
    EQUIPMENT_PACKAGES,
    SUMMARY,
} = CONFIGURATOR_STEPS;

const ConfiguratorFooter = () => {
    const { pathname, search } = useLocation();
    const carsRef = useRef(null);
    const { modelGroup: modelGroupFromUrl } = useParams();
    const urlParams = new URLSearchParams(search);
    const modelGroupCodeFromUrl  = urlParams.get('modelGroupCode');
    const { onlineCars } = useCurrentAppCommonData();
    const { pollutionAndSafetyDetails, subModels: subModelsState } = useCurrentConfigOptions();
    const { dataList } = pollutionAndSafetyDetails;
    const { subModels } = subModelsState;
    const currentSelectedCar = useCurrentCar();
    const isEngineAndTrimLevelModelsPage = pathname.includes('/configurator/models/');
    const brand = useCurrentBrand();
    const {
        setSpecificationParams,
        toggleSpecificationsModal,
        getPollutionAndSafetyDetailsAsync,
    } = useActionDispatch();
    const { PHONE: contactPhoneNumber } = getManufacturerContactInfo(brand);
    const forFurtherInfo = replacePlaceholdersInTemplate(
        STRINGS.FOR_FURTHER_INFORMATION_PLEASE_CONTACT,
        [contactPhoneNumber],
    );

    const {
        selectedStep: currentStep,
        selectedCar,
    } = useSelectedCurrentData();

    const {
        modelCode,
        trimLevelCode: StyleLevelCode,
        modelGroupCode: selectedCarModelGroupCode,
        modelGroup: selectedCarModelGroup,
        isBeforeManufacture,
        manufactureText,
    } = selectedCar;
    
    const modelGroup = modelGroupFromUrl ?? selectedCarModelGroup;
   
    const modelFirstCar = onlineCars?.find(
        (car) => car.modelGroup.toLowerCase().replaceAll(' ', '-') === modelGroup?.toLowerCase(),
    );

    const modelGroupCode = modelFirstCar?.modelGroupCode ?? selectedCarModelGroupCode;

    const regulationInfo = dataList[0]?.standard;
    const groupModelDescription = dataList[0]?.groupModelDescription;
    const allowedStepsToGetPollutionAndSafety = [
        ENGINE_AND_TRIM_LEVEL, EQUIPMENT_PACKAGES, SUMMARY];

    useEffect(() => {
        const isShouldGetGroup = currentStep === ENGINE_AND_TRIM_LEVEL;

        if (allowedStepsToGetPollutionAndSafety.includes(currentStep)) {
            // prevent sending weird undefined when changing groupModels
            if (modelGroupFromUrl || modelGroupCode) {
                getPollutionAndSafetyDetailsAsync(
                    isShouldGetGroup,
                    brand,
                    modelCode,
                    StyleLevelCode,
                    modelGroupCode,
                );
            }
        }
    }, [currentStep, modelGroupFromUrl]);
    
    const getCarsToRender = () => {
        let carsToRender = [];

        if (isEngineAndTrimLevelModelsPage) {
            carsToRender = onlineCars.filter(
                (car) => car.modelGroup.toLowerCase().replaceAll(' ', '-')
                    === modelGroup?.toLowerCase());
        } if (currentStep === EQUIPMENT_PACKAGES) {
            carsToRender = subModels.filter(
                (car) => {
                    return parseInt(car.modelGroupCode) === parseInt(modelGroupCodeFromUrl);
                });
        } if (currentStep > EQUIPMENT_PACKAGES) {
            carsToRender.push(currentSelectedCar);
        }

        return carsToRender;
    };

    carsRef.current = getCarsToRender();

    return (
        <Styled.ConfiguratorFooter>
            <Styled.Content>
                <Styled.ComparisonAndSpecifications>
                    <Specifications
                        // eslint-disable-next-line no-shadow
                        onClick={
                            (
                                modelGroupCode,
                                modelCode,
                                modelDescription,
                                originalAdditionsCode,
                                version,
                                trimLevelCode,
                                id,
                            ) => {
                                setSpecificationParams(
                                    modelGroupCode,
                                    modelCode,
                                    modelDescription,
                                    originalAdditionsCode,
                                    version,
                                    trimLevelCode,
                                    id,
                                );
                                toggleSpecificationsModal();
                            }
                        }
                        cars={ carsRef.current }
                        modelGroupDescription={ groupModelDescription }
                    />
                    <Comparison
                        brand={ brand }
                        modelGroupCode={ modelGroupCode }
                        modelGroupDescription={ groupModelDescription }
                    />
                    <Styled.Text> {forFurtherInfo} </Styled.Text>
                </Styled.ComparisonAndSpecifications>
                <Styled.PollutionAndSafety>
                    <Safety
                        cars={ carsRef.current }
                        pollutionAndSafetyDetails={ pollutionAndSafetyDetails }
                        safetyEquipmentLevel={ currentSelectedCar.safetyEquipmentLevel }
                        isBeforeManufacture={ isBeforeManufacture }
                    />
                    <Pollution
                        cars={ carsRef.current }
                        pollutionLevel={ currentSelectedCar.pollutionLevel }
                        regulation={ regulationInfo }
                        isBeforeManufacture={ isBeforeManufacture }
                        manufactureText={ manufactureText }
                    />
                </Styled.PollutionAndSafety>
            </Styled.Content>
        </Styled.ConfiguratorFooter>
    );
};

export default ConfiguratorFooter;
