import React, {
    useState, useRef, useLayoutEffect,
} from 'react';
import ScrollMoreOverlay from '../../ScrollMoreOverlay/ScrollMoreOverlay';
import ModelGroupItemsList from '../../AudiConfigurator/Steps/Model/ModelGroupItemsList/ModelGroupItemsList';
import { useCurrentAppCommonData, useScrollPosition, useCurrentConfigOptions } from '../../../hooks';
import STRINGS from '../../../language';
import {
    FILTER_BY,
    SCROLL_OFFSET,
    AUDI_SCROLL_OFFSET,
    TITLE_FIELD,
    MODEL_SCROLL_OFFSET,
} from '../../../constants';
import {
    getFullManufacturer,
    replacePlaceholdersInTemplate,
} from '../../../utils/commonUtils';
import { isShowOvarlay, parseOnlineCarsByModelGroups } from './Model.utils';
import * as Styled from './Model.styles';

const Model = () => {
    const [toggleModelGroup, setToggleModelGroup] = useState(null);
    const [modelListBounding, setModelListBounding] = useState({});
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const appCommonData = useCurrentAppCommonData();
    const { pollutionAndSafetyDetails: { isLoading } } = useCurrentConfigOptions();
    const scrollPosition = useScrollPosition();
    const {
        onlineCars, currentBrand, isCupra, isAudi,
    } = appCommonData;
    const manufacturerName = getFullManufacturer(currentBrand, 'hebrew');
    const modelTitle = replacePlaceholdersInTemplate(isAudi
        ? STRINGS.CHOOSE_YOUR_BRAND_WITH_DASH : STRINGS.CHOOSE_YOUR_BRAND, [manufacturerName]);
    const modelGroupsItemsByModel = parseOnlineCarsByModelGroups(onlineCars, FILTER_BY.BY_MODEL);
    const modelListNode = useRef();
    const modelGroupRef = useRef();
    const scrollOffset = isAudi ? AUDI_SCROLL_OFFSET : SCROLL_OFFSET;

    const toggleModelGroupHandler = (groupId, modelGroupNode) => {
        const selectedGroupId = toggleModelGroup === groupId ? null : groupId;

        modelGroupRef.current = modelGroupNode;
        setToggleModelGroup(selectedGroupId);
    };

    useLayoutEffect(() => {
        if (toggleModelGroup) {
            window.scrollTo({
                left: 0,
                top: modelGroupRef.current.offsetTop + MODEL_SCROLL_OFFSET,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
            setIsOverlayVisible(false);
        }
    }, [toggleModelGroup]);

    useLayoutEffect(() => {
        if (toggleModelGroup && isShowOvarlay(modelGroupsItemsByModel, toggleModelGroup)) {
            if (modelGroupRef.current.offsetTop < scrollPosition
                && scrollPosition < modelGroupRef.current.offsetTop + scrollOffset) {
                setIsOverlayVisible(true);
            } else {
                setIsOverlayVisible(false);
            }
        }
    }, [scrollPosition]);
    
    useLayoutEffect(() => {
        const handleResize = () => {
            if (modelListNode.current) {
                setModelListBounding(modelListNode.current.getBoundingClientRect());
            }
        };
            
        window.addEventListener('resize', handleResize);

        handleResize();

        return () =>    {
            window.removeEventListener('resize', handleResize);
        };
    }, [modelListBounding.width]);
    
    return (
        <Styled.Model>
            <Styled.ModelHeader>
                <Styled.ModelTitle>{modelTitle}</Styled.ModelTitle>
            </Styled.ModelHeader>
            <ModelGroupItemsList
                nodeRef={ modelListNode }
                items={ modelGroupsItemsByModel }
                currentBrand={ currentBrand }
                isCupra={ isCupra }
                modelGroupItemTitle={ TITLE_FIELD.MANUFACTURER }
                modelListBounding={ modelListBounding }
                toggleModelGroup={ toggleModelGroup }
                toggleModelGroupHandler={ toggleModelGroupHandler }
            />
            <ScrollMoreOverlay isVisible={ isOverlayVisible } text={ STRINGS.MORE_MODELS } />
        </Styled.Model>
    );
};

export default Model;
