/* eslint-disable max-len */
import React, { useEffect, useState, useMemo } from 'react';
import HelmetTitleDescription from '../../../components/HelmetTitleDescription/HelmetTitleDescription';
import {
    useActionDispatch,
    useCurrentAppCommonData,
    useSpecifications,
} from '../../../hooks';
import {
    getCarPlaceholderImageUrl,
    getHomePageThumbnail,
    getModelGroupCatalogLink,
} from '../../../utils/assetsUtils';
import { isArrayAndNotEmpty, isArrayEmpty } from '../../../utils/commonUtils';
import STRINGS from '../../../language';
import SpecificationsData from './SpecificationsData/SpecificationsData';
import MainTabs from './MainTabs/MainTabs';
import Header from './Header/Header';
import * as Styled from './Specifications.styles';

const { EQUIPMENT, ENGINE, MEASUREMENTS } = STRINGS;
const TABS = [EQUIPMENT, ENGINE, MEASUREMENTS];

const Specifications = () => {
    const {
        onlineCars,
        currentBrand: brand,
    } = useCurrentAppCommonData();
    const {
        equipment,
        engine,
        measurements,
        groupModelCode,
        modelCode,
        modelDescription,
        originalAdditionsCode,
        version: carVersion,
        trimLevelCode,
    } = useSpecifications();

    const {
        getEquipmentSpecificationsAsync,
        getMeasurementsSpecificationsAsync,
        getEngineSpecificationsAsync,
    } = useActionDispatch();
    const [tabs, setTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(null);
    const catalogUrl = useMemo(() => onlineCars.find(
        (car) => car.modelGroupCode === groupModelCode)?.catalogLink
        || getModelGroupCatalogLink(brand, groupModelCode),
    []);

    const selectTabHandler = (tab) => {
        if (selectedTab !== tab) {
            setSelectedTab(tab);
        }
    };

    const getAllSpecifications = async () => {
        const results = await Promise.all([
            getEquipmentSpecificationsAsync(modelCode, trimLevelCode, carVersion),
            getEngineSpecificationsAsync(modelCode, trimLevelCode, carVersion),
            getMeasurementsSpecificationsAsync(groupModelCode),
        ]);

        const tabsResult = results?.reduce((acc, currValue, index) => {
            if (isArrayAndNotEmpty(currValue)) {
                acc.push(TABS[index]);
            }

            return acc;
        }, []) ?? [];

        setTabs(tabsResult);
        if (!isArrayEmpty(tabsResult)) {
            setSelectedTab(tabsResult[0]);
        }
    };

    const renderSpecificationsPage = () => {
        return (
            <>
            
                <Header
                    carImageFallBackSrc={ getCarPlaceholderImageUrl(brand) }
                    carImage={ getHomePageThumbnail(brand, groupModelCode, trimLevelCode) }
                    modelDescription={ modelDescription }
                />
            
                <MainTabs
                    selectedTab={ selectedTab }
                    onSelect={ selectTabHandler }
                    catalogUrl={ catalogUrl }
                    tabs={ tabs }
                />
                
                <Styled.SpecificationsDataContainer>
                    <SpecificationsData
                        specifications={ {
                            equipment,
                            engine,
                            measurements,
                        } }
                        selectedTab={ selectedTab }
                    />
                </Styled.SpecificationsDataContainer>
            </>
        );
    };

    useEffect(() => {
        getAllSpecifications();
    }, [originalAdditionsCode]);
    
    return (
        <Styled.Specifications>
            <HelmetTitleDescription canonical={ window.location.href }>
                <meta name="robots" content="noindex,nofollow" />
            </HelmetTitleDescription>
            {renderSpecificationsPage()}
        </Styled.Specifications>
    );
};

export default Specifications;
