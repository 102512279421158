import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../language';
import * as Styled from './AddOrRemoveButton.styles';

export default function AddOrRemoveButton({
    onClickHandler, isSelected, width,
}) {
    const addOrRemoveText = isSelected ? STRINGS.REMOVE : STRINGS.ADD;

    return (
        <Styled.AddOrRemoveButton onClick={ onClickHandler } $isSelected={ isSelected } width={ width }>
            { addOrRemoveText }
        </Styled.AddOrRemoveButton>
    );
}

AddOrRemoveButton.defaultProps = {
    width: '',
};

AddOrRemoveButton.propTypes  = {
    isSelected: PropTypes.bool.isRequired,
    width: PropTypes.string,
    onClickHandler: PropTypes.func.isRequired,
};
