import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './FaqParagraph.styles';

const FaqParagraph = ({ text }) => {
    return <Styled.FaqParagraph>{text}</Styled.FaqParagraph>;
};

FaqParagraph.defaultProps = {
    text: '',
};
FaqParagraph.propTypes = {
    text: PropTypes.string,
};

export default FaqParagraph;
