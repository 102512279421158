import React from 'react';
import PropTypes from 'prop-types';
import {
    useCurrentAddOns,
    useCurrentAddOnGroup,
    useIconFromAssets,
} from '../../../../../hooks';
import { getIconFromAssets } from '../../../../../utils/assetsUtils';
import { ICONS_NAMES } from '../../../../../constants';
import AddOnItem from './AddOnItem/AddOnItem';
import * as Styled from './AddOnGroup.styles';

const AddOnGroup = ({
    title,
    onSelect,
    onSelectAddOn,
    isActive,
    addOnItems,
    groupCode,
    onOpenMoreInfoModal,
}) => {
    const selectedAddOns = useCurrentAddOns();
    const selectedAddOnGroup = useCurrentAddOnGroup();

    let arrowIcon = useIconFromAssets(ICONS_NAMES.MENU_ARROW_DOWN);

    if (!isActive) {
        arrowIcon = getIconFromAssets(ICONS_NAMES.MENU_ARROW_DOWN_BLACK);
    }

    const _addOnItems = addOnItems.map((addOnItem) => {
        const {
            price,
            description,
            groupDescription,
            familyCode,
            image,
            local,
            priceAfterDiscount,
            isDiscount,
        } = addOnItem;
        const isSelected = selectedAddOns[selectedAddOnGroup?.groupCode]?.title
        === description;
        const isDisabled = selectedAddOns[selectedAddOnGroup?.groupCode] && !isSelected;
    
        return (
            <AddOnItem
                title={ description }
                groupDescription={ groupDescription }
                price={ parseInt(price) }
                priceAfterDiscount={ parseInt(priceAfterDiscount) }
                isDiscount={ isDiscount }
                onOpenMoreInfoModal={ onOpenMoreInfoModal }
                onSelect={ onSelectAddOn }
                local={ local }
                isGroupActive={ isActive }
                isSelected={ isSelected }
                isDisabled={ isDisabled }
                familyCode={ familyCode }
                groupCode={ groupCode }
                image={ image }
                key={ description }
            />
        );
    });

    return (
        <Styled.AddOnGroupItem $isActive={ isActive }>
            <Styled.AddOnGroup>
                <Styled.Title
                    $isActive={ isActive }
                    $arrowIcon={ arrowIcon }
                    onClick={ () => onSelect(groupCode) }
                    role="button"
                >
                    {title}
                </Styled.Title>
                <Styled.AddOnItems>{_addOnItems}</Styled.AddOnItems>
            </Styled.AddOnGroup>
        </Styled.AddOnGroupItem>
    );
};

AddOnGroup.defaultProps = {
    onSelect: () => {},
    onSelectAddOn: () => {},
    onOpenMoreInfoModal: () => {},
    isActive: false,
    addOnItems: [],
};

AddOnGroup.propTypes = {
    title: PropTypes.string.isRequired,
    onSelect: PropTypes.func,
    onSelectAddOn: PropTypes.func,
    onOpenMoreInfoModal: PropTypes.func,
    isActive: PropTypes.bool,
    groupCode: PropTypes.string.isRequired,
    addOnItems: PropTypes.array,
};

export default AddOnGroup;
