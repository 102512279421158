import { useState, useEffect } from 'react';

/**
 * 
 * @param {number} width
 * @returns {{width: number | undefined, height: number | undefined}} 
 */
function useWindowSize(width) {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
  
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
        // Set window width/height to state
           
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
      
        // Add event listener
        window.addEventListener('resize', handleResize);
      
        // Call handler right away so state gets updated with initial window size
        handleResize();
      
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, [width, windowSize.width]); // Empty array ensures that effect is only run on mount
  
    return windowSize;
}

/**
 * 
 * @param {number} width 
 */
function useIsMobile(width) {
    const size = useWindowSize(width);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (size.width > width && isMobile !== false) {
            setIsMobile(false);
        } else if (size.width <= width && isMobile !== true) {
            setIsMobile(true);
        }
    }, [isMobile, size.width, width]);

    return isMobile;
}

export default useIsMobile;
