import GET_MODEL_HEADER_MOCK from '../../MOCKDATA/getModelHeader.json';
import GET_MODEL_DETAILS_MOCK from '../../MOCKDATA/getModelDetails.json';
import GET_CAR_EQUIPMENT from '../../MOCKDATA/getCarEquipmentPackages.json';
import GET_INTERNAL_COLORS from '../../MOCKDATA/getInternalColors.json';
import GET_ORIGINAL_PACKAGES from '../../MOCKDATA/getOriginalPackages.json';
import GET_MODEL_LOCAL_GROUPS from '../../MOCKDATA/getModelLocalGroups.json';

const mocks = {
    // getModelHeader: GET_MODEL_HEADER_MOCK,
    // getModelDetails: GET_MODEL_DETAILS_MOCK,
    // getCarEquipmentPackages: GET_CAR_EQUIPMENT,
    // getInternalColors: GET_INTERNAL_COLORS,
    // getOriginalPackages: GET_ORIGINAL_PACKAGES,
    // getModelLocalGroups: GET_MODEL_LOCAL_GROUPS,
};
   
export const isUrlMocked = (url) => url in mocks;

export const getMockError = (config) => {
    const mockError = new Error();
    const { url } = config;

    mockError.mockData = mocks[url];
    mockError.config = config;
    
    return Promise.reject(mockError);
};

export const isMockError = (error) => Boolean(error.mockData);

export const getMockResponse = (mockError) => {
    const { mockData, config } = mockError;
    const { status, message } = mockData;
    // Handle mocked error (any non-2xx status code)

    if (status && String(status)[0] !== '2') {
        const err = new Error(message || 'mock error');

        err.code = status;
        
        return Promise.reject(err);
    }

    return Promise.resolve({
        data: mockData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config,
        isMock: true,
    });
};
