import styled, { css } from 'styled-components';
import { ICONS_NAMES } from '../../constants';
import { getIconFromAssets } from '../../utils/assetsUtils';

const closeIcon = getIconFromAssets(ICONS_NAMES.CLOSE_BLACK);

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  /* opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transition: opacity 300ms ease-in; */
`;

export const Layer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  z-index: 200;
`;

export const Modal = styled.div`
  position: absolute;
  top:50%;
  left:50%;
  max-width: 95%;
  padding: 15px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 3px 7px 14px rgb(0 0 0 / 50%);
  width: ${({ width }) => `${width}px`};
  min-height: ${({ height }) => `${height}px`};
  z-index: 210;
  transform: translate(-50%,-50%);
`;

export const Content = styled.div`
   padding: 35px;
  ${({ $isAudi }) => $isAudi && css`
   padding: 15px;
  `};
`;

export const CloseButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${closeIcon});
  background-size: contain;
  background-color: transparent;
  cursor: pointer;
  border-radius:50%;
  left:  ${({ $position }) => $position === 'left' ? '15px' : null};
`;

export const LoaderContainer = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height:100%;
  min-height:100%;
  width:100%;
  position: absolute;
  left: 0;
  right: 0;
 `;
