import styled from 'styled-components';
import CONFIG from '../../../../../../config';
import { ICONS_NAMES } from '../../../../../../constants';
import { getIconFromAssets } from '../../../../../../utils/assetsUtils';

const { DEVICE_SIZE } = CONFIG;

const closeIcon = getIconFromAssets(ICONS_NAMES.CLOSE_BLACK);

export const ModelItemsHeader = styled.div`
  display: none;
  height: 50px;
  width: 100%;
  padding: 0 20px;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #EFEFEF;
  }
`;

export const CloseButton = styled.button`
    width: 25px;
    height: 25px;
    margin-bottom: 1px;
    outline: none;
    border: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${closeIcon});
    background-size: contain;
    background-color: transparent;
`;

export const Title = styled.p`
    margin: 0;
    margin-right: 1rem;
    font-size: 26px;
    font-weight: bold;
`;
