import React from 'react';
import PropTypes from 'prop-types';
import { getStepTitle } from '../../../../utils/configuratorUtils';
import Styled from './ConfiguratorRight.style';

const ConfiguratorRight = ({
    component,
    currentStep,
    configOptions,
}) => {
    const ConfComponent = component || (() => null);

    return (
        <Styled.ConfigurationalRight>
            <Styled.Header>
                <Styled.Title key={ currentStep }>{getStepTitle(currentStep)}</Styled.Title>
            </Styled.Header>
            <Styled.Body>
                <ConfComponent configOptions={ configOptions } />
            </Styled.Body>
        </Styled.ConfigurationalRight>
    );
};

ConfiguratorRight.defaultProps = {
    component: null,
    currentStep: null,
};

ConfiguratorRight.propTypes = {
    component: PropTypes.elementType,
    currentStep: PropTypes.number,
    configOptions: PropTypes.object.isRequired,
};

export default ConfiguratorRight;
