import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    useCurrentConfigOptions,
    useActionDispatch,
    useCurrentCar,
    useCurrentHoop,
} from '../../../../hooks';
import { ICON_AND_TITLE_OFFSET } from '../../../../constants';
import AddOnsMenu from './AddOnsMenu/AddOnsMenu';
import AddOnsList from './AddOnsList/AddOnsList';
import MobileAddOns from './MobileAddOns/MobileAddOns';
import * as Styled from './AudiAddOnsStep.styles';

const AudiAddOnsStep = ({ onGoBack }) => {
    const { modelLocalGroups } = useCurrentConfigOptions();
    const { modelCode, carVersion } = useCurrentCar();
    const selectedHoop = useCurrentHoop();
    const hoopCode = selectedHoop.value;
    const addOnListContainerRef = useRef(null);
    const addOnGroupRef = useRef({});

    const scrollToAddOnsGroup = (groupCode) => {
        addOnListContainerRef.current.scrollTo({
            top: addOnGroupRef.current[groupCode].offsetTop + ICON_AND_TITLE_OFFSET,
            behavior: 'smooth',
        });
    };

    const {
        getModelLocalGroupsAsync,
    } = useActionDispatch();

    useEffect(() => {
        getModelLocalGroupsAsync(modelCode, hoopCode, carVersion);
    }, []);
    
    return (
        <Styled.AudiAddOnsStep>
            <AddOnsMenu
                modelLocalGroups={ modelLocalGroups }
                hoopCode={ hoopCode }
                scrollToAddOnsGroup={ scrollToAddOnsGroup }
            />
            <AddOnsList
                modelLocalGroups={ modelLocalGroups }
                hoopCode={ hoopCode }
                addOnGroupRef={ addOnGroupRef }
                addOnListContainerRef={ addOnListContainerRef }
            />
            <MobileAddOns
                modelLocalGroups={ modelLocalGroups }
                hoopCode={ hoopCode }
                onGoBack={ onGoBack }
            />
        </Styled.AudiAddOnsStep>
    );
};

AudiAddOnsStep.defaultProps = {
    onGoBack: () => {},
};

AudiAddOnsStep.propTypes = {
    onGoBack: PropTypes.func,
};

export default AudiAddOnsStep;
