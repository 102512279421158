import styled from 'styled-components';
import CONFIG from '../../../../../config';
import { COLORS } from '../../../../../constants';
import { pxToRem } from '../../../../../utils/commonUtils';
import {
    configurationLeftScrollBar,
} from '../../../../common/styles';

const { DEVICE_SIZE } = CONFIG;

const { BG_COLOR_DISABLED_BUTTON } = COLORS;

export const AddOnsMenu = styled.div`
  margin: 50px 30px 0 0;
  width: 17%;
  border-left: 1px solid ${BG_COLOR_DISABLED_BUTTON};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display:none;
  }
`;

export const AddOnsStepTitle = styled.h1`
  margin: 0;
  font-size: ${pxToRem(32)};
`;

export const AddOnsGroupTitlesContainer = styled.div`
  overflow-y: scroll;
  height: ${({ theme }) => theme.configurator.desktop.height};
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  gap: 3px;
  ${configurationLeftScrollBar}
`;
