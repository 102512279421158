import styled from 'styled-components';
import { COLORS } from '../../../../constants';
import { pxToRem } from '../../../../utils/commonUtils';
import CONFIG from '../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const Figure = styled.figure`
  margin: 0;
`;
export const ImageBox = styled.div`
  max-width: 100%;
  height: ${({ $isAudi }) => $isAudi ? 'auto' : '170px'};
  margin: auto;
  display: flex;
  align-items: center;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    height: auto;
  }
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const FigCaption = styled.figcaption`
  margin: 15px 0 6px 0;
  display: flex;
`;
export const FigCaptionText = styled.p`
  margin: 0;
  color: ${COLORS.COLOR_ITEM_CAR_FIGCAPTION};
  font-size: ${pxToRem(20)};
  font-weight: 800;
  text-decoration: none;
`;
