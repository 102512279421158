import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../language';
import { DASH_SIGN } from '../../../../constants';
import PollutionModel from './PollutionModel/PollutionModel';
import PollutionRange from './PollutionRange/PollutionRange';
import * as Styled from './Pollution.styles';

const Pollution = ({
    cars,
    pollutionLevel,
    regulation,
    isBeforeManufacture,
    manufactureText,
}) => {
    const isManyModels = cars.length > 1;
    
    const renderContent = () => {
        if (isBeforeManufacture && !isManyModels) {
            return (
                <Styled.Text>
                    {manufactureText || STRINGS.POLLUTION_DISCLAIMER}
                </Styled.Text>
            );
        }

        return (
            <Styled.PollutionModelAndRange>
                <PollutionModel
                    cars={ cars }
                />
                <PollutionRange
                    regulation={ regulation }
                    pollutionLevel={ isBeforeManufacture ? DASH_SIGN : pollutionLevel }
                    isManyModels={ isManyModels }
                />
            </Styled.PollutionModelAndRange>
        );
    };
    
    return (
        <Styled.Pollution>
            <Styled.Title>{STRINGS.AIR_POLLUTION}:</Styled.Title>
            { renderContent() }
        </Styled.Pollution>
    );
};

Pollution.propTypes = {
    cars: PropTypes.array.isRequired,
    pollutionLevel: PropTypes.number.isRequired,
    regulation: PropTypes.string.isRequired,
    isBeforeManufacture: PropTypes.bool.isRequired,
    manufactureText: PropTypes.string,
};

Pollution.defaultProps = {
    manufactureText: '',
};

export default Pollution;
