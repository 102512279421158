import styled, { css } from 'styled-components';
import CONFIG from '../../../../config';
import { COLORS } from '../../../../constants';
import { pxToRem } from '../../../../utils/commonUtils';
import { StickyConfigLeftFooterOnMobile } from '../../../common/styles';
import { ReactComponent as LeftArrow } from '../../../../assets/icons/left-arrow.svg';

const { DEVICE_SIZE } = CONFIG;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    ${StickyConfigLeftFooterOnMobile};
    bottom: ${({ $isAudi }) => $isAudi ? '50px' : 0};
  }
`;

export const OpenModalFormsButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${({ $isAudi }) => $isAudi && css`
    flex-direction: column-reverse;
    gap: 10px;
    margin-top: 20px;
    padding: 0 20px 0 60px;
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
      padding: 0 20px 0 20px;
    }
  `}
`;

export const SpeakWithRepresentativeButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  font-family: ${({ theme }) => theme['font-family']};
  font-weight: normal;
  font-size: ${pxToRem(14)};
  color: #333;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  ${({ $isAudi }) => $isAudi && css`
    width: 100%;
    border: 1px solid black;
    padding: 20px;
    max-height: 60px;
    font-size: ${pxToRem(15)};
    text-decoration: none;
    font-weight: 700;
  `}
`;

export const LeftArrowIcon = styled(LeftArrow)`
  transition: transform .2s ease-in-out;
  display: inline-block;
  width: 25px;
  height: 25px;
  color: 'white';
`;

export const RevealUniqueOnlinePriceButton = styled.button`
  color: ${({ theme }) => theme.buttonTextColor};
  font-family: ${({ theme }) => theme['font-family']};
  font-weight: 700;
  font-size: ${pxToRem(15)};
  background-color: ${({ theme }) => theme.buttonColor};
  padding: 25px;
  max-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 0.4rem;
  border-color: transparent;
  @media(hover: hover){
    &:hover {
      background-color: ${({ theme }) => theme.buttonHoverBackgroundColor};
      color: ${({ theme }) => theme.buttonTextHoverColor};
      border: ${({ $isCupra, $isAudi }) => ($isCupra || $isAudi) ? `2px solid ${COLORS.BLACK}` : '2px solid transparent'};
      transition:  background-color 0.6s;
      ${LeftArrowIcon} {
        transform: translateX(-5px);
      }
    }
  }
  &:disabled{
    opacity: .3;
    cursor: not-allowed;
    @media(hover: hover){
      &:hover{
        background-color: ${({ theme }) => theme.buttonColor};
        ${LeftArrowIcon} {
          transform: translate3d(0);
        }
      }
    }
  }

  ${({ $isAudi }) => $isAudi && css`
      width: 100%;
      border-radius: 0;
      padding: 20px;
      font-size: ${pxToRem(15)};
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 140;
    }
  `}
`;

export const CheckboxLabel = styled.span``;

export const CheckboxLink = styled.a.attrs({
    target: '_blank',
    rel: 'noreferrer',
})``;

export const ContinueToPaymentButton = styled(RevealUniqueOnlinePriceButton)``;
