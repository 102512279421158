import styled from 'styled-components';
import { COLORS } from '../../constants';
import CONFIG from '../../config';

const { DEVICE_SIZE } = CONFIG;

const Container = styled.div`
    z-index: 131;
    position: fixed;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    width: ${({ $isOpen }) => $isOpen ? '420px' : 0};
    @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
        width: ${({ $isOpen }) => $isOpen ? '100%' : 0};
        top: 68px;
        height: calc(100% - 68px);
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    overflow: hidden;
    background-color: ${COLORS.WHITE};
    box-shadow: 0px 0px 15px #00000029;
    transition: all 0.3s ease-in-out;
    ul {
        opacity: ${({ $isOpen }) => $isOpen ? 1 : 0};
        transition: all 0.4s ease-in-out;
    }
    overflow: hidden;
`;

const LinksContainer = styled.ul`
    width: 80%;
    margin: 0;
    padding: 0;
    list-style: none;
`;

const Style = {
    Container,
    LinksContainer,
};

export default Style;
