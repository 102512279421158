import styled, { css } from 'styled-components';
import { pxToRem } from '../../../../../utils/commonUtils';
import { COLORS } from '../../../../../constants';

export const AddOnGroupItem = styled.li`
  border-bottom: 1px solid ${COLORS.GROUP_ITEM_COLOR_BORDER_BOTTOM};
  ${({ $isActive }) => $isActive
    && css`
      border-bottom: none;
      & h5 {
        color: ${({ theme }) => theme.primaryColor};
      }
    `}
`;
export const AddOnGroup = styled.div``;
export const AddOnItems = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;
export const Title = styled.h5`
  font-weight: 700;
  font-size: ${pxToRem(17)};
  margin: 0;
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;

  &:before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background-image:url(${({ $arrowIcon }) => $arrowIcon});
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: ${({ $isActive }) => $isActive ? 'rotate(0)' : 'rotate(90deg)'}
  }
`;
