/**
 * Input Field Object Example:
 @{./RegistrationForm.fields}
 */
/**
 * receives an array in the inputFields format
 * returns an object with the following format : {field_1:'',field_2:''} etc...
 * @param {array} inputFields 
 * @param {object} customerObject 
 */
export const parseRegInputFieldsToState = (inputFields, customerObject) => {
    return inputFields.reduce((acc, item) => {
        const itemCopy = { ...item };

        itemCopy.value = customerObject[item.name] ?? item.value;
        acc[item.name ?? item.id] = itemCopy;
        
        return acc;
    }, {});
};
