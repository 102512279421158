import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../language';
import FailureScreenBody from './FailureScreenBody';

/**
 * 
 * @param {{error?: Error}} param0 
 */
function FailureScreen({ error, isPaymentError }) {
    const errorMessage = error?.message;

    return (
        <FailureScreenBody isPaymentError={ isPaymentError } errorMessage={ errorMessage } />
    );
}

FailureScreen.propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string.isRequired,
    }),
    isPaymentError: PropTypes.bool,
};

FailureScreen.defaultProps = {
    error: new Error(STRINGS.SOMETHING_WENT_WRONG),
    isPaymentError: false,
};

export default FailureScreen;
