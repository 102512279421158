import styled from 'styled-components';
import { ConfiguratorFooterTitle } from '../../../../../components/common/styles';
import CONFIG from '../../../../../config';
import { pxToRem } from '../../../../../utils/commonUtils';

const { DEVICE_SIZE } = CONFIG;

export const SafetyRange = styled.div`
  max-width: 375px;
  @media (max-width: ${DEVICE_SIZE.LARGE}px) {
    margin-bottom: 20px;
  }
  & ul {
    @media (max-width: ${DEVICE_SIZE.SMALL}px) {
      padding: 0;
    }
  }
  & ul li {
    @media (max-width: ${DEVICE_SIZE.SMALL}px) {
      width: 25px;
    }
    @media (max-width: ${DEVICE_SIZE.VERY_SMALL}px) {
      width: 20px;
    }
  }
  & div span {
    @media (max-width: ${DEVICE_SIZE.SMALL}px) {
      font-size: ${pxToRem(11)};
    }
  }
`;

export const Title = styled(ConfiguratorFooterTitle)``;
