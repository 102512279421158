/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
    useActionDispatch,
    useCurrentAppCommonData,
    useCurrentConfigOptions,
    useSelectedCurrentData,
} from '../../../hooks';
import Loader from '../../Loader';
import { useUrlSearchParams } from '../../../hooks/utils';
import useIsMobile from '../../../hooks/useWindowSize';
import { isObjectEmpty } from '../../../utils/commonUtils';
import { getHomePageThumbnail } from '../../../utils/assetsUtils';
import { CONFIGURATOR_QUERY_PARAMS, CONFIGURATOR_STEPS } from '../../../constants';
import STRINGS from '../../../language';
import CONFIG from '../../../config';
import useQueryParams from '../../../hooks/useQueryParams';
import EquipmentPackagesDesktop from './components/EquipmentPackagesDesktop/EquipmentPackagesDesktop';
import EquipmentPackagesMobile from './components/EquipmentPackagesMobile/EquipmentPackagesMobile';
import * as Styled from './EquipmentPackages.styles';

const { COLOR } = CONFIGURATOR_STEPS;

const EquipmentPackages = () => {
    const {
        getModelsAdditionalOptionsAsync,
    } = useActionDispatch();
    const {
        selectedExternalColor,
        selectedCar,
        selectedInternalColor,
        selectedHoop,
        selectedAddOnGroup,
        selectedAddOns,
    } = useSelectedCurrentData();
    const {
        selectChosenCar,
        selectCurrentInternalColor,
        selectCurrentExternalColor,
        selectCurrentHoop,
        selectCurrentAddOnGroup,
        selectCurrentAddOn,
        selectIsInSummary,
        selectCurrentStep,
        initializeConfiguratorData,
    } = useActionDispatch();
    const {
        currentBrand,
    } = useCurrentAppCommonData();
    const {
        updateQueryParam,
    } = useQueryParams();
    const isMobile = useIsMobile(CONFIG.DEVICE_SIZE.MEDIUM);
    const urlParams = useUrlSearchParams();

    const { subModels: allSubModels } = useCurrentConfigOptions();
    const { subModels, isLoading } = allSubModels;
    const isManySubModels = subModels.length > 1;

    const modelCode  = urlParams.get('modelCode')?.toUpperCase();
    const trimLevelCode  = urlParams.get('trimLevelCode')?.toUpperCase();
    const modelGroupCode  = urlParams.get('modelGroupCode')?.toUpperCase();
    const modelImage = getHomePageThumbnail(currentBrand, modelGroupCode, trimLevelCode);

    const initState = () => {
        if (!isObjectEmpty(selectedExternalColor)) {
            selectCurrentExternalColor({});
        }
        if (!isObjectEmpty(selectedInternalColor)) {
            selectCurrentInternalColor({});
        }
        
        if (!isObjectEmpty(selectedHoop)) {
            selectCurrentHoop({});
        }
        if (selectedAddOnGroup) {
            selectCurrentAddOnGroup(null);
        }
        if (!isObjectEmpty(selectedAddOns)) {
            selectCurrentAddOn({});
        }
        initializeConfiguratorData();
        selectIsInSummary(false);
    };
    
    const handlePickAdditionalOption = (car) => {
        selectChosenCar(car);
        if (car.originalAdditionsCode) {
            updateQueryParam(CONFIGURATOR_QUERY_PARAMS.ORIGINAL_ADDITIONS, car.originalAdditionsCode);
        }
        initState();
        selectCurrentStep(COLOR);
    };

    useEffect(() => {
        const getModelsAdditionalOptions = async () => {
            if (modelCode) {
                const subModelsResponse = await getModelsAdditionalOptionsAsync(
                    modelGroupCode,
                    modelCode,
                    trimLevelCode,
                    selectedCar,
                );
    
                if (subModelsResponse.length === 1) {
                    handlePickAdditionalOption(subModelsResponse[0]);
                }
            }
        };

        getModelsAdditionalOptions();
    }, [modelGroupCode, modelCode, trimLevelCode]);
    
    if (isLoading) {
        return <Styled.LoaderContainer><Loader /></Styled.LoaderContainer>;
    }
    
    return (
        <Styled.EquipmentPackages>
            <Styled.Header>
                <Styled.ModelImage src={ modelImage } alt={ selectedCar.modelDescription } />
                <Styled.HeaderTexts>
                    <Styled.Title>
                        { selectedCar.modelDescription }
                    </Styled.Title>
                    { isManySubModels && (
                        <Styled.SubTitleContainer>
                            <Styled.SubTitle>
                                { STRINGS.CHOOSING_A_CONFIGURATION }
                            </Styled.SubTitle>
                            <Styled.InfoIcon
                                data-tooltip-id="originalAdditionTooltip"
                            />
                            <Styled.ToolTip
                                isCapture={ isMobile }
                                id="originalAdditionTooltip"
                            >
                                { STRINGS.FOR_YOUR_CHOICE_PICK_ADDITIONAL_ITEM }
                            </Styled.ToolTip>
                        </Styled.SubTitleContainer>
                    ) }
                </Styled.HeaderTexts>
            </Styled.Header>
            { isMobile ? (
                <EquipmentPackagesMobile onSelect={ handlePickAdditionalOption } />
            ) : (
                <EquipmentPackagesDesktop onSelect={ handlePickAdditionalOption } />
            )}
        </Styled.EquipmentPackages>
    );
};

export default EquipmentPackages;
