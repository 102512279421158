import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../Divider/Divider';
import { RLM } from '../../../../../constants/constants';
import Styled from './EquipmentPackageSection.style';

const EquipmentPackageSection = ({
    description,
    details,
}) => {
    const isDetailsExist = details.length > 0;
    
    return (
        <Styled.Container>
            <Styled.Title>{ description }</Styled.Title>
            { isDetailsExist && (
                <Styled.DetailsList>
                    { details.map((detail) => (
                        <Styled.DetailItem>
                            {RLM}
                            <Styled.Detail>{ `- ${detail}` }</Styled.Detail>
                        </Styled.DetailItem>
                    ))}
                </Styled.DetailsList>
            ) }
            <Divider />
        </Styled.Container>
    );
};

EquipmentPackageSection.propTypes = {
    description: PropTypes.string.isRequired,
    details: PropTypes.array.isRequired,
};

export default EquipmentPackageSection;
