import styled from 'styled-components';
import { COLORS } from '../../../constants';
import { pxToRem } from '../../../utils/commonUtils';

export const OriginalAdditionsContainer = styled.ul`
  margin: 0;
  padding: 0;
`;

export const SingleOriginalAdditionContainer = styled.li`
  padding: 10px 0;
  border-bottom: 1px solid ${COLORS.SPECIFICATION_ITEM_COLOR_BORDER_BOTTOM};
  list-style: none;
`;

export const OriginalAdditionItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OriginalAdditionNameAndImage = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: ${pxToRem(15)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  &::before {
    content: "";
    width: ${({ $isAudi }) => $isAudi ? '40px' : '50px'};
    height: ${({ $isAudi }) => $isAudi ? '40px' : '50px'};
    margin-left: 15px;
    border-radius: ${({ $isAudi }) => !$isAudi && '50%'};
    background-position: ${({ $isAudi }) => $isAudi ? '85% center' : '60% center'};
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-image: url(${({ $image }) => $image});
  }
`;
