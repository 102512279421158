import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../language';
import * as CustomTypes from '../../constants/propTypes';
import Styled from './SalesDetails.style';
import SalesDetailsItem from './SalesDetailsItem';

/**
 *
 * @param {{ 
 * modelPrice: import('../../types').ModelPrice,
 * specification: import('../../types').Specification,
 * extras: import('../../types').Extras,
 * licenceFee: import('../../types').LicenceFee,
 * onlineDiscount: import('../../types').OnlineDiscount,
 * totalPayment: import('../../types').TotalPayment,
 * extraData: import('../../types').ExtraData,
 * showPaymentButton: boolean,
 * onPaymentButtonClick: () => void,
 * isloadingPayment: boolean,
 * outColor: string,
 * colors: {value: string, description: string}[]
 * }} param0
 */
const SalesDetails = ({
    modelPrice,
    specification,
    extras,
    licenceFee,
    onlineDiscount,
    totalPayment,
    extraData,
    salesPhone,
    showPaymentButton,
    onPaymentButtonClick,
    isloadingPayment,
}) => {
    /**
   *
   * @param {import('../../types').ParsedResponse['specification' | 'extras']} data
   * @param {string} type
   */
    const renderItems = (data, type) => {
        if (!data?.items?.length) {
            return null;
        }

        const items = data.items.map((item) => {
            const { title, icon, typeDescription } = item;
            
            const _title = typeDescription ? `${typeDescription} ${title}` : title;
            
            return (
                <React.Fragment key={ title }>
                    <SalesDetailsItem
                        title={ _title }
                        prices={ item.prices }
                        icon={ icon }
                        isTotalPrice={ item.isTotalPrice }
                    />
                    <Styled.Hr />
                </React.Fragment>
            );
        });

        return (
            <>
                <SalesDetailsItem
                    className={ type }
                    title={ data.title }
                    prices={ data.prices }
                    icon={ data.icon }
                    isTotalPrice={ data.isTotalPrice }
                />
                {items}
            </>
        );
    };

    /**
   *
   * @param {import('../../types').ParsedResponse['licenceFee'| 'onlineDiscount' | 'totalPayment']} data
   * @param {bool} isWithoutSeperator
   * @param {string} type
   */
    const renderSingle = (data, type, isWithoutSeperator) => {
        if (data?.prices?.total === null || Number.isNaN(data.prices.total)) {
            return null;
        }

        return (
            <>
                <SalesDetailsItem
                    className={ type }
                    title={ data.title }
                    prices={ data.prices }
                    icon={ data.icon }
                    isTotalPrice={ data.isTotalPrice }
                />
                {isWithoutSeperator ? null : <Styled.HrBold />}
            </>
        );
    };

    const renderExtras = () => {
        if (!extraData) {
            return null;
        }

        return (
            <Styled.ExtraData>
                {extraData.map((item) => {
                    return (
                        <Styled.ExtrasListItem key={ item.text }>
                            {item.text}
                        </Styled.ExtrasListItem>
                    );
                })}
            </Styled.ExtraData>
        );
    };

    const _salesPhoneEl =  salesPhone && (
        <a href={ `tel:${salesPhone}` }>
            {STRINGS.CONTACT_SALES_PERSON}
        </a>
    );
    
    return (
        <>
            {renderSingle(modelPrice, 'modelPrice')}
            {renderItems(specification, 'specification')}
            {renderItems(extras, 'extras')}
            {renderSingle(licenceFee, 'licenceFee')}
            {renderSingle(onlineDiscount, 'onlineDiscount')}
            {renderSingle(totalPayment, 'totalPayment', true)}
            {renderExtras()}
            
            {_salesPhoneEl}

            {showPaymentButton && totalPayment?.prices?.total > 0 && (
                <Styled.Footer>
                    <Styled.PaymentButton
                        onClick={ onPaymentButtonClick }
                        disabled={ isloadingPayment || (totalPayment.prices.total <= 0) }
                    >
                        {isloadingPayment ? STRINGS.LOADING : STRINGS.CONTINUE_TO_PAYMENT}
                    </Styled.PaymentButton>
                </Styled.Footer>
            )}
        
        </>
    );
};

SalesDetails.propTypes = {
    modelPrice: CustomTypes.ModelPriceType.isRequired,
    specification: CustomTypes.SpecificationType.isRequired,
    extras: CustomTypes.ExtrasType.isRequired,
    licenceFee: CustomTypes.LicenceFeeType.isRequired,
    onlineDiscount: CustomTypes.OnlineDiscountType.isRequired,
    totalPayment: CustomTypes.TotalPaymentType.isRequired,
    extraData: CustomTypes.ExtraDataType,
    showPaymentButton: PropTypes.bool,
    onPaymentButtonClick: PropTypes.func,
    isloadingPayment: PropTypes.bool,
    outColor: PropTypes.string.isRequired,
    colors: PropTypes.arrayOf(CustomTypes.ColorPickerItemType).isRequired,
    salesPhone: PropTypes.string,
};

SalesDetails.defaultProps = {
    extraData: null,
    showPaymentButton: false,
    onPaymentButtonClick: () => {},
    isloadingPayment: false,
    salesPhone: null,
};

export default SalesDetails;
