import React from 'react';
import PropTypes from 'prop-types';
import PickerItem from '../../PickerItem/PickerItem';
import useIsMobile from '../../../hooks/useWindowSize';
import { ColorPickerItemType } from '../../../constants';
import { isArrayEmpty } from '../../../utils/commonUtils';
import CONFIG from '../../../config';
import * as Styled from './ColorPicker.styles';

const { DEVICE_SIZE } = CONFIG;

const ColorPicker = ({
    colors, pickedColorCode, onPick, isAudi,
}) => {
    const isMobile = useIsMobile(DEVICE_SIZE.MEDIUM);

    if (!colors || isArrayEmpty(colors)) {
        return null;
    }
    
    const _colors = colors.map((item) => {
        const {
            value, price, description, icon, arrivalDate, isPackageRelated,
        } = item;
        
        return (
            <PickerItem
                key={ value }
                text={ description }
                selected={ value === pickedColorCode }
                value={ value }
                price={ price }
                inStockString={ arrivalDate }
                tooltipContent={ description }
                image={ icon }
                onClick={ () => onPick(item) }
                isPackageRelated={ isPackageRelated }
                isToolipDisabled={ isMobile }
                isAudi={ isAudi }
            />
        );
    });

    return (
        <Styled.ColorPicker $isAudi={ isAudi }>
            {_colors}
        </Styled.ColorPicker>
    );
};

ColorPicker.propTypes = {
    colors: PropTypes.arrayOf(
        ColorPickerItemType,
    ).isRequired,
    pickedColorCode: PropTypes.string,
    onPick: PropTypes.func,
    isAudi: PropTypes.bool,
};

ColorPicker.defaultProps = {
    pickedColorCode: null,
    onPick: null,
    isAudi: false,
};

export default ColorPicker;
