import styled from 'styled-components';
import { COLORS } from '../../constants';
import { pxToRem } from '../../utils/commonUtils';

export const TableHeadData = styled.th.attrs(({ $colspan }) => ({
    colSpan: $colspan,
}))`
    border:1px solid ${COLORS.BORDER_TABLE_DATA_COLOR};
    font-size:${pxToRem(12)};
    padding:10px;
    font-weight:bold;
    background-color:${({ $bg }) => $bg || null};
    text-align:center;
`;
