import styled from 'styled-components';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const Overlay = styled.div`
  position: fixed;
  z-index: 140;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  
  &.show-up-enter .portal-content {
    transform: translateX(100%);
  }
  
  &.show-up-enter-active .portal-content {
    transform: translateX(0);
    transition: all 400ms;
  }
  
  &.show-up-exit-active .portal-content {
    transform: translateX(100%);
    transition: all 400ms;
  }
  &.show-up-exit-active {
    transition: all 500ms;
  }


  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: flex;
    justify-content: center;
    &.show-up-enter .portal-content {
      transform: translateY(100%);
    }
    
    &.show-up-enter-active .portal-content {
      transform: translateY(0);
      transition: all 400ms;
    }
    
    &.show-up-exit-active .portal-content {
      transform: translateY(100%);
      transition: all 400ms;
    }
    &.show-up-exit-active {
      transition: all 500ms;
    }
  }
`;

export const PaymentDetailsContainer = styled.div`
   position: fixed;
   z-index: 150;
   background-color: white;
   box-shadow: rgb(0 0 0 / 50%) 3px 5px 14px;
   @media (min-width: ${DEVICE_SIZE.MEDIUM}px) {
    bottom: 0;
    right: 0;
    width: 30%;
    height: 100%;
    padding: 0;
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
   bottom: 62px;
   position: fixed;
   width: 95%;
   height: 75%;
   border-radius: 10px 10px 0 0;
  }
`;
