import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../language';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import Loader from '../../../../components/Loader';
import { isArrayEmpty, isObjectEmpty } from '../../../../utils/commonUtils';
import InnerTabs from '../InnerTabs/InnerTabs';
import EquipmentData from './EquipmentData/EquipmentData';
import * as Styled from './Equipment.styles';

const Equipment = ({ equipment }) => {
    const [selectedTab, setSelectedTab] = useState(null);
    const { data, isLoading } = equipment;
    const { tabs, screenData } = data;

    const selectTabHandler = useCallback((tab) => {
        if (selectedTab !== tab) {
            setSelectedTab(tab);
        }
    }, [selectedTab]);

    useEffect(() => {
        if (!isObjectEmpty(data) && !isArrayEmpty(tabs) && !selectedTab) {
            setSelectedTab(tabs[0]);
        }
    }, [tabs]);

    if (isLoading) {
        return <Loader />;
    }

    if (isObjectEmpty(data)) {
        return <ErrorMessage errorMessage={ STRINGS.NO_DATA_TO_DISPLAY } />;
    }

    return (
        <Styled.Equipment>
            <Styled.Header>
                <InnerTabs
                    tabs={ tabs }
                    onSelect={ selectTabHandler }
                    selectedTab={ selectedTab }
                />
            </Styled.Header>
            <EquipmentData
                data={ screenData }
                selectedTab={ selectedTab }
            />
        </Styled.Equipment>
    );
};

Equipment.propTypes = {
    equipment: PropTypes.object.isRequired,
};

export default Equipment;
