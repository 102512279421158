import React from 'react';
import PropTypes from 'prop-types';

import STRINGS from '../../../../../language';
import * as Styled from './SpecificationsItem.styles';

const SpecificationsItem = ({
    modelDescription,
    onClick,
}) => {
    const linkText = `
    ל${STRINGS.SPECIFICATION} - ${modelDescription}>>
    `;
    
    return (
        <Styled.SpecificationsItem>
            <Styled.SpecificationLink onClick={ onClick }>
                {linkText}
            </Styled.SpecificationLink>
        </Styled.SpecificationsItem>
    );
};

SpecificationsItem.propTypes = {
    modelDescription: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default SpecificationsItem;
