import styled from 'styled-components';
import { TableHeadData } from '../../../../../components/TableHeadData/TableHeadData.styles';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const SafetyModel = styled.div`
 margin-left:30px;
 @media(max-width:${DEVICE_SIZE.LARGE}px){
    margin-bottom:20px;
    margin-left:0;
  }
`;
export const ModelTable = styled.table`
border-collapse: collapse;
border-spacing: 0;
`;
export const ModelThead = styled.thead``;
export const ModelTr = styled.tr``;
export const ModelTbody = styled.tbody``;
export const ModelTh = styled(TableHeadData)``;
