import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { footerContentBottomTextStyle } from '../../common/styles';

export const Disclaimer = styled.div`
    max-width: 100%;
`;

export const DisclaimerText = styled.p`
    ${footerContentBottomTextStyle}
`;

export const LinkToWebsite = styled(Link)`
    color:${({ theme }) => theme.disclaimerTextColor};
    text-decoration:none;
    &:hover{
        text-decoration:underline;
}
`;
