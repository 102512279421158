import React from 'react';
import PropTypes from 'prop-types';
import { MUTAGS, TITLE_POSITION } from '../../constants';
import * as Styled  from './ImageBlockWithTextInTheMiddle.styles';

const { BOTTOM, CENTER, TOP } = TITLE_POSITION;

const ImageBlockWithTextInTheMiddle = ({
    text, image, brand, position,
}) => {
    const isDefaultImage = brand === MUTAGS.CHAMPION;
    
    return (
        <Styled.ImageBlockWithTextInTheMiddle
            $isDefaultImage={ isDefaultImage }
            $image={ image }
            $position={ position }
        >
            {!isDefaultImage && <Styled.Title>{text}</Styled.Title>}
        </Styled.ImageBlockWithTextInTheMiddle>
    );
};

ImageBlockWithTextInTheMiddle.defaultProps = {
    text: '',
    position: CENTER,
    
};

ImageBlockWithTextInTheMiddle.propTypes = {
    text: PropTypes.string,
    image: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    position: PropTypes.oneOf([BOTTOM, CENTER, TOP]),
};

export default ImageBlockWithTextInTheMiddle;
