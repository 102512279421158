import React from 'react';
import PropTypes from 'prop-types';
import Style from './DrawerItem.styles';

const DrawerItem = ({
    title, link, target, icon, onClick,
}) => {
    return (
        <Style.Container $icon={ icon } onClick={ onClick }>
            <Style.Link target={ target } to={ link }>{title}</Style.Link>
        </Style.Container>
    );
};

DrawerItem.defaultProps = {
    target: null,
    icon: null,
};

DrawerItem.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    target: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

export default DrawerItem;
