import { mapEquipmentPackages } from '../utils';

export const getEquipmentPackagesPending = (state) => ({
    ...state,
    equipmentPackages: {
        isLoading: true,
        error: null,
        equipmentPackages: [],
    },
});

export const getEquipmentPackagesSuccess = (state, action) => {
    const {
        equipmentPackages,
    } = action;

    const mappedEquipmentPackage = mapEquipmentPackages(equipmentPackages);

    // To Do: Refactor this function to work with object instead of an array.

    // const equipmentPackagesOnly = mappedEquipmentPackage.reduce((
    //     acc, item) => {
    //     if (item.type === EQUIPMENT_PACKAGE) {
    //         acc.push(item);
    //     }
        
    //     return acc;
    // }, []);

    const equipmentPackageArray = [];

    equipmentPackageArray.push(mappedEquipmentPackage);

    return {
        ...state,
        equipmentPackages: {
            isLoading: false,
            error: null,
            equipmentPackages: equipmentPackageArray,
        },
    };
};

export const getEquipmentPackagesFailure = (state, action) => {
    const { error } = action;

    return {
        ...state,
        equipmentPackages: {
            isLoading: false,
            error,
            equipmentPackages: [],
        },
    };
};
