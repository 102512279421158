import React from 'react';
import PropTypes from 'prop-types';
import ModelGroupItem from '../../../../Steps/Model/ModelGroupItem/ModelGroupItem';
import { AudiModelGroupItem } from '../ModelGroupItem/ModelGroupItem';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import { getCarPlaceholderImageUrl } from '../../../../../utils/assetsUtils';
import { isArrayAndNotEmpty } from '../../../../../utils/commonUtils';
import STRINGS from '../../../../../language';
import { useCurrentAppCommonData } from '../../../../../hooks';
import * as Styled from './ModelGroupItemsList.style';

const ModelGroupItemsList = ({
    nodeRef,
    items,
    currentBrand,
    isCupra,
    modelListBounding,
    modelGroupItemTitle,
    toggleModelGroup,
    toggleModelGroupHandler,
}) => {
    const { isAudi } = useCurrentAppCommonData();
    const ComponentToRender = isAudi ? AudiModelGroupItem : ModelGroupItem;

    if (!isArrayAndNotEmpty(items)) {
        return (
            <Styled.ErrorWrapper>
                <ErrorMessage errorMessage={ STRINGS.NO_CARS_TO_DISPLAY } />
            </Styled.ErrorWrapper>
        );
    }

    return (
        <Styled.ListWrapper ref={ nodeRef }>
            {
                items?.map((modelGroupItem) => {
                    const {
                        id, image, imageAlt, price, description, cars,
                    } = modelGroupItem;
                        
                    return (
                        <ComponentToRender
                            key={ id }
                            id={ id }
                            image={ image }
                            imageAlt={ imageAlt }
                            imageFallback={ getCarPlaceholderImageUrl(currentBrand) }
                            title={ modelGroupItem[modelGroupItemTitle] }
                            price={ price }
                            description={ description }
                            modelItems={ cars }
                            modelListBounding={ modelListBounding }
                            selectedModelGroup={ toggleModelGroup }
                            isSelected={ id === toggleModelGroup  }
                            onToggleGroup={ toggleModelGroupHandler }
                            isCupra={ isCupra }
                        />
                    );
                })
            }
        </Styled.ListWrapper>
    );
};

ModelGroupItemsList.defaultProps = {
    toggleModelGroup: null,
};

ModelGroupItemsList.propTypes = {
    nodeRef: PropTypes.any.isRequired,
    items: PropTypes.array.isRequired,
    currentBrand: PropTypes.string.isRequired,
    isCupra: PropTypes.bool.isRequired,
    modelListBounding: PropTypes.any.isRequired,
    modelGroupItemTitle: PropTypes.string.isRequired,
    toggleModelGroup: PropTypes.string,
    toggleModelGroupHandler: PropTypes.func.isRequired,
};

export default ModelGroupItemsList;
