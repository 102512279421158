import { isObjectEmpty } from '../../utils/commonUtils';

/**
 * receives [{}] of current configurator (saved in redux) specifications of the following type
  1. internal color
  2. external color
  3. hoops
* returns parsed [{}] in the following structure: 
{
    name:string,
    price: string
    image: string
}
 * @param {array} specifications 
 */

export const parseSpecificationsToPaymentDetails = (specifications) => {
    return specifications
        .filter((specification) => !isObjectEmpty(specification))
        .map((specification) => {
            const {
                name,
                description,
                price,
                image,
                icon,
                title,
                prices,
                type,
            } = specification;

            return {
                name: name ?? description ?? title,
                price: price ?? prices?.price,
                image: image ?? icon,
                discount: prices?.discount ?? 0,
                isDiscount: prices?.discount > 0,
                total: prices?.total ?? price ?? prices?.price,
                type: type ?? null,
            };
        });
};

/**
 * receives [{}] of current configurator (saved in redux) addOns Objecy of the following type
  {
      5 : {title,price,image},
      7: {title,price,image},
  }
* returns parsed [{}] in the following structure: 
{
    name:string,
    price: string
    image: string
}
 * @param {Object} addOns 
 */

export const parseAddOnsToPaymentDetails = (addOns, addonGroups) => {
    const selectedAddOnsArray = Object.values(addOns);

    if (selectedAddOnsArray.length <= 0) {
        return [];
    }

    return selectedAddOnsArray.reduce((acc, addOn) => {
        if (isObjectEmpty(addOn)) {
            return acc;
        }
        
        const {
            title,
            local,
            value,
            price,
            image,
            icon,
            prices,
            name,
            isDiscount,
            priceAfterDiscount,
            groupCode,
        } = addOn;

        const addonWithGroupCode = addonGroups?.find((addonsGroup) => {
            if (addonsGroup.listLocalPackages.some((someShit) => someShit.description === title)) {
                return addonsGroup.groupCode;
            }

            return null;
        });

        acc.push({
            value: local ?? value.slice(0, 3),
            name: title ?? name,
            price: price ?? prices?.price,
            image: image ?? icon,
            discount: prices?.discount ?? 0,
            isDiscount: prices?.discount > 0 || isDiscount,
            total: prices?.total ?? price ?? prices?.price,
            priceAfterDiscount,
            groupCode: groupCode || addonWithGroupCode?.groupCode,
        });

        return acc;
    }, []);
};

export const findCarById = (id) => (car) => id === car.id;
export const filterCarByModelGroup = (modelGroup) => (car) => {
    return car.modelGroup.toLowerCase().replaceAll(' ', '-') === modelGroup;
};
