import styled from 'styled-components';
import CONFIG from '../../config';

const { DEVICE_SIZE } = CONFIG;

export const CheckoutPage = styled.div`
`;

export const Container = styled.div`
    padding-top:45px;
    display:flex;
    max-width:1200px;
    margin:auto;
    width:95%;
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        flex-direction:column;
    }
`;
