import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './TabItem.styles';

const TabItem = ({ name, onSelect, isSelected }) => {
    return (
        <Styled.TabItem
            $isSelected={ isSelected }
            onClick={ onSelect }
        >
            {name}
        </Styled.TabItem>
    );
};

TabItem.propTypes = {
    name: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
};

export default TabItem;
