import styled from 'styled-components';

export const Container = styled.div`
  justify-content: center;
  display: flex;
  flex-flow: row;
  padding: 3rem 0;
  animation: reveal 300ms ease-out forwards;

  .PickerItem {
    margin: 0.66rem;
  }

  @keyframes reveal {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
