import * as actionTypes from '../actionTypes';
import {
    getEquipmentSpecifications,
    getEngineSpecifications,
    getMeasurementsSpecifications,
} from '../../fetch/specifications';

const getSpecificationsPending = (actionType) => ({
    type: actionTypes[actionType],
});

const getSpecificationsSuccess = (actionType, data, originalAdditionsCode) => ({
    type: actionTypes[actionType],
    data,
    originalAdditionsCode,
});

const getSpecificationsFailure = (actionType, error) => ({
    type: actionTypes[actionType],
    error,
});

export const getEquipmentSpecificationsAsync = (modelCode, styleLevelCode, version) => {
    return async (dispatch) => {
        dispatch(
            getSpecificationsPending(actionTypes.GET_EQUIPMENT_SPECIFICATIONS_PENDING),
        );

        try {
            const response = await getEquipmentSpecifications(modelCode, styleLevelCode, version);

            const { data } = response;

            dispatch(
                getSpecificationsSuccess(
                    actionTypes.GET_EQUIPMENT_SPECIFICATIONS_SUCCESS,
                    data,
                ),
            );

            return data.specifications;
        } catch (error) {
            dispatch(getSpecificationsFailure(
                actionTypes.GET_EQUIPMENT_SPECIFICATIONS_FAILURE,
                error,
            ));
        }
    };
};

export const getEngineSpecificationsAsync = (
    modelCode,
    styleLevelCode,
    sVersion,
) => {
    return async (dispatch) => {
        dispatch(
            getSpecificationsPending(actionTypes.GET_ENGINE_SPECIFICATIONS_PENDING),
        );

        try {
            const response = await getEngineSpecifications(
                modelCode,
                styleLevelCode,
                sVersion,
            );

            const { data } = response;
            
            dispatch(
                getSpecificationsSuccess(
                    actionTypes.GET_ENGINE_SPECIFICATIONS_SUCCESS,
                    data,
                ),
            );

            return data;
        } catch (error) {
            dispatch(
                getSpecificationsFailure(
                    actionTypes.GET_ENGINE_SPECIFICATIONS_FAILURE,
                    error,
                ),
            );
        }
    };
};

export const getMeasurementsSpecificationsAsync = (modelGroupCode) => {
    return async (dispatch) => {
        dispatch(
            getSpecificationsPending(actionTypes.GET_MEASUREMENTS_SPECIFICATIONS_PENDING),
        );

        try {
            const response = await getMeasurementsSpecifications(modelGroupCode);

            const { data } = response;

            dispatch(
                getSpecificationsSuccess(
                    actionTypes.GET_MEASUREMENTS_SPECIFICATIONS_SUCCESS,
                    data,
                ),
            );

            return data;
        } catch (error) {
            dispatch(
                getSpecificationsFailure(
                    actionTypes.GET_MEASUREMENTS_SPECIFICATIONS_FAILURE,
                    error,
                ),
            );
        }
    };
};

export const initializeSpecifications = () => ({
    type: actionTypes.INITALIZE_SPECIFICATIONS,
});

export const setSpecificationParams = (
    groupModelCode,
    modelCode,
    modelDescription,
    originalAdditionsCode,
    version,
    trimLevelCode,
    id,
) => ({
    type: actionTypes.SET_SPECIFICATION_MODEL_CODE,
    data: {
        groupModelCode,
        modelCode,
        modelDescription,
        originalAdditionsCode,
        version,
        trimLevelCode,
        id,
    },
});

export const toggleSpecificationsModal = () => ({
    type: actionTypes.TOGGLE_SPECIFICATIONS_MODAL,
});
