import {
    // API_METHODS,
    NEW_API_METHODS,
} from '../constants';
import axios from './axios';

// const { GET_MUTAG_DETAILS } = API_METHODS;
const {
    GET_ALL_MODELS,
    GET_BRAND_VIDEO_LINK,
    GET_MANUFACTURING_DATES,
} = NEW_API_METHODS.VEHICLE;

/**
 * 1. responsible for getting the common data(inital), to be shared accoss all app
 * 2. equivalent (Champion) api call is getModelHeader
 */

/**
 *  @POST request
 * @param {string} brand (champion equivalent - sMutag)
 * @returns cars[{car},{car},{car}]
 */

export const getAppCommonData = async () => {
    const result = await axios.get(GET_ALL_MODELS);

    return result;
};

export const getMutagVideo = async (brand) => {
    const data = {
        brand,
    };

    const result = await axios.get(`${GET_BRAND_VIDEO_LINK}`, data);

    return result;
};

export const GetModelGroupManufacturingDates = async (brand) => {
    const data = {
        brand,
    };

    const result = await axios.get(`${GET_MANUFACTURING_DATES}`, data);

    return result;
};
