import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../language';
import IMAGES from '../../assets';
// import CarImage from '../../components/CarImage/CarImage';
import * as Styled from './Success.styles';

const SuccessBody = ({ data }) => {
    const contactInfoLine = (
        <>
            <Styled.IconRow as="div" icon={ IMAGES.V }>
                <Styled.TextLineContainer>
                    <Styled.TextLineSpan>
                        {STRINGS.SUCCESS_SALES_CENTER_TEXT}
                    </Styled.TextLineSpan>
                    <Styled.BoldTextLineSpan>
                        {data.salesCenterName}
                    </Styled.BoldTextLineSpan>
                    <Styled.TextLineSpan>
                        {STRINGS.SUCCESS_SALES_MAN_TEXT}
                    </Styled.TextLineSpan>
                    <Styled.BoldTextLineSpan>{data.salesMan}</Styled.BoldTextLineSpan>
                </Styled.TextLineContainer>
            </Styled.IconRow>
              
            <Styled.IconRow as="div" icon={ IMAGES.PHONE } width={ 20 } height={ 20 }>
                <Styled.TextLineContainer>
                    <Styled.TextLineSpan>
                        {STRINGS.SUCCESS_SALES_CONTACT_TEXT}
                    </Styled.TextLineSpan>
                    <Styled.Link href={ `tel:${data.salesPhone}` }>
                        {data.salesPhone}
                    </Styled.Link>
                    <Styled.TextLineSpan>
                        {` ${STRINGS.SUCCESS_SALES_MAIL_TEXT}`}
                    </Styled.TextLineSpan>
                    <Styled.Link href={ `mailto:${data.salesMail}` }>
                        {data.salesMail}
                    </Styled.Link>
                </Styled.TextLineContainer>
            </Styled.IconRow>
        </>
    );

    return (
        <Styled.Body>
            <Styled.Title>{STRINGS.SUCCESS_TITLE}</Styled.Title>
            <Styled.SubTitle>{STRINGS.SUCCESS_MESSAGE}</Styled.SubTitle>

            <Styled.TextLine>
                {`${STRINGS.SUCCESS_SERVICE_ID_MESSAGE}: ${data.OrderIdForContact}`}
            </Styled.TextLine>

            {/* <Styled.LegalText>{STRINGS.SUCCESS_LEGAL_MESSAGE_2}</Styled.LegalText>
                <Styled.LegalText>{STRINGS.SUCCESS_WITH_TAX}</Styled.LegalText> */}

            <Styled.ActionsBlock>
                {/* <Styled.IconRow icon={ IMAGES.DOWNLOAD } width={ 16 } height={ 16 }>
                        <Styled.Link href={ data.carPdf }>
                            {STRINGS.SUCCESSS_PDF_TEXT}
                        </Styled.Link>
                    </Styled.IconRow> */}
                {contactInfoLine}
            </Styled.ActionsBlock>
        </Styled.Body>
    );
};

SuccessBody.propTypes = {
    data: PropTypes.shape({
        salesCenterName: PropTypes.string,
        salesMail: PropTypes.string,
        salesMan: PropTypes.string,
        salesPhone: PropTypes.string,
        OrderIdForContact: PropTypes.string,
        // carPdf: PropTypes.string,
    }).isRequired,
};

export default SuccessBody;
