import styled from 'styled-components';

export const Container = styled.div`
  background-color: #FFFFFF;
  display: flex;
  flex-flow: column;
`;

export const SocialBox = styled.div`
    display: block;
    margin: 3rem 1rem 0 1rem;
    text-align: center;

    .social-link {
        margin-right: 1rem;
        :first-child {
            margin-right: 0;
        }
    }

    @media (max-width: 1100px) {
        margin-top: 1rem;
    }

    @media (max-width: 600px) {
        margin-bottom: 2rem;
    }
`;

export const ShareTextContainer = styled.div`
    display: inline-block;
    padding: 0 2rem 0 6rem;
    border-right: 4px solid #b3b4b8;
    vertical-align: middle;
    margin: 1rem 0;     /* spacing on line-break of buttons */

    @media (max-width: 600px) {
        padding-left: 2rem;
    }
`;

export const ShareText = styled.div`
    display: block;
    font-size: 1.2rem;
    line-height: 130%;
    text-align: right;
`;

export const ShareIcons = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

// ---------------------------------
// ---------------------------------
// ---------------------------------

export const ColorPickerWrapper = styled.div`
  // margin: 3rem auto;
  // height: 3.5rem;
`;

export const SocialLinkIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme?.primaryColor ?? '#222'};
  color: ${({ theme }) => theme?.primaryColor ?? '#222'};
  font-size: 1.3rem;
  background-color: #f9f5f3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;

  &:hover {
    border-color: ${({ theme }) => theme?.secondaryColor ?? '#666'};
    color: ${({ theme }) => theme?.secondaryColor ?? '#666'};
    transform: scale(1.05);
  }
`;

export const SocialLinkIconText = styled.div`
  font-size: 0.9rem;
  color: black;
  font-weight: 600;
`;

export const SocialNetworkItem = styled.div`
  width: 50px;
  gap: 5px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
`;

export const CarImageContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1100px) {
    max-width: 800px;
  }
`;
