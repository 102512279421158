import React, {
    useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
    useCurrentBrand,
    useActionDispatch,
    useCurrentAddOns,
    useCurrentAddOnGroup,
} from '../../../../../hooks';
import useQueryParams from '../../../../../hooks/useQueryParams';
import { getStepTitle } from '../../../../../utils/configuratorUtils';
import { CONFIGURATOR_QUERY_PARAMS, CONFIGURATOR_STEPS } from '../../../../../constants';
import AddOnItem from '../Components/AddOnItem/AddOnItem';
import * as Styled from './MobileAddOns.styles';

const MobileAddOns = ({
    modelLocalGroups,
    hoopCode,
    onGoBack,
}) => {
    const currentBrand = useCurrentBrand();
    const selectedAddOns = useCurrentAddOns();
    const selectedAddOnGroup = useCurrentAddOnGroup();
    const pickedAddOnGroupCode = selectedAddOnGroup?.groupCode;
    const {
        getAddOnsExtraInfoAsync,
        selectCurrentAddOn,
        selectIsInSummary,
        selectCurrentAddOnGroup,
    } = useActionDispatch();
    const {
        addQueryParam,
        deleteQueryParam,
    } = useQueryParams();
    const { addOns } = modelLocalGroups;
    const { addOnsExtraInformation } = modelLocalGroups;

    const selectAddOnHandler = useCallback((addOn) => {
        const addOnsCodesArray = Object.keys(selectedAddOns).map(
            (key) => selectedAddOns[key].local);

        const addOnKey = `${addOn.groupCode}-${addOn.local}`;

        const isAddOnExist = addOnsCodesArray.includes(addOn.local);

        if (isAddOnExist) {
            deleteQueryParam(CONFIGURATOR_QUERY_PARAMS.ADDONS, addOnKey);
        } else {
            addQueryParam(CONFIGURATOR_QUERY_PARAMS.ADDONS, addOnKey);
        }

        selectCurrentAddOn(addOn);
        selectIsInSummary(false);
    }, [selectedAddOns]);

    const selectAddOnGroupHandler = useCallback(
        (addOnGroup) => {
            let selectedAddOn = {};

            if (pickedAddOnGroupCode !== addOnGroup.groupCode) {
                selectedAddOn = addOnGroup;
            }

            selectCurrentAddOnGroup(selectedAddOn);
        },
        [pickedAddOnGroupCode],
    );

    useEffect(() => {
        if (!pickedAddOnGroupCode && addOns[hoopCode]) {
            // No AddOnGroup was selected - select the first group
            const firstAddOnGroup = addOns[hoopCode][0];

            selectAddOnGroupHandler(firstAddOnGroup);
        }
    }, [addOns[hoopCode]]);

    useEffect(() => {
        if (addOns[hoopCode]) {
            addOns[hoopCode].forEach((AddOnGroup) => {
                const { listLocalPackages } = AddOnGroup;

                const { groupCode } = AddOnGroup;

                getAddOnsExtraInfoAsync(currentBrand, groupCode, listLocalPackages);
            });
        }
    }, [addOns[hoopCode]]);

    const renderAddOnsGroup = useCallback(() => {
        if (addOns[hoopCode]) {
            return addOns[hoopCode].map((AddOnGroup) => {
                const { listLocalPackages, groupDescription, groupCode } = AddOnGroup;
                const { image } = listLocalPackages[0];

                const isActive = pickedAddOnGroupCode === groupCode;

                const renderAddons = () => {
                    return  listLocalPackages.map((AddOn, index) => {
                        const {
                            description,
                            price,
                            local,
                            priceAfterDiscounts,
                            isDiscount,
                        } = AddOn;

                        return (
                            <AddOnItem
                                key={ local }
                                description={ description }
                                groupDescription={ groupDescription }
                                groupCode={ groupCode }
                                localCode={ local }
                                addOnsExtraInformation={ addOnsExtraInformation }
                                index={ index }
                                price={ parseInt(price) }
                                image={ image }
                                priceAfterDiscounts={ priceAfterDiscounts }
                                isDiscount={ isDiscount }
                                onSelect={ selectAddOnHandler }
                                isGroupSelected={ isActive }
                            />
                        );
                    });
                };
                       
                return (
                    <Styled.AddonsSection key={ groupCode }>
                        <Styled.AddOnGroupTitleAndIcon onClick={
                            () => selectAddOnGroupHandler(AddOnGroup)
                        }
                        $isActive={ isActive }
                        >
                            <Styled.Icon $IconUrl={ image } />
                            <Styled.Title>{groupDescription}</Styled.Title>
                        </Styled.AddOnGroupTitleAndIcon>
                        
                        <Styled.AddOns $isActive={ isActive }>
                            {renderAddons()}
                        </Styled.AddOns>
                    </Styled.AddonsSection>
                );
            });
        }
            
        return null;
    }, [modelLocalGroups, addOnsExtraInformation, pickedAddOnGroupCode, selectedAddOns]);

    return (
        <Styled.MobileAddOns>

            <Styled.GoBackButtonMobileWrapper>
                <Styled.AudiGoBackButtonMobile
                    onClick={ onGoBack }
                />
                <Styled.StepTitle>
                    {getStepTitle(CONFIGURATOR_STEPS.ADD_ONS, true)}
                </Styled.StepTitle>
            </Styled.GoBackButtonMobileWrapper>

            {renderAddOnsGroup()}
        </Styled.MobileAddOns>
    );
};

MobileAddOns.defaultProps = {
    onGoBack: () => {},
};

MobileAddOns.propTypes = {
    modelLocalGroups: PropTypes.object.isRequired,
    hoopCode: PropTypes.string.isRequired,
    onGoBack: PropTypes.func,
};

export default MobileAddOns;
