import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './TextArea.styles';

const TextArea = ({
    onChange, cols, rows, value, name, maxLength,
}) => {
    return (
        <Styled.TextArea
            id={ name }
            name={ name }
            value={ value }
            maxLength={ maxLength }
            cols={ cols }
            rows={ rows }
            onChange={ onChange }
        />
       
    );
};

TextArea.defaultProps = {
    onChange: () => {},
    value: '',
    cols: 5,
    rows: 3,
    maxLength: null,
  
};

TextArea.propTypes = {
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    cols: PropTypes.number,
    rows: PropTypes.number,
    maxLength: PropTypes.number,

};

export default TextArea;
