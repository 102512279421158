import * as actionTypes from '../../actionTypes';
import { getInternalColors } from '../../../fetch/configurator_options';
import { throwErrorIfNoData } from '../../../fetch/fetchUtils';
import { mapInternalColors } from '../../dispatchers/utils';

const getInternalColorsPending = () => ({
    type: actionTypes.GET_INTERNAL_COLORS_PENDING,
});

const getInternalColorsSuccess = (data, externalColorCode, url) => ({
    type: actionTypes.GET_INTERNAL_COLORS_SUCCESS,
    data,
    externalColorCode,
    url,
});

export const getInternalColorsFailure = (error) => ({
    type: actionTypes.GET_INTERNAL_COLORS_FAILURE,
    error,
});

export const getInternalColorsAsync = (
    modelCode,
    externalColorCode,
    trimLevelCode,
    sOriginalAdditions,
) => {
    return async (dispatch) => {
        dispatch(getInternalColorsPending());
        try {
            const response = await getInternalColors(
                modelCode,
                externalColorCode,
                trimLevelCode,
                sOriginalAdditions,
                
            );
           
            const { data } = response;

            throwErrorIfNoData(data);

            const parsedColors = mapInternalColors(data);

            dispatch(getInternalColorsSuccess(parsedColors, externalColorCode));

            return parsedColors;
        } catch (error) {
            dispatch(getInternalColorsFailure(error));

            return [];
        }
    };
};
