import React from 'react';
import PropTypes from 'prop-types';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Styled from './ImageSwiper.styles';

const ImageSwiper = ({ images, view }) => {
    const isMultipleImages = images.length > 1;

    const renderSwiperSlides = () => {
        return images.map((image) => {
            return (
                <Styled.SwiperSlideCustom>
                    <Styled.Image key={ image } $src={ image } $view={ view } />
                </Styled.SwiperSlideCustom>
            );
        });
    };

    return (
        <Styled.SwiperContainer>
            <Styled.SwiperCustom
                dir="rtl"
                modules={ [Navigation, Pagination] }
                navigation={ {
                    enabled: true,
                    nextEl: '.custom-swiper-button-next',
                    prevEl: '.custom-swiper-button-prev',
                    disabledClass: 'swiper-button-disabled',
                } }
                pagination={ {
                    dynamicBullets: true,
                    clickable: true,
                } }
                slidesPerView={ 1 }
                centerInsufficientSlides
                loop
            >
                { isMultipleImages && (
                    <>
                        <Styled.NavigationButton className="custom-swiper-button-prev right">
                            <Styled.RightIcon />
                        </Styled.NavigationButton>
                        <Styled.NavigationButton className="custom-swiper-button-next left">
                            <Styled.LeftIcon />
                        </Styled.NavigationButton>
                    </>
                )}
                { renderSwiperSlides() }
            </Styled.SwiperCustom>
        </Styled.SwiperContainer>

    );
};

ImageSwiper.defaultProps = {
    images: [],
    view: 'contain',
};

ImageSwiper.propTypes = {
    images: PropTypes.array,
    view: PropTypes.string,
};

export default ImageSwiper;
