import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { COLORS } from '../../../constants';
import CONFIG from '../../../config';
import { presentationalFadeIn } from '../../../utils/animationsConfig';

const { DEVICE_SIZE } = CONFIG;

export const Presentation = styled(motion.div).attrs({
    ...presentationalFadeIn,
})`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    position: relative;
  }
  ${({ $isSingleImage }) => $isSingleImage
    && css`
      height: 100%;
      @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
        height: ${({ theme }) => theme.presentation.mobile.height};
      }
      & img {
        max-width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-height: 100%;
      }
      & img.cover{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    `};
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  position: relative;
  background-color: ${COLORS.WHITE};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    height: 200px;
  }
`;

export const NoPreviewWrapper = styled(LoaderWrapper)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  position: relative;
  background-color: ${COLORS.WHITE};
  z-index: 0;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    height: 200px;
  }
`;

export const ThumbnailImage = styled.div.attrs(() => ({
    role: 'button',
}))`
  height: 120px;
  width: 185px;
  box-shadow: 0px 0px 25px #0000006f;
  border: 5px solid ${COLORS.WHITE};
  position: absolute;
  left: 54px;
  bottom: 22px;
  cursor: pointer;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${({ isCover }) => (isCover ? 'cover' : 'contain')};
  background-color: ${COLORS.WHITE};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: none;
  }
`;
