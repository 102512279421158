import Car360Presentation from '../../Car360Presentation/Car360Presentation';
import AddOnsMenu from '../../AudiConfigurator/Steps/Addons/AddOnsMenu/AddOnsMenu';
import Configuration from './Configuration';

const AddOns = {};

AddOns.Configuration = Configuration;
AddOns.Presentation = Car360Presentation;
AddOns.AudiAddOnsMenuList = AddOnsMenu;
AddOns.AudiAddonsList = Car360Presentation;

export default AddOns;
