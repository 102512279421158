/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { getWarrentyLink } from '../../../../../utils/assetsUtils';
import { MENUFACTURS } from '../../../../../constants';

const TermsCupra = () => {
    return (
        <ol style={ { textAlign: 'right' } }>
            <li aria-level="1"><strong>כללי</strong></li>
            <ol>
                <li aria-level="2"><span >אתר צ'מפיון מוטורס בע"מ (להלן "האתר") הינו בבעלות חברת צ'מפיון מוטורס בע"מ (להלן "החברה") ומופעל על ידה. האתר משמש כאתר סחר אלקטרוני לרכישת רכב חדש המיובא על ידי החברה על ידי הגולשים באתר (להלן בהתאמה: "הרכב" ו-"המשתמש/ים"").</span></li>
                <li aria-level="2"><span >הוראות תקנון זה יחולו על כל גלישה ו/או רכישת רכב באתר, על ידי המשתמשים באתר, עשויות להשתנות מעת לעת, על פי שיקול דעתה הבלעדי של החברה. אשר על כן, טרם ביצוע פעולה כלשהי באתר, מתבקש המשתמש לקרוא, בעיון רב, את תנאי השימוש, לרבות&nbsp;</span><a href="/privacy-policy"><span >מדיניות הפרטיות</span></a><span >.</span></li>
                <li aria-level="2"><span >החברה שומרת לעצמה את הזכות לשנות, למחוק ו/או להוסיף על תנאי שימוש אלו בכל עת וללא התחייבות למתן הודעה מוקדמת ו/או בדיעבד. כל תוספת ו/או שינוי שנעשה באתר, בתוכן האתר ו/או בתנאים אלו יחייבו אותך עם המשך שימושך בו. לפיכך, אנו ממליצים לך לעיין בתנאים אלו מעת לעת. שימושך באתר לאחר ביצוע השינויים מעיד על הסכמתך לשינויים אלו. ככל שאינך מסכים לשינויים אנא הימנע מהמשך שימוש באתר או פנה אלינו לסיוע כדי לתת לך פתרונות חלופיים.</span></li>
                <li aria-level="2"><span >נוסח התקנון המחייב והקובע בכל מועד הינו נוסח התקנון המפורסם באתר.</span></li>
                <li aria-level="2"><span >בכל מקרה של סתירה ו/או אי התאמה, מכל מין וסוג שהוא בין תוכן האתר לבין הוראות תקנון זה, יגברו ויחולו הוראות התקנון.</span></li>
                <li aria-level="2"><span >תוקף תנאי השימוש הינו מצטבר ולא לחילופין ותנאי השימוש יפורשו כמתקיימים זה לצד זה ולא מצמצמים זה את תחולת זה.</span></li>
                <li aria-level="2"><span >שימוש באתר, לרבות גלישה ו/או רכישת רכב על ידי המשתמש, מהווה אישור כי המשתמש קרא את הוראות תקנון זה וכן כי הוא מסכים להוראות תקנון זה, לרבות לתנאי מדיניות הפרטיות, המפורטים בתקנון זה, כפי שיעודכנו מעת לעת, וכן מסכים לפעול לפיהן ולקבלן ללא כל הגבלה או הסתייגות. אם אינך מסכים לתנאי השימוש, אנא הימנע משימוש באתר או פנה אלינו כדי שנוכל לסייע לך בדרכים חלופיות לשביעות רצונך.</span></li>
                <li aria-level="2"><span >לקבלת מידע מפורט אודות מדיניות הפרטיות באתר,&nbsp;לחצו כאן.</span></li>
                <li aria-level="2"><span >מובהר בזאת, כי השימוש בלשון זכר בתנאי השימוש, נעשה מטעמי נוחות בלבד ואין בו כדי לפגוע ו/או ליצור אפליה כלשהי.</span></li>
                <li aria-level="2"><span >המשתמש רשאי לעשות שימוש באתר לצרכים אישיים ובלתי מסחריים בלבד. חל איסור לעשות באתר ו/או בכל חלק ממנו שימוש למטרה שאיננה לצורך אישי או בלתי מסחרי.</span></li>
                <li aria-level="2"><span >הרישום לאתר וכן השימוש במידע אותו מסר המשתמש לחברה ו/או שהצטבר אודות המשתמש בעת השימוש באתר, יתבצע בהתאם להוראות כל דין ובהתאם למדיניות הפרטיות של האתר, המהווה חלק בלתי נפרד מתנאי השימוש.</span></li>
            </ol>
            <li aria-level="1"><strong>שימושים אסורים באתר</strong></li>
            <ol>
                <li aria-level="2"><span >ללא מתן הרשאה כתובה מראש על-ידי החברה, אינך רשאי לבצע את הפעולות ו/או המעשים הבאים בעת שימושך באתר ובתוכן האתר:</span></li>
                <ol>
                    <li aria-level="3"><span >לעשות שימוש מסחרי באתר ו/או בתוכן האתר.</span></li>
                    <li aria-level="3"><span >להעתיק, לשחזר, לשנות, לעבד, לתרגם, לבצע הנדסה חוזרת, להפיץ, לשדר, להציג, לבצע, לשכפל, לפרסם ולאחסן את תוכן האתר, כולו או חלקו.</span></li>
                    <li aria-level="3"><span >להפעיל או לאפשר הפעלת כל יישום מחשב או כל אמצעי אחר, לרבות תוכנות מסוג Crawlers, Robots וכדומה, לשם חיפוש, סריקה, העתקה או אחזור אוטומטי של תוכן האתר.</span></li>
                    <li aria-level="3"><span >להציג תוכן מהאתר בתוך מסגרת (iframe) גלויה או סמויה או להציג את תוכן האתר בכל דרך שהיא, לרבות באמצעות כל תוכנה, מכשיר, אביזר או פרוטוקול תקשורת, המשנים את עיצובם המקורי באתר ו/או מחסירים דבר כלשהוא.</span></li>
                    <li aria-level="3"><span >לשבש או להפר כל זכות של משתמש אחר באתר, לרבות הזכות לפרטיות ו/או לאסוף מידע אישי על המשתמשים באתר ללא הסכמתם המפורשת בכתב, לרבות באמצעים אוטומטיים.</span></li>
                    <li aria-level="3"><span >לפגוע בכבודו או בפרטיותו של משתמש אחר ו/או להשתמש באתר ו/או בתוכן האתר כדי לפגוע בשמו הטוב של כל אדם ו/או לפרסם דברי הסתה, הונאה, תרמית, לשון הרע ו/או כל מידע אחר שהינו שקרי, בלתי אמין או שיש בו כדי לפגוע במזיד.</span></li>
                    <li aria-level="3"><span >שימוש באתר או בתוכן האתר לשם יצירת מאגר מידע ו/או לקט.</span></li>
                </ol>
                <li aria-level="2"><span >אי עמידה במגבלות אלו עלולה להוביל למניעת גישתך לאתר ואף לחשוף אותך לאחריות אזרחית ו/או פלילית, על-פי כל דין.</span></li>
            </ol>
            <li aria-level="1"><strong>תוכן האתר ופרסומות</strong></li>
            <ol>
                <li aria-level="2"><span >האתר מציע למשתמשים רכישה של רכב מתוצרים שונים, מדגמים שונים וברמת אבזור שונה, והכול בהתאם לשיקול דעתה הבלעדי של החברה. מובהר בזאת כי החברה רשאית בכל עת ועל פי שיקול דעתה הבלעדי להוסיף ו/או לגרוע רכב המוצע למכירה באתר וכן לקבוע את אופן מכירתו ואת מחירו.</span></li>
                <li aria-level="2"><span >האתר בכללותו, כולל כל התכנים המופיעים בו והרכב הנמכר בו מותאמים לישראל ומיועדים לשימוש בישראל, אלא אם כן צוין אחרת ומוצעים לציבור ולמשתמש כמות שהם ('As Is').</span></li>
                <li aria-level="2"><span >כוונת החברה היא שהתוכן המופיע באתר יהיה נכון ומדויק ואולם, יתכן והתוכן אינו שלם ולחלופין יתכן ונפלו טעויות טכניות או אחרות בתוכן.</span></li>
                <li aria-level="2"><span >החברה אינה אחראית לאי דיוקים, או לטעויות שנפלו ביחס לתוכן. החברה מבהירה בזאת שהתוכן הכלול באתר אינו מהווה המלצה ו/או חוות דעת ביחס לרכב המתפרסם באתר ו/או ביחס להתאמתו למשתמש מסוים, ועל כן כל הסתמכות על הצהרות, הבעות עמדה, עצות או כל תוכן אחר המוצג באתר ו/או במקום אחר שאיננו מטעם החברה נעשה לפי שיקול דעתו של המשתמש ובאחריותו הבלעדית; על המשתמש לבצע בעצמו את כל הערכותיו ובדיקותיו לגבי הרכב, לרבות נסיעת מבחן, והוא מוותר ויהיה מנוע מלהעלות כל טענה לגבי הסתמכות על מי מאלה.</span></li>
                <li aria-level="2"><span >כל תוכן ו/או מידע המובא באתר בקשר למאפייני הרכב או לשירות הניתן בקשר לרכב (כדוגמת מימון או ביטוח) אשר מקורו בצדדים שלישיים, כגון יבואן ו/או יצרן הרכב ו/או ספק השירות (לפי העניין), לרבות מפרטים, הוראות הפעלה ונתונים שונים, נמסר על ידם ולחברה לא תהיה כל אחריות לגביו.</span></li>
                <li aria-level="2"><span >תכונות, דגמים, עיצוב, צבעים או חזות של רכב המתואר או מוצג באתר, לרבות כל תמונה או הצגה של רכב הכלולים באתר, הינם על מנת לדמות למשתמש את הרכב המבוקש בצורה הקרובה והטובה ביותר. ככל ומשתמש זיהה ו/או חושד כי ישנו פער בין נראות הרכב המבוקש בפועל לבין הרכב המופיע באתר, בצמוד לתיאור הרכב המבוקש, אנא פנה לחברה אשר תפעל לתקנו בהקדם, בהתאם לצורך. בכל מקרה, האמור במפרט הטכני גובר על התצלום המופיע ליד פירוט הרכב.</span></li>
                <li aria-level="2"><span >באתר עשויים להיכלל קישורים ('לינקים') לאתרי אינטרנט אחרים, שאינם מופעלים על-ידי החברה. הקישורים נועדו אך ורק לנוחותם ולידיעתם של המשתמשים. קישורים אלו אינם בשליטתה של החברה והיא אינה מפקחת על תוכן האתרים המקושרים. ככל שהאתר מכיל קישורים אלו, אין בכך כדי להעיד על הסכמה ו/או אחריות של החברה לתכנים המופיעים באתרים המקושרים ו/או מהווה ערובה לאמינותם, עדכניותם, תקינותם או חוקיותם ו/או למדיניות הפרטיות ותנאי השימוש הננקטים על ידי בעליהם. החברה אינה אחראית לכל תוצאה שתיגרם כתוצאה מהשימוש באתרים המקושרים ו/או מהסתמכות עליהם וממליצה לקרוא בעיון את תנאי השימוש ומדיניות הפרטיות של אתרים אלו, ככל שקיימים, ו/או לפנות לבעלי האתרים המקושרים בכל מקרה של טענה או בקשה בעניין. החברה רשאית לפי שיקול דעתה הבלעדי להסיר כל קישור מהאתר ו/או להוסיף קישורים נוספים. החברה אינה מתחייבת כי הקישורים לאתרי האינטרנט האמורים יהיו פעילים ויובילו לאתר אינטרנט פעיל.</span></li>
                <li aria-level="2"><span >כל שימוש בתוכן באתר, לרבות בתוכן המסופק על ידי החברה (לרבות מדריכי קניה, מאמרים, מפרטים, הוראות שימוש ו/או תמונות) ו/או בתוכן של צד שלישי, לרבות כל כניסה של המשתמש לאתרים של צדדים שלישיים על ידי הפניה באתר ו/או בפרסומת באתר, יתבצע על אחריותו הבלעדית של המשתמש. למשתמש לא תהיה כל טענה ו/או תביעה כנגד החברה בקשר לתוכן באתר, לרבות לתוכן המסופק על ידי החברה ו/או לתוכן של צד שלישי וכן, לרבות תוכן לגבי רכב ו/או מבצעים, בגין כל נזק ישיר או עקיף, הנובע משימוש ו/או כניסה כאמור ו/או בשל הסתמכות על תוכן של צד שלישי ו/או בשל פגיעה בפרטיות בעקבות שימוש כאמור.</span></li>
                <li aria-level="2"><span >החברה משתדלת שהמידע המובא באתר יהיה מדויק ככל הניתן. עם זאת, ייתכנו באתר ו/או בתוכן ובמידע המובאים בו, לרבות לגבי כל רכב ו/או שירות ו/או מידע על מבצעים, שיבושים, אי דיוקים וטעויות, בין היתר עקב מעשים ו/או מחדלים של החברה ו/או מי מטעמה ו/או של צדדים שלישיים. המשתמש פוטר בזאת את החברה מכל אחריות בשל המידע ו/או התוכן המובא על ידה באתר ו/או טעויות בו, לרבות בגין כל נזק, ישיר או עקיף, הנגרם למשתמש בשל כל תוכן ו/או המידע כאמור ו/או הסתמכות המשתמש עליו, לרבות בקשר לרכב, תיאורים, מחירים וכדומה.</span></li>
                <li aria-level="2"><span >המחיר המתפרסם באתר לצד הרכב המוצע למכירה הינו המחיר המקורי של הרכב כהגדרתו בהסכם הזמנת הרכב.</span></li>
                <li aria-level="2"><span >תנאיו&nbsp;ותוקפו של כל מבצע המפורסם או שיפורסם באתר יהיו כפופים למלאי שהוקצב לאותו מבצע כמפורסם באתר.</span></li>
            </ol>
            <li aria-level="1"><strong>אופן הטיפול בהזמנה</strong></li>
            <ol>
                <li aria-level="2"><span >רכישת רכב באתר מותנית ברישום לאתר ובמסירת פרטים אישיים בסיסיים כנדרש בדף ההרשמה. במסגרת ההרשמה על המשתמש לבחור בשם משתמש וסיסמא ולמסור פרטים בסיסיים אישיים שיופיע בדף ההרשמה כגון: שם, כתובת, דואר אלקטרוני, מספרי טלפון וכד' וכל פרט אחר אשר נדרש למוסרו בדף ההרשמה. כדי שההזמנה תבוצע במהירות וללא תקלות על המשתמש להקפיד על מסירת הפרטים הנכונים אחרת לא תוכל החברה להבטיח את ביצוע הרכישה.</span></li>
                <li aria-level="2"><span >מיד לאחר הקשת כל הפרטים וביצוע ההזמנה, תבצע החברה בדיקה של פרטי כרטיס האשראי באמצעותו שולמה המקדמה. הרכב יסופק למשתמש שרכש את הרכב רק לאחר קבלת אישור על תשלום המחיר המקורי בגין הרכב ובהתאם ליתר הוראות תנאי השימוש והסכם הזמנת הרכב.</span></li>
                <li aria-level="2"><span >באמצעות האתר ניתן להזמין רכב עד גמר המלאי. אם לאחר ההזמנה התברר כי הרכב אזל מהמלאי בשל נסיבות שלא היו ידועות בעת ביצוע ההזמנה, החברה תודיע למשתמש בהקדם האפשרי כי הרכב אינו במלאי. במקרה שכזה החברה תודיע למשתמש על ביטול ההזמנה או לחילופין תציע לו רכב אחר. בוטלה ההזמנה בנסיבות אלה, למשתמש לא תהא טענה בקשר לכך בכפוף להשבת המחיר המקורי או חלקו ששולם לחברה על ידי המשתמש, אם שולם.</span></li>
                <li aria-level="2"><span >ההזמנה תירשם במחשבי החברה. כמו כן יישלח אישור בדואר אלקטרוני ו/או במסרון על ביצוע ההזמנה תוך מספר שעות מסיום הליך ביצוע ההזמנה.</span></li>
                <li aria-level="2"><span >יובהר ויודגש, משלוח הדואר האלקטרוני ו/או המסרון אינם מהווים ראיה על ביצוע&nbsp;ההזמנה&nbsp;ואינם מחייבים את החברה. רישומי מחשב עיבוד הנתונים של החברה, המנהלת רישום ממוחשב של כל ההזמנות באתר כאמור, יהוו ראיה לכאורה לאמור בהם.</span></li>
                <li aria-level="2"><span >מובהר בזאת, כי הקלדת פרטים אישיים כוזבים אסורה לחלוטין ומהווה עבירה פלילית על פי הוראות חוק העונשין והעושה כן צפוי להליכים משפטיים, פליליים ואזרחיים, לרבות תביעות נזיקין בגין נזקים שיגרמו לחברה עקב שיבוש הליכי ההזמנה.</span></li>
                <li aria-level="2"><span >במקרה בו תשלום המקדמה ו/או יתרת המחיר המקורי לא אושר על ידי חברות האשראי ו/או הגורם המממן ו/או הבנקים יקבל המשתמש הודעה מתאימה. לצורך השלמת ההזמנה יידרש המשתמש ליצור קשר עם החברה בתוך 7 ימי עסקים ממועד קבלת ההודעה לצורך הסדרת התשלום. יובהר ויודגש, הזמנה תחשב כמושלמת רק לאחר תשלום המחיר המקורי.</span></li>
                <li aria-level="2"><span >בקשה של המשתמש לשינוי פרטי כרטיס אשראי או אופן החיוב, ככל שתאושר על ידי החברה על פי שיקול דעתה הבלעדי, עשויה להיות מחויבת בתשלום עמלה על ידי חברות האשראי ו/או הגורם המממן ו/או הבנקים והמשתמש מתחייב לשאת בתשלום עמלה כאמור.</span></li>
                <li aria-level="2"><span >מובהר כי האתר משמש למכירה קמעונאית בלבד. מחירי הרכב המוצעים באתר והמבצעים שבו תקפים להזמנת רכב באמצעות האתר בלבד והם אינם חלים באולמות התצוגה של החברה ו/או מי מטעמה.</span></li>
            </ol>
            <li aria-level="1"><strong>הזכאים להשתמש האתר</strong></li>
            <ol>
                <li aria-level="2"><span >הזכאות להשתמש באתר בהתאם לתנאיו ולהוראותיו, וליהנות משירותיו תינתן לכל משתמש העומד בתנאים הבאים:</span></li>
                <ol>
                    <li aria-level="3"><span >שימוש כללי באתר יותר לכל משתמש באשר הוא.</span></li>
                    <li aria-level="3"><span >אולם, רכישת רכב יוכל לבצע רק משתמש הכשיר על פי דין לבצע פעולה משפטית אשר הזדהה באופן מלא בהתאם למדיניות החברה. במקרה שכזה, תהיה אסורה הרשמה לאתר ו/או התחזות לאדם או לתאגיד במהלך השימוש באתר, ומובהר כי הדבר מהווה הפרה חמורה של תקנון האתר.</span></li>
                    <li aria-level="3"><span >למען הסר ספק מובהר כי לחברה נתונה הרשות בכל עת ועל פי שיקול דעתה הבלעדי למנוע ממשתמשים את הגישה לביצוע פעולות שונות באתר החברה, במקרים הבאים:</span></li>
                    <ol>
                        <li aria-level="4"><span >היה והתנהגותם אינה הולמת ו/או היה והם מבצעים שימוש בלתי חוקי או בלתי ראוי או בלתי סביר באתר, לרבות אי ציות לתנאי השימוש ו/או למדיניות הפרטיות.</span></li>
                        <li aria-level="4"><span >היה ויש בהתנהגותם כדי לגרום לפגיעה בבטיחות, במיתקנים או בציוד של החברה או ברכב המוצגים באתר;</span></li>
                        <li aria-level="4"><span >היה ובמועד השימוש באתר, המשתמשים חייבים תשלום לחברה תמורת שירות שקיבלו ממנה בעבר שמועד פירעונו חלף;</span></li>
                    </ol>
                </ol>
            </ol>
            <li aria-level="1"><strong>ביטול הזמנת רכב, מועדי אספקה, אחריות</strong></li>
            <ol>
                <li aria-level="2"><span >בהתאם לקבוע בתנאי הסכם הזמנת הרכב כפי שייחתם על ידי הצדדים.&nbsp;תוכלו למצוא דוגמא של הסכם הזמנה ללא הפרטים המלאים של הרכב&nbsp;</span><a href="https://drive.google.com/file/d/1-e40XjdtUeDyhDJRHEolOgw8VtpwW6ds/view"><strong>כאן</strong></a><span >&nbsp;ושל תעודת האחריות לרכב באתר&nbsp;</span><a href={ getWarrentyLink(MENUFACTURS.CUPRA) } rel="noreferrer" target="_blank"><strong>כאן</strong></a></li>
            </ol>
            <li aria-level="1"><strong>בעלות וזכויות קניין רוחני</strong></li>
            <ol>
                <li aria-level="2"><span >האתר והמידע המופיעים בו לרבות עיצוב האתר, לרבות כל מידע מכל מין וסוג שהוא, ובכלל זה כל תוכן מילולי, חזותי, קולי, אור-קולי (audio-visual), ולרבות טקסט, תמונה, קול, וידאו, בכל אמצעי מדיה ובכל מכשיר קצה, הקיימים היום ו/או שיהיו קיימים בעתיד ו/או כל שילוב שלהם לרבות כתבות, מאמרים, ידיעות, סקירות, נתונים, קבצים, מפות, עצות, ניתוחים, המלצות, הנחיות, הערכות וכל מידע ו/או תוכן אחר בכל פורמט שהוא, וכן עיצובם, עיבודם, עריכתם, הפצתם ודרך הצגתם, לרבות (אך לא רק): כל תמונה, שרטוט, עיצוב, צילום, איור, הנפשה (animation), תרשים, דמות, הדמיה, דגימה (sample), סרטון, קובץ קולי וקובץ מוסיקלי; כל תוכנה, קובץ, קוד מחשב, יישום, תסדיר (format), פרוטוקול, מאגר נתונים וממשק וכל תו, סימן, סמל וצלמית (icon) (להלן "התוכן" או "התכנים"), מוגנים על-ידי דיני זכויות יוצרים של מדינת ישראל, אמנות בינ"ל וחוקי זכויות יוצרים של מדינות אחרות. נאסר על המשתמש להכניס שינויים, להעתיק, לפרסם, להפיץ, לשדר, להציג, לבצע, לשכפל, להנפיק רישיון, ליצור עבודות נגזרות או למכור כל חלק מן התוכן הכלול באתר ללא הסכמתה המוקדמת, הכתובה והמפורשת של החברה.</span></li>
                <li aria-level="2"><span >כל זכויות הקניין הרוחני בקשר עם האתר מכל מין וסוג שהוא (וזאת בין אם המדובר בזכויות רשומות ובין אם המדובר בזכויות שטרם נרשמו), ובכלל זאת שם המתחם ("Domain"), הפטנטים, סימני המסחר, שמות המסחר, המדגמים, זכויות יוצרים, סודות מסחריים, המוניטין, 'Know How', שיטות,&nbsp;קוד המקור והקוד הבינארי, סמלים מסחריים, תוכנות מחשב, מושגים, מידע סודי, סימני שירות, מאגרי מידע, יצירות נגזרות, תגליות, נוסחאות, מוניטין, רעיונות, שיפורים, מדגמים, מידע, חידושים, המצאות, ידע, לוגו, נתוני שוק, שיטות, זכויות מוסריות, יצירות ספרותיות, קובץ גרפי, מידע טכני, אופן הצגתו ועיצובו של האתר וכן כל עניין או פרט הקשורים באתר, הם רכושה הבלעדי של החברה או של צד שלישי, אשר הרשה לחברה להשתמש בהן, והשימוש בכל הזכויות האמורות מותר לחברה בלעדית (או לצדדים שלישיים נוספים, במידה והם בעלי רישיון לעשות כן). אין להעתיק, להפיץ, לשכפל, למכור, לתרגם ו/או לבצע פעולה אחרת כלשהי, מסחרית ושאינה מסחרית, בכל סוגי הטקסטים ו/או קודים ו/או תמונות ו/או סימני מסחר ו/או תצלומים ו/או סרטונים ו/או כל תוכן אחר המופיע באתר, אלא אם התקבל אישור החברה לכך מראש ובכתב.</span></li>
                <li aria-level="2"><span >מבלי לגרוע מהאמור, שם החברה וסימני המסחר שלה, לרבות אובייקטים הקשורים למוניטין החברה, כגון (אך לא רק): סימנים, לוגו, סמלילים, סימני שירות, או כל אובייקט אחר כפי שהם מופיעים באתר, הינם קניינה הבלעדי של החברה ואין לעשות בהם כל שימוש.</span></li>
            </ol>
            <li aria-level="1"><strong>הגבלת אחריות</strong></li>
            <ol>
                <li aria-level="2"><span >החברה עושה מאמצים רבים כדי לספק לך חווית שימוש איכותית ובטוחה באתר. עם זאת, האתר אינו חסין מתקלות ו/או בעיות ועשויה שלא להיות לך גישה לאתר מעת לעת.</span></li>
                <li aria-level="2"><span >ידוע למשתמש, כי האתר, לרבות כל תוכן ו/או שירות בו, ניתנים על ידי החברה במצבם כפי שהם ובכפוף לזמינותם ('As Is' ו- 'As Available'). החברה לא תישא בכל אחריות שהיא, מפורשת או משתמעת בקשר עם האתר, לרבות כל תוכן או שירות בו, וכן בקשר עם התאמתם למטרה ספציפית ו/או לדרישות המשתמש. המשתמש מצהיר ומתחייב בזה, כי הוא אחראי באופן בלעדי ומלא לכל שימוש שהוא עושה באתר, לרבות בכל תוכן ו/או שירות בו, וכי ידוע לו שהחברה אינה אחראית, בין במישרין בין בעקיפין, לכל שימוש שהוא עושה באתר כאמור.</span></li>
                <li aria-level="2"><span >החברה ו/או מי מטעמה, לא יישאו בכל אחריות שהיא, לנזק ו/או אובדן, ישיר או עקיף, לרבות נזק נלווה, תוצאתי, אקראי או פיצויים עונשיים (ובכלל זה, ומבלי לגרוע מכלליות האמור, פיצויים בגין הפסד עבודה ועסקים, הפסד רווחים, הפסקות והפרעות עבודה, הפסד ו/או איבוד מידע עסקי, פגיעה במוניטין, וכל הפסד ו/או נזק כספי אחר, צפוי ושאינו צפוי), הנובעים ו/או הקשורים, באתר, או בכל תוכן ו/או שירות בו, או בכל שימוש בהם, ולרבות אך מבלי לגרוע:</span></li>
                <ol>
                    <li aria-level="3"><span >בשימוש ו/או אי יכולת שימוש באתר, לרבות כל תוכן ו/או שירות בו, מכל סיבה שהיא;</span></li>
                    <li aria-level="3"><span >הודעות ו/או קבצים שנתקבלו אצל המשתמש במהלך ו/או עקב השימוש באתר, לרבות כל תוכן ו/או שירות בו;</span></li>
                    <li aria-level="3"><span >שימוש או הסתמכות על מידע ותכנים המתפרסמים באתר, בין על ידי החברה ובין על ידי צדדים שלישיים;</span></li>
                    <li aria-level="3"><span >כל מעשה ו/או מחדל המתבצע בתוכן האתר או בקשר עמו לאחר מסירתו לצדדים שלישיים;</span></li>
                    <li aria-level="3"><span >הפרעות, זמינות ותקינות של האתר, לרבות כל תוכן ו/או שירות בו, מכל סיבה שהיא, ולרבות, הנובעים משיבושים או מכשלים ברשת האינטרנט או ברשת הטלפוניה;</span></li>
                    <li aria-level="3"><span >נזק או אובדן אשר נגרם כתוצאה משגגה, טעות, אי דיוק וכדומה, בתוכן באתר.</span></li>
                </ol>
                <li aria-level="2"><span >החברה אינה אחראית לכל פעילות בלתי חוקית שתתבצע, ככל שתתבצע, על ידי מי מהמשתמשים באתר ו/או כל גורם אחר שאין לה שליטה עליו.</span></li>
                <li aria-level="2"><span >שיבוש בקליטת פרטי המשתמשים במחשבי החברה מכל סיבה שהיא, לרבות תקלה הקשורה לחברה, לא יהווה עילה לכל טענה ו/או תביעה מצד המשתמשים.</span></li>
                <li aria-level="2"><span >אין בהגבלת האחריות בסעיף &rlm;זה, בכדי לגרוע מכל הגבלת אחריות אחרת המפורטת בתנאי השימוש, לרבות מדיניות הפרטיות.</span></li>
            </ol>
            <li aria-level="1"><strong>ציות לתנאים ושיפוי</strong></li>
            <ol>
                <li aria-level="2"><span >המשתמש מתחייב בזאת לציית לכל הוראות הדין, לרבות חוק ו/או תקנה, העשויים לחול על שימושו באתר ו/או בתוכנו, לרבות תנאי שימוש אלו.</span></li>
                <li aria-level="2"><span >כמו כן, המשתמש מתחייב לשפות את החברה ו/או מי מטעמה בגין כל נזק,&nbsp;הפסד, אובדן רווח,&nbsp;תשלום או הוצאה שיגרמו להם, במישרין או בעקיפין, ובכלל זה פגיעה במוניטין, פגיעה כלכלית ו/או מסחרית, שכ"ט עו"ד והוצאות משפט בגין כל טענה, דרישה ו/או תביעה&nbsp;הנובעים, במישרין ו/או בעקיפין, מהפרת תנאי השימוש ו/או כל ממעשה ו/או מחדל של המשתמש ו/או מכל חיוב בו תחויב החברה ו/או מי מטעמה אשר על פי תנאי השימוש לחברה אין אחריות לגביו; או שתועלה נגדם על ידי צד שלישי כלשהו כתוצאה משימוש פסול ו/או בלתי חוקי על ידי המשתמש באתר ו/או בתוכנו, לרבות הפרת תנאים אלו.</span></li>
            </ol>
            <li aria-level="1"><strong>זמינות ו/או שינוי האתר</strong></li>
            <ol>
                <li aria-level="2"><span >החברה רשאית להתיר או לאסור את גישת המשתמש לאתר בכל זמן על פי שיקול דעתה הבלעדי ושומרת לעצמה את הזכות הבלעדית לשנות או להפסיק את הפעלת האתר, כולו או חלקו, בכל זמן וללא מתן הודעה.</span></li>
                <li aria-level="2"><span >מבלי לגרוע מהאמור, תהיה החברה רשאית להפסיק את הפעילות המתבצעת באתר, כולה או חלקה, באופן זמני ו/או קבוע, בכל עת, ומבלי שתהיה למשתמש כל טענה, זכות ו/או תביעה בקשר לכך.</span></li>
                <li aria-level="2"><span >החברה תהיה רשאית להתנות את הכניסה ו/או השימוש באתר, כולו או חלקו, ברישום ו/או בתשלום, ומבלי שתהיה למשתמש כל טענה, זכות ו/או תביעה בקשר לכך.</span></li>
            </ol>
            <li aria-level="1"><strong>התיישנות</strong></li>
            <ol>
                <li aria-level="2"><span >מבלי לגרוע מהאמור בתקנון תנאי שימוש זה, המשתמש מודע, מסכים ומאשר בזאת כי תקופת ההתיישנות לגבי כל תביעה ו/או טענה, ו/או דרישה כנגד החברה ו/או צדדים שלישיים הקשורים לשימוש באתר על ידי המשתמש ו/או לרכב המופיעים באתר, תוגבל לתקופה של 6 חודשים וכלל הצדדים רואים בכך כהסכם לתקופת ההתיישנות כמשמעו בסעיף 19 בחוק ההתיישנות, תשי"ח-1958.</span></li>
            </ol>
            <li aria-level="1"><strong>סמכות שיפוט וברירת דין</strong></li>
            <ol>
                <li aria-level="2"><span >על כל שימוש באתר ו/או&nbsp;על כל תביעה, דרישה או בקשה הנובעים משימוש זה ו/או מכל דבר ועניין אחר הקשורים באופן ישיר או עקיף לאתר זה יחולו דיני מדינת ישראל בלבד. מקום השיפוט יהיה בבית המשפט לו הסמכות בתל-אביב-יפו, ישראל בלבד, והדין המהותי והפרוצדוראלי שיחול יהיה הדין הישראלי,&nbsp;אשר יגבר על כל כללי ברירת דין המפנים לתחולת דין זר.</span></li>
            </ol>
            <li aria-level="1"><strong>שונות</strong></li>
            <ol>
                <li aria-level="2"><span >תנאי שימוש המפורטים לעיל אינם יוצרים ולא יפורשו כיוצרים כל שותפות, מיזם משותף, יחסי עובד מעביד, סוכן או שלוח בין המשתמש לבין החברה.</span></li>
                <li aria-level="2"><span >אם תנאי מתנאי השימוש ימצא בלתי חוקי, בטל או בלתי אכיף מכל סיבה, תנאי זה ימחק מתנאי השימוש ומחיקתו לא תשפיע על חוקיות ותקפות תנאי&nbsp;השימוש הנותרים. במקרה שכזה, יראו בקיומו של תנאי דומה אחר אכיף במקום התנאי שנמחק או הוסר.</span></li>
                <li aria-level="2"><span >תנאי שימוש אלו מהווים את ההסכם המלא בינך לבין החברה בכל הנוגע לאופן השימוש באתר ומחליפים כל הבנה ו/או הסכמה אחרת, בעל-פה או בכתב, הנוגעת לשימוש באתר ובתוכנו.</span></li>
            </ol>
            <li aria-level="1"><strong>יצירת קשר</strong></li>
            <ol>
                <li aria-level="2"><span >בכל עניין, שאלה ו/או בקשה, אנא פנו אל שירות הלקוחות של החברה באמצעות השארת פנייה באתר&nbsp;או באמצעות הצ'אט&nbsp;בעמוד&nbsp;</span><a href="/contact"><strong>צור קשר</strong></a><strong>&nbsp;</strong><span >או בדוא"ל לכתובת</span><a href="mailto:info_cupra@champ.co.il"><strong>&nbsp;info_cupra@champ.co.il</strong></a><strong>&nbsp;</strong><span >.</span></li>
            </ol>
        </ol>
    );
};

export default TermsCupra;
