import styled from 'styled-components';
import { pxToRem } from '../../utils/commonUtils';

export const ColorsRangeRuler = styled.div`
  display:flex;
  align-items:center;
  margin-top: 15px;
  z-index:10;
  position:relative;
`;

export const Title = styled.span`
  font-weight:400;
  font-size:${pxToRem(12)};
  text-align: center;
  line-height: 1;
`;

export const ColorsRangeRulerList = styled.ul`
  margin: 0;
  list-style-type: none;
  padding: 0;
  display:flex;
  direction:ltr;
  padding:0 5px;
`;
