/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { MENUFACTURS } from '../../../../../constants';

const WhereToSeeTheVehicle = ({ brand }) => {
    const isVwc = brand === MENUFACTURS.VOLKSWAGEN_COMMERCIAL;
    const whereToSeeTheVehicleValue = isVwc ? 'ניתן להתרשם מהרכב באולמות תצוגה:\nאולם תצוגה פולקסווגן, ראשל״צ\nאולם תצוגה פולקסווגן, ירושלים\nאולם תצוגה פולקסווגן, סוכנות דור, נצרת' : 'ניתן להתרשם מדגם הרכב (גם אם ברמת גימור שונה) גם באולמות התצוגה שכתובתם מצוינת ברשימת אולמות התצוגה';

    return (
        <p>{ whereToSeeTheVehicleValue }</p>
    );
};

WhereToSeeTheVehicle.propTypes = {
    brand: PropTypes.string.isRequired,
};

export default WhereToSeeTheVehicle;
