import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getStepContinueBtnTitle } from '../../../../utils/configuratorUtils';
import STRINGS from '../../../../language';
import { CONFIGURATOR_STEPS } from '../../../../constants';
import PaymentDetailsModal from '../../PaymentDetailsModal/PaymentDetailsModal';
import ContinueButton from '../ContinueButton/ContinueButton';
import * as Styled from './ConfiguratorFooter.style';

const ConfiguratorFooter = ({
    fullPrice,
    isContinueButtonDisabled,
    onContinue,
    nextStep,
}) => {
    const [isPaymentDetailsVisible, setIsPaymentDetailsVisible] = useState(false);

    return (
        <Styled.Footer>
            <Styled.RightSide>
                <ContinueButton
                    title={ getStepContinueBtnTitle(nextStep, CONFIGURATOR_STEPS) }
                    isDisabled={ isContinueButtonDisabled }
                    onClick={ onContinue }
                />
            </Styled.RightSide>
            <Styled.LeftSide>
                <Styled.PaymentDetailsContainer>
                    <Styled.PriceContainer>
                        <Styled.PriceDescription>
                            {STRINGS.FOOTER_PRICE_INCLUDES_VAR_AND_LICENSING_FEE}
                        </Styled.PriceDescription>
                        <Styled.FullPrice>{fullPrice} &#8362;</Styled.FullPrice>
                    </Styled.PriceContainer>
                    <Styled.PaymentDetailsButton
                        onClick={ () => setIsPaymentDetailsVisible(!isPaymentDetailsVisible) }
                    >
                        <Styled.InfoIcon />
                        {STRINGS.DETAILED_PRICE}
                    </Styled.PaymentDetailsButton>
                </Styled.PaymentDetailsContainer>
            </Styled.LeftSide>
            <PaymentDetailsModal
                isPaymentDetailsVisible={ isPaymentDetailsVisible }
                onClose={ () => setIsPaymentDetailsVisible(false) }
            />
        </Styled.Footer>
    );
};

ConfiguratorFooter.propTypes = {
    fullPrice: PropTypes.string.isRequired,
    isContinueButtonDisabled: PropTypes.bool.isRequired,
    onContinue: PropTypes.func.isRequired,
    nextStep: PropTypes.string.isRequired,
};

export default ConfiguratorFooter;
