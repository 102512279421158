import styled, { css } from 'styled-components';
import { Text } from '../../../Engine/Engine.styles';
import CONFIG from '../../../../../../config';
import { ContentWrapper as ToolTipContentWrapper }
    from '../../../../../../components/QuestionMarkTooltip/QuestionMarkTooltip.styles';
import { pxToRem } from '../../../../../../utils/commonUtils';

const { DEVICE_SIZE } = CONFIG;

export const EquipmentScreenItem = styled.div`
  display: flex;
  &:nth-of-type(odd) {
    background-color: #ebebeb;
  }
`;
export const Name = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex: 1 1 85%;
  max-width: 85%;
`;
export const TextWithEllipsis = styled(Text).attrs({
    dir: 'rtl',
})`
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    font-size: ${pxToRem(13)};
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const Icon = styled.div`
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 3px solid #fafaf9;
  &:after {
    content: "${({ $isAvailable }) => ($isAvailable ? '' : '-')}";
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    ${({ $isAvailable }) => $isAvailable
      && css`
        background-color: ${({ theme }) => theme.primaryColor};
        background-image: url(${({ $icon }) => $icon});
      `}
  }
`;

export const ToolTipContainer = styled.div`
  margin: 0 5px;
  flex-grow: 1;
  display: none;
  ${ToolTipContentWrapper} {
    right: auto;
    left: 0;
    &:after {
      left: 4px;
      right: auto;
      bottom: -3px;
    }
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: block;
  }
`;
