import React, {
    useCallback, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import Loader from '../../../Loader';
import {
    useActionDispatch,
    useCurrentAddOnGroup,
    useCurrentBrand,
    useCurrentCar,
    useCurrentHoop,
    useCurrentAddOns,
} from '../../../../hooks';
import { getScreenBlockErrorMsg } from '../../../../utils/configuratorUtils';
import {
    isObjectEmpty,
} from '../../../../utils/commonUtils';
import { CONFIGURATOR_QUERY_PARAMS } from '../../../../constants';
import useQueryParams from '../../../../hooks/useQueryParams';
import AddOnGroup from './AddOnGroup/AddOnGroup';
import MoreInformationModal from './MoreInformationModal/MoreInformationModal';
import * as Styled from './Configuration.styles';

const Configuration = ({ configOptions }) => {
    const currentBrand = useCurrentBrand();
    const {
        selectCurrentAddOnGroup,
        getModelLocalGroupsAsync,
        selectCurrentAddOn,
        selectIsInSummary,
    } = useActionDispatch();
    const { modelCode, carVersion } = useCurrentCar();
    const selectedHoop = useCurrentHoop();
    const selectedAddOnGroup = useCurrentAddOnGroup();
    const selectedAddOns = useCurrentAddOns();
    const { modelLocalGroups } = configOptions;
    const { addOns, isLoading, error } = modelLocalGroups;
    const pickedAddOnGroupCode = selectedAddOnGroup?.groupCode;
    const [isMoreInfoModalVisible, setIsMoreInfoModalVisible] = useState(false);
    const moreInfo = useRef({});
    const screenErrMsg = getScreenBlockErrorMsg(currentBrand);
    const {
        addQueryParam,
        deleteQueryParam,
    } = useQueryParams();
    const {
        title, price, local, image,
    } = moreInfo.current;
    const hoopCode = selectedHoop.value;
   
    const selectAddOnHandler = useCallback((addOn) => {
        const addOnsCodesArray = Object.keys(selectedAddOns).map(
            (key) => selectedAddOns[key].local);

        const addOnKey = `${addOn.groupCode}-${addOn.local}`;

        const isAddOnExist = addOnsCodesArray.includes(addOn.local);

        if (isAddOnExist) {
            deleteQueryParam(CONFIGURATOR_QUERY_PARAMS.ADDONS, addOnKey);
        } else {
            addQueryParam(CONFIGURATOR_QUERY_PARAMS.ADDONS, addOnKey);
        }

        selectCurrentAddOn(addOn);
        selectIsInSummary(false);
    }, [selectedAddOns]);
    
    const isSelected = selectedAddOns[selectedAddOnGroup?.groupCode]?.title
    === title;

    const selectAddOnGroupHandler = useCallback(
        (addOnGroup) => {
            let selectedAddOn = {};

            if (pickedAddOnGroupCode !== addOnGroup.groupCode) {
                selectedAddOn = addOnGroup;
            }

            selectCurrentAddOnGroup(selectedAddOn);
        },
        [pickedAddOnGroupCode],
    );

    const moreInfoModalVisibilityHandler = useCallback((data = {}) => {
        if (!isObjectEmpty(data)) {
            moreInfo.current = data;
        }
        setIsMoreInfoModalVisible((p) => !p);
    });

    const renderAddOnGroups = (addOnGroups) => {
        return addOnGroups?.map((addOn) => {
            const { groupCode, listLocalPackages, groupDescription } = addOn;

            return (
                <AddOnGroup
                    onSelect={ () => selectAddOnGroupHandler(addOn) }
                    onSelectAddOn={ selectAddOnHandler }
                    onOpenMoreInfoModal={ moreInfoModalVisibilityHandler }
                    isActive={ pickedAddOnGroupCode === groupCode }
                    groupCode={ groupCode }
                    addOnItems={ listLocalPackages }
                    title={ groupDescription }
                    key={ groupCode }
                />
            );
        });
    };

    const getAddons = () => {
        getModelLocalGroupsAsync(modelCode, hoopCode, carVersion);
    };

    // refetch addons every entry to update add-on list to after discount prices
    useEffect(() => {
        getAddons();
    }, []);

    useEffect(() => {
        if (!pickedAddOnGroupCode && addOns[hoopCode]) {
            // No AddOnGroup was selected - select the first group
            const firstAddOnGroup = addOns[hoopCode][0];

            selectAddOnGroupHandler(firstAddOnGroup);
        }
    }, [addOns[hoopCode]]);

    if (isLoading) {
        return (
            <Styled.LoaderWrapper>
                <Loader />
            </Styled.LoaderWrapper>
        );
    }

    if (error) {
        return (
            <Styled.ErrorWrapper>
                <ErrorMessage
                    color="red"
                    errorMessage={ screenErrMsg }
                />
            </Styled.ErrorWrapper>
        );
    }
    
    return (
        <Styled.AddOnsConfiguration>
            <MoreInformationModal
                title={ title }
                price={ price }
                local={ local }
                brand={ currentBrand }
                desktopImage={ image }
                mobileImage={ image }
                isVisible={ isMoreInfoModalVisible }
                onClose={ moreInfoModalVisibilityHandler }
                isSelected={ isSelected }
            />

            <Styled.AddOns>{renderAddOnGroups(addOns[hoopCode])}</Styled.AddOns>
        </Styled.AddOnsConfiguration>
    );
};

Configuration.propTypes = {
    configOptions: PropTypes.object.isRequired,
};

export default Configuration;
