import styled from 'styled-components';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const SystemDetails = styled.div`
 margin-left:30px;
 @media(max-width:${DEVICE_SIZE.MEDIUM}px){
   margin-left:0;
 }
`;
export const SystemDetailsList = styled.ul`
  list-style-type:none;
  margin:0;
  padding:0;
  display: grid;
    grid-template-columns: 1fr 1fr;
    @media(max-width:${DEVICE_SIZE.SMALL}px){
     display:flex;
     flex-wrap: wrap;
 }
`;
