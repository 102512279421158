import styled from 'styled-components';
import CONFIG from '../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const InnerTabs = styled.div`
  height: 42px;
`;
export const Tabs = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
    @media(max-width:${DEVICE_SIZE.LARGE}px){
      justify-content: initial;
      overflow-x:auto;
    }
`;
