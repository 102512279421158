/* eslint-disable import/no-mutable-exports */
import CONFIG from '../../../../config';
import { MENUFACTURS } from '../../../../constants';

let Configuration;

switch (CONFIG.brand) {
    case MENUFACTURS.VOLKSWAGEN_COMMERCIAL:
        Configuration = require(
            '../../../CommercialConfigurator/Steps/Color/Color',
        ).default;
        break;
    default:
        Configuration = require('./Configuration').default;
}

export default Configuration;
