import styled, { css } from 'styled-components';
import { ReactComponent as LeftArrow } from '../../../../assets/icons/left-arrow-thin.svg';
import { COLORS } from '../../../../constants';

export const LeftArrowIcon = styled(LeftArrow)`
  transition: transform .2s ease-in-out;
  display: inline-block;
  width: 25px;
  height: 25px;
  color: 'white';
`;

export const ContinueThemeButton = styled.button.attrs(({ $isDisabled }) => ({
    disabled: $isDisabled,
}))`
  flex: 1.5;
  width: 100%;
  height: 44px;
  max-width: 265px;
  padding: 0 32px;
  font-family: ${({ theme }) => theme['font-family']};
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  border: none;
  border-radius: ${({ theme }) => theme.buttonButtonRadius};
  outline: none;
  background-color: ${({ theme }) => theme.buttonColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: ${({ theme }) => theme.buttonTextColor};
  box-shadow: 2px 2px 4px 0px #00000040;
  transition: .2s ease-in-out;
  @media (hover: hover){
    &:hover{
      ${LeftArrowIcon} {
        transform:translateX(-5px);
      }
      color: ${({ theme }) => theme.buttonTextHoverColor};
      /* background-color: ${COLORS.BLACK}; */
      opacity: 0.8;
    }
  }
//if button disabled
  ${({ $isDisabled }) => $isDisabled
    && css`
      opacity: 0.5;
      user-select: none;
      pointer-events: none;
  `}
`;
