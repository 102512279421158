export const GET_EQUIPMENT_SPECIFICATIONS_PENDING = 'GET_EQUIPMENT_SPECIFICATIONS_PENDING';
export const GET_EQUIPMENT_SPECIFICATIONS_SUCCESS = 'GET_EQUIPMENT_SPECIFICATIONS_SUCCESS';
export const GET_EQUIPMENT_SPECIFICATIONS_FAILURE = 'GET_EQUIPMENT_SPECIFICATIONS_FAILURE';

export const GET_ENGINE_SPECIFICATIONS_PENDING = 'GET_ENGINE_SPECIFICATIONS_PENDING';
export const GET_ENGINE_SPECIFICATIONS_SUCCESS = 'GET_ENGINE_SPECIFICATIONS_SUCCESS';
export const GET_ENGINE_SPECIFICATIONS_FAILURE = 'GET_ENGINE_SPECIFICATIONS_FAILURE';

export const GET_MEASUREMENTS_SPECIFICATIONS_PENDING = 'GET_MEASUREMENTS_SPECIFICATIONS_PENDING';
export const GET_MEASUREMENTS_SPECIFICATIONS_SUCCESS = 'GET_MEASUREMENTS_SPECIFICATIONS_SUCCESS';
export const GET_MEASUREMENTS_SPECIFICATIONS_FAILURE = 'GET_MEASUREMENTS_SPECIFICATIONS_FAILURE';

export const GET_SPECIFICATIONS_INIT_PENDING = 'GET_SPECIFICATIONS_INIT_PENDING';
export const GET_SPECIFICATIONS_INIT_SUCCESS = 'GET_SPECIFICATIONS_INIT_SUCCESS';
export const GET_SPECIFICATIONS_INIT_FAILURE = 'GET_SPECIFICATIONS_INIT_FAILURE';

export const SET_SPECIFICATION_MODEL_CODE = 'SET_SPECIFICATION_MODEL_CODE';
export const TOGGLE_SPECIFICATIONS_MODAL = 'TOGGLE_SPECIFICATIONS_MODAL';

export const INITALIZE_SPECIFICATIONS = 'INITALIZE_SPECIFICATIONS';
