import React, { useState, useRef } from 'react';
import { useCurrentAppCommonData, useSelectedCurrentData } from '../../../hooks';
import { useCarPlaceHolderImageUrl, useOutCarImageUrl } from '../../../utils/assets.hooks';
import { isArrayAndNotEmpty } from '../../../utils/commonUtils';
import { MEDIA_MODE } from '../../../constants';
import STRINGS from '../../../language';
import Image from '../../Image/Image';
import Car360 from '../../Configurator/Car360/Car360';
import MediaModeButton from './MediaModeButton/MediaModeButton';
import ImagesSlider from './ImagesSlider/ImagesSlider';
import Styled from './Presentation.style';

const ExternalPresentation = () => {
    const {
        selectedCar,
        selectedExternalColor,
        selectedInternalColor,
    } = useSelectedCurrentData();
    const { modelGroupCode, trimLevelCode } = selectedCar;
    const {
        carImages: externalCarImages,
        link360: externalLink360,
        value: externalSelectedColorValue,
    } = selectedExternalColor;
    const {
        carImages: internalCarImages,
        link360: internalLink360,
    } = selectedInternalColor;
    const { currentBrand } = useCurrentAppCommonData();
    const carImageUrl = useOutCarImageUrl(
        currentBrand,
        modelGroupCode,
        trimLevelCode,
        externalSelectedColorValue);
    const fallbackSrc = useCarPlaceHolderImageUrl(currentBrand);
    const [mediaMode, setMediaMode] = useState(MEDIA_MODE.EXTERNAL);
    const mediaContainerRef = useRef(null);

    const carHasMedia = isArrayAndNotEmpty(externalCarImages) || !!externalLink360;
    const carHasBoth360AndMedia = isArrayAndNotEmpty(externalCarImages) && !!externalLink360;

    const toggleFullScreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            mediaContainerRef.current.requestFullscreen();
        }
    };

    const renderExternalContent = () => {
        if (!carHasMedia) {
            return (
                <Image
                    showLoading
                    fallbackSrc={ fallbackSrc }
                    src={ carImageUrl }
                    alt={ STRINGS.CAR_IMAGE_ALT }
                />
            );
        }

        if (externalLink360 && mediaMode === MEDIA_MODE.EXTERNAL) {
            return <Car360 src={ externalLink360 } />;
        }

        if (mediaMode === MEDIA_MODE.INTERNAL) {
            if (internalLink360) {
                return <Car360 src={ internalLink360 } />;
            }

            return <ImagesSlider images={ internalCarImages } />;
        }
        
        return <ImagesSlider images={ externalCarImages } />;
    };

    return (
        <Styled.Container ref={ mediaContainerRef }>
            <Styled.FullScreenButton onClick={ toggleFullScreen }>
                <Styled.FullScreenIcon />
            </Styled.FullScreenButton>
            <Styled.MediaButtonContainer>
                <MediaModeButton
                    isVisible={ carHasBoth360AndMedia }
                    isCar360Exist={ carHasBoth360AndMedia }
                    isCarImagesExist={ carHasBoth360AndMedia }
                    isInternalMediaExist={
                        isArrayAndNotEmpty(internalCarImages) || !!internalLink360
                    }
                    activeMode={ mediaMode }
                    onChangeActiveMode={ (mode) => setMediaMode(mode) }
                />
            </Styled.MediaButtonContainer>
            { renderExternalContent() }
        </Styled.Container>
    );
};

export default ExternalPresentation;
