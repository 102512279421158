import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import {
    useCurrentCar, useCurrentIsInSummary, useCheckoutIsFinished, useActionDispatch,
} from '../../hooks';
import HelmetTitleDescription from '../../components/HelmetTitleDescription/HelmetTitleDescription';
import { ROUTES } from '../../constants';
import CheckoutSiderBar from './CheckoutSiderBar/CheckoutSiderBar';
import CheckoutProgress from './CheckoutProgress/CheckoutProgress';
import * as Styled from './Checkout.styles';

const Checkout = () => {
    const currentCar = useCurrentCar();
    const { isSelected: isCarSelected } = currentCar;
    const isInSummary = useCurrentIsInSummary();
    const isCheckoutFinished = useCheckoutIsFinished();
    const { setIsInCheckout } = useActionDispatch();

    useEffect(() => {
        document.body.dataset.pageName = 'checkout';
        setIsInCheckout(true);
        
        return () => {
            document.body.dataset.pageName = '';
            setIsInCheckout(false);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [isCheckoutFinished]);
    
    /**
     * in case we go to checkout without going through summary,
     * go back to configurator
     */
    if (!isInSummary) {
        return <Navigate to={ currentCar.hrefToConfigurator ?? ROUTES.HOME } />;
    }

    if (!isCarSelected) {
        return <Navigate to={ ROUTES.HOME } />;
    }
    
    return (
        <Styled.CheckoutPage>
            <HelmetTitleDescription canonical={ window.location.href } />
            <Styled.Container>
                <CheckoutProgress />
                <CheckoutSiderBar />
            </Styled.Container>
        </Styled.CheckoutPage>
    );
};

export default Checkout;
