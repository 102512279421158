import React from 'react';
import PropTypes from 'prop-types';
import StepCard from './StepCard/StepCard';
import * as Styled from './StepCards.style';

const StepCards = ({ data }) => {
    return (
        <Styled.Container>
            { data.map((item) => {
                const {
                    key, title, content, icon,
                } = item;

                return <StepCard key={ key } title={ title } content={ content } icon={ icon } />;
            }) }
        </Styled.Container>
    );
};

StepCards.propTypes = {
    data: PropTypes.array.isRequired,
};

export default StepCards;
