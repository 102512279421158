import { TYPES } from '../constants';
import STRINGS from '../language';
import {
    getAddOnIconUrl,
    getEquipmentPackageImageUrl,
    getInColorIconUrl,
    getOutColorIconUrl,
    getWheelIconUrl,
} from './assetsUtils';
import { consoleLogInDev, getCurrentBrandFromEnv } from './commonUtils';

const brandFromEnv = getCurrentBrandFromEnv();

/**
 *
 * @param {import('../types').ResponseObject[]} data
 * @returns {import('../types').ParsedResponse}
 */
const parsePreSaleResponseData = (data) => {
    const initialobj = {
        carName: null,
        carManufacturer: null,
        brand: brandFromEnv,
        modelGroup: null,
        trimLevel: '',
        trimLevelCode: null,
        saleCenter: {
            name: null,
            phone: null,
            mail: null,
            id: null,
        },
        salesMan: {
            name: null,
            phone: null,
            mail: null,
        },
        modelPrice: {
            title: STRINGS.PAYMENT_TITLE_MODEL,
            prices: {
                price: null,
                discount: null,
                total: null,
            },
        },
        specification: {
            title: STRINGS.PAYMENT_TITLE_SPECIFICATION,
            items: [],
        },
        extras: {
            title: STRINGS.PAYMENT_TITLE_EXTRAS,
            items: [],
        },
        licenceFee: {
            title: STRINGS.PAYMENT_TITLE_LICENSE_FEE,
            prices: {
                price: null,
                discount: null,
                total: null,
            },
        },
        onlineDiscount: {
            title: STRINGS.PAYMENT_TITLE_ONLINE_DISCOUNT,
            prices: {
                price: null,
                discount: null,
                total: null,
            },
        },
        totalPayment: {
            title: STRINGS.PAYMENT_TITLE_TOTAL,
            prices: {
                price: null,
                discount: null,
                total: null,
            },
            isTotalPrice: true,
        },
        extraData: [],
    };
    const parsedObj = data.reduce((result, item) => {
        const {
            type, value, description, price, totalPrice, discount,
        } = item;

        switch (type) {
            case TYPES.MUTAG: {
                result.carManufacturer = description;
                break;
            }
            case TYPES.MODEL_GROUP: {
                result.modelGroup = value;
                break;
            }
            case TYPES.GIMUR: {
                result.trimLevel = description;
                result.trimLevelCode = value;
                break;
            }
            case TYPES.MODEL: {
                result.carName = description;
                result.modelPrice.prices = {
                    price: parseInt(price),
                    discount: parseInt(discount),
                    total: parseInt(totalPrice),
                };
                break;
            }
            case TYPES.NAME_SNIF: {
                result.saleCenter.name = description;
                result.saleCenter.id = value;
                break;
            }
            case TYPES.TELF_SNIF: {
                result.saleCenter.phone = description;
                break;
            }
            case TYPES.MAIL_SNIF: {
                result.saleCenter.mail = description;
                break;
            }
            case TYPES.NAME_USER: {
                result.salesMan.name = description;
                break;
            }
            case TYPES.TELF_USER: {
                result.salesMan.phone = description;
                break;
            }
            case TYPES.MAIL_USER: {
                result.salesMan.mail = description;
                break;
            }
            case TYPES.COLOR_INT:
            case TYPES.COLOR_OUT: {
                result.specification.items.push({
                    type,
                    icon: null,
                    value,
                    title: `${description}`,
                    typeDescription: null,
                    prices: {
                        price: parseInt(price),
                        discount: parseInt(discount),
                        total: parseInt(totalPrice),
                    },
                });
                break;
            }
            case TYPES.LMEMI: {
                result.extras.items.push({
                    type,
                    icon: null,
                    value,
                    title: description,
                    prices: {
                        price: parseInt(price),
                        discount: parseInt(discount),
                        total: parseInt(totalPrice),
                    },
                });
                break;
            }
            case TYPES.JANT: {
                const defaultHoop = description === STRINGS.HOOP;

                result.specification.items.push({
                    type,
                    icon: null,
                    value,
                    title: !defaultHoop ? description : `${STRINGS.HOOP_FOR_TRIM_LEVEL} ${result.trimLevel}`,
                    prices: {
                        price: parseInt(price),
                        discount: parseInt(discount),
                        total: parseInt(totalPrice),
                    },
                });
                break;
            }
            case TYPES.SAH: {
                result.totalPayment.prices = {
                    price: parseInt(price),
                    discount: parseInt(discount),
                    total: parseInt(totalPrice),
                };
                break;
            }
            case TYPES.AGRA: {
                result.licenceFee.prices = {
                    price: parseInt(price),
                    discount: parseInt(discount),
                    total: parseInt(totalPrice),
                };
                break;
            }
            case TYPES.MEHIRON:
            case TYPES.DATE_AGAA: {
                result.extraData.push({
                    text: description,
                });
                break;
            }
            case TYPES.MAAM: {
                result.tax = {
                    title: description,
                    price: parseInt(price),
                    discount: parseInt(discount),
                    total: parseInt(totalPrice),
                };
                break;
            }
            default: {
                break;
            }
        }

        return result;
    }, initialobj);

    parsedObj.totalPayment.prices.price += parsedObj.licenceFee.prices.total;
    parsedObj.totalPayment.prices.total += parsedObj.licenceFee.prices.total;

    // eslint-disable-next-line no-use-before-define
    setMediaUrls(parsedObj);

    consoleLogInDev(parsedObj);

    return parsedObj;
};

const setMediaUrls = (data) => {
    const { brand, modelGroup, trimLevelCode } = data;

    // car specifications icons
    data.specification.items.forEach((item) => {
        let icon = '';
        
        switch (item.type) {
            case TYPES.MEMIS: {
                icon = getEquipmentPackageImageUrl(brand, item.value, modelGroup, trimLevelCode);
                break;
            }
            case TYPES.COLOR_INT: {
                icon = getInColorIconUrl(brand, item.value);
                break;
            }
            case TYPES.COLOR_OUT: {
                icon = getOutColorIconUrl(brand, item.value);
                break;
            }
            case TYPES.JANT: {
                icon = getWheelIconUrl(brand, modelGroup, trimLevelCode, item.value);
                break;
            }
            default: {
                icon = '';
            }
        }

        item.icon = icon;
    });

    // car add-ons icons (original[memis], local[lmemis], jant)
    data.extras.items.forEach((item) => {
        let icon = '';

        switch (item.type) {
            case TYPES.LMEMI: {
                // 0 = memi-id, 1 = memi-group-id
                const memiData = item.value.split('-');

                icon = getAddOnIconUrl(brand, memiData?.[1]);
                break;
            }
            default: {
                icon = getAddOnIconUrl(brand, item.value);
            }
        }

        item.icon = icon;
    });
};

const isIE = () => {
    const ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones */
    const isIe = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

    return isIe;
};

/**
 *
 * @param {import('../types').ParsedResponse} data
 * @returns {boolean}
 */
const isCostValid = (data) => {
    const total = data.totalPayment?.prices?.total ?? 0;

    let calc = 0;
    const specification =    data.specification?.items.reduce(
        (acc, a) => acc + (a?.prices?.total ?? 0),
        0,
    ) ?? 0;
    const onlineDiscount = data.onlineDiscount?.prices?.total ?? 0;
    const licenceFee = data.licenceFee?.prices?.total ?? 0;
    const extras =    data.extras?.items?.reduce((acc, a) => acc + (a?.prices?.total ?? 0), 0)
    ?? 0;
    const modalPrice = data.modelPrice?.prices?.total ?? 0;

    calc += specification;
    calc += onlineDiscount;
    calc += licenceFee;
    calc += extras;
    calc += modalPrice;

    const isValid = calc === total;

    if (!isValid) {
        consoleLogInDev('const not valid');
    }

    return isValid;
};

// https://stackoverflow.com/a/34064434
function htmlDecode(input) {
    const doc = new DOMParser().parseFromString(input, 'text/html');

    return doc.documentElement.textContent;
}

export {
    parsePreSaleResponseData,
    isIE,
    isCostValid,
    htmlDecode,
};

// {
//     carName: 'Arona FR',
//     carManufacturer: 'Seat',
//     modelPrice: {
//         title: 'מחיר הדגם',
//         prices: {
//             originalPrice: '115,900',
//             beforePrice: '121,900',
//         },
//     },
//     specification: {
//         title: 'מפרט הרכב',
//         items: [{
//             icon: 'sdf',
//             title: 'צבע מורכב לבן גג אפור',
//             prices: {
//                 originalPrice: '0',
//             },
//         },
//         {
//             icon: 'sdf',
//             title: 'צבע פנים FR אפור שחור',
//             prices: {
//                 originalPrice: '0',
//             },
//         },
//         {
//             icon: 'sdf',
//             title: 'KJ75KZ34-WHEEL',
//             prices: {
//                 originalPrice: '0',
//             },
//         }],
//     },
//     extras: {
//         title: 'תוספות',
//         items: [{
//             icon: 'sdf',
//             title: 'מצלמה + SAVE + Connect it',
//             prices: {
//                 originalPrice: '1,875',
//                 beforePrice: '2,875',
//             },
//         }],
//     },
//     licenceFee: {
//         title: 'אגרת רישוי',
//         prices: {
//             originalPrice: '1,529',
//         },
//     },
//     onlineDiscount: {
//         title: 'סה"כ הנחה לרוכשים Online',
//         prices: {
//             originalPrice: '-6,803',
//         },
//     },
//     totalPayment: {
//         title: 'סה"כ לתשלום',
//         prices: {
//             originalPrice: '119,304',
//             beforePrice: '126,107',
//         },
//         isTotalPrice: true,
//     },
//     extraData: [{
//         text: 'דגם סיאט ארונה על פי מחירון חברה מספר 48 החל מ-25.6.2020',
//     },
//     {
//         text: 'המחירים כוללים מע"מ',
//     },
//     {
//         text: 'לתשומת ליבך מידע נוסף על הרכב נמצא',
//         link: 'https://www.google.com',
//     }],
// };
