import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './SystemDetailItem.styles';

const SystemDetailItem = ({ name, value, valueType }) => {
    return (
        <Styled.SystemDetailItem>
            <Styled.Detail $valueType={ valueType } $value={ value }>
                {name}
            </Styled.Detail>
        </Styled.SystemDetailItem>
    );
};

SystemDetailItem.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    valueType: PropTypes.string.isRequired,
};

export default SystemDetailItem;
