import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../language';
import { RequestedSubjectType } from '../../../../constants';
import {
    getManufacturerContactInfo,
    getEmailHrefString,
    getTelHrefString,
} from '../../../../utils/commonUtils';
import { useCurrentBrand } from '../../../../hooks';
import InputFormGroup from '../../../../components/InputFormGroup/InputFormGroup';
import SelectFormGroup from '../../../../components/SelectFormGroup/SelectFormGroup';
import TextAreaGroup from '../../../../components/TextAreaGroup/TextAreaGroup';
import { SELECT_NAME, TEXT_AREA_NAME } from './Form.fields';
import * as Styled from './Form.styles';

const Form = ({
    inputFields, selectOptions, onChangeInput, input, onSubmit,
}) => {
    const currentBrand = useCurrentBrand();
    const manufacturerContactInfo = getManufacturerContactInfo(currentBrand);
    const { EMAIL, PHONE } = manufacturerContactInfo;
    const emailHref = getEmailHrefString(EMAIL);
    const phoneRef = getTelHrefString(PHONE);
    const textArea = input[TEXT_AREA_NAME];
    const contentIsToLong = textArea.value.trim().length === textArea.maxLength;

    const formGroups = () => {
        return inputFields.map((inputField) => {
            const {
                name, type, label, isRequired, isValid, errorMessage,
            } = inputField;
            
            return (
                <InputFormGroup
                    key={ name }
                    inputName={ name }
                    label={ label }
                    isValid={ isValid }
                    errorMessage={ errorMessage }
                    inputType={ type }
                    isRequired={ isRequired }
                    inputValue={ input[name].value }
                    isErrorShown={ input[name].isErrorShown }
                    maxlength={ input[name].maxLength }
                    onChange={ onChangeInput }
                />
            );
        });
    };

    return (
        <Styled.FormContainer>
            <Styled.Title>{STRINGS.TALK_TO_US}</Styled.Title>
            <Styled.Text>
                {STRINGS.CONTACT_US_GET_MORE_DETAILS_TEXT}
                <Styled.EmailTo href={ emailHref } >{EMAIL}</Styled.EmailTo>
                <br />
                {STRINGS.OR_PHONE}&nbsp;
                <Styled.Tel href={ phoneRef } >{PHONE}</Styled.Tel>
            </Styled.Text>
            <Styled.Form action="/">
                <Styled.FieldSet>
                    {formGroups()}
                </Styled.FieldSet>
                <SelectFormGroup
                    selectName={ SELECT_NAME }
                    selectValue={ input[SELECT_NAME].value }
                    options={ selectOptions }
                    onChange={ onChangeInput }
                    label={ STRINGS.REQUESTED_SUBJECT }
                
                />
                <TextAreaGroup
                    label={ STRINGS.APPLYING_CONTENT }
                    name={ TEXT_AREA_NAME }
                    value={ input[TEXT_AREA_NAME].value }
                    maxLength={ input[TEXT_AREA_NAME].maxLength }
                    onChange={ onChangeInput }
                />
                {contentIsToLong && (
                    <Styled.Error>
                        {`${STRINGS.YOU_HAVE_REACHED_THE_MAX_NUMBER_OF_CHARACTERS}: ${input[TEXT_AREA_NAME].maxLength}`}
                    </Styled.Error>
                )}
                <Styled.SubmitButton onClick={ onSubmit }>
                    {STRINGS.SUBMIT_APPLICATION}
                </Styled.SubmitButton>
            </Styled.Form>
        </Styled.FormContainer>
    );
};

Form.defaultProps = {
    inputFields: [],
    onChangeInput: () => {},
    input: {},
    onSubmit: () => {},
};

Form.propTypes = {
    inputFields: PropTypes.arrayOf(PropTypes.object),
    selectOptions: PropTypes.arrayOf(RequestedSubjectType).isRequired,
    onChangeInput: PropTypes.func,
    onSubmit: PropTypes.func,
    input: PropTypes.object,
};

export default Form;
