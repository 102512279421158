/* eslint-disable import/no-mutable-exports */
import CONFIG from '../../../config';
import { MENUFACTURS } from '../../../constants';

let EngineAndTrimLevel;

switch (CONFIG.brand) {
    case MENUFACTURS.VOLKSWAGEN_COMMERCIAL:
        EngineAndTrimLevel = require(
            '../../CommercialConfigurator/Steps/EngineAndTrimLevel/EngineAndTrimLevel',
        ).default;
        break;
    default:
        EngineAndTrimLevel = require('./EngineAndTrimLevel').default;
}

export default EngineAndTrimLevel;
