import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import * as Styled from './PaymentDetailsModal.styles';

const PaymentDetailsModal = ({
    onClose, isVisible, children,
}) => {
    const backdrop = useRef(null);
    const clickHandler = (e) => e.target === backdrop.current && onClose();

    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden';
            document.body.dataset.moreInfo = isVisible;

            return;
        }
        
        document.body.style.overflow = 'visible';
        document.body.dataset.moreInfo = isVisible;
    }, [isVisible]);

    const modal = (
        <CSSTransition in={ isVisible } timeout={ 500 } classNames="show-up" unmountOnExit >
            <Styled.Container ref={ backdrop } onClick={ clickHandler }>
                <Styled.PaymentDetailsModal className="modal-content">
                    { children }
                </Styled.PaymentDetailsModal>
            </Styled.Container>
        </CSSTransition>
    );

    return ReactDOM.createPortal(modal, document.body);
};

PaymentDetailsModal.defaultProps = {
    isVisible: false,
    onClose: () => {},
    title: '',
    displayPrice: 0,
    addonUniID: '',
};

PaymentDetailsModal.propTypes = {
    isVisible: PropTypes.bool,
    isSelected: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.string,
    displayPrice: PropTypes.number,
    addonUniID: PropTypes.string,
    brand: PropTypes.string.isRequired,
};

export default PaymentDetailsModal;
