import styled, { css } from 'styled-components';
import CONFIG from '../../../../../config';
import { configurationLeftScrollBar } from '../../../../common/styles';

const { DEVICE_SIZE } = CONFIG;

export const ProgressScreenSteps = styled.div`
 display: flex;
 flex-direction: column;
 overflow-y: auto;
 max-height: 100%;
 height: 758px ;
 border-bottom: 1px solid lightgray;
 ${configurationLeftScrollBar};
 .CheckOutScreenTab{
   flex: 1;
 }
 @media(max-width:${DEVICE_SIZE.MEDIUM}px){
  padding-top: 0;
  height: ${() => window.innerHeight - 150}px;
 }
`;

export const StepContainer = styled.div`
  max-width: 800px;
  display: ${({ $isVisible }) => $isVisible ? 'flex' : 'none'};
  padding-top: 30px ;
  opacity: 1;
  transition: 0.5s linear ;
  ${({ $isDisabled }) => $isDisabled
  && css`
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
  `}
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    padding: 35px 25px 35px 25px;
    flex-direction: column;
  }
`;

export const CarTitle = styled.h1`
  margin: 0;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 68px;
`;

export const SubTitle = styled.p`
  margin: 0;
  margin-top: 8px;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;
