import styled from 'styled-components';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const Container = styled.div`
  .swiper-pagination {
    display: none;
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
      display: block;
      direction: rtl;
      top: 15px;
      height: fit-content;
    }
  }
`;

const NavigationButtonsContainer = styled.div`
  position: absolute;
  top: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 50;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    top: 0;
    padding: 0 10px;
  }
`;

const NavigationButton = styled.div.attrs({
    role: 'button',
})`
  width: 44px;
  height: 44px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  background-color: ${({ theme }) => theme.primaryColor};
  transition: all ease-in-out 0.3s;
  &.swiper-button-disabled {
    opacity: 0;
  }
  &.right {
    left: auto;
    right: 10px;
    &::before {
      content: '→';
      font-size: 20px;
      color: white;
    }
  }
  &.left {
    right: auto;
    left: 10px;
    &::before {
      content: '←';
      font-size: 20px;
      color: white;
    }
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    &.right {
      width: auto;
      background-color: transparent;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      gap: 4px;
      &::after {
        content: 'הקודם';
        text-decoration: underline;
        color: ${({ theme }) => theme.primaryColor};
      }
      &::before {
        content: '→';
        font-size: 20px;
        color: ${({ theme }) => theme.primaryColor};
      }
    }
    &.left {
      width: auto;
      background-color: transparent;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      gap: 4px;
      &::before {
        content: 'הבא';
        font-size: 16px;
        text-decoration: underline;
        color: ${({ theme }) => theme.primaryColor};
      }
      &::after {
        content: '←';
        font-size: 20px;
        color: ${({ theme }) => theme.primaryColor};
      }
    }
  }
`;

const Styled = {
    Container,
    NavigationButtonsContainer,
    NavigationButton,
};

export default Styled;
