import styled from 'styled-components';
import CONFIG from '../../../../../config';
import { COLORS } from '../../../../../constants';
import { FigCaption, ImageBox } from '../../../../Steps/Model/ModelImage/ModelImage.styles';
import {
    HeaderDesktop,
} from '../../../../Steps/Model/ModelItemCollapsible/HeaderDesktop/HeaderDesktop.styles';

const { DEVICE_SIZE } = CONFIG;

export const ModelItem = styled.li`
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  max-width: 344px;
  min-width: 344px;
  min-height: 400px;
  gap: 24px;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex: 1 1 100%;
    max-width: initial;
    min-width: initial;

    ${HeaderDesktop}{
      display: block;
      & header{
        max-width:100%;
      }
    }
    ${ImageBox}{
      max-width:200px;
    }
    ${FigCaption}{
      justify-content: center;
    }
  }
`;

const UpperContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 238px;
`;

const ColorfulContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 188px;
  width: 100%;
  background-color: ${({ theme, color }) => color ?? theme.primaryColor};
  padding: 24px;
  gap: 8px;
`;

const ModelName = styled.p`
  margin: 0;
  color: #FFF;
  text-align: right;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`;

const ModelDescription = styled.p`
  margin: 0;
  color: #FFF;
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const Image = styled.img`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 134px;
  object-fit: cover;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    object-fit: contain;
  }
`;

const PriceTitle = styled.h4`
  font-size: 14px;
  font-weight: 700;
  margin: 0;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PriceWrapper = styled.div`
  font-size: 26px;
  font-weight: 700;
  color: ${COLORS.BLACK};
  display: flex;
  align-items: baseline;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    margin-inline-start: 8px;
  }
`;

const Price = styled.p`
  margin: 0;
  line-height: 1;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    line-height: 1.5;
  }
`;

const NisSymbol = styled.span`
  font-size: 14px;
`;

const ButtonWrapper = styled.div.attrs({
    role: 'button',
})`
  width: 90%;
  margin: auto;
`;

const Styled = {
    ModelItem,
    UpperContent,
    ColorfulContainer,
    ModelName,
    ModelDescription,
    Image,
    PriceTitle,
    PriceContainer,
    PriceWrapper,
    Price,
    NisSymbol,
    ButtonWrapper,
};

export default Styled;
