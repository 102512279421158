import React, {
    useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
    useActionDispatch,
    useCurrentAddOnGroup,
    useCurrentAddOns,
    useCurrentBrand,
    useCurrentCar,
    useCurrentHoop,
} from '../../../../hooks';
import useQueryParams from '../../../../hooks/useQueryParams';
import { getScreenBlockErrorMsg } from '../../../../utils/configuratorUtils';
import { COLORS, CONFIGURATOR_QUERY_PARAMS } from '../../../../constants';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import Loader from '../../../Loader';
import AddOnGroup from './AddOnGroup/AddOnGroup';
import Styled from './AddOns.style';

const AddOns = ({ configOptions }) => {
    const currentBrand = useCurrentBrand();
    const {
        selectCurrentAddOnGroup,
        getModelLocalGroupsAsync,
        selectCurrentAddOn,
        selectIsInSummary,
    } = useActionDispatch();
    const { modelCode, carVersion } = useCurrentCar();
    const selectedHoop = useCurrentHoop();
    const selectedAddOnGroup = useCurrentAddOnGroup();
    const selectedAddOns = useCurrentAddOns();
    const { modelLocalGroups } = configOptions;
    const { addOns, isLoading, error } = modelLocalGroups;
    const pickedAddOnGroupCode = selectedAddOnGroup?.groupCode;
    const screenErrMsg = getScreenBlockErrorMsg(currentBrand);
    const {
        addQueryParam,
        deleteQueryParam,
    } = useQueryParams();

    const hoopCode = selectedHoop.value;
 
    const selectAddOnHandler = useCallback((addOn) => {
        const addOnsCodesArray = Object.keys(selectedAddOns).map(
            (key) => selectedAddOns[key].local);

        const addOnKey = `${addOn.groupCode}-${addOn.local}`;

        const isAddOnExist = addOnsCodesArray.includes(addOn.local);

        if (isAddOnExist) {
            deleteQueryParam(CONFIGURATOR_QUERY_PARAMS.ADDONS, addOnKey);
        } else {
            addQueryParam(CONFIGURATOR_QUERY_PARAMS.ADDONS, addOnKey);
        }

        selectCurrentAddOn(addOn);
        selectIsInSummary(false);
    }, [selectedAddOns]);
  
    const selectAddOnGroupHandler = useCallback(
        (addOnGroup) => {
            let selectedAddOn = {};

            if (pickedAddOnGroupCode !== addOnGroup.groupCode) {
                selectedAddOn = addOnGroup;
            }

            selectCurrentAddOnGroup(selectedAddOn);
        },
        [pickedAddOnGroupCode],
    );

    const renderAddOnGroups = (addOnGroups) => {
        return addOnGroups?.map((addOn) => {
            const { groupCode, listLocalPackages, groupDescription } = addOn;

            return (
                <AddOnGroup
                    onSelect={ () => selectAddOnGroupHandler(addOn) }
                    onSelectAddOn={ selectAddOnHandler }
                    isActive={ pickedAddOnGroupCode === groupCode }
                    groupCode={ groupCode }
                    addOnItems={ listLocalPackages }
                    title={ groupDescription }
                    key={ groupCode }
                />
            );
        });
    };

    const getAddons = () => {
        getModelLocalGroupsAsync(modelCode, hoopCode, carVersion);
    };

    // refetch addons every entry to update add-on list to after discount prices
    useEffect(() => {
        getAddons();
    }, []);

    useEffect(() => {
        if (!pickedAddOnGroupCode && addOns[hoopCode]) {
            // No AddOnGroup was selected - select the first group
            const firstAddOnGroup = addOns[hoopCode][0];

            selectAddOnGroupHandler(firstAddOnGroup);
        }
    }, [addOns[hoopCode]]);

    if (isLoading) {
        return (
            <Styled.LoaderWrapper>
                <Loader />
            </Styled.LoaderWrapper>
        );
    }

    if (error) {
        return (
            <Styled.ErrorWrapper>
                <ErrorMessage
                    color={ COLORS.RED }
                    errorMessage={ screenErrMsg }
                />
            </Styled.ErrorWrapper>
        );
    }
  
    return (
        <Styled.AddOnsConfiguration>
            <Styled.AddOns>{renderAddOnGroups(addOns[hoopCode])}</Styled.AddOns>
        </Styled.AddOnsConfiguration>
    );
};

AddOns.propTypes = {
    configOptions: PropTypes.object.isRequired,
};

export default AddOns;
