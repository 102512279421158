import * as actionTypes from '../actionTypes';
import * as dispatchers from '../dispatchers';

const initialState = {
    init: {
        isLoading: true,
        error: null,
        modelDescription: null,
        trimLevelDescription: null,
        catalogLink: null,
        showTabEquipment: false,
        showTabEngine: false,
        showTabSizes: false,
    },
    equipment: {
        data: {
            tabs: [],
            screenData: {},
        },
        isLoading: false,
        error: null,
    },
    engine: {
        data: {
            tabs: [],
            screenData: {},
        },
        isLoading: false,
        error: null,
    },
    measurements: {
        data: [],
        isLoading: false,
        error: null,
    },
    modelDescription: null,
    groupModelCode: null,
    modelCode: null,
    originalAdditionsCode: null,
    version: null,
    trimLevelCode: null,
    id: null,
    isModalVisible: false,
    
};

export const specifications = (state = initialState, action) => {
    switch (action.type) {
        // PENDING
        case actionTypes.GET_SPECIFICATIONS_INIT_PENDING:
            return dispatchers.getSpecificationsPending(state, 'init');
        case actionTypes.GET_EQUIPMENT_SPECIFICATIONS_PENDING:
            return dispatchers.getSpecificationsPending(state, 'equipment');
        case actionTypes.GET_ENGINE_SPECIFICATIONS_PENDING:
            return dispatchers.getSpecificationsPending(state, 'engine');
        case actionTypes.GET_MEASUREMENTS_SPECIFICATIONS_PENDING:
            return dispatchers.getSpecificationsPending(state, 'measurements');

            // SUCCESS
        case actionTypes.GET_SPECIFICATIONS_INIT_SUCCESS:
            return dispatchers.getSpecificationsInitSuccess(state, action);
        case actionTypes.GET_EQUIPMENT_SPECIFICATIONS_SUCCESS:
            return dispatchers.getEquipmentSpecificationsSuccess(state, action);
        case actionTypes.GET_ENGINE_SPECIFICATIONS_SUCCESS:
            return dispatchers.getEngineSpecificationsSuccess(state, action);
        case actionTypes.GET_MEASUREMENTS_SPECIFICATIONS_SUCCESS:
            return dispatchers.getMeasurementsSpecificationsSuccess(state, action);
            // FAILURE
        case actionTypes.GET_SPECIFICATIONS_INIT_FAILURE:
            return dispatchers.getSpecificationsFailure(state, action, 'init');
        case actionTypes.GET_EQUIPMENT_SPECIFICATIONS_FAILURE:
            return dispatchers.getSpecificationsFailure(state, action, 'equipment');
        case actionTypes.GET_ENGINE_SPECIFICATIONS_FAILURE:
            return dispatchers.getSpecificationsFailure(state, action, 'engine');
        case actionTypes.GET_MEASUREMENTS_SPECIFICATIONS_FAILURE:
            return dispatchers.getSpecificationsFailure(
                state,
                action,
                'measurements',
            );
        case actionTypes.INITALIZE_SPECIFICATIONS:
            return dispatchers.initializeSpecifications(initialState);
        case actionTypes.SET_SPECIFICATION_MODEL_CODE:
            return dispatchers.setSpecificationParams(state, action);
        case actionTypes.TOGGLE_SPECIFICATIONS_MODAL:
            return dispatchers.ToggleSpecificationsModal(state);
        default:
            return state;
    }
};
