import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { COLORS } from '../../../../constants';
import { pxToRem } from '../../../../utils/commonUtils';
import CONFIG from '../../../../config';
import {
    configurationLeftScrollBar,
    StickyConfigLeftFooterOnMobile,
} from '../../../../components/common/styles';
import { stepTitleAnimation } from '../../../../utils/animationsConfig';

const { DEVICE_SIZE } = CONFIG;

export const ConfigurationalLeft = styled.aside`
  flex: 1 1 35%;
  max-width: 35%;
  border-right: 1px solid ${COLORS.BORDER_COLOR_CONFIGURATION_LEFT};
  border-left: 1px solid ${COLORS.BORDER_COLOR_CONFIGURATION_LEFT};
  display: flex;
  flex-direction: column;
  max-height:auto;
  transition: max-height .3s ease-out;
  @media (min-width: ${DEVICE_SIZE.EXTRA_LARGE}px) {
    flex-basis: 25%;
    max-width: 25%;
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    width: 100%;
    max-width: 100%;
    background: ${COLORS.BG_COLOR_CONFIGURATION_LEFT_FOOTER_MOBILE};
    position: fixed;
    z-index: 99;
    bottom: 0;
    background: #fff;
    position: relative;
    z-index: 120;
  }
`;

export const Footer = styled.footer`
  padding: 20px;
  border-top: 1px solid ${COLORS.BORDER_COLOR_CONFIGURATION_LEFT};
  margin-top: auto;
  display: flex;
  align-items: center;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    ${StickyConfigLeftFooterOnMobile};
  }
`;
export const TooltipWrapper = styled.div`
  display: flex;
`;

export const FooterRightWrapper = styled.div`
  flex: 1;
  height: 100%;
`;

export const TooltipTitle = styled.h4`
  font-weight: bold;
  font-size: ${pxToRem(14)};
  margin: 0 0 0 5px;
  line-height: 1.5;
`;

export const PriceDescription = styled.p`
  font-size: ${pxToRem(11.5)};
  font-weight: 500;
  margin: 0;
  color: ${COLORS.COLOR_TEXT_PRICE_DESCRIPTION};
`;

export const FullPrice = styled.p`
  margin: 0;
  font-size: ${pxToRem(20)};
  font-weight: 800;
  white-space: nowrap;
  line-height: 1.5;
  width: 100%;
`;

export const PaymentDetailsButton = styled.button`
  border: none;
  outline: none;
  font-weight: bold;
  line-height: 19px;
  text-decoration: underline;
  font-family: ${({ theme }) => theme['font-family']};
  font-size: ${pxToRem(14)};
  color: ${({ theme }) => theme.primaryColor};
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  &:hover {
    text-decoration: none;
  }
`;

export const Header = styled.header`
  padding: 20px 30px;
  border-bottom: 1px solid ${COLORS.BORDER_COLOR_CONFIGURATION_LEFT_HEADER};
  background-color: ${COLORS.BG_COLOR_CONFIGURATION_LEFT_HEADER};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: none;
  }
`;

export const Title = styled(motion.h2).attrs({
    ...stepTitleAnimation,
})`
  font-size: ${pxToRem(19)};
  font-weight: 700;
  margin: 0;
`;

export const Body = styled.div`
  ${configurationLeftScrollBar}
  padding: 20px;
  /* height: 400px; */
  // 323px = header:68px; stepper bar: 50px; configurationLeft header:64px configuration left footer:141px;
  height:${({ theme }) => theme.configurator.desktop.height};
  /* height:calc(100vh - 323px);  */
  padding: 20px;
  overflow-y: auto;
  width: 97%;
  margin: auto;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    height:${({ theme }) => theme.configurator.mobile.height};
    padding: 20px 10px;
    margin:0;
    width: 100%;
  }
`;

export const ContinueButton = styled.button.attrs(({ $isDisabled }) => ({
    disabled: $isDisabled,
}))`
  flex: 1.5;
  width: 100%;
  max-width: 265px;
  padding: 0 23px;
  font-family: ${({ theme }) => theme['font-family']};
  font-weight: 700;
  font-size: ${pxToRem(15)};
  border: none;
  border-radius: ${({ theme }) => theme.buttonButtonRadius};
  outline: none;
  background-color: ${({ theme }) => theme.buttonColor};
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-right: auto;
  color: ${COLORS.BORDER_COLOR_CONFIGURATION_LEFT_CONTINUE_BUTTON};
  &:after {
    content: "";
    display: inline-block;
    background-image:url(${({ $icon })  => $icon});
    background-repeat: no-repeat;
    background-position: center;
    width: 25px;
    height: 25px;
  }
  ${({ $isDisabled }) => !$isDisabled
    && css`
    @media(hover: hover){
      &:hover{
        background-color:${COLORS.BLACK};
      }
    }
    `}

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    height: 46px;
  }
  //if button disabled
  ${({ $isDisabled }) => $isDisabled
    && css`
      opacity: 0.5;
      user-select: none;
      /* pointer-events: none; */
      cursor:not-allowed;
    `}
`;
