import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PaymentDetailItem from '../PaymentDetailItem/PaymentDetailItem';
import { useActionDispatch, useCurrentAddOns, useCurrentAppCommonData } from '../../../hooks';
import useQueryParams from '../../../hooks/useQueryParams';
import STRINGS from '../../../language';
import { CONFIGURATOR_QUERY_PARAMS } from '../../../constants';
import * as Styled from './AddOns.styles';

const AddOns = ({ addOns }) => {
    const { otp }  = useSelector(({ authenticationReducer }) => authenticationReducer);
    const {
        removeCurrentAddOn,
        removeAddOn: removeAddOnFromConfiguratorOptions,
    } = useActionDispatch();
    const selectedAddOns = useCurrentAddOns();
    const { isAudi } = useCurrentAppCommonData();
    const { deleteQueryParam } = useQueryParams();
    const { isVerified } = otp;

    if (addOns.length <= 0) {
        return null;
    }

    const removeAddOn = (addOn) => {
        const addOnKey = `${addOn.groupCode}-${addOn.value}`;

        deleteQueryParam(CONFIGURATOR_QUERY_PARAMS.ADDONS, addOnKey);
        // Removes add on from selectedData reducer
        removeCurrentAddOn(addOn);
        // Removes add on from configuratorOptions reducer(after discount reveal)
        removeAddOnFromConfiguratorOptions(addOn);
    };

    const _addOns = addOns.map((addOn) => {
        const {
            name, price, image, isDiscount, total, priceAfterDiscount, type,
        } = addOn;

        const totalPrice = priceAfterDiscount && isVerified ? priceAfterDiscount : total;
   
        return (
            <PaymentDetailItem
                name={ name }
                price={ price }
                type={ type }
                isDiscount={ isDiscount && isVerified }
                total={ totalPrice }
                image={ image }
                key={ name }
                onRemove={ () => removeAddOn(addOn) }
            />
        );
    });

    return (
        <Styled.AddOns>
            <Styled.Title $isAudi={ isAudi }>{STRINGS.ADD_ONS}</Styled.Title>
            <Styled.List>{_addOns}</Styled.List>
        </Styled.AddOns>
    );
};

AddOns.defaultProps = {
    addOns: [],
};

AddOns.propTypes = {
    addOns: PropTypes.array,
};

export default AddOns;
