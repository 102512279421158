import React from 'react';
import { useCurrentBrand } from '../../../../hooks';
import { MENUFACTURS } from '../../../../constants';
import TermsVw from './TermsVw/TermsVw';
import TermsVwc from './TermsVwc/TermsVwc';
import TermsSeat from './TermsSeat/TermsSeat';
import TermsSkoda from './TermsSkoda/TermsSkoda';
import TermsCupra from './TermsCupra/TermsCupra';
import TermsAudi from './TermsAudi/TermsAudi';
import * as Styled from './TermsBody.styles';

const TermsBody = () => {
    const currentBrand = useCurrentBrand();
  
    const renderTerms = (brand) => {
        switch (brand) {
            case MENUFACTURS.SEAT:
                return <TermsSeat />;
            case MENUFACTURS.SKODA:
                return <TermsSkoda />;
            case MENUFACTURS.VOLKSWAGEN:
                return <TermsVw />;
            case MENUFACTURS.VOLKSWAGEN_COMMERCIAL:
                return <TermsVwc />;
            case MENUFACTURS.CUPRA:
                return <TermsCupra />;
            case MENUFACTURS.AUDI:
                return <TermsAudi />;
            default:
                return null;
        }
    };
    
    return (
        <Styled.TermsBody>
            {renderTerms(currentBrand)}
        </Styled.TermsBody>
    );
};

export default TermsBody;
