import {
    parseAddOnsToPaymentDetails,
    parseSpecificationsToPaymentDetails,
} from '../../../screens/Configurator/configurator.utils';
import { parsePreSaleResponseData } from '../../../utils';

export const getFinalDiscountPricePending = (state) => ({
    ...state,
    finalDiscount: {
        ...state.finalDiscount,
        isLoading: true,
    },
});

export const getFinalDiscountPriceSuccess = (state, action) => {
    const { data, chosenHoopsString } = action;

    const parsedResponse = parsePreSaleResponseData(data);
 
    const {
        specification, extras, modelPrice, licenceFee: licenseFee, totalPayment,
    } = parsedResponse;
    const parsedSpecifications = parseSpecificationsToPaymentDetails(specification.items);

    const addonGroups = state.modelLocalGroups.addOns[chosenHoopsString];

    const parsedAddOns = parseAddOnsToPaymentDetails(extras.items, addonGroups);

    return {
        ...state,
        finalDiscount: {
            ...state.finalDiscount,
            data: {
                modelPrice: {
                    ...modelPrice.prices,
                    isDiscount: modelPrice.prices?.discount > 0,
                },
                specifications: parsedSpecifications,
                addOns: parsedAddOns,
                licenseFee: {
                    ...licenseFee.prices,
                    isDiscount: licenseFee.prices?.discount > 0,

                },
                totalPayment: {
                    ...totalPayment.prices,
                    isDiscount: totalPayment.prices?.discount > 0,
                },
            },
            isLoading: false,
        },
    };
};

export const getFinalDiscountPriceFailure = (state, action) => {
    const { error } = action;
  
    return {
        ...state,
        finalDiscount: {
            ...state.finalDiscount,
            error,
            isLoading: false,
        },
    };
};

export const resetFinalDiscountPrices = (state) => {
    return {
        ...state,
        finalDiscount: {
            data: {
                modelPrice: null,
                specifications: null,
                addOns: null,
                licenseFee: null,
                totalPayment: null,
            },
            isLoading: false,
            error: null,
        },
    };
};
