import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentBrand } from '../../hooks';
import { getFullManufacturer } from '../../utils/commonUtils';
import { get404PageImage } from '../../utils/assetsUtils';
import useIsMobile from '../../hooks/useWindowSize';
import STRINGS from '../../language';
import { ROUTES } from '../../constants';
import CONFIG from '../../config';
import * as Styled from './PageNotFound.styles';

const { DEVICE_SIZE } = CONFIG;

const { HOME } = ROUTES;

const PageNotFound = () => {
    const navigate = useNavigate();
    const currentBrand = useCurrentBrand();
    const manufacturerInHebrew = getFullManufacturer(currentBrand, 'hebrew');
    const goPrevPageHandler = () => {
        navigate.replace(HOME);
    };
    const isMobile = useIsMobile(DEVICE_SIZE.MEDIUM);
    
    const IMAGE_404 = get404PageImage(currentBrand, isMobile);

    return (
        <Styled.PageNotFound>
            <Styled.Right>
                <Styled.Content>
                    <Styled.Header>{STRINGS.PAGE_NOT_EXIST}</Styled.Header>
                    <Styled.SubTitle>{STRINGS.WE_SEARCHED_ITS_NOT_HERE}</Styled.SubTitle>

                    <Styled.Text>{STRINGS.INTRESTED_IN_A_NEW_VEHICLE(manufacturerInHebrew)}</Styled.Text>
                    <Styled.PreviousPageButton onClick={ goPrevPageHandler }>
                        {STRINGS.PICK_YOUR_VEHICLE(manufacturerInHebrew)}
                    </Styled.PreviousPageButton>
                </Styled.Content>
            </Styled.Right>
            <Styled.Left $image={ IMAGE_404 } />
        </Styled.PageNotFound>
    );
};

export default PageNotFound;
