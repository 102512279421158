import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './MeasurementItem.styles';

const MeasurementItem = ({ image, title }) => {
    return (
        <Styled.MeasurementItem>
            <Styled.Image src={ image } />
            <Styled.Figcaption>{title}</Styled.Figcaption>
        </Styled.MeasurementItem>
    );
};

MeasurementItem.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default MeasurementItem;
