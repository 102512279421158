import styled from 'styled-components';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const AddOnsList = styled.div`
  width: 45%;
  max-height: calc(100vh - 220px);
  margin-right: 100px;
  overflow-y: scroll;
  padding-bottom: 60px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display:none;
  }
`;

export const AddOnGroupTitle = styled.div`
  border-bottom: 1px solid black;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 5px;
`;
