import styled from 'styled-components';

export const AddOnGroupTitleAndIcon = styled.div`
   display: flex;
   align-items: center;
   gap: 5px;
   &:hover{
      cursor: pointer;
   }
`;

export const Title = styled.h4``;

export const Icon = styled.span`
   width: 42px;
   height: 42px;
   display: inline-block;
   border-radius: 50%;
   border: 1px solid black;
   background-image: url(${({ $IconUrl }) => $IconUrl});
   background-size: cover;
`;
