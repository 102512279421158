import { useNavigate, useLocation } from 'react-router-dom';

const useQueryParams = () => {
    const navigate = useNavigate();
    const { search, hash } = useLocation();
    const queryParams = new URLSearchParams(search);

    const getQueryParam = (paramName) => {
        const paramValues = queryParams.getAll(paramName);
        
        if (paramValues.length === 0) {
            return '';
        } if (paramValues.length === 1) {
            return paramValues[0];
        }
        
        return paramValues;
    };

    // Add query parameter
    const addQueryParam = (paramName, paramValue) => {
        queryParams.append(paramName, paramValue);
        navigate({ search: `?${queryParams.toString()}`, hash }, { replace: true });
    };

    // Add or modify a query parameter
    const updateQueryParam = (paramName, paramValue) => {
        queryParams.set(paramName, paramValue);
        navigate({ search: `?${queryParams.toString()}`, hash }, { replace: true });
    };

    // Delete query parameter
    const deleteQueryParam = (paramName, paramValue = undefined) => {
        queryParams.delete(paramName, paramValue);
        navigate({ search: `?${queryParams.toString()}`, hash }, { replace: true });
    };

    // Get hash from the url
    const getHash = () => {
        return hash?.replace('#', '');
    };

    // Add hash to the url
    const addHash = (hashName) => {
        navigate({ search: `?${queryParams.toString()}`, hash: hashName }, { replace: true });
    };

    const removeHash = () => {
        navigate({ search: `?${queryParams.toString()}` }, { replace: true });
    };

    return {
        getQueryParam,
        addQueryParam,
        updateQueryParam,
        deleteQueryParam,
        getHash,
        addHash,
        removeHash,
    };
};

export default useQueryParams;
