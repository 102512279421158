import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './VideoPlayer.styles';

const VideoPlayer = ({ videoSource }) => {
    const videoUrl = videoSource;
    
    if (!videoSource) {
        return null;
    }

    return (
        <Styled.VideoPlayer
            src={ videoUrl }
            width="100%"
            height="100%"
            controls
            type="video/mp4"
        />
    );
};

VideoPlayer.propTypes = {
    videoSource: PropTypes.string.isRequired,
};

export default VideoPlayer;
