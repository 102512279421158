import styled, { css } from 'styled-components';
import { COLORS } from '../../../constants';
import { pxToRem } from '../../../utils/commonUtils';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const ConfiguratorStepsWrapper = styled.div`
  padding: 10px;
  background-color:${({ theme }) => theme.configuratorStepperBGColor};
  position:relative;
  max-width: 1920px;
  margin: 0 auto;
  overflow: auto;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    position: sticky;
    top: 0;
    z-index: 130;
  }
`;

export const ConfiguratorSteps = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type:none;
  height: 100%;
  display:flex;
  justify-content: center;
  white-space: nowrap;
  ${({ $isAudi }) => $isAudi && css`
    max-width: 1500px;
    margin: auto;
    justify-content: flex-start;
  `}
  @media(max-width:${DEVICE_SIZE.SMALL}px){
      display:inline-flex;
    }
`;

export const ModelDescription = styled.p`
  color: ${COLORS.COLOR_TEXT_CONFIG_STEPS_CAR_DESCRIPTION};
  font-weight: 500;
  font-size:${pxToRem(14)};
  margin: 0;
    display: inline-block;
    position: absolute;
    left: 79%;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    @media(max-width:${DEVICE_SIZE.LARGE}px){
      display:none;
    }
`;

export const CallToRepresentative = styled.button`
background-color: transparent;
color: white;
border: 1px solid white;
font-size:${pxToRem(16)};
padding: 6px 15px;
font-weight: 400;
font-family: ${({ theme }) => theme?.['font-family']};
cursor: pointer;
position: absolute;
top: 50%;
left: 218px;
transform: translateY(-50%);
@media(max-width:${DEVICE_SIZE.MEDIUM}px){
      display:none;
    }
`;
