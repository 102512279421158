import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../../../language';
import * as Styled from './AudiAddOrRemoveButton.styles';

const AudiAddOrRemoveButton = ({
    onClickHandler, isSelected, width, isDisabled,
}) => {
    const addOrRemoveText = isSelected ? STRINGS.REMOVE : STRINGS.ADD;

    return (
        <Styled.AudiAddOrRemoveButton
            onClick={ onClickHandler }
            $isSelected={ isSelected }
            width={ width }
            $isDisabled={ isDisabled }
        >
            { addOrRemoveText }
        </Styled.AudiAddOrRemoveButton>
    );
};

AudiAddOrRemoveButton.defaultProps = {
    width: '',
};

AudiAddOrRemoveButton.propTypes  = {
    isSelected: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    width: PropTypes.string,
    onClickHandler: PropTypes.func.isRequired,
};

export default AudiAddOrRemoveButton;
