/* eslint-disable import/no-mutable-exports */
import CONFIG from '../../config';
import { MENUFACTURS } from '../../constants';

let Configuration;

switch (CONFIG.brand) {
    case MENUFACTURS.VOLKSWAGEN_COMMERCIAL:
        Configuration = require(
            '../../components/CommercialConfigurator/Steps/Checkout/Checkout',
        ).default;
        break;
    default:
        Configuration = require('./Checkout').default;
}

export default Configuration;
