import React from 'react';
import PropTypes from 'prop-types';
import { MEDIA_MODE } from '../../../../constants';
import Styled from './MediaModeButton.style';

const MediaModeButton = ({
    isVisible,
    activeMode,
    onChangeActiveMode,
    isCar360Exist,
    isCarImagesExist,
    isInternalMediaExist,
}) => {
    if (!isVisible) {
        return null;
    }
  
    return (
        <Styled.Container>
            { isCarImagesExist && (
                <Styled.ModeButton onClick={ () => onChangeActiveMode(MEDIA_MODE.STATIC) }>
                    <Styled.StaticImagesIcon $isActive={ activeMode === MEDIA_MODE.STATIC } />
                </Styled.ModeButton>
            )}
            { isCar360Exist && (
                <Styled.ModeButton onClick={ () => onChangeActiveMode(MEDIA_MODE.EXTERNAL) }>
                    <Styled.External360Icon $isActive={ activeMode === MEDIA_MODE.EXTERNAL } />
                </Styled.ModeButton>
            ) }
            { isInternalMediaExist && (
                <Styled.ModeButton onClick={ () => onChangeActiveMode(MEDIA_MODE.INTERNAL) }>
                    <Styled.Internal360Icon $isActive={ activeMode === MEDIA_MODE.INTERNAL } />
                </Styled.ModeButton>
            )}
        </Styled.Container>
    );
};

MediaModeButton.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    activeMode: PropTypes.number.isRequired,
    onChangeActiveMode: PropTypes.func.isRequired,
    isCar360Exist: PropTypes.bool.isRequired,
    isCarImagesExist: PropTypes.bool.isRequired,
    isInternalMediaExist: PropTypes.bool.isRequired,
};

export default MediaModeButton;
