import styled from 'styled-components';
import { pxToRem } from '../../../../utils/commonUtils';
import CONFIG from '../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const PaymentAndDownPayment = styled.div`
 width:100%;
 padding-bottom:30px;
 border-bottom: 1px solid #e1e1e1;
`;
export const Content = styled.div`
  max-width:550px;

`;
export const Title = styled.h2`
 margin:0 0 7px 0;
 font-weight:700;
 font-size:${pxToRem(24)};
 line-height:26px;

`;
export const Description = styled.p`
margin:0 0 50px 0 ;
 font-weight:400;
 font-size:${pxToRem(19)};
 line-height:27px;
`;

export const CredidGuardIcons = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  margin-top: 20px;
`;

export const Icon = styled.img`
  @media(max-width:${DEVICE_SIZE.SMALL}px){
      max-width:235px;
  }
`;

export const BoldText = styled.span`
font-weight:900;
 font-size:${pxToRem(19)};
 line-height:27px;
`;

export const ErrorContainer = styled.div`

 width: 100%;
 font-size: 32px;
`;
export const IframeContainer = styled.div`
 
 height:290px;
 position:relative;
 @media(max-width:${DEVICE_SIZE.LARGE}px){
  height:360px;
 }
`;

export const CreditGuardIFrame = styled.iframe.attrs(() => ({
    name: 'onlineCarCreditGuard',
}))`
background-color: ${({ src, error }) => !src || !!error ? 'gray' : ''};
width: 100%;
 height: 100%;
 position: absolute;
 top: 0;
 right: 0;
 border: none;
`;
