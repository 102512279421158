import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select/Select';
import { RequestedSubjectType } from '../../constants';
import * as Styled from './SelectFormGroup.styles';

const SelectFormGroup = ({
    selectName,
    selectValue,
    options,
    onChange,
    label,
}) => {
    return (
        <Styled.SelectFormGroup>
            <Styled.Label>
                {label}
            </Styled.Label>
            <Select
                options={ options }
                name={ selectName }
                value={ selectValue }
                onChange={ onChange }
            />
        </Styled.SelectFormGroup>
    );
};

SelectFormGroup.defaultProps = {
    selectValue: '',

};

SelectFormGroup.propTypes = {
    selectName: PropTypes.string.isRequired,
    selectValue: PropTypes.string,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(RequestedSubjectType).isRequired,
};

export default SelectFormGroup;
