import styled from 'styled-components';
import { ErrorMessage } from '../../../../../components/CheckBoxFormGroup/CheckBoxFormGroup.styles';
import { COLORS } from '../../../../../constants';
import { pxToRem } from '../../../../../utils/commonUtils';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const RegistrationForm = styled.div`
  position: relative;
  #FormGroup-id_number label{
    color:rgba(0, 0, 0, 0.5);
  }
  #FormGroup-id_number input{
   border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
  }
`;
export const Terms = styled.div`
  margin-top: 30px;
  label {
    font-size: ${pxToRem(17)};
  }
  ${ErrorMessage} {
    margin: 0;
    font-size: ${pxToRem(17)};
    font-weight: 400;
    color: #bb2416;
  }
`;

export const Underline = styled.button.attrs(() => ({
    type: 'button',
}))`
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-family: ${({ theme }) => theme['font-family']};
  font-size: ${pxToRem(17)};
  font-weight: 400;
  color: ${COLORS.BLACK};
`;

export const Text = styled.span`
  padding: 0;
  margin: 0;
  font-size: ${pxToRem(17)};
  font-weight: 400;
  color: ${({ isError }) => (isError ? '#bb2416' : COLORS.BLACK)};
`;

export const TermsOfUserConfirmation = styled.span`
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  font-size: ${pxToRem(17)};
  font-weight: 400;
  background-color: transparent;
  font-family: ${({ theme }) => theme['font-family']};
  &:hover ${Underline} {
    color: #007674;
  }
`;

export const Disclaimer = styled.p`
  padding: 10px 0;
  margin: 0;
  font-size: ${pxToRem(17)};
  font-weight: 400;
`;

export const ErrorMessageContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const LoaderContainer = styled(ErrorMessageContainer)`
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StepHeadContainer = styled.div`
  display: inline-flex;
  @media(max-width: ${DEVICE_SIZE.SMALL}px) {
    flex-direction: ${({ $isAudi }) => !$isAudi && 'column'};
  }
`;

export const TitleContainer = styled.div`
  display: block;
`;

export const IconAndSubTitleWrapper = styled.div`
  display: none;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: block;
  }
`;

export const SubmitButtonWrapper = styled.div.attrs({
    role: 'button',
})`
  width: 100%;
`;
