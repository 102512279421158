import axios from 'axios';
import {
    DEVELOPMENT_ENV, ERROR_CODES,
} from '../constants';
import CONFIG from '../config';
import {
    consoleLogInDev,
} from '../utils/commonUtils';
import {
    isUrlMocked, isMockError, getMockResponse, getMockError,
} from './mockAxios/mock-axios';

const mockingEnabled = process.env.NODE_ENV === DEVELOPMENT_ENV;
const { BASE_URL: baseUrl } = CONFIG;
// const authorizationToken = getSessionStorageAuthToken();

const BASE_URL = baseUrl;

const axiosCustomInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
});

axiosCustomInstance.defaults.headers.common['Content-Type'] = 'application/json';
// axiosCustomInstance.defaults.headers.common.Authorization = authorizationToken ?? '';

// axios.defaults.withCredentials = true;

// Add a request interceptor
axiosCustomInstance.interceptors.request.use((config) => {
    const { url } = config;

    if (mockingEnabled && isUrlMocked(url)) {
        return getMockError(config);
    }

    return config;
}, (error) => Promise.reject(error));
  
// Add a response interceptor
axiosCustomInstance.interceptors.response.use((response) => response, (error) => {
    if (isMockError(error)) {
        return getMockResponse(error);
    }

    consoleLogInDev('this is error axiosCustomInstance interceptors', error);

    const { status, data } = error?.response ?? {};
    const err = error;

    if (status === ERROR_CODES.INTERNAL_SERVER_ERROR) {
        if (!data) {
            err.response.data = 'Internal Server Error';
        }
    }
    
    return Promise.reject(err);
});

/**
 * Assign a common header to all outing requests being made;
 * @param {string} name - header name/key
 * @param {string} value  - header value
 */

export const setDefaultCommonHeaders = (name, value) => {
    axiosCustomInstance.defaults.headers.common[name] = value;
};

export default axiosCustomInstance;
