import React from 'react';
import PropTypes from 'prop-types';
import ColorsRangeRulerItem from './ColorsRangeRulerItem/ColorsRangeRulerItem';
import * as Styled from './ColorsRangeRuler.styles';

const ColorsRangeRuler = ({
    lowRangeTitle,
    highRangeTitle,
    colors,
    isManyModels,
    firstColorNumberIndex,
    selectedColorNumber,
    itemFontColor,
}) => {
    const _colors = colors.map((color, index) => {
        const number = index + firstColorNumberIndex;
        
        return (
            <ColorsRangeRulerItem
                isSelected={ selectedColorNumber === number }
                isManyModels={ isManyModels }
                key={ color }
                textColor={ itemFontColor }
                number={ number }
                bg={ color }
            />
        );
    });
    
    return (
        <Styled.ColorsRangeRuler>
            <Styled.Title>
                {highRangeTitle}
            </Styled.Title>
            <Styled.ColorsRangeRulerList>
                {_colors}
            </Styled.ColorsRangeRulerList>
            <Styled.Title>
                {lowRangeTitle}
            </Styled.Title>
        </Styled.ColorsRangeRuler>
    );
};

ColorsRangeRuler.defaultProps = {
    lowRangeTitle: null,
    highRangeTitle: null,
    itemFontColor: null,
    selectedColorNumber: null,
    firstColorNumberIndex: 0,
    isManyModels: false,
};

ColorsRangeRuler.propTypes = {
    lowRangeTitle: PropTypes.string,
    highRangeTitle: PropTypes.string,
    itemFontColor: PropTypes.string,
    colors: PropTypes.array.isRequired,
    isManyModels: PropTypes.bool,
    selectedColorNumber: PropTypes.number,
    firstColorNumberIndex: PropTypes.number,

};

export default ColorsRangeRuler;
