import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PickerItem from '../PickerItem';
import { ColorPickerItemType } from '../../constants';
import { getOutColorIconUrl } from '../../utils/assetsUtils';
import { useCurrentBrand } from '../../hooks';
import * as Styled from './ColorPicker.styles';

function ColorPicker({
    colors, className, pickedColor, onPick,
}) {
    const currentBrand = useCurrentBrand();

    const _colors = colors.map(({ value, description }) => (
        <PickerItem
            key={ value }
            selected={ value === pickedColor }
            value={ value }
            // text={ description }
            tooltipContent={ description }
            image={ getOutColorIconUrl(currentBrand, value) }
            onClick={ onPick ? () => onPick(value) : undefined }
        />
    ));

    if (!colors || colors.length <= 1) {
        return null;
    }
    
    return (
        <Styled.Container className={ classnames('ColorPicker', className) }>
            {_colors}
        </Styled.Container>
    );
}

ColorPicker.propTypes = {
    colors: PropTypes.arrayOf(
        ColorPickerItemType,
    ).isRequired,
    className: PropTypes.string,
    pickedColor: PropTypes.string,
    onPick: PropTypes.func,
};

ColorPicker.defaultProps = {
    className: null,
    pickedColor: null,
    onPick: null,
};

export default ColorPicker;
