import styled from 'styled-components';

export const Body = styled.div`
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    direction: rtl;
`;

export const MainContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const Image = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: ${({ isLoading }) => isLoading ? 'blur(20px)' : ''};
    transition: 1s filter ease-in-out;
`;

export const Container = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;

    @media only screen and (max-width: 600px) {
      width: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0.6))
    }
`;

export const Text = styled.h1`
    color: white;
    font-family: sans-serif;

    @media only screen and (max-width: 600px) {
      text-align: center;
    }
`;

export const Logo = styled.img`
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    width: ${({ isSquare }) => isSquare ? 150 : 280}px;
    tint-color: black;
    @media only screen and (max-width: 600px) {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
`;
