import styled, { css } from 'styled-components';
import { pxToRem } from '../../../../../../utils/commonUtils';
import { ReactComponent as CheckMarkSvg } from '../../../../../../assets/icons/svg-check-mark.svg';
import { COLORS } from '../../../../../../constants';
import { lineThrough, nisCurrencySign } from '../../../../../common/styles';

const AddOnItem = styled.li.attrs({
    role: 'button',
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid #DFE4E8;
  transform: scale(1, 0);
  transition: all 0.3s;
  max-height: 0;
  cursor: pointer;
  ${({ $isActive }) => $isActive
    && css`
      padding: 16px;
      transform: scale(1, 1);
      max-height: 500px;
    `}
  ${({ $isSelected }) => $isSelected
    && css`
      border: 2px solid #10833B;
    `}
  ${({ $isDisabled }) => $isDisabled
    && css`
      opacity: 0.3;
    `}
`;

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PickAndTitleContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px; 
`;

const PickIndicator = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: 2px solid #DFE4E8;
  transition: all 0.3s;
  ${({ $isSelected }) => $isSelected
    && css`
      border: 2px solid #10833B;
      background-color: #10833B;
    `}
`;

const CheckMarkIcon = styled(CheckMarkSvg)`
  display: inline-block;
  width: 12px;
  height: 12px;
  path {
    fill: white;
  }
`;

const ItemName = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: ${COLORS.BLACK};
`;

const PricesContainer = styled.div`
  display: flex;
`;

const Price = styled.p`
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: ${COLORS.BLACK};
  ${nisCurrencySign};
  ${({ $showDiscount }) => $showDiscount
    && css`
      ${lineThrough}
    `};
`;

const MoreInformation = styled.button`
  font-size: ${pxToRem(13)};
  text-decoration: underline;
  padding: 0 5px 5px 5px;
  font-weight: 700;
  background-color: transparent;
  border: none;
  margin: 0;
  outline: none;
  color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;
`;

const Styled = {
    AddOnItem,
    RowContainer,
    PickAndTitleContainer,
    PickIndicator,
    CheckMarkIcon,
    ItemName,
    MoreInformation,
    Price,
    PricesContainer,
};

export default Styled;
