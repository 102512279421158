export const GET_CONFIGURATOR_OPTIONS_PENDING = 'GET_CONFIGURATOR_OPTIONS_PENDING';
export const GET_CONFIGURATOR_OPTIONS_SUCCESS = 'GET_CONFIGURATOR_OPTIONS_SUCCESS';
export const GET_CONFIGURATOR_OPTIONS_FAILURE = 'GET_CONFIGURATOR_OPTIONS_FAILURE';
/** *************************************************************************************** */
export const GET_INTERNAL_COLORS_PENDING = 'GET_INTERNAL_COLORS_PENDING';
export const GET_INTERNAL_COLORS_SUCCESS = 'GET_INTERNAL_COLORS_SUCCESS';
export const GET_INTERNAL_COLORS_FAILURE = 'GET_INTERNAL_COLORS_FAILURE';
/** *************************************************************************************** */
export const GET_ORIGINAL_PACKAGES_PENDING = 'GET_ORIGINAL_PACKAGES_PENDING';
export const GET_ORIGINAL_PACKAGES_SUCCESS = 'GET_ORIGINAL_PACKAGES_SUCCESS';
export const GET_ORIGINAL_PACKAGES_FAILURE = 'GET_ORIGINAL_PACKAGES_FAILURE';
/** *************************************************************************************** */
export const GET_MODEL_LOCAL_GROUPS_PENDING = 'GET_MODEL_LOCAL_GROUPS_PENDING';
export const GET_MODEL_LOCAL_GROUPS_SUCCESS = 'GET_MODEL_LOCAL_GROUPS_SUCCESS';
export const GET_MODEL_LOCAL_GROUPS_FAILURE = 'GET_MODEL_LOCAL_GROUPS_FAILURE';
/** *************************************************************************************** */
export const GET_FINAL_DISCOUNT_PRICE_PENDING = 'GET_FINAL_DISCOUNT_PRICE_PENDING';
export const GET_FINAL_DISCOUNT_PRICE_SUCCESS = 'GET_FINAL_DISCOUNT_PRICE_SUCCESS';
export const GET_FINAL_DISCOUNT_PRICE_FAILURE = 'GET_FINAL_DISCOUNT_PRICE_FAILURE';

export const RESET_FINAL_DISCOUNT_PRICES = 'RESET_FINAL_DISCOUNT_PRICES';

export const GET_EQUIPMENT_PACKAGE_PENDING = 'GET_EQUIPMENT_PACKAGE_PENDING';
export const GET_EQUIPMENT_PACKAGE_SUCCESS = 'GET_EQUIPMENT_PACKAGE_SUCCESS';
export const GET_EQUIPMENT_PACKAGE_FAILURE = 'GET_EQUIPMENT_PACKAGE_FAILURE';

/** *************************************************************************************** */
export const REMOVE_ADD_ON = 'REMOVE_ADD_ON';
/** *************************************************************************************** */
export const INITIALIZE_CONFIGURATOR_OPTIONS =  'INITIALIZE_CONFIGURATOR_OPTIONS';
/** *************************************************************************************** */
export const INITIALIZE_CONFIGURATOR_DATA =  'INITIALIZE_CONFIGURATOR_DATA';
/** *************************************************************************************** */
export const SET_EXTERNAL_COLORS_ERROR = 'SET_EXTERNAL_COLORS_ERROR';

/** *************************************************************************************** */
export const GET_POLLUTION_AND_SAFETY_DETAILS_DATA_PENDING = 'GET_POLLUTION_AND_SAFETY_DETAILS_DATA_PENDING';
export const GET_POLLUTION_AND_SAFETY_DETAILS_DATA_SUCCESS = 'GET_POLLUTION_AND_SAFETY_DETAILS_DATA_SUCCESS';
export const GET_POLLUTION_AND_SAFETY_DETAILS_DATA_FAILURE = 'GET_POLLUTION_AND_SAFETY_DETAILS_DATA_FAILURE';

/** *************************************************************************************** */
export const GET_ADD_ONS_EXTRA_INFO_PENDING = 'GET_ADD_ONS_EXTRA_INFO_PENDING';
export const GET_ADD_ONS_EXTRA_INFO_SUCCESS = 'GET_ADD_ONS_EXTRA_INFO_SUCCESS';
export const GET_ADD_ONS_EXTRA_INFO_FAILURE = 'GET_ADD_ONS_EXTRA_INFO_FAILURE';
/** *************************************************************************************** */
export const GET_MODELS_ADDITIONAL_OPTIONS_PENDING = 'GET_MODELS_ADDITIONAL_OPTIONS_PENDING';
export const GET_MODELS_ADDITIONAL_OPTIONS_SUCCESS = 'GET_MODELS_ADDITIONAL_OPTIONS_SUCCESS';
export const GET_MODELS_ADDITIONAL_OPTIONS_FAILURE = 'GET_MODELS_ADDITIONAL_OPTIONS_FAILURE';
