/**
 * push data to GTM data-layer
 * @param {Object} eventData GTM event
 */
export const pushGtmEvent = (eventData) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(eventData);
};

export const parseGtmDataToDimension3Format = (gtmData) => {
    return gtmData.map((gtmItem) => {
        const { name, title } = gtmItem;
       
        return name ?? title;
    }).join(' | ');
};
