import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import STRINGS from '../../../language';
import { useCurrentBrand, useCurrentAppCommonData } from '../../../hooks';
import {
    getFullManufacturer,
    isArrayAndNotEmpty,
    replacePlaceholdersInTemplate,
} from '../../../utils/commonUtils';
import ModelItemCollapsible from '../Model/ModelItemCollapsible/ModelItemCollapsible';
import HelmetTitleDescription from '../../HelmetTitleDescription/HelmetTitleDescription';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { useSelectModelItem } from '../Model/Model.hooks';
import { getMetaDataByModelGroup } from '../../../utils/seoUtils';
import * as Styled from './EngineAndTrimLevel.styles';

const EngineAndTrimLevel = () => {
    const [selectedModelItem, setSelectedModelItem] = useSelectModelItem(null);
    const { search } = useLocation();
    const {  modelGroupCode: modelGroupCodeFromParams, modelGroup } = useParams();
    const { onlineCars, isCupra } = useCurrentAppCommonData();
    const currentBrand = useCurrentBrand();
    const urlParams = new URLSearchParams(search);
    const modelGroupCode =  urlParams.get('modelGroupCode') ?? modelGroupCodeFromParams;
    const manufacturerName = getFullManufacturer(currentBrand, 'hebrew');
    const carItems = onlineCars
        .filter((car) => car.modelGroup.toLowerCase().replaceAll(' ', '-') === modelGroup);
    const firstCar = carItems[0] ?? {};
    const { hebrewModelGroup } = firstCar;
    const { title, description } = getMetaDataByModelGroup(currentBrand, modelGroupCode);

    const chooseYourModelGroup = replacePlaceholdersInTemplate(
        STRINGS.CHOOSE_YOUR_MODEL_GROUP,
        [hebrewModelGroup ?? manufacturerName],
    );

    const renderModelItems = () => {
        if (!isArrayAndNotEmpty(carItems)) {
            return (
                <Styled.ErrorWrapper>
                    <ErrorMessage errorMessage={ STRINGS.NO_CARS_TO_DISPLAY } />
                </Styled.ErrorWrapper>
            );
        }
        
        return carItems.map((car) => {
            const { id } = car;
            
            return (
                <ModelItemCollapsible
                    key={ id }
                    model={ car }
                    brand={ currentBrand }
                    isActive={ selectedModelItem === id }
                    onSelect={ setSelectedModelItem }
                    isCupra={ isCupra }
                />
            );
        });
    };

    return (
        <Styled.EngineAndTrimLevel>
            <HelmetTitleDescription
                title={ title }
                description={ description }
                canonical={ window.location.href }
            />
            <Styled.Header>
                <Styled.Title>
                    {`${manufacturerName} ${hebrewModelGroup ?? ''}`}
                </Styled.Title>
                <Styled.SubTitle>
                    {chooseYourModelGroup}
                </Styled.SubTitle>
            </Styled.Header>
            <Styled.List>
                {renderModelItems()}
            </Styled.List>
        </Styled.EngineAndTrimLevel>
    );
};

export default EngineAndTrimLevel;
