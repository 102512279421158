import { useSelector } from 'react-redux';
import { distinctObj } from  '../utils/commonUtils';

export const useCurrentAppCommonData = () => {
    return useSelector(({ appCommonDataReducer }) => appCommonDataReducer);
};

export const useCurrentBrand = () => {
    const appCommonDataReducer = useCurrentAppCommonData();
    const { currentBrand } = appCommonDataReducer;
    
    return currentBrand;
};

export const useUniqueCars = (uniquIdentifier) => {
    const appCommonData = useCurrentAppCommonData();
    const { onlineCars }  = appCommonData;
    
    return distinctObj(onlineCars, uniquIdentifier);
};
