import styled from 'styled-components';
import CONFIG from '../../../../../config';

const { MEDIUM } = CONFIG.DEVICE_SIZE;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 4rem;
    width: 100%;
    @media (max-width: ${MEDIUM}px) {
        display: none;
    }
`;
