import { useEffect } from 'react';
import { OTP_CREDENTIAL } from '../constants';
import CONFIG from '../config';
import useIsMobile from './useWindowSize';

const { DEVICE_SIZE } = CONFIG;

const useAutoOtpCredentials = (onSuccessCallback) => {
    const isMobile = useIsMobile(DEVICE_SIZE.LARGE);

    useEffect(() => {
    // Feature detection
        if ((OTP_CREDENTIAL in window) && isMobile) {
            // Allows us to cancel the web API if the form is submitted manually
            const ac = new AbortController();

            // Let the browser listen for any sms message containing an OTP code.
            navigator.credentials
                .get({
                    otp: { transport: ['sms'] },
                    signal: ac.signal,
                })
                .then((otp) => {
                    onSuccessCallback(otp.code);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [isMobile]);
};

export default useAutoOtpCredentials;
