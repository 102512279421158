import styled, { css } from 'styled-components';
import { COLORS } from '../../../../constants';
import { pxToRem } from '../../../../utils/commonUtils';
import CONFIG from '../../../../config';
import {
    configurationLeftScrollBar,
} from '../../../common/styles';

const { DEVICE_SIZE } = CONFIG;

export const ConfigurationalRight = styled.aside`
  flex: 1 1 50%;
  max-width: 50%;
  display: flex;
  position: relative;
  flex-direction: column;
  max-height:auto;
  transition: max-height .3s ease-out;
  @media (min-width: ${DEVICE_SIZE.EXTRA_LARGE}px) {
    flex-basis: 50%;
    max-width: 50%;
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    width: 100%;
    max-width: 100%;
    background: ${COLORS.BG_COLOR_CONFIGURATION_LEFT_FOOTER_MOBILE};
    position: fixed;
    z-index: 99;
    bottom: 0;
    background: #fff;
    position: relative;
  }
  ${({ isAddOnsStep }) => isAddOnsStep && css`
  &:after{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    height: 90%;
    width: 2px;
    background-color: red;
  }
  `}
`;

export const Header = styled.header`
  padding: 50px 30px 0 0;
  background-color: ${COLORS.BG_COLOR_CONFIGURATION_LEFT_HEADER};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: none;
  }
`;

export const Title = styled.h2`
  font-size: ${pxToRem(32)};
  font-weight: 700;
  margin: 0;
`;

export const Body = styled.div`
  ${configurationLeftScrollBar}
  padding: 20px;
  height:${({ theme }) => theme.configurator.desktop.height};
  padding: 20px;
  overflow-y: auto;
  width: 97%;
  margin: auto;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    height:${({ theme }) => theme.configurator.mobile.height};
    padding: 20px 20px 60px 0;
    margin:0;
    width: 100%;
  }
`;
