import styled from 'styled-components';
import { COLORS } from '../../../../../constants';

export const LicenseFeePrice = styled.p`
  margin:0;
  color:${COLORS.COLOR_TEXT_ITEM_CAR_LICENSE_FEE_PRICE};
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const LicenseFeePriceSpan = styled.span``;
