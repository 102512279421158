export const getModelLocalGroupsPending = (state) => ({
    ...state,
    modelLocalGroups: {
        ...state.modelLocalGroups,
        isLoading: true,
    },
});

export const getModelLocalGroupsSuccess = (state, action) => {
    const { data: modelLocalGroups, selectedHoopCode } = action;
    
    return {
        ...state,
        modelLocalGroups: {
            ...state.modelLocalGroups,
            addOns: {
                ...state.modelLocalGroups.addOns,
                [selectedHoopCode]: modelLocalGroups,
            },
            isLoading: false,
        },
    };
};

export const getModelLocalGroupsFailure = (state, action) => {
    const { error } = action;
  
    return {
        ...state,
        modelLocalGroups: {
            ...state.modelLocalGroups,
            isLoading: false,
            error,
        },
    };
};

export const removeAddOn = (state, action) => {
    const { addOn: selectedAddOn } = action;
    const { price, total } = selectedAddOn;

    const { addOns } = state.finalDiscount.data;

    if (addOns) {
        const updatedConfiguratorAddOns = addOns?.filter((addOn) => {
            return addOn.name !== selectedAddOn.name;
        });
        
        return {
            ...state,
            finalDiscount: {
                ...state.finalDiscount,
                data: {
                    ...state.finalDiscount.data,
                    addOns: [...updatedConfiguratorAddOns],
                    totalPayment: {
                        ...state.finalDiscount.data.totalPayment,
                        price: state.finalDiscount.data.totalPayment.price - price,
                        total: state.finalDiscount.data.totalPayment.total - total,
                    },
                },
            },
        };
    }

    return { ...state };
};

export const getAddOnsExtraInfoPending = (state) => ({
    ...state,
    modelLocalGroups: {
        ...state.modelLocalGroups,
        isLoading: true,
        addOnsExtraInformation: {
            ...state.modelLocalGroups.addOnsExtraInformation,
        },
    },
});

export const getAddOnsExtraInfoSuccess = (state, action) => {
    const { data } = action;

    return {
        ...state,
        modelLocalGroups: {
            ...state.modelLocalGroups,
            isLoading: false,
            addOnsExtraInformation: {
                ...state.modelLocalGroups.addOnsExtraInformation,
                ...data,
            },
        },
    };
};

export const getAddOnsExtraInfoFailure = (state, action) => {
    const { error } = action;
    
    return {
        ...state,
        modelLocalGroups: {
            ...state.modelLocalGroups,
            isLoading: false,
            error,
            addOnsExtraInformation: {
                ...state.modelLocalGroups.addOnsExtraInformation,
            },
        },
    };
};
