import React from 'react';
import STRING from '../../language';
import { getChampionBlackLogo } from '../../utils/assetsUtils';
import { Logo } from './ChampionLogo.style';

const ChampionLogo = () => {
    return (
        <Logo src={ getChampionBlackLogo() } alt={ STRING.CHAMPION_LOGO_ALT } />
    );
};

export default ChampionLogo;

ChampionLogo.propTypes = {
};

ChampionLogo.defaultProps = {
};
