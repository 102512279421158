import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../../components/Input/Input';
import * as Styled from './InputFormGroup.styles';

const InputFormGroup = ({
    inputType,
    inputName,
    inputValue,
    onChange,
    inputPlaceholder,
    label,
    isRequired,
    isErrorShown,
    errorMessage,
    isReadOnly,
    maxLength,
    isAutoFocused,
}) => {
    return (
        <Styled.InputFormGroup id={ `FormGroup-${inputName}` } isError={ isErrorShown }>
            {label && (
                <Styled.Label isError={ isErrorShown } isReadOnly={ isReadOnly } >
                    {isErrorShown ? errorMessage : label}
                </Styled.Label>
            )}
            <Input
                type={ inputType }
                name={ inputName }
                isReadOnly={ isReadOnly }
                placeholder={ inputPlaceholder }
                isRequired={ isRequired }
                value={ inputValue }
                onChange={ onChange }
                maxlength={ maxLength }
                isAutoFocused={ isAutoFocused }
            />
        </Styled.InputFormGroup>
    );
};

InputFormGroup.defaultProps = {
    inputType: 'text',
    inputValue: '',
    isRequired: true,
    isErrorShown: false,
    errorMessage: null,
    onChange: () => {},
    inputPlaceholder: null,
    isReadOnly: false,
    maxLength: null,
    label: null,
    isAutoFocused: false,
};

InputFormGroup.propTypes = {
    inputType: PropTypes.string,
    inputName: PropTypes.string.isRequired,
    inputValue: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    isErrorShown: PropTypes.bool,
    errorMessage: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    isReadOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    isAutoFocused: PropTypes.bool,
};

export default InputFormGroup;
