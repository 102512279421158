import styled from 'styled-components';

const Container = styled.div`
    display: grid;
    width: 100%;
    height: 100vh;
    position: relative;
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    overflow-y: auto;
`;

const Style = {
    Container,
    Content,
};

export default Style;
