import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as Styled from './SalesDetailsItem.style';

/**
 * 
 * @param {{
 *  title: string, 
 *  prices: import('../../types').Prices, 
 *  icon: string, 
 *  isTotalPrice: boolean, 
 *  className: string
 * }} param0 
 */
const SalesDetailsItem = ({
    title, prices, icon, isTotalPrice, className,
}) => {
    const renderPrice = () => {
        if (prices) {
            if (prices.discount && parseInt(prices.discount) > 0) {
                return (
                    <Styled.Price
                        className={ classnames('SalesDetailsItem-Price', 'withDiscount', {
                            isTotalPrice,
                        }) }
                        prices={ prices }
                    >
                        <Styled.Price2
                            className={ classnames('SalesDetailsItem-Price2', {
                                isTotalPrice,
                            }) }
                            isTotalPrice={ isTotalPrice }
                        >
                            {parseInt(prices.total).toLocaleString()}
                        </Styled.Price2>
                        <Styled.PriceBeforeDiscount
                            className={ classnames('SalesDetailsItem-PriceBeforeDiscount', {
                                isTotalPrice,
                            }) }
                            isTotalPrice={ isTotalPrice }
                        >
                            {parseInt(prices.price).toLocaleString()}
                        </Styled.PriceBeforeDiscount>
                    </Styled.Price>
                );
            }

            return (
                <Styled.Price
                    className={ classnames('SalesDetailsItem-Price',  { isTotalPrice }) }
                    prices={ prices }
                    isTotalPrice={ isTotalPrice }
                >
                    <Styled.Price2
                        className={ classnames('SalesDetailsItem-Price2', { isTotalPrice }) }
                        isTotalPrice={ isTotalPrice }
                    >
                        {parseInt(prices.total).toLocaleString()}
                    </Styled.Price2>
                </Styled.Price>
            );
        }

        return null;
    };

    return (
        <Styled.Wrapper
            className={ classnames('SalesDetailsItem', className, { hasIcon: !!icon }) }
            $icon={ icon }
        >
            {icon && <Styled.Icon className="SalesDetailsItem-Icon" $icon={ icon } />}
            <Styled.Title
                className="SalesDetailsItem-Title"
                $icon={ icon }
                isTotalPrice={ isTotalPrice }
            >
                {title}
            </Styled.Title>
            {renderPrice()}
        </Styled.Wrapper>
    );
};

SalesDetailsItem.propTypes = {
    title: PropTypes.string,
    prices: PropTypes.shape({
        total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        discount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    icon: PropTypes.string,
    isTotalPrice: PropTypes.bool,
    className: PropTypes.string,
};

SalesDetailsItem.defaultProps = {
    title: null,
    prices: null,
    icon: null,
    className: null,
    isTotalPrice: false,
};

export default SalesDetailsItem;
