import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Styled from './SyncedRow.styles';

const SyncedRow = ({
    components,
    isStickyRow,
}) => {
    const [isPinned, setIsPinned] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const cachedRef = containerRef.current.node;
        const observer = new IntersectionObserver(
            ([e]) => setIsPinned(e.intersectionRatio < 1),
            { threshold: [1] },
        );
          
        observer.observe(cachedRef);

        return () => observer.unobserve(cachedRef);
    }, []);

    return (
        <Styled.ScrollSyncPane
            ref={ containerRef }
        >
            <Styled.Container
                $isSticky={ isStickyRow }
                $isPinned={ isPinned }
            >
                { components.map((component) => (
                    <Styled.ItemWrapper
                        $isSticky={ isStickyRow }
                    >
                        { component }
                    </Styled.ItemWrapper>
                ))}
            </Styled.Container>
        </Styled.ScrollSyncPane>
    );
};

SyncedRow.propTypes = {
    components: PropTypes.array.isRequired,
    isStickyRow: PropTypes.bool.isRequired,
};

export default SyncedRow;
