import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import CONFIG from '../../../../../config';
import { COLORS } from '../../../../../constants';

const { MEDIUM } = CONFIG.DEVICE_SIZE;

export const ImageSlider = styled.ul`
  display: none;
  padding: 0;
  margin: 0;
  margin-top: 2rem;
  list-style-type: none;
  width: 100%;
  height:100%;
  position: relative;
  @media (max-width: ${MEDIUM}px) {
        display: block;
        flex-direction: column;
}

  & .StepCardsSliderDots {
    display: flex !important;
    left: 45%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 0;
    list-style-type: none;
  }

  & .StepCardsSliderDots li button {
    margin: 0 6px;
    height: 5px;
    width: 28px;
    background: gray;
    border: none;
    outline: none;
    font-size: 0;
    line-height: 0;
  }

  & .StepCardsSliderDots li:not(.slick-active) button {
    cursor: pointer;
  }
  & .StepCardsSliderDots li.slick-active button {
    background: ${({ theme }) => theme.primaryColor};
  }
  @media(max-width:${MEDIUM}px){
    height:240px;
  }

  .slick-list {
    margin: 0 -10px;
    padding-bottom: 30px !important;
  }

  .slick-slide {
      direction: rtl;
      padding: 0 10px;
  }
`;

export const SwiperCustom = styled(Swiper).attrs((props) => ({
    ...props,
}))`
  padding: 0 50px;
  padding-bottom: 40px;
  width: 100%;
  height: 100%;
  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 28px;
      height: 5px;
      margin-top: 20px;
      border-radius: 0%;
      opacity: 1;
      background-color: ${COLORS.GRAY};
    }
  }
  .swiper-pagination-bullet-active{
    background-color: ${({ theme }) => theme.primaryColor} !important;
  }
  .swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom: 0;
  }
`;

export const SwiperSlideCustom = styled(SwiperSlide)`
`;
