import React from 'react';
import PropTypes from 'prop-types';
import { useCurrentCar } from '../../../../hooks';
import { getStepTitle } from '../../../../utils/configuratorUtils';
import { CONFIGURATOR_STEPS } from '../../../../constants';
import Styled from './PresentationLeft.style';

const { MODEL, ENGINE_AND_TRIM_LEVEL, EQUIPMENT_PACKAGES } = CONFIGURATOR_STEPS;
const fullScreensList = [MODEL, ENGINE_AND_TRIM_LEVEL, EQUIPMENT_PACKAGES];

const  PresentationalLeft = ({
    component, configOptions, currentStep,
}) => {
    const {
        modelDescription,
    } = useCurrentCar();
    const PresentationComponent = component || (() => null);
    const isFullScreen = fullScreensList.find((screen) => currentStep === screen);

    return (
        <Styled.PresentationalLeft key={ currentStep } isFullScreen={ isFullScreen }>
            <Styled.Header>
                <Styled.StepTitle >
                    {getStepTitle(currentStep)}
                </Styled.StepTitle>
                { !isFullScreen && (
                    <Styled.ModelDescription>
                        { modelDescription }
                    </Styled.ModelDescription>
                )}
            </Styled.Header>
            <PresentationComponent configOptions={ configOptions } />
        </Styled.PresentationalLeft>
    );
};

PresentationalLeft.defaultProps = {
    component: null,
    currentStep: null,
};

PresentationalLeft.propTypes = {
    component: PropTypes.elementType,
    currentStep: PropTypes.number,
    configOptions: PropTypes.object.isRequired,
};

export default PresentationalLeft;
