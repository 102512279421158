import styled from 'styled-components';
import { TableData } from '../../../../../components/TableData/TableData.styles';
import { TableHeadData } from '../../../../../components/TableHeadData/TableHeadData.styles';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const PollutionModel = styled.div`
  margin-left: 30px;
  overflow-x: auto;
  max-width: 50%;
  @media (max-width: ${DEVICE_SIZE.LARGE}px) {
    margin-left: 0;
    max-width: 100%;
  }
`;
export const ModelTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
export const ModelThead = styled.thead``;
export const ModelTr = styled.tr``;
export const ModelTbody = styled.tbody``;
export const ModelTd = styled(TableData)``;
export const ModelTh = styled(TableHeadData)``;
