import React from 'react';
import STRINGS from '../../language';
import { MENUFACTURS } from '../../constants';
import { useCurrentBrand } from '../../hooks';
import { getManufacturerWebsite } from '../../utils/commonUtils';
import OnLinePurchaseNav from './OnLinePurchaseNav/OnLinePurchaseNav';
import ModelsAndSpecificationsNav from './ModelsAndSpecificationsNav/ModelsAndSpecificationsNav';
import Disclaimer from './Disclaimer/Disclaimer';
import SocialLinks from './SocialLinks/SocialLinks';
import * as Styled from './Footer.styles';

const Footer = () => {
    const currentBrand = useCurrentBrand();

    return (
        <Styled.Footer>
            <Styled.ContentTopContainer>
                <Styled.ContentTop>
                    <Styled.ModelsAndSpecifications>
                        <Styled.NavTitle>{STRINGS.MODELS}</Styled.NavTitle>
                        <ModelsAndSpecificationsNav />
                    </Styled.ModelsAndSpecifications>
                    <Styled.OnlinePurchase>
                        <Styled.NavTitle>{STRINGS.ONLINE_PURCHASE}</Styled.NavTitle>
                        <OnLinePurchaseNav />
                    </Styled.OnlinePurchase>
                </Styled.ContentTop>
            </Styled.ContentTopContainer>
            <Styled.ContentBottomContainer>
                <Styled.ContentBottom>
                    <Styled.DisclaimerAndSocialLinks>
                        <Styled.DisclaimerAndLinkToChampion>
                            <Disclaimer currentBrand={ currentBrand } />
                            <Styled.LinkToChampionMotors>
                                <Styled.LinkToWebsite
                                    target="_blank"
                                    key="linkToChampionMotorsWebsite"
                                    to={ getManufacturerWebsite(MENUFACTURS.CHAMPION) }
                                >
                                    {STRINGS.TO_CHAMPIONS_WEBSITE}
                                </Styled.LinkToWebsite>
                            </Styled.LinkToChampionMotors>
                        </Styled.DisclaimerAndLinkToChampion>
                        <SocialLinks />
                    </Styled.DisclaimerAndSocialLinks>
                </Styled.ContentBottom>
            </Styled.ContentBottomContainer>
        </Styled.Footer>
    );
};

export default Footer;
