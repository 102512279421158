import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Select.styles';

const Select = ({
    onChange, options, name, value,
}) => {
    const _options = options.map((option) => {
        const { text, value: val } = option;
        
        return <Styled.Option key={ val } value={ val }>{text}</Styled.Option>;
    });
    
    return (
        <Styled.Select
            id={ name }
            name={ name }
            value={ value  }
            onChange={ onChange }
        >
            {_options}
        </Styled.Select>
    );
};

Select.defaultProps = {
    options: [],
    value: null,
    onChange: () => {},
  
};

Select.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,

};

export default Select;
