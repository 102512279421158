import styled from 'styled-components';
import Lottie from 'lottie-react';
import errorAnimation from '../../../../assets/animations/error_animation.json';
import CONFIG from '../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const Container = styled.div`
  width: 100%;
  display: block;
  padding: 1rem;
  padding-right: 2rem;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    padding-right: 1rem;
  }
`;

export const StepHead = styled.div`
  display: flex;
  align-items: center;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ErrorAnimation = styled(Lottie).attrs({
    loop: true,
    animationData: errorAnimation,
})`
  width: 150px;
  height: 150px;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    width: 250px;
    height: 250px;
  }
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 36px;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    font-size: 24px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* padding-right: 2.5rem; */
  padding-left: 2.5rem;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const OrderNumber = styled.p`
  font-size: 18px;
  font-weight: 500;
`;

export const PayAttentionText = styled.p`
  padding: 1rem 0;
  border-top: 2px solid #D3D3D3;
  border-bottom: 2px solid #D3D3D3;
  font-size: 18px;
  font-weight: bold;
`;

export const RegularText = styled.p`
  font-size: 16px;
`;

export const Text = styled(RegularText)`
  margin: 0;
`;

export const BoldText = styled(RegularText)`
  font-weight: bold;
`;

export const Seperator = styled.div`
  width: 100%;
  height: 2px;
  margin: 1rem 0;
  background-color: #00000029;
`;

export const Link = styled.a`
  color: black;
  margin: 0;
  font-size: 18px;
`;

export const ContactInfoContainer = styled.div`
  display: block;
`;

export const ContactText = styled.p`
  margin: 0;
`;
