import { useSelector } from 'react-redux';

export const useSpecifications = () => {
    return useSelector(({ specificationsReducer }) => specificationsReducer);
};

export const useIsSpecificationInitNotEmpty = () => {
    const specificationReducer = useSpecifications();
    const { modelDescription } = specificationReducer.init;
    
    return !modelDescription;
};

export const useSpecificationModelDescription = () => {
    const specificationReducer = useSpecifications();
    const { modelDescription } = specificationReducer.init;
    
    return modelDescription;
};

export const useSpecificationModelCode = () => {
    const specificationReducer = useSpecifications();
    const { modelCode } = specificationReducer;

    return modelCode;
};

export const useIsSpecificationDataNotEmpty = () => {
    const specificationReducer = useSpecifications();
    const { tabs } = specificationReducer.equipment.data;
    
    return tabs?.length < 1;
};

export const useIsSpecificationsModalVisible = () => {
    const specificationReducer = useSpecifications();
    const { isModalVisible } = specificationReducer;

    return isModalVisible;
};
