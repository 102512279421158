import React from 'react';
import PropTypes from 'prop-types';
import modelCarShape from '../../../../Steps/Model/modelItemShape';
import { useActionDispatch } from '../../../../../hooks';
import { getHomePageThumbnail } from '../../../../../utils/assetsUtils';
import {
    isArrayEmpty,
    renderNisSymbol,
    replacePlaceholdersInTemplate,
} from '../../../../../utils/commonUtils';
import STRINGS from '../../../../../language';
import SelectButton from '../../../SelectButton/SelectButton';
// eslint-disable-next-line max-len
import LicenseFeePrice from '../../../../Steps/Model/ModelItemCollapsible/LicenseFeePrice/LicenseFeePrice';
import CarRank from '../../../../Steps/Model/ModelItemCollapsible/CarRank/CarRank';
import Styled from './EngineAndTrimLevelModelItem.style';

const EngineAndTrimLevelModelItem = ({
    model,
    brand,
}) => {
    const { toggleSpecificationsModal, setSpecificationParams } = useActionDispatch();
    const {
        id,
        price: carPrice,
        licenseFee,
        mainCharacteristics,
        modelDescription,
        modelCode,
        safetyEquipmentLevel,
        originalAdditionsCode,
        carVersion,
        pollutionLevel,
        hrefToConfigurator,
        modelGroupCode,
        trimLevelCode,
        isBeforeManufacture,
    } = model;

    const price = parseFloat(carPrice).toLocaleString();
    const licenseFeePrice = parseFloat(licenseFee).toLocaleString();
    const fullPrice = parseFloat(licenseFee) + parseFloat(carPrice);
    const imageSrc = getHomePageThumbnail(brand, modelGroupCode, trimLevelCode);
    const imageAlt = replacePlaceholdersInTemplate(
        STRINGS.PREVIEW_OF_MODEL_DESCRIPTION,
        [modelDescription],
    );
    
    const onSpecificationLinkClick = () => {
        setSpecificationParams(
            modelGroupCode,
            modelCode,
            modelDescription,
            originalAdditionsCode,
            carVersion,
            trimLevelCode,
            id,
        );
        toggleSpecificationsModal();
    };

    const renderCharacteristicsAndSpecification = () => {
        return (
            <>
                <Styled.MainCharacteristicsAndSpecificationContainer>
                    <Styled.SpecsButton
                        onClick={ onSpecificationLinkClick }
                    >
                        <Styled.SpecsIcon />
                        { STRINGS.SPECIFICATION }
                    </Styled.SpecsButton>
                    { !isArrayEmpty(mainCharacteristics) && (
                        <Styled.MainCharacteristicsTitle>
                            { STRINGS.SPECS_AND_MAIN_CHARACTISTICS }
                        </Styled.MainCharacteristicsTitle>
                    ) }
                </Styled.MainCharacteristicsAndSpecificationContainer>
                { !isArrayEmpty(mainCharacteristics) && (
                    <Styled.MainCharacteristicsList>
                        { mainCharacteristics?.map((characteristic) => (
                            <Styled.MainCharacteristicItem>
                                { characteristic}
                            </Styled.MainCharacteristicItem>
                        ))}
                    </Styled.MainCharacteristicsList>
                )}
            </>
        );
    };
  
    return (
        <Styled.ModelItem>
            <Styled.Image
                src={ imageSrc }
                alt={ imageAlt }
            />
            <Styled.ModelTitlesContainer>
                <Styled.ModelName>{ modelDescription }</Styled.ModelName>
            </Styled.ModelTitlesContainer>
            <Styled.PriceContainer>
                <Styled.PriceWrapper>
                    <Styled.PriceTitle>{STRINGS.STARTING_FROM}-</Styled.PriceTitle>
                    <Styled.Price>
                        <Styled.NisSymbol>{ renderNisSymbol() }</Styled.NisSymbol>
                        { fullPrice.toLocaleString() }
                    </Styled.Price>
                </Styled.PriceWrapper>
                {STRINGS.INCLUDES_VAR_AND_LICENSING_FEE}
            </Styled.PriceContainer>
            <Styled.SelectButtonContainer>
                <SelectButton
                    text={ STRINGS.PICK }
                    href={ hrefToConfigurator }
                />
            </Styled.SelectButtonContainer>
            { renderCharacteristicsAndSpecification() }
            <Styled.Footer>
                <LicenseFeePrice price={ price } licenseFee={ licenseFeePrice } />
                <CarRank
                    safety={ safetyEquipmentLevel }
                    pollution={ pollutionLevel }
                    isBeforeManufacture={ isBeforeManufacture }
                />
            </Styled.Footer>
        </Styled.ModelItem>
    );
};

EngineAndTrimLevelModelItem.propTypes = {
    model: PropTypes.shape(modelCarShape).isRequired,
    brand: PropTypes.string.isRequired,
};

export default EngineAndTrimLevelModelItem;
