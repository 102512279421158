import {
    MENUFACTURS,
    LOGOS_PATHS,
    MUTAGS,
    MENUFACTURS_SOCIAL_LINKS,
    ACCESSIBILITY_DECLARATION_LINKS,
    MUTAG_FOLDER,
    CHAMPION_MEDIA_URL,
    SIZES,
    DEVICE_TYPE,
} from '../constants';
import IMAGES from '../assets';

const { DESKTOP, MOBILE } = DEVICE_TYPE;

const getMutagFolderName = (brand) => {
    switch (brand?.toUpperCase()) {
        case MENUFACTURS.AUDI:
            return MUTAG_FOLDER.AUDI; // TODO: Change to audi when there's media
        case MENUFACTURS.SEAT:
            return MUTAG_FOLDER.SEAT;
        case MENUFACTURS.SKODA:
            return MUTAG_FOLDER.SKODA;
        case MENUFACTURS.VOLKSWAGEN:
            return MUTAG_FOLDER.VOLKSWAGEN;
        case MENUFACTURS.VOLKSWAGEN_COMMERCIAL:
            return MUTAG_FOLDER.VOLKSWAGEN_COMMERCIAL;
        case MENUFACTURS.CUPRA:
            return MUTAG_FOLDER.CUPRA;
        default:
            return 'Champion';
    }
};

export const getBrandByMutag = (mutag) => {
    switch (mutag?.toUpperCase()) {
        case MUTAGS.AUDI:
            return MENUFACTURS.AUDI;
        case MUTAGS.SEAT:
            return MENUFACTURS.SEAT;
        case MUTAGS.SKODA:
            return MENUFACTURS.SKODA;
        case MUTAGS.VOLKSWAGEN:
            return MENUFACTURS.VOLKSWAGEN;
        case MUTAGS.VOLKSWAGEN_COMMERCIAL:
            return MENUFACTURS.VOLKSWAGEN_COMMERCIAL;
        case MUTAGS.CUPRA:
            return MENUFACTURS.CUPRA;
        default:
            return MENUFACTURS.CHAMPION;
    }
};

export const getSubBrandByMutag = (subMutag) => {
    return subMutag?.toUpperCase() === MUTAGS.CUPRA ? MENUFACTURS.CUPRA : '';
};

export const getMutagBlackLogo = (brand) => {
    const brandFolder = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandFolder}/General_Media/Logo/Black/black.png`;
};

export const getMutagWhiteLogo = (brand) => {
    const brandFolder = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandFolder}/General_Media/Logo/White/white.png`;
};

/**
 * get url of the current logo according to brand(mutag)
 * @param {string} brand מותג
 * @return {string} url
 */

export const getMutagLogo = (brand, size = SIZES.LARGE) => {
    if (!brand) {
        return LOGOS_PATHS[MENUFACTURS.CHAMPION][size];
    }

    return LOGOS_PATHS[brand.toUpperCase()][size] ?? LOGOS_PATHS[MENUFACTURS.CHAMPION][size];
};

export const getWarrentyLink = (brand) => {
    if (!brand) {
        brand = MENUFACTURS.CHAMPION;
    }

    const brandFolder = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandFolder}/General_Media/warrenty/warrenty.pdf`;
};

export const getAccessibilityDeclarationLink = (brand) => {
    if (!brand) {
        brand = MENUFACTURS.CHAMPION;
    }

    return ACCESSIBILITY_DECLARATION_LINKS[brand.toUpperCase()];
};

/**
 *
 * @param {string} brand
 * @param {string} linkType
 * @returns relevant social link (instagram|facebook|youtube)
 */

export const getMutagSocialLink = (brand, linkType) => {
    if (!brand || !linkType) {
        return '';
    }
    const brandUpperCase = brand.toUpperCase();
    const linkTypeUpperCase = linkType.toUpperCase();

    return MENUFACTURS_SOCIAL_LINKS[brandUpperCase]?.[linkTypeUpperCase] ?? '';
};

export const getChampionBlackLogo = () => {
    return `${CHAMPION_MEDIA_URL}Champion/logo/black_with_logo/champion.png`;
};

export const getInnerPageHeaderImage = (brand) => {
    if (!brand || brand === MUTAGS.CHAMPION) {
        return getChampionBlackLogo();
    }
    const brandFolder = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandFolder}/Online_Sale_Site/images/1480X480/1480X480.jpg`;
};

export const getContactUsPageImage = (brand) => {
    if (!brand || brand === MUTAGS.CHAMPION) {
        return getChampionBlackLogo();
    }
    const brandFolder = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandFolder}/Online_Sale_Site/images/490X700/490X700.jpg`;
};

/**
 * Get url for outer color icon
 * @param {string} brand מותג
 * @param {string} outColor צבע חיצוני (גג וגוף)
 */
export const getOutColorIconUrl = (brand, outColor) => {
    const brandFolder = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandFolder}/Proceed_online/exterior/${outColor}.png`;
};

/**
 * Get url for inner color icon
 * @param {string} brand מותג
 * @param {string} inColor צבע פנים
 */
export const getInColorIconUrl = (brand, inColor) => {
    const brandFolder = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandFolder}/Proceed_online/interior/${inColor}.png`;
};

/**
 * Get url for car wheel icon
 * @param {string} brand מותג
 * @param {number} modelGroupCode  קוד מספר דגם
 * @param {number} trimLevelCode  קוד רמת גימור
 * @param {string | number} trimLevelOrTrimLevelCode 
 */
export const getWheelIconUrl = (brand, modelGroupCode, trimLevelCode, trimLevelOrTrimLevelCode) => {
    const brandFolder = getMutagFolderName(brand);
    
    return `${CHAMPION_MEDIA_URL}${brandFolder}/Proceed_online/${modelGroupCode}/${trimLevelCode}/images/rim/${trimLevelOrTrimLevelCode}.png`;
};

/**
 * Get url for add-on icon
 * @param {string} brand מותג
 * @param {number} addOnId מזהה תוספת - מספר ייחודי
 */
export const getAddOnIconUrl = (brand, addOnId) => {
    const brandFolder = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandFolder}/Proceed_online/add_on/${addOnId}.jpg`;
};

/**
 * Get car image from outside
 * @param {string} brand מותג
 * @param {string} modelGroup קבוצת דגם
 * @param {string} trimLevelCode רמת גימור
 * @param {string} outColor צבע חיצוני (גג וגוף)
 */
export const getOutCarImageUrl = (
    brand,
    modelGroup,
    trimLevelCode,
    outColor,
) => {
    const brandFolder = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandFolder}/Proceed_online/${modelGroup}/${trimLevelCode}/images/exterior/${outColor}/1.png`;
};

/**
 * Get car image placeholder (for when no car-image)
 * @param {string} brand מותג
 */
export const getCarPlaceholderImageUrl = (brand) => {
    const brandName = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandName}/Proceed_online/default/${brandName}.png`;
};

/**
 * gets an icon name + brand - returns icon path or fallback if there is no brand
 * @param {string} iconName
 * @param {string} brand
 */

export const getIconFromAssetsByBrand = (iconName, brand) => {
    if (!brand) {
        brand = MENUFACTURS.CHAMPION;
    }
    const iconNameUpperCase = iconName.toUpperCase();

    return (
        IMAGES[iconNameUpperCase]?.[brand.toUpperCase()]
    ?? IMAGES[iconNameUpperCase][MENUFACTURS.CHAMPION]
    );
};

/**
 * return icon path
 * @param {string} iconName
 */
export const getIconFromAssets = (iconName) => {
    return IMAGES[iconName.toUpperCase()];
};

/**
 * @param {string} brand
 * @param {numeric} modelGroupCode
 * @param {numeric} trimLevelCode
 */
export const getHomePageThumbnail = (
    brand,
    modelGroupCode,
    trimLevelCode,
) => {
    const brandFolder = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandFolder}/Proceed_online/${modelGroupCode}/${trimLevelCode}/images/welcome.png`;
};

/**
 * @param {string} brand
 * @param {numeric} modelGroupCode
 */
export const getOnelineCarMenuThumbail = (brand, modelGroupCode) => {
    const brandFolder = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandFolder}/Proceed_online/${modelGroupCode}/online/online.png`;
};

/**
 * 
 * @param {string} brand   @example: S
 * @param {numeric} modelGroupCode /@example 22
 * @param {numeric} trimLevelCode  @example 57
 * @param {string} externalColorCode @example/ B4B4
 * @returns 360 url
 */

export const getCar360UrlExternal = (
    brand,
    modelGroupCode,
    trimLevelCode,
    externalColorCode,
) => {
    const brandFolder = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandFolder}/Proceed_online/${modelGroupCode}/${trimLevelCode}/360/exterior/${externalColorCode}/index.html`;
};
/**
 * 
 * @param {STRING} url 
 * @returns full url with https protocol 
 */
export const addHttpsToUrl = (url) => `https://${url}`;

/**
 * 
 * @param {string} brand 
 * @param {string} addOnLocalCode 
 * @returns html txt string
 */

export const getAddOnsMoreInfoHtmlUri = (brand, addOnLocalCode) => {
    const brandFolder = getMutagFolderName(brand);
    
    return `${CHAMPION_MEDIA_URL}${brandFolder}/Proceed_online/add_on/HTML/${addOnLocalCode.toLowerCase()}.txt`;
};

/**
 * Get url for add-on icon
 * @param {string} brand מותג
 * @param {number} addOnId מזהה תוספת - מספר ייחודי
 * @param {string} decice device type 
 */
export const getAddOnImageUrl = (brand, addOnId, device = DESKTOP) => {
    const brandFolder = getMutagFolderName(brand);
    const deviceType = device !== DESKTOP ? MOBILE : DESKTOP;
    
    return `${CHAMPION_MEDIA_URL}${brandFolder}/Proceed_online/add_on/${deviceType}/${addOnId}.jpg`;
};

/**
 * Get car image from inside
 * @param {string} brand מותג
 * @param {string} modelGroup קבוצת דגם
 * @param {string} trimLevelCode רמת גימור
 */
export const getInCarImageUrl = (
    brand,
    modelGroup,
    trimLevelCode,
    selectedColorValue,
) => {
    const brandFolder = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandFolder}/Proceed_online/${modelGroup}/${trimLevelCode}/images/interior/${selectedColorValue}/1.png`;
};

/*
 * @param {string} brand  example : R
 * @param {numeric} equipmentPackageId  - example : #F3, #F4
 * @param {numeric} modelGroupCode : example 27
 * @param {numeric} trimLevelCode : example 96
 * @param {boolean} isBasicPackage
 * @param {string} decice device type 
 * @return {string}
    return path example for NOT basic equipment package : 
    http://champ-media.champion.co.il/CUPRA/Proceed_online/27/96/images/package/desktop/STANDARD-27.png
   
    return path example for basic equipment package :
    http://champ-media.champion.co.il/CUPRA/Proceed_online/27/96/images/package/desktop/STANDARD-27.png
 */

export const getEquipmentPackageImageUrl = (
    brand,
    equipmentPackageCode,
    modelGroupCode,
    trimLevelCode,
) => {
    const brandFolder = getMutagFolderName(brand);

    if (equipmentPackageCode === null) {
        return `${CHAMPION_MEDIA_URL}${brandFolder}/General_Media/Logo/Black/black.png`;
    }

    let equipmentPackageId = equipmentPackageCode;

    // To Do: when there's gonna be more than one originalAddition create a saparate function
    // for getting the image url and remove the if statement.
    if (equipmentPackageId?.charAt(0) === '#') {
        equipmentPackageId =  equipmentPackageCode?.substring(1); // #F3 => F3
    } else if (equipmentPackageId === '') {
        equipmentPackageId = `STANDARD-${modelGroupCode}`;
    }
        
    return `${CHAMPION_MEDIA_URL}${brandFolder}/Proceed_online/${modelGroupCode}/${trimLevelCode}/images/package/desktop/${equipmentPackageId}.png`;
};

export const get404PageImage = (brand, isMobile) => {
    const brandFolder = getMutagFolderName(brand);

    if (isMobile) {
        return `${CHAMPION_MEDIA_URL}${brandFolder}/Proceed_online/404/mobile/404.jpg`;
    }
    
    return `${CHAMPION_MEDIA_URL}${brandFolder}/Proceed_online/404/l/404.jpg`;
};

export const getOriginalAdditionImage = (brand) => {
    const brandFolder = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandFolder}/Proceed_online/original/default.png`;
};

export const getModelGroupCatalogLink = (brand, modelGroupCode) => {
    const brandFolder = getMutagFolderName(brand);

    return `${CHAMPION_MEDIA_URL}${brandFolder}/Greeting/${modelGroupCode}.pdf`;
};
