import React from 'react';
import PropTypes from 'prop-types';
import { useCurrentConfigOptions } from '../../../../../hooks';
import { getOriginalAdditionsDetailsLinks } from '../../../../../utils/commonUtils';
import STRINGS from '../../../../../language';
import AdditionalOptionHeader from '../AdditionalOptionHeader/AdditionalOptionHeader';
import LabelGridItem from '../LabelGridItem/LabelGridItem';
import EquipmentPackageSection from '../EquipmentPackageSection/EquipmentPackageSection';
import OriginalAdditionSection from '../OriginalAdditionSection/OriginalAdditionSection';
import ColorsBar from '../ColorsBar/ColorsBar';
import AdditionalOptionFooter from '../AdditionalOptionFooter/AdditionalOptionFooter';
import Styled from './EquipmentPackagesDesktop.style';

const EquipmentPackagesDesktop = ({
    onSelect,
}) => {
    const { subModels: allSubModels } = useCurrentConfigOptions();
    const { subModels } = allSubModels;
    const renderAdditionalOptionsHeaders = () => {
        const headers = subModels?.map(({
            price,
            totalPrice,
            licenseFee,
            arrivalDate,
        }) => {
            return (
                <AdditionalOptionHeader
                    price={ price }
                    fullPrice={ totalPrice }
                    licenseFeePrice={ licenseFee }
                    arrivalDate={ arrivalDate }
                />
            );
        });

        return (
            <>
                <LabelGridItem
                    title={ STRINGS.PRICE_INCLUDES_VAR_AND_LICENSING_FEE }
                />
                { headers }
            </>
        );
    };

    const renderEquipmentPackagesSection = () => {
        const equipmentPackages = subModels?.map(({
            equipmentPackageDescription,
            equipmentPackageDetails,
        }) => {
            return (
                <EquipmentPackageSection
                    description={ equipmentPackageDescription ?? STRINGS.WITHOUT_EQUIPMENT_PACKAGE }
                    details={ equipmentPackageDetails }
                />
            );
        });

        return (
            <>
                <LabelGridItem
                    title={ STRINGS.EQUIPMENT_PACKAGE }
                    alignTop
                />
                { equipmentPackages }
            </>
        );
    };

    const renderOriginalAdditionsRows = () => {
        const originalAdditionsDetailsLinks = getOriginalAdditionsDetailsLinks(subModels);
        const originalAdditions = subModels?.reduce((allOriginalAdditions, model) => {
            return {
                ...allOriginalAdditions,
                ...model.originalAdditionDescriptions,
            };
        }, {});

        const originalAdditionsRows = Object.entries(originalAdditions).map(([key, value]) => {
            const originalAdditionRow = subModels?.map(({
                originalAdditionDescriptions,
            }) => {
                const isIncluded = !!originalAdditionDescriptions[key];

                return (
                    <OriginalAdditionSection
                        isIncluded={ isIncluded }
                    />
                );
            });

            return (
                <>
                    <LabelGridItem
                        title={ value }
                        additionalData={ originalAdditionsDetailsLinks[key] }
                    />
                    { originalAdditionRow }
                </>
            );
        });

        return originalAdditionsRows;
    };

    const renderColorsRow = () => {
        const colorsRow = subModels?.map(({
            links,
        }) => {
            return (
                <ColorsBar
                    externalColors={ links.availableColorIconImages }
                    internalColors={ links.availableUpholsteryColorIconImages }
                />
            );
        });

        return (
            <>
                <LabelGridItem
                    title={ STRINGS.COLORS }
                />
                { colorsRow }
            </>
        );
    };

    const renderAdditionalOptionsFooter = () => {
        const footers = subModels?.map((model) => {
            return (
                <AdditionalOptionFooter
                    hrefToConfigurator={ model.hrefToConfigurator }
                    onClick={ () => onSelect(model) }
                    safetyLevel={ model.safetyEquipmentLevel }
                    pollutionLevel={ model.pollutionLevel }
                    isBeforeManufacture={ model.isBeforeManufacture }
                />
            );
        });

        return (
            <>
                <Styled.Dummy />
                { footers}
            </>
        );
    };

    return (
        <Styled.Container>
            <Styled.GridContainer
                $length={ subModels.length + 1 }
            >
                { renderAdditionalOptionsHeaders() }
                { renderEquipmentPackagesSection() }
                { renderOriginalAdditionsRows() }
                { renderColorsRow() }
                { renderAdditionalOptionsFooter() }
            </Styled.GridContainer>
        </Styled.Container>
    );
};

EquipmentPackagesDesktop.propTypes = {
    onSelect: PropTypes.func.isRequired,
};

export default EquipmentPackagesDesktop;
