import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLORS } from '../../../constants';
import { pxToRem } from '../../../utils/commonUtils';
import { InlineText } from '../../common/styles';
import {
    ImageBox,
    Figure,
    FigCaptionText,
} from '../../Steps/Model/ModelImage/ModelImage.styles';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const OnlineCarLink = styled(Link)`
  text-decoration:none;
  ${Figure}{
    width:100%;
     margin:0;
  }
  ${ImageBox}{
    width:265px;
    height: 100%;
    margin:auto;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        width:100%;
        height:auto;
    }
  }
  ${FigCaptionText}{
    margin-top:20px;
    width: 100%;
  text-align:center;
  font-size:${pxToRem(17)};
  font-weight:700;
  color: ${COLORS.FIG_CAPTION_COLOR_NAV_CAR_ONLINE};
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        margin-top:0;
    }
  }
`;

export const OnLineCar = styled.div`

  min-height:265px;
  &:hover{
     background-color:${COLORS.HOVER_COLOR_NAV_CAR_ONLINE};
 }
 @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        min-height:auto;
    }
    
`;

export const OnlineCarItem = styled.li`
flex:0 1 265px;
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        flex:1 1 50%;
        max-width:50%;
        padding:10px;
    }
    
`;

export const ToPurchase = styled.div`
      margin-top: 30px;
   text-align:center;
   @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        display:none;
    }
`;

export const Text = styled(InlineText)`
   
    border-bottom: 2px solid #000;
    padding-bottom: 6px;
    font-size:${pxToRem(16)};
    color:${COLORS.INFO_LINK_COLOR_NAV_CAR_ONLINE};
`;

export const MoreInfoLink = styled(Link)`
  color:${COLORS.INFO_LINK_COLOR_NAV_CAR_ONLINE};
  font-weight:400;
  font-size:${pxToRem(14)};
  text-decoration:none;
  display: block;
    margin: 10px;
    text-align: center;
  &:hover{
    text-decoration:underline;
  }
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        display:none;
    }
`;
