import React from 'react';
import PropTypes from 'prop-types';
import PageContainer from '../../components/PageContainer';
import STRINGS from '../../language';
import ChampionLogo from '../../components/ChampionLogo';
import * as Styled from './FailureScreen.styles';

function FailureScreenBody({ isPaymentError, errorMessage }) {
    return (
        <PageContainer>
            <Styled.Wrapper>
                { !isPaymentError && (
                    <ChampionLogo />
                )}

                <Styled.ErrorMessageContainer>
                    <Styled.Title>
                        {STRINGS.FAILURE_TEXT1}
                        <Styled.TitleSecondary>{STRINGS.FAILURE_TEXT2}</Styled.TitleSecondary>
                    </Styled.Title>
                    {!!errorMessage && <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>}
                </Styled.ErrorMessageContainer>
            </Styled.Wrapper>
        </PageContainer>
    );
}

FailureScreenBody.propTypes = {
    isPaymentError: PropTypes.bool,
    errorMessage: PropTypes.string,
};

FailureScreenBody.defaultProps = {
    isPaymentError: false,
    errorMessage: null,
};

export default FailureScreenBody;
