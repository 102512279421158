import styled from 'styled-components';
import { Car360 } from '../../Configurator/Car360/Car360.styles';
import { ReactComponent as FullScreen } from '../../../assets/icons/fullscreen.svg';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

const Container = styled.div`
  position: relative;
  height: ${({ theme }) => theme.presentation.desktop.height};
  display: flex;
  flex: 1;
  ${Car360} {
    flex: 1;
  }
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    height: ${({ theme }) => theme.presentation.mobile.height};
  }
  img {
    width: 100%;
    object-fit: cover;
  }
`;

const MediaButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;
`;

const FullScreenButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 48px;
  height: 48px;
  outline: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.95);
  z-index: 10;
  :hover {
    background-color: #F3F4F5;
  }
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: none;
  }
`;

const FullScreenIcon = styled(FullScreen)``;

const Styled = {
    Container,
    MediaButtonContainer,
    FullScreenButton,
    FullScreenIcon,
};

export default Styled;
