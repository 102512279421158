import React from 'react';
import PropTypes from 'prop-types';
import { useCurrentAppCommonData } from '../../../../hooks';
import * as Styled from './ContinueButton.style';

const ContinueButton = ({
    title,
    isDisabled,
    onClick,
}) => {
    const { isCupra } = useCurrentAppCommonData();

    return (
        <Styled.ContinueThemeButton
            onClick={ onClick }
            $isDisabled={ isDisabled }
            $isCupra={ isCupra }
        >
            {title}
            <Styled.LeftArrowIcon />
        </Styled.ContinueThemeButton>
    );
};

ContinueButton.defaultProps = {
    isDisabled: false,
    title: '',
    onClick: () => {},
};

ContinueButton.propTypes = {
    isDisabled: PropTypes.bool,
    title: PropTypes.string,
    onClick: PropTypes.func,
};

export default ContinueButton;
