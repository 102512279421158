import React from 'react';
import PropTypes from 'prop-types';
import { ColorPickerItemType } from '../../../constants';
import { isArrayEmpty } from '../../../utils/commonUtils';
import STRINGS from '../../../language';
import useIsMobile from '../../../hooks/useWindowSize';
import CONFIG from '../../../config';
import { PickerItem } from './PickerItem';
import Styled from './Picker.style';

const { DEVICE_SIZE } = CONFIG;

const Picker = ({
    items,
    pickedItem,
    onPick,
}) => {
    const isMobile = useIsMobile(DEVICE_SIZE.MEDIUM);
    const hasPrice = pickedItem.price !== 0;

    if (!items || isArrayEmpty(items)) {
        return null;
    }
    
    const _colors = items.map((item) => {
        const {
            icon, value, price, description, arrivalDate, isPackageRelated,
        } = item;
        
        return (
            <PickerItem
                key={ value }
                value={ value }
                icon={ icon }
                isSelected={ pickedItem.value === value }
                isTooltipDisabled={ isMobile }
                tooltipContent={ description }
                isPackageRelated={ isPackageRelated }
                price={ price }
                inStockString={ arrivalDate }
                onSelect={ () => onPick(item) }
            />
        );
    });

    return (
        <Styled.Container>
            <Styled.ColorPicker>
                {_colors}
            </Styled.ColorPicker>
            <Styled.SelectedItemContainer>
                <Styled.RowContainer>
                    <Styled.ItemName>{ pickedItem.description }</Styled.ItemName>
                    <Styled.Price>{ hasPrice ? ` ${parseFloat(pickedItem.price).toLocaleString()} ₪` :  STRINGS.WITH_NO_EXTRA_COST }</Styled.Price>
                </Styled.RowContainer>
                <Styled.ArrivalDate>{ pickedItem.arrivalDate }</Styled.ArrivalDate>
            </Styled.SelectedItemContainer>
        </Styled.Container>
    );
};

Picker.propTypes = {
    items: PropTypes.arrayOf(
        ColorPickerItemType,
    ).isRequired,
    pickedItem: PropTypes.object.isRequired,
    onPick: PropTypes.func,
};

Picker.defaultProps = {
    onPick: null,
};

export default Picker;
