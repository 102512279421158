import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { getNextOrPrevStep } from '../../../utils/configuratorUtils';
import { useCurrentCar } from '../../../hooks';
import { CONFIGURATOR_STEPS } from '../../../constants';
import ConfiguratorFooter from '../ConfiguratorFooter/ConfiguratorFooter';
import ConfiguratorLayout from '../ConfiguratorLayout';
/** ************ Steps  ************* */
import Model from '../../../components/Steps/Model';
import EngineAndTrimLevel from '../../../components/Steps/EngineAndTrimLevel';
import EquipmentPackages from '../../../components/Steps/EquipmentPackages/EquipmentPackages';
import Color from '../../../components/Steps/Color/Color';
import InternalColor from '../../../components/Steps/InternalColor/InternalColor';
import Hoops from '../../../components/Steps/Hoops/Hoops';
import AddOns from '../../../components/Steps/AddOns/AddOns';
import Summary from '../../../components/Steps/Summary';

const ConfiguratorContent = ({
    currentStep,
    currentStepHandler,
    goBackHandler,
    configOptions,
}) => {
    const currentSelectedCar = useCurrentCar();
    const {
        id: currentSelectedCarId,
        modelGroup,
    } = currentSelectedCar;

    let footerComponent = ConfiguratorFooter;

    let presentationalComponent;

    let configurationComponent;

    const nextStep =  getNextOrPrevStep(currentStep, 'next', CONFIGURATOR_STEPS);

    const prevStep = getNextOrPrevStep(currentStep, 'prev', CONFIGURATOR_STEPS);

    const {
        MODEL,
        ENGINE_AND_TRIM_LEVEL,
        EQUIPMENT_PACKAGES,
        COLOR,
        INTERNAL_COLOR,
        HOOPS,
        ADD_ONS,
        SUMMARY,
    } = CONFIGURATOR_STEPS;

    const nextStepHandler = useCallback(() => {
        window.scrollTo(0, 0);
        currentStepHandler(nextStep);
    }, [currentStep, currentSelectedCarId]);

    switch (currentStep) {
        case MODEL:
            presentationalComponent = Model;
            footerComponent = null;
            break;
        case ENGINE_AND_TRIM_LEVEL:
            presentationalComponent = EngineAndTrimLevel;
            break;
        case EQUIPMENT_PACKAGES:
            presentationalComponent = EquipmentPackages;
            //    footerComponent = null;// todo:: maybe change it after getting data from the server
            break;
        case COLOR:
            presentationalComponent = Color.Presentation;
            configurationComponent = Color.Configuration;
            break;
        case INTERNAL_COLOR:
            presentationalComponent = InternalColor.Presentation;
            configurationComponent = InternalColor.Configuration;
            break;
        case HOOPS:
            presentationalComponent = Hoops.Presentation;
            configurationComponent = Hoops.Configuration;
            break;
        case ADD_ONS:
            presentationalComponent = AddOns.Presentation;
            configurationComponent = AddOns.Configuration;
            break;
        case SUMMARY:
            return (
                <Summary
                    onGoBack={ goBackHandler }
                    configOptions={ configOptions }
                    footerComponent={ footerComponent }
                />
            );
        default:
            footerComponent = null;
            presentationalComponent = null;
    }
    
    return (
        <ConfiguratorLayout
            configOptions={ configOptions }
            modelGroup={ modelGroup }
            onContinue={ nextStepHandler }
            onGoBack={ goBackHandler }
            nextStep={ nextStep }
            prevStep={ prevStep }
            currentStep={ currentStep }
            footerComponent={ footerComponent }
            presentationalComponent={ presentationalComponent }
            configurationComponent={ configurationComponent }
        />
    );
};

ConfiguratorContent.defaultProps = {
    currentStep: null,
    currentStepHandler: () => {},
    goBackHandler: () => {},

};

ConfiguratorContent.propTypes = {
    currentStep: PropTypes.number,
    currentStepHandler: PropTypes.func,
    configOptions: PropTypes.object.isRequired,
    goBackHandler: PropTypes.func,
};

export default React.memo(ConfiguratorContent);
