import * as actionTypes from '../actionTypes';
import * as dispatchers from '../dispatchers';

const initialState = {
    id: null,
    modelGroup: null,
    modelGroupCode: null,
    modelCode: null,
    modelDescription: null,
    safetyEquipmentLevel: null,
    pollutionLevel: null,
    hebrewModelGroup: null,
    regulation: null,
    subModels: {
        isLoading: false,
        subModels: [],
    },
    internalColors: {
        colors: {},
        isLoading: false,
        error: null,
    },
    originalPackages: {
        packages: {},
        isLoading: false,
        error: null,
    },
    modelLocalGroups: {
        addOns: {},
        addOnsExtraInformation: {},
        isLoading: false,
        error: null,
    },
    pollutionAndSafetyDetails: {
        dataList: [],
        isLoading: false,
        error: null,
    },
    originalAdditions: {
        isLoading: true,
        error: null,
        originalAdditions: [],
        hoops: [],
    },
    equipmentPackages: {
        isLoading: false,
        error: null,
        equipmentPackages: [],
    },
    finalDiscount: {
        data: {
            modelPrice: null,
            specifications: null,
            addOns: null,
            licenseFee: null,
            totalPayment: null,
        },
        isLoading: false,
        error: null,
    },
    isLoading: false,
    error: null,
    externalColorsError: null,
    externalColors: [],

};

export const configuratorOptions = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CONFIGURATOR_OPTIONS_PENDING:
            return dispatchers.getConfiguratorOptionsPending(state);
        case actionTypes.GET_CONFIGURATOR_OPTIONS_SUCCESS:
            return dispatchers.getConfiguratorOptionsSuccess(state, action);
        case actionTypes.GET_CONFIGURATOR_OPTIONS_FAILURE:
            return dispatchers.getConfiguratorOptionsFailure(state, action);
        case actionTypes.GET_INTERNAL_COLORS_PENDING:
            return dispatchers.getInternalColorsPending(state);
        case actionTypes.GET_INTERNAL_COLORS_SUCCESS:
            return dispatchers.getInternalColorsSuccess(state, action);
        case actionTypes.GET_INTERNAL_COLORS_FAILURE:
            return dispatchers.getInternalColorsFailure(state, action);
        case actionTypes.GET_ORIGINAL_PACKAGES_PENDING:
            return dispatchers.getOriginalPackagesPending(state);
        case actionTypes.GET_ORIGINAL_PACKAGES_SUCCESS:
            return dispatchers.getOriginalPackagesSuccess(state, action);
        case actionTypes.GET_ORIGINAL_PACKAGES_FAILURE:
            return dispatchers.getOriginalPackagesFailure(state, action);
        case actionTypes.GET_MODEL_LOCAL_GROUPS_PENDING:
            return dispatchers.getModelLocalGroupsPending(state);
        case actionTypes.GET_MODEL_LOCAL_GROUPS_SUCCESS:
            return dispatchers.getModelLocalGroupsSuccess(state, action);
        case actionTypes.GET_MODEL_LOCAL_GROUPS_FAILURE:
            return dispatchers.getModelLocalGroupsFailure(state, action);
        case actionTypes.GET_FINAL_DISCOUNT_PRICE_PENDING:
            return dispatchers.getFinalDiscountPricePending(state);
        case actionTypes.GET_FINAL_DISCOUNT_PRICE_SUCCESS:
            return dispatchers.getFinalDiscountPriceSuccess(state, action);
        case actionTypes.GET_FINAL_DISCOUNT_PRICE_FAILURE:
            return dispatchers.getFinalDiscountPriceFailure(state, action);
        case actionTypes.RESET_FINAL_DISCOUNT_PRICES:
            return dispatchers.resetFinalDiscountPrices(state);
        case actionTypes.SET_EXTERNAL_COLORS_ERROR:
            return dispatchers.setExternalColorsError(state, action);
        case actionTypes.GET_EQUIPMENT_PACKAGE_PENDING:
            return dispatchers.getEquipmentPackagesPending(state);
        case actionTypes.GET_EQUIPMENT_PACKAGE_SUCCESS:
            return dispatchers.getEquipmentPackagesSuccess(state, action);
        case actionTypes.GET_EQUIPMENT_PACKAGE_FAILURE:
            return dispatchers.getEquipmentPackagesFailure(state, action);
        case actionTypes.REMOVE_ADD_ON:
            return dispatchers.removeAddOn(state, action);
        case actionTypes.INITIALIZE_CONFIGURATOR_OPTIONS:
            return dispatchers.initializeConfiguratorOptions(initialState);
        case actionTypes.INITIALIZE_CONFIGURATOR_DATA:
            return dispatchers.initializeConfiguratorData(state);
        case actionTypes.GET_POLLUTION_AND_SAFETY_DETAILS_DATA_PENDING:
            return dispatchers.getPollutionAndSafetyDetailsDataPending(state);
        case actionTypes.GET_POLLUTION_AND_SAFETY_DETAILS_DATA_SUCCESS:
            return dispatchers.getPollutionAndSafetyDetailsDataSuccess(state, action);
        case actionTypes.GET_POLLUTION_AND_SAFETY_DETAILS_DATA_FAILURE:
            return dispatchers.getPollutionAndSafetyDetailsDataFailure(state, action);
        case actionTypes.GET_ADD_ONS_EXTRA_INFO_PENDING:
            return dispatchers.getAddOnsExtraInfoPending(state);
        case actionTypes.GET_ADD_ONS_EXTRA_INFO_SUCCESS:
            return dispatchers.getAddOnsExtraInfoSuccess(state, action);
        case actionTypes.GET_ADD_ONS_EXTRA_INFO_FAILURE:
            return dispatchers.getAddOnsExtraInfoFailure(state, action);
        case actionTypes.GET_MODELS_ADDITIONAL_OPTIONS_PENDING:
            return dispatchers.getModelsAdditionalOptionsPending(state);
        case actionTypes.GET_MODELS_ADDITIONAL_OPTIONS_SUCCESS:
            return dispatchers.getModelsAdditionalOptionsSuccess(state, action);
        case actionTypes.GET_MODELS_ADDITIONAL_OPTIONS_FAILURE:
            return dispatchers.getModelsAdditionalOptionsFailure(state, action);
        default:
            return state;
    }
};
