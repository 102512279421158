import React, { useRef, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import confetti from 'canvas-confetti';
import ContinueThemeButton from '../../../../components/ContinueThemeButton/ContinueThemeButton';
import { useCurrentBrand, useCheckoutIsFinished, useCurrentAppCommonData } from '../../../../hooks';
import useIsMobile from '../../../../hooks/useWindowSize';
import { getCustomerSiteUrlByBrand, navigateProgrammatically } from '../../../../utils/commonUtils';
import { getIconFromAssets } from '../../../../utils/assetsUtils';
import CONFIG from '../../../../config';
import { ROUTES } from '../../../../constants';
import STRINGS from '../../../../language';
import { conffeti as conffetiConfig } from './PaymentSuccess.config';
import * as Styled from './PaymentSuccess.styles';

const { delay, durationInMilliseconds, conffetiThemeColors } = conffetiConfig;
const { DEVICE_SIZE } = CONFIG;

const PaymentSuccess  = () => {
    const themeContext = useContext(ThemeContext);
    const animationRef = useRef();
    const timerAnimationRef = useRef();
    const brand = useCurrentBrand();
    const isMobile = useIsMobile(DEVICE_SIZE.MEDIUM);
    const { isAudi } = useCurrentAppCommonData();
    const isPaymentFinished = useCheckoutIsFinished();
    const happyFaceIcon = getIconFromAssets('happy_face');
    const chatIcon = getIconFromAssets('chat');
    
    const navigateToCustomerSite = () => {
        navigateProgrammatically(getCustomerSiteUrlByBrand(brand));
    };

    const navigateTo = (to) => {
        navigateProgrammatically(to);
    };

    useEffect(() => {
        const conffetiColors = conffetiThemeColors([themeContext.primaryColor, '#ffffff']);
        const end = Date.now() + (durationInMilliseconds); // 4000 = 4 sec
        
        const frame = () => {
            confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: 0, y: 1 },
                colors: conffetiColors,
                startVelocity: 90,
            });
            confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: 1, y: 1 },
                colors: conffetiColors,
                startVelocity: 90,
            });

            if (Date.now() < end) {
                animationRef.current = requestAnimationFrame(frame);
            }
        };

        if (isPaymentFinished) {
            timerAnimationRef.current =  setTimeout(() => {
                frame();
            }, delay);
        }

        return () => {
            const { current: setTimeOutId } = timerAnimationRef;

            if (setTimeOutId) {
                clearTimeout(setTimeOutId);
                cancelAnimationFrame(animationRef.current);
                confetti.reset();
            }
        };
    }, [isPaymentFinished]);

    return (
        <Styled.PaymentSuccess>
            <Styled.Title>{STRINGS.WE_HAVE_RECEIVED_YOU_ORDER_WHAT_AN_EXCITEMENT}</Styled.Title>
            <Styled.SubTitle isMobile={ isMobile }>
                {STRINGS.PAYMENT_SUCCESS_OUR_REPRESENTATIVE_WILL_CONTACT_YOU_SHORTLY}
            </Styled.SubTitle>
            <Styled.Disclaimer>{STRINGS.PAYMENT_SUCCESS_DISCLAIMER}</Styled.Disclaimer>
            <Styled.ActionContainer>
                <Styled.Action
                    onClick={ () => navigateTo(ROUTES.FAQ) }
                    $icon={ happyFaceIcon }
                >{STRINGS.ANSWERS_TO_ALL_OF_YOUR_QUESTIONS}
                </Styled.Action>
                <Styled.Action
                    onClick={ () => navigateTo(ROUTES.CONTACT) }
                    $icon={ chatIcon }
                >{STRINGS.WANT_TO_SPEAK_WITH_US}
                </Styled.Action>
            </Styled.ActionContainer>
            <ContinueThemeButton
                title={ STRINGS.TAKE_ME_TO_MY_PERSONAL_AREA }
                onClick={ navigateToCustomerSite }
                isAudi={ isAudi }
            />
        </Styled.PaymentSuccess>
    );
};

export default PaymentSuccess;
