import styled from 'styled-components';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const ErrorWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ListWrapper = styled.ul`
    display: flex;
    margin: 0;
    margin-top: 1.5rem;
    padding: 0;
    list-style-type: none;
    flex-wrap: wrap;
    @media (max-width: ${DEVICE_SIZE.LARGE}px) {
        justify-content: center;
    }
`;
