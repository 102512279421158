import React from 'react';
import PropTypes from 'prop-types';
import Styled from './PageContainer.style';

const PageContainer = ({
    children,
}) => {
    return (
        <Styled.Container>
            <Styled.Content>
                {children}
            </Styled.Content>
        </Styled.Container>
    );
};

PageContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.func])),
        PropTypes.element,
        PropTypes.func,
        PropTypes.node,
    ])
        .isRequired,
};

PageContainer.defaultProps  = {
};

export default PageContainer;
