import React from 'react';
import PropTypes from 'prop-types';
import Question from '../Question/Question';
import Answer from '../Answer/Answer';
import * as Styled from './FaqItem.styles';

const FaqItem = ({
    question, answer, onSelect, isSelected,
}) => {
    return (
        <Styled.FaqItem onClick={ () => onSelect(question) } >
            <Styled.Faq >
                <Question isSelected={ isSelected } question={ question } />
                <Answer isSelected={ isSelected } answer={ answer } />
            </Styled.Faq>
        </Styled.FaqItem>
    );
};

FaqItem.defaultProps = {
    isSelected: false,
    onSelect: () => {},
};

FaqItem.propTypes = {
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,
    answer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    question: PropTypes.string.isRequired,
};

export default FaqItem;
