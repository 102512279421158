import styled from 'styled-components';
import CONFIG from '../../../../../config';
import { CarRank } from '../../../Model/ModelItemCollapsible/CarRank/CarRank.styles';
import { SelectButton } from '../SelectButton/SelectButton.style';

const { DEVICE_SIZE } = CONFIG;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 10px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  padding: 20px;
  margin-bottom: 30px;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    border: none;
    padding: 0 20px;
    margin-bottom: 0;
    ${SelectButton} {
      display: none;
    }
    ${CarRank} {
      gap: 16px;
      border: none;
      & > p {
        margin: 0;
      }
    }
  }
`;

const Styled = {
    Container,
};

export default Styled;
