import React from 'react';
import PropTypes from 'prop-types';
import { getIconFromAssets } from '../../../utils/assetsUtils';
import { ICONS_NAMES } from '../../../constants';
import * as Styled from './TogglePresentationBtn.styles';

const TogglePresentationBtn = ({ onToggle, isMediaVisible, isVisible }) => {
    if (!isVisible) {
        return null;
    }
    const car360IconWhite = getIconFromAssets(ICONS_NAMES.C360);
    const galleryIconWhite = getIconFromAssets(ICONS_NAMES.GALLERY);
    const car360IconDark = getIconFromAssets(ICONS_NAMES.C360_DARK);
    const galleryIconDark = getIconFromAssets(ICONS_NAMES.GALLERY_DARK);

    const isCar360Active = !isMediaVisible;
    const isGalleryActive = !isCar360Active;

    return (
        <Styled.TogglePresentationBtn onClick={ onToggle } >
            <Styled.IconBox $isActive={ isCar360Active }>
                <Styled.Icon src={ isCar360Active ? car360IconWhite : car360IconDark } />
            </Styled.IconBox>
            <Styled.IconBox $isActive={ isGalleryActive }>
                <Styled.Icon src={ isGalleryActive ? galleryIconWhite :  galleryIconDark } />
            </Styled.IconBox>
        </Styled.TogglePresentationBtn>
    );
};

TogglePresentationBtn.defaultProps = {
    onToggle: () => {},
    isMediaVisible: false,
    isVisible: true,
};

TogglePresentationBtn.propTypes = {
    onToggle: PropTypes.func,
    isMediaVisible: PropTypes.bool,
    isVisible: PropTypes.bool,
};

export default TogglePresentationBtn;
