import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLORS, ICONS_NAMES } from '../../../../constants';
import { pxToRem } from '../../../../utils/commonUtils';
import CONFIG from '../../../../config';
import { getIconFromAssets } from '../../../../utils/assetsUtils';

const { DEVICE_SIZE } = CONFIG;

const specificationIcon = getIconFromAssets(ICONS_NAMES.DOWNLAOD_SPECIFICATIONS);

export const MainTabs = styled.div`
  height: 45px;
`;
export const Tabs = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  /* @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    justify-content:space-between;
  } */
`;

export const CatalogLink = styled(Link)`
  border: 1px solid ${COLORS.BLACK};
  border-radius: 4px;
  align-self: center;
    padding: 5px 10px;
  font-size: ${pxToRem(14)};
  font-weight: 400;
  display:flex;
  justify-content:center;
  align-items:center;
  line-height: 1;
  color:${COLORS.BLACK};
  text-decoration:none;
  margin-right:45px;
  background-color:${COLORS.WHITE};
  @media (max-width: ${DEVICE_SIZE.LARGE}px) {
    position: absolute;
    top: 100%;
    transform: translateY(-50%);
    margin:auto;
  }

  &:before{
    content:'';
    width:16px;
    height:16px;
    display:block;
    background-image:url(${specificationIcon});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin-left:7.5px;
  }
  &:hover{
    text-decoration:underline;
  }
`;
