import styled from 'styled-components';
import CONFIG from '../../config';
import { pxToRem } from '../../utils/commonUtils';

const { DEVICE_SIZE } = CONFIG;

export const PageNotFound = styled.div`
 display:flex;
 width: 100%;
 max-width: 100%;
 @media(max-width:${DEVICE_SIZE.MEDIUM}px){
       flex-direction: column-reverse;
   }
`;

export const Left = styled.div`
 width: 100%;
 height: 80vh;
 background-image: url(${({ $image }) => $image});
 background-repeat: no-repeat;
 background-size: cover;
 background-position: 90% 20%;
 @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    height: 30vh;
   }
`;

export const Right = styled.div`
   position: absolute;
   display:flex;
   justify-content: center;
   padding-top: 150px;
   width: 50%;
   height: 100%;
   color: white;
   background-color: rgba(0,0,0,0.6);
   @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    position: static;
    width: 100%;
    height: 50%;
    padding-top: 30px;
    padding-bottom: 80px;
    background-color: rgb(51, 48, 46);
   }
`;

export const Image = styled.img`
   height: 100%;
   width: 100%;
   @media(max-width:${DEVICE_SIZE.MEDIUM}px){
     width: 100%;
     height: 100%;
   }
`;

export const Content = styled.div`
   text-align: center;
   @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    width: 100%;
    height: 100%;
   }
`;

export const Text = styled.p`
  margin:0;
  font-weight:400;
  line-height: 19px;
  font-size:${pxToRem(24)};
`;

export const Header = styled(Text)`
  margin-bottom: 18px;
  font-weight:700;
  line-height: 36px;
  font-size:${pxToRem(46)};
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    margin-bottom: 20px;
    line-height: 28px;
    font-size:${pxToRem(36)};
   }
`;

export const SubTitle = styled(Text)`
  font-size:${pxToRem(32)};
  margin-bottom: 145px;
  line-height: 26px;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    font-size:${pxToRem(28)};
    margin-bottom: 60px;
   }
`;

export const PreviousPageButton = styled.button`
 color: white;
 font-weight:700;
 font-size:${pxToRem(24)};
 background-color:transparent;
 outline:none;
 display:flex;
 align-items: center;
 border: 2px solid white;
 border-radius: 4px;
 padding: 10px 25px;
 margin-right:auto;
 margin-left:auto;
 margin-top: 18px;
 cursor: pointer;
 
`;
