import styled from 'styled-components';
import { ReactComponent as Info } from '../../../../../assets/icons/info.svg';
import CONFIG from '../../../../../config';
import { Divider } from '../Divider/Divider.styles';

const { DEVICE_SIZE } = CONFIG;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    padding: 0 12px;
    border: none;
    ${Divider} {
      display: none;
    }
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    padding: 0;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 50%;
  background-color: #C6C6C6;
`;

const ColorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
`;

const IconWrapper = styled.div`
  padding: 10px;
`;

const InfoIcon = styled(Info).attrs(({ theme }) => ({
    width: 18,
    height: 18,
    stroke: theme.primaryColor,
}))`
  min-width: 18px;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: right;
  margin: 0;
`;

const Dummy = styled.div`
  height: 1px;
`;

const Styled = {
    Container,
    InnerContainer,
    VerticalDivider,
    ColorsContainer,
    IconWrapper,
    InfoIcon,
    Text,
    Dummy,
};

export default Styled;
