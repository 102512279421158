import styled from 'styled-components';
import CONFIG from '../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SmallTitle = styled.h4`
    margin: 0;
    font-size: 18px;
    text-align: center;
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
     max-width: 300px;
  }
`;

export const BigTitle = styled.h1`
    margin: 0;
    margin-top: 1.5rem;
    font-size: 40px;
    text-align: center;
`;
