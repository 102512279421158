import React from 'react';
import PropTypes from 'prop-types';
import { useCurrentAppCommonData } from '../../../../../hooks';
import Styled from './SelectButton.style';

const SelectButton = ({
    to,
    onClick,
    children,
}) => {
    const {
        isAudi,
        isCupra,
    } = useCurrentAppCommonData();
    
    return (
        <Styled.SelectButton
            to={ to }
            onClick={ onClick }
            $isAudi={ isAudi }
            $isCupra={ isCupra }
        >
            { children }
        </Styled.SelectButton>
    );
};

SelectButton.propTypes = {
    to: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
};

export default SelectButton;
