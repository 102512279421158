/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../Divider/Divider';
import AdditionalOriginalAdditionDetailsTooltip from '../AdditionalOriginalAdditionDetailsTooltip/AdditionalOriginalAdditionDetailsTooltip';
import Styled from './LabelItemMobile.styles';

const LabelItemMobile = ({
    title,
    additionalData,
}) => {
    return (
        <Styled.Wrapper>
            <Styled.Container>
                <Styled.Label>{title}</Styled.Label>
                { additionalData && (
                    <>
                        <Styled.InfoIcon
                            data-tip
                            data-for={ additionalData }
                        />
                        <AdditionalOriginalAdditionDetailsTooltip
                            id={ additionalData }
                            dataLink={ additionalData }
                        />
                    </>
                ) }
            </Styled.Container>
            <Divider />
        </Styled.Wrapper>
    );
};

LabelItemMobile.propTypes = {
    title: PropTypes.string.isRequired,
    additionalData: PropTypes.string,
};

LabelItemMobile.defaultProps = {
    additionalData: null,
};

export default LabelItemMobile;
