import styled from 'styled-components';
import { COLORS } from '../../../../../constants';
import { pxToRem } from '../../../../../utils/commonUtils';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const IdentificationForm = styled.div`
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    form {
      width: 100%;
    }
  }
  .input-text{
    font-size:${pxToRem(17)};
    border: none;
    border-bottom:1px solid ${COLORS.BLACK};
    background-color:transparent;
  }
`;

export const LoaderContainer = styled.div`
   height:267px;
   width:100%;
   display:flex;
   justify-content: center;
   align-items: center;
`;

export const ErrorContainer = styled.div`
   padding: 30px;
    text-align: center;
`;

export const StepHeadContainer = styled.div`
  display: inline-flex;
  @media(max-width: ${DEVICE_SIZE.SMALL}px) {
    flex-direction: ${({ $isAudi }) => !$isAudi && 'column'};
  }
`;

export const FormAndButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  @media(max-width: ${DEVICE_SIZE.SMALL}px) {
    width: 100%;
    button {
      width: unset;
      max-width: unset;
    }
  }
`;

export const TitleContainer = styled.div`
  display: block;
`;

export const IconAndSubTitleWrapper = styled.div`
  display: none;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: block;
  }
`;

export const GoBackButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 4rem;
  font-family: ${({ theme }) => theme['font-family']};
  font-size: 18px;
`;
