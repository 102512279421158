import styled from 'styled-components';
import { motion } from 'framer-motion';
import CONFIG from '../../../config';
import { COLORS } from '../../../constants';
import { staggerParentConfig } from '../../../utils/animationsConfig';

const { DEVICE_SIZE } = CONFIG;

const Container = styled.div`
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    margin-bottom: 10%;
  }
`;

const ColorPicker = styled(motion.ul).attrs({
    ...staggerParentConfig,
})`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  padding: 0;
  gap: 8px;
  animation: reveal 300ms ease-out forwards;
  list-style-type: none;

  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    max-width: 100%;
    flex-wrap: nowrap;
    overflow: auto;
    padding: 10px 0;
  }

  @keyframes reveal {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const SelectedItemContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid #10833B;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    margin: 10%;
  }
`;

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ItemName = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: ${COLORS.BLACK};
`;

const Price = styled.p`
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: ${COLORS.BLACK};
`;

const ArrivalDate = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${COLORS.BLACK};
`;

const Styled = {
    Container,
    ColorPicker,
    SelectedItemContainer,
    RowContainer,
    ItemName,
    Price,
    ArrivalDate,
};

export default Styled;
