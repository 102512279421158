import React from 'react';
import PropTypes from 'prop-types';
import { ICONS_NAMES } from '../../../../../constants';
import STRINGS from '../../../../../language';
import { getIconFromAssets } from '../../../../../utils/assetsUtils';
import IconAndSubTitle from '../../../IconAndSubTitle';
import CheckBoxFormGroup from '../../../../../components/CheckBoxFormGroup/CheckBoxFormGroup';
import { useCurrentAppCommonData } from '../../../../../hooks';
import {
    getManufacturerContactInfo,
    replacePlaceholdersInTemplate,
} from '../../../../../utils/commonUtils';
import * as Styled from './Financing.styles';

const { CALCULATE } = ICONS_NAMES;
const financingIcon = getIconFromAssets(CALCULATE);
const CheckBoxId = 'financing';

const Financing = ({ isFinancing, onCheck }) => {
    const { currentBrand, isAudi } = useCurrentAppCommonData();
    const { PHONE: contactPhoneNumber } = getManufacturerContactInfo(currentBrand);
    const disclaimerAndContactUsForTradeIn = replacePlaceholdersInTemplate(
        STRINGS.AUDI_FINANCING_DISCLAIMER_AND_TRADE_IN,
        [contactPhoneNumber],
    );

    return (
        <Styled.Financing>
            <Styled.StepHeadContainer $isAudi={ isAudi }>
                <IconAndSubTitle
                    icon={ financingIcon }
                    subTitle={ isAudi ? STRINGS.AUDI_FINANCING : STRINGS.FINANCING }
                />
                <Styled.TitleContainer>
                    <Styled.Title>{ isAudi
                        ? STRINGS.AUDI_ONE_SECOND_BEFORE_WE_CONTINUE
                        : STRINGS.ONE_SECOND_BEFORE_WE_CONTINUE }
                    </Styled.Title>
                    <Styled.SubTitle>
                        { isAudi
                            ? STRINGS.AUDI_WE_WHOULD_LIKE_TO_KNOW_IF_YOU_NEED_FINANCING
                            : STRINGS.WE_WHOULD_LIKE_TO_KNOW_IF_YOU_NEED_FINANCING}
                    </Styled.SubTitle>
                </Styled.TitleContainer>
            </Styled.StepHeadContainer>

            <Styled.Content $isAudi={ isAudi }>
                <CheckBoxFormGroup
                    label={ STRINGS.I_WHOULD_LIKE_TO_GET_A_FINANCING }
                    id={ CheckBoxId }
                    isChecked={ isFinancing }
                    onCheck={ onCheck }
                />
                <Styled.Disclaimer>{ isAudi
                    ? disclaimerAndContactUsForTradeIn
                    : STRINGS.FINANCING_DISCLAIMER}
                </Styled.Disclaimer>
            </Styled.Content>
        </Styled.Financing>
    );
};

Financing.defaultProps = {
    onCheck: () => {},
    isFinancing: false,
};

Financing.propTypes = {
    onCheck: PropTypes.func,
    isFinancing: PropTypes.bool,
};

export default Financing;
