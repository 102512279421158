import styled from 'styled-components';
import { pxToRem } from '../../../utils/commonUtils';
import { COLORS } from '../../../constants';
import { lineThrough, nisCurrencySign } from '../../common/styles';

export const PriceItem = styled.div`
  padding: ${({ $isAudi }) => $isAudi ? '15px 0 8px 0' : '10px 0 15px 0'};
  border-bottom: ${({ $isAudi }) => $isAudi ? `2px solid ${COLORS.COLOR_HEADER_NAV_LINK_HOVER}` : `3px solid ${COLORS.BLACK}`};
  display: flex;
  justify-content: space-between;
`;
export const Name = styled.p`
  font-weight: 700;
  font-size: ${({ $isAudi }) => $isAudi ? `${pxToRem(18)}` : `${pxToRem(15)}`};
  margin: 0;
`;
export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const Price = styled.span`
  font-size: ${({ $isAudi }) => $isAudi ? `${pxToRem(16)}` : `${pxToRem(13)}`};
  font-weight: 400;
  ${nisCurrencySign}
`;
export const PriceBeforeDiscount = styled(Price)`
  margin:0 0 0 10px;
  font-size: ${({ $isAudi }) => $isAudi ? `${pxToRem(15)}` : `${pxToRem(12)}`};
  position: relative;
  ${lineThrough}
`;
