import React, {
    useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import HelmetTitleDescription from '../../components/HelmetTitleDescription/HelmetTitleDescription';
import {
    useCurrentConfigOptions,
    useSelectedCurrentData,
    useCurrentAppCommonData,
    useActionDispatch,
    useGoToRoute,
} from '../../hooks';
import useQueryParams from '../../hooks/useQueryParams';
import { isObjectEmpty, parseEquipmentPackagesUrl } from '../../utils/commonUtils';
import { getNextOrPrevStep } from '../../utils/configuratorUtils';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import STRINGS from '../../language';
import { CONFIGURATOR_QUERY_PARAMS, CONFIGURATOR_STEPS, ROUTES } from '../../constants';
import { filterCarByModelGroup } from './configurator.utils';
import ConfiguratorContent from './ConfiguratorContent/ConfiguratorContent';
import ConfiguratorSteps from './ConfiguratorSteps';
import * as Styled from './Configurator.styles';

const {
    MODEL,
    ENGINE_AND_TRIM_LEVEL,
    EQUIPMENT_PACKAGES,
    COLOR,
} = CONFIGURATOR_STEPS;

const {
    HOME,
    CONFIGURATOR,
} = ROUTES;

const Configurator = () => {
    const {
        selectCurrentStep,
        selectChosenCar,
        selectCurrentExternalColor,
        selectCurrentInternalColor,
        selectCurrentHoop,
        selectCurrentAddOn,
        selectCurrentAddOnGroup,
        initializeSelectedCurrentData,
        initializeConfiguratorOptions,
        initializeSpecifications,
        initializeCheckout,
        initializeConfiguratorData,
        initializeAllCurrentSelectedData,
        getModelsAdditionalOptionsAsync,
        getExternalColorsAsync,
        getInternalColorsAsync,
        getOriginalPackagesAsync,
        getModelLocalGroupsAsync,
    } = useActionDispatch();
    const goTo = useGoToRoute();
    const [renderContent, setRenderContent] = useState(false);
    const [canonical, setCanonical] = useState(window.location.href);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { modelGroup } = useParams();
    const {
        selectedCar,
        selectedStep: currentStep,
        selectedExternalColor,
        selectedAddOnGroup,
    } = useSelectedCurrentData();
    const { onlineCars } = useCurrentAppCommonData();
    const configOptions = useCurrentConfigOptions();
    const {
        modelDescription,
        subModels: allSubModels,
    } = configOptions;
    const { subModels } = allSubModels;
    const initializeActionsRef = useRef([
        initializeConfiguratorOptions,
        initializeSelectedCurrentData,
        initializeSpecifications,
        initializeCheckout,
    ]);
    const {
        getQueryParam,
        deleteQueryParam,
        getHash,
    } = useQueryParams();
    const [isLoadingSummaryLink, setIsLoadingSummaryLink] = useState(false);

    const modelCode = getQueryParam(CONFIGURATOR_QUERY_PARAMS.MODEL);
    const modelGroupCode = getQueryParam(CONFIGURATOR_QUERY_PARAMS.MODEL_GROUP);
    const trimLevelCode = getQueryParam(CONFIGURATOR_QUERY_PARAMS.TRIM_LEVEL);
    const originalAdditionsCode = getQueryParam(CONFIGURATOR_QUERY_PARAMS.ORIGINAL_ADDITIONS);
    const externalColorCode = getQueryParam(CONFIGURATOR_QUERY_PARAMS.EXTERNAL_COLOR);
    const internalColorCode = getQueryParam(CONFIGURATOR_QUERY_PARAMS.INTERNAL_COLOR);
    const hoopCode = getQueryParam(CONFIGURATOR_QUERY_PARAMS.HOOPS);
    const addOnsCodes = getQueryParam(CONFIGURATOR_QUERY_PARAMS.ADDONS);
    const isConfiguratorPath = pathname === CONFIGURATOR;
    const isConfiguratorSummary = getHash() === CONFIGURATOR_QUERY_PARAMS.SUMMARY;

    const carItemsByModelGroup = onlineCars.filter(
        (car) => car.modelGroupCode.toString() === modelGroupCode);

    const prevStep = getNextOrPrevStep(currentStep, 'prev', CONFIGURATOR_STEPS);

    let isWrongModelGroup = false;

    const carIdFromUrl = originalAdditionsCode ? `${modelCode}-${trimLevelCode}-${originalAdditionsCode}` : `${modelCode}-${trimLevelCode}`;
        
    const selectCurrentStepHandler = useCallback((stepId) => {
        if (currentStep !== stepId) {
            selectCurrentStep(stepId);
        }
    }, [currentStep]);

    const prevStepHandler = useCallback(() => {
        const { hrefToModels } = selectedCar;

        window.scrollTo({
            behavior: 'smooth',
            top: 0,
        });
        if (currentStep === EQUIPMENT_PACKAGES) {
            if (carItemsByModelGroup.length > 1) {
                goTo(hrefToModels);
            } else {
                goTo(HOME);
            }
        } else if (currentStep === ENGINE_AND_TRIM_LEVEL) {
            goTo(HOME);
        } else if (currentStep === COLOR) {
            if (subModels.length < 2 && carItemsByModelGroup.length > 1) {
                goTo(hrefToModels);
            } else if (subModels.length > 1) {
                selectCurrentStepHandler(prevStep);
            } else {
                goTo(HOME);
            }
        } else {
            selectCurrentStepHandler(prevStep);
        }
    }, [currentStep]);

    const handleSummaryLink = async () => {
        try {
            setIsLoadingSummaryLink(true);
            setRenderContent(true);
            const chosenCar = onlineCars?.find((carItem) => carItem.id === `${modelCode}-${trimLevelCode}`);
    
            if (isObjectEmpty(chosenCar)) {
                setIsLoadingSummaryLink(false);
                navigate(ROUTES.HOME, { replace: true });

                return selectCurrentStepHandler(CONFIGURATOR_STEPS.MODEL);
            }
    
            selectChosenCar(chosenCar);
    
            const additionalOptionsRes = await getModelsAdditionalOptionsAsync(
                modelGroupCode,
                modelCode,
                trimLevelCode,
                chosenCar,
            );
    
            const chosenSubModel = additionalOptionsRes?.find(
                (carItem) => carItem.id === carIdFromUrl);
    
            if (isObjectEmpty(chosenSubModel)) {
                setIsLoadingSummaryLink(false);

                return selectCurrentStepHandler(CONFIGURATOR_STEPS.EQUIPMENT_PACKAGES);
            }
    
            selectChosenCar(chosenSubModel);
    
            const externalColorsRes = await getExternalColorsAsync(
                modelCode,
                trimLevelCode,
                originalAdditionsCode,
            );
    
            const externalChosenColor = externalColorsRes?.find(
                (color) => color.value === externalColorCode,
            );
    
            if (isObjectEmpty(externalChosenColor)) {
                setIsLoadingSummaryLink(false);

                return selectCurrentStepHandler(CONFIGURATOR_STEPS.COLOR);
            }
    
            selectCurrentExternalColor(externalChosenColor);
    
            const internalColorsRes = await getInternalColorsAsync(
                modelCode,
                externalColorCode,
                trimLevelCode,
                originalAdditionsCode,
            );
    
            const internalChosenColor = internalColorsRes?.find(
                (color) => color.value === internalColorCode,
            );
    
            if (isObjectEmpty(internalChosenColor)) {
                setIsLoadingSummaryLink(false);

                return selectCurrentStepHandler(CONFIGURATOR_STEPS.INTERNAL_COLOR);
            }
    
            selectCurrentInternalColor(internalChosenColor);
    
            const hoopsRes = await getOriginalPackagesAsync(
                modelCode,
                trimLevelCode,
                externalColorCode,
                internalColorCode,
                chosenSubModel.trimLevel,
                originalAdditionsCode,
            );
    
            selectCurrentHoop(hoopsRes[0]); // TODO: change later to support multi hoops
                
            const addOnsRes = await getModelLocalGroupsAsync(
                modelCode,
                hoopCode,
                chosenSubModel.carVersion,
            );
    
            if (addOnsCodes) {
                const addOnsArray = Array.isArray(addOnsCodes) ? addOnsCodes : [addOnsCodes];

                addOnsArray.forEach((addOn) => {
                    const [groupCode, local] = addOn.split('-');
            
                    const addOnGroupToSelect = addOnsRes.find(
                        (addOnGroup) => addOnGroup.groupCode === groupCode);
        
                    const addOnFromGroup = addOnGroupToSelect?.listLocalPackages?.find(
                        (_addOn) => _addOn.local === local);
        
                    if (!addOnFromGroup) {
                        deleteQueryParam(CONFIGURATOR_QUERY_PARAMS.ADDONS, addOn);

                        return false;
                    }
                    selectCurrentAddOn({
                        title: addOnFromGroup.description,
                        groupDescription: addOnGroupToSelect.groupDescription,
                        price: addOnFromGroup.price,
                        image: addOnFromGroup.image,
                        local,
                        priceAfterDiscount: addOnFromGroup.priceAfterDiscount,
                        isDiscount: addOnFromGroup.isDiscount,
                        groupCode,
                    });
        
                    return true;
                });
            }
            const addOnGroupToSelect = addOnsRes[0].listLocalPackages[0];

            if (!addOnGroupToSelect) {
                setIsLoadingSummaryLink(false);

                return selectCurrentStepHandler(CONFIGURATOR_STEPS.ADD_ONS);
            }

            selectCurrentAddOnGroup(addOnGroupToSelect);
    
            selectCurrentStep(CONFIGURATOR_STEPS.SUMMARY);
            setIsLoadingSummaryLink(false);
        } catch (err) {
            setIsLoadingSummaryLink(false);
            navigate(ROUTES.HOME, { replace: true });
            
            return selectCurrentStepHandler(CONFIGURATOR_STEPS.MODEL);
        }
    };

    useEffect(() => {
        setRenderContent(false);
        setCanonical(window.location.href);
        /**
         * If the route is === '/' which is the {ROUTES.HOME}
           1. go first step of the configurator (Home screen) 
           2.initial relevant reducers
         */
        if (pathname === '/') {
            selectCurrentStepHandler(MODEL);
            // initialize data
            initializeActionsRef.current.forEach((initAction) => {
                initAction();
            });
            setRenderContent(true);
        } else if (isConfiguratorSummary) {
            handleSummaryLink();
        } else if (isConfiguratorPath) {
            /**
             * if the route is == '/configurator' {ROUTES.CONFIGURATOR} or /configurator/equipment-packages
                 and it has querystring params:
                1. take the query string params ?brand&modelCode&trimLevelCode 
                2. send a request in order to get configurator options
                3. proceed with the inner logic
             */
            if (modelCode && trimLevelCode) {
                const chosenCar = subModels?.find((carItem) => carItem.id === carIdFromUrl)
                                || onlineCars?.find((carItem) => carItem.id === `${modelCode}-${trimLevelCode}`);

                if (chosenCar) {
                    selectChosenCar(chosenCar);

                    // indicates the chosen model is not from sub models.
                    if (!chosenCar.links) {
                        selectCurrentStepHandler(EQUIPMENT_PACKAGES);
                        if (isObjectEmpty(selectedExternalColor)) {
                            selectCurrentExternalColor({});
                            initializeConfiguratorData();
                        }
                        setRenderContent(true);
                    } else if (isConfiguratorPath) {
                        /**
                        * __Handling coming back from checkout__
                        * In case we go to checkout and want to go back, we are checking the last selected
                        * data - addOnGroup and if it empty it means that we didn't finish the configuration
                        * so we initialize the configurator.
                        */
                        if (!selectedAddOnGroup) {
                            selectCurrentExternalColor({});
                            initializeConfiguratorData();
                            selectCurrentStepHandler(EQUIPMENT_PACKAGES);
                        }
                        setRenderContent(true);
                    } else {
                        selectCurrentStepHandler(EQUIPMENT_PACKAGES);
                        if (isObjectEmpty(selectedExternalColor)) {
                            selectCurrentExternalColor({});
                            initializeConfiguratorData();
                        }
                        setRenderContent(true);
                    }
                } else {
                    initializeConfiguratorData();
                    navigate(
                        parseEquipmentPackagesUrl(
                            modelCode,
                            trimLevelCode,
                            modelGroupCode,
                        ), { replace: true },
                    );
                    selectCurrentStepHandler(EQUIPMENT_PACKAGES);
                }
            }
        } else if (modelGroup) {
            initializeAllCurrentSelectedData();
            /**
             * if the route is '/configurator/models/:modelGroup
             */
            const carsByMGCode = onlineCars.filter(filterCarByModelGroup(modelGroup));
            const firstCar = carsByMGCode[0];
            /**
             * checking if the user didn't enter invalid modelGroupCode to the url
             */
            
            isWrongModelGroup = !firstCar;

            if (carsByMGCode.length === 1) {
                // There is only one car under the modelGroupCode - proceed to Color or EquipmentPackages step
                initializeConfiguratorData();
                selectCurrentStepHandler(EQUIPMENT_PACKAGES);
                navigate(firstCar.hrefToConfigurator, { replace: true });
            } else if (!isWrongModelGroup) {
                /**
                 * There are 2 or more cars - proceed to Engine and Trim level step
                 *  if a car has already been selected, initializeSelectedCurrentData
                 */
                initializeConfiguratorData();
                selectCurrentStepHandler(ENGINE_AND_TRIM_LEVEL);
                setRenderContent(true);
            }
        }
    }, [pathname, modelCode]);

    const renderConfiguratorContent = () => {
        if (isWrongModelGroup || !renderContent) {
            return (
                <Styled.ErrorWrapper>
                    <ErrorMessage errorMessage={ STRINGS.NO_CARS_TO_DISPLAY } />
                </Styled.ErrorWrapper>
            );
        }

        if (isLoadingSummaryLink) {
            return (
                <Styled.CustomLoaderWrapper>
                    <Styled.CustomLoader>
                        <Loader />
                    </Styled.CustomLoader>
                    <ConfiguratorContent
                        configOptions={ configOptions }
                        currentStepHandler={ selectCurrentStepHandler }
                        goBackHandler={ prevStepHandler }
                        currentStep={ currentStep }
                    />
                </Styled.CustomLoaderWrapper>
            );
        }
        
        return (
            <ConfiguratorContent
                configOptions={ configOptions }
                currentStepHandler={ selectCurrentStepHandler }
                goBackHandler={ prevStepHandler }
                currentStep={ currentStep }
            />
        );
    };

    return (
        <Styled.Configurator id="Configurator">
            <HelmetTitleDescription canonical={ canonical } />
            <ConfiguratorSteps
                modelDescription={ modelDescription }
                currentStepHandler={ selectCurrentStepHandler }
                currentStep={ currentStep }
                goBackHandler={ prevStepHandler }
            />
            {renderConfiguratorContent()}
        </Styled.Configurator>
    );
};

export default Configurator;
