import styled from 'styled-components';
import { pxToRem } from '../../../../utils/commonUtils';

export const Specifications = styled.div`
    padding-bottom: 20px;
    border-bottom: 2px dashed;
    display:inline-block;
`;
export const Title = styled.h5`
 font-weight:800;
 font-size:${pxToRem(23)};
 margin: 0 0 10px 0;
`;
export const List = styled.ul`
  margin:0;
  padding:0;
  list-style-type:none;
`;
