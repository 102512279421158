import styled from 'styled-components';
import { pxToRem } from '../../../../utils/commonUtils';
import { COLORS } from '../../../../constants';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';

import CONFIG from '../../../../config';
import { nisCurrencySign, lineThrough } from '../../../common/styles';

const { DEVICE_SIZE } = CONFIG;

export const PaymentDetailItem = styled.li`
  padding: 10px 0;
  border-bottom: 1px solid ${COLORS.SPECIFICATION_ITEM_COLOR_BORDER_BOTTOM};
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

export const NameAndImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Image = styled.div`
  width: 32px;
  height: 32px;
  margin-left: 15px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ $image }) => $image});
`;

export const Name = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
`;

export const DeleteOption = styled(TrashIcon).attrs({
    role: 'button',
})`
  cursor: pointer;
  margin-right: 16px;
`;

export const Price = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  ${nisCurrencySign}
`;
export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex-direction: column;
    justify-content: center;;
  }
`;

export const PriceBeforeDiscount = styled(Price)`
  margin:0 0 0 10px;
  font-size: ${pxToRem(12)};
  position: relative;
  ${lineThrough}
`;
