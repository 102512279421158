import styled from 'styled-components';
import {
    ModalContainer as MC,
    Layer,
    Modal as M,
    Content as C,
    CloseButton as CB,
} from '../../../../../../components/Modal/Modal.styles';
import { configurationLeftScrollBar } from '../../../../../../components/common/styles';

export const ModalContainer = styled(MC)`
   display:block;
   ol{
    padding:0;
}
`;
export const BackDrop = styled(Layer)``;
export const Modal = styled(M)`
  width:700px;
  height:500px;
`;
export const ScrollBar = styled.div`
    height:100%;
    overflow-y: scroll;
    ${configurationLeftScrollBar};
    direction: ltr;
`;

export const Content = styled(C)`
       direction: rtl
`;

export const CloseButton = styled(CB)`

`;
