import STRINGS from '../../../../../language';

export const OTP_FORM_GROUP = {
    name: 'otp',
    type: 'text',
    value: '',
    label: null,
    isRequired: true,
    validator: (value) => value.length > 0,
    isValid: false,
    errorMessage: STRINGS.YOU_DID_NOT_ENTER_ANYTHING,
    isErrorShown: false,
};
