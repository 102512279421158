import React, {
    useEffect, useRef, useCallback,
} from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import AddOrRemoveButton from '../../../../AddOrRemoveButton/AddOrRemoveButton';
import STRINGS from '../../../../../language';
import { useSelectedCurrentData } from '../../../../../hooks/selected_current_data';
import Loader from '../../../../Loader';
import { useActionDispatch, useCurrentBrand } from '../../../../../hooks';
import { getAddOnIconUrl } from '../../../../../utils/assetsUtils';
import HEBREW from '../../../../../language/hebrew';
import * as Styled from './MoreInformationModal.styles';

const MoreInformationModal = ({
    onClose, isVisible, title, price: displayPrice, local: addonUniID, brand, isSelected,
}) => {
    const { selectMoreInfoModalAsync } = useActionDispatch();
    const { selectedAddOnsInformation, selectedAddOnGroup } = useSelectedCurrentData();
    const { selectCurrentAddOn, selectIsInSummary } = useActionDispatch();
    const { isLoading: isLoadingMoreInfo, error } = selectedAddOnsInformation;
    const currentMoreInformationHtml = selectedAddOnsInformation[addonUniID];
    
    const currentBrand = useCurrentBrand();
    const backdrop = useRef(null);
    const clickHandler = (e) => e.target === backdrop.current && onClose();

    const selectAddOnHandler = useCallback((addOn) => {
        selectCurrentAddOn(addOn);
        selectIsInSummary(false);
    }, []);
    
    const clickHandlerAdd = () => {
        const addonToAdd = selectedAddOnGroup?.listLocalPackages?.find(
            (addon) =>  addon.local === addonUniID);

        const {
            description,
            groupDescription,
            price,
            local,
            familyCode,
            priceAfterDiscounts,
            isDiscount,
        } = addonToAdd;

        const updatedAddon = {
            title: description,
            groupDescription,
            price: parseInt(price),
            image: getAddOnIconUrl(currentBrand, familyCode),
            local,
            priceAfterDiscount: parseInt(priceAfterDiscounts),
            isDiscount,
        };

        selectAddOnHandler(updatedAddon);
    };

    const getMoreInformation = () => {
        if (!currentMoreInformationHtml) {
            selectMoreInfoModalAsync(brand, addonUniID);
        }
    };

    useEffect(() => {
        getMoreInformation();
    }, [isVisible]);

    const renderMoreInformation = () => {
        if (isLoadingMoreInfo) {
            return <Styled.LoaderContainer><Loader /></Styled.LoaderContainer>;
        }
        if (currentMoreInformationHtml) {
            return (
                <Styled.MoreInformation>
                    { parse(currentMoreInformationHtml) }
                </Styled.MoreInformation>
            );
        }
        if (error) {
            return (
                <Styled.NoDataContainer>
                    {STRINGS.THERE_IS_NO_INFORMATION_TO_SHOW}
                </Styled.NoDataContainer>
            );
        }
        
        return null;
    };

    const modal = (
        <CSSTransition in={ isVisible } timeout={ 500 } classNames="show-up" unmountOnExit>
            <Styled.Container ref={ backdrop } onClick={ clickHandler }>
                <Styled.MoreInformationModal className="modal-content">
                    <Styled.CloseButton $brand={ currentBrand } onClick={ onClose }>{ HEBREW.CLOSE }</Styled.CloseButton>
                    <Styled.Data>
                        <Styled.TitleAndMoreInformationContainer>
                            <Styled.Title>{title}</Styled.Title>
                            {renderMoreInformation()}
                        </Styled.TitleAndMoreInformationContainer>
                        <Styled.BottomContainer>
                            <Styled.Hr />
                            <Styled.BottomContentContainer>
                                <Styled.Price>{displayPrice}</Styled.Price>
                                <AddOrRemoveButton
                                    onClickHandler={ () => clickHandlerAdd() }
                                    isSelected={ isSelected }
                                    width="100%"
                                />
                            </Styled.BottomContentContainer>
                        </Styled.BottomContainer>
                    </Styled.Data>
                </Styled.MoreInformationModal>
            </Styled.Container>
        </CSSTransition>
    );

    return ReactDOM.createPortal(modal, document.body);
};

MoreInformationModal.defaultProps = {
    isVisible: false,
    onClose: () => {},
    title: '',
    displayPrice: 0,
    addonUniID: '',
};

MoreInformationModal.propTypes = {
    isVisible: PropTypes.bool,
    isSelected: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.string,
    displayPrice: PropTypes.number,
    addonUniID: PropTypes.string,
    brand: PropTypes.string.isRequired,
};

export default MoreInformationModal;
