import styled from 'styled-components';
import { pxToRem } from '../../../../../utils/commonUtils';
import {
    FullPriceAndTooltip,
    FullPriceWrapper,
} from '../../../Model/ModelItemCollapsible/FullPriceAndTooltip/FullPriceAndTooltip.styles';
import {
    LicenseFeePrice,
} from '../../../Model/ModelItemCollapsible/LicenseFeePrice/LicenseFeePrice.styles';
import { SelectButton } from '../SelectButton/SelectButton.style';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 10px;
  padding: 10px;
  ${SelectButton} {
    width: 85%;
  }
`;

const PricesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: ${({ theme }) => `1px solid ${theme.primaryColor}`};
  border-radius: 8px;
  padding: 8px;
  gap: 8px;
  ${FullPriceAndTooltip} {
    margin-top: 0;
  }
  ${FullPriceWrapper} {
    @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
      flex-direction: column;
      align-items: center;
    }
  }
  ${LicenseFeePrice} {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    text-align: center;
    gap: 2px;
  }
`;

const ArrivalDateContainer = styled.div`
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrivalDate = styled.p`
  font-weight: ${({ $isBold }) => $isBold ? '700' : '500'};
  font-size: ${pxToRem(16)};
  margin: 0;
  margin: 0.75rem 0;
  display: flex;
  text-align: center;
`;

const Styled = {
    Container,
    PricesWrapper,
    ArrivalDateContainer,
    ArrivalDate,
};

export default Styled;
