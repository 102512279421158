import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../Modal/Modal';
import InputFormGroup from '../../../../InputFormGroup/InputFormGroup';
import PhoneInput from '../../../../AudiConfigurator/Steps/Summary/ContactRepresentativeModal/PhoneIntput/PhoneInput';
import CheckBoxFormGroup from '../../../../CheckBoxFormGroup/CheckBoxFormGroup';
import SubmitButton from '../../../../SubmitButton/SubmitButton';
import Image from '../../../../Image';
import { useCarPlaceHolderImageUrl, useOutCarImageUrl } from '../../../../../utils/assets.hooks';
import { useCurrentAppCommonData, useSelectedCurrentData } from '../../../../../hooks';
import { PHONE_PREFIXES } from '../../../../../constants';
import * as Styled from './ModalForm.styles';
import { INPUT_FIELDS, CHECKBOX_FIELDS } from './ModalForm.fields';

const ModalForm = ({
    title,
    description,
    onChangeInput,
    onCheck,
    inputs, // For state
    checkBoxInputs,
    submitButtonTitle,
    onSubmit,
    isVisible,
    formName,
    onClose,
    inputFields, // For UI
    checkboxFields,
    isLoading,
    height,
    width,
    isRightImage,
}) => {
    const { selectedExternalColor, selectedCar } = useSelectedCurrentData();
    const { modelGroupCode, trimLevelCode } = selectedCar;
    const { currentBrand, isAudi } = useCurrentAppCommonData();
    const carImageUrl = useOutCarImageUrl(
        currentBrand,
        modelGroupCode,
        trimLevelCode,
        selectedExternalColor.value);
    const fallbackSrc = useCarPlaceHolderImageUrl(currentBrand);
    const renderInputFormGroups = () => {
        return inputFields.map((inputField, index) => {
            const {
                name,
                label,
                errorMessage,
                type,
                isRequired,
                maxlength,
            } = inputField;

            if (type === 'tel' && isAudi) {
                return (
                    <PhoneInput
                        key={ name }
                        maxlength={ maxlength }
                        inputName={ name }
                        label={ label }
                        errorMessage={ errorMessage }
                        inputType={ type }
                        isRequired={ isRequired }
                        inputValue={ inputs[name].value }
                        isErrorShown={ inputs[name].isErrorShown }
                        onChange={ onChangeInput }
                    />
                );
            }

            return (
                <InputFormGroup
                    key={ name }
                    maxlength={ maxlength }
                    inputName={ name }
                    label={ label }
                    errorMessage={ errorMessage }
                    inputType={ type }
                    isRequired={ isRequired }
                    inputValue={ inputs[name].value }
                    isErrorShown={ inputs[name].isErrorShown }
                    onChange={ onChangeInput }
                    isAudi={ isAudi }
                    isAutoFocused={ index === 0 }
                />
            );
        });
    };

    const renderCheckBoxFormGroups = () => {
        return checkboxFields.map((checkBoxField) => {
            const {
                id, label, errorMessage, isRequired,
            } = checkBoxField;

            return (
                <CheckBoxFormGroup
                    onCheck={ onCheck }
                    id={ id }
                    key={ id }
                    errorMessage={ errorMessage }
                    isErrorShown={ checkBoxInputs[id].isErrorShown }
                    isRequired={ isRequired }
                    label={ label }
                    isChecked={ checkBoxInputs[id].isChecked }
                />
            );
        });
    };

    return (
        <Modal
            isLoading={ isLoading }
            onClose={ onClose }
            isVisible={ isVisible }
            height={ height }
            width={ width }
            isAudi={ isAudi }
        >
            <Styled.ModalFormContent>
                {isRightImage && (
                    <Styled.ImageContainer>
                        <Image src={ carImageUrl } fallbackSrc={ fallbackSrc } />
                    </Styled.ImageContainer>
                )}
                <Styled.FormContainer>
                    <Styled.Title>{title}</Styled.Title>
                    {description && <Styled.Description>{description}</Styled.Description>}
                    <Styled.Form name={ formName } action="/">
                        {renderInputFormGroups()}
                        <Styled.Terms>{renderCheckBoxFormGroups()}</Styled.Terms>
                        <SubmitButton onSubmit={ onSubmit } title={ submitButtonTitle } />
                    </Styled.Form>
                </Styled.FormContainer>
            </Styled.ModalFormContent>
        </Modal>
    );
};

ModalForm.defaultProps = {
    description: null,
    onChangeInput: () => {},
    onCheck: () => {},
    onSubmit: () => {},
    onClose: () => {},
    inputs: {},
    checkBoxInputs: {},
    isVisible: false,
    inputFields: INPUT_FIELDS,
    checkboxFields: CHECKBOX_FIELDS,
    isLoading: false,
    height: null,
    width: null,
    
};

ModalForm.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    onChangeInput: PropTypes.func,
    onSubmit: PropTypes.func,
    onCheck: PropTypes.func,
    onClose: PropTypes.func,
    inputs: PropTypes.object,
    checkBoxInputs: PropTypes.object,
    submitButtonTitle: PropTypes.string.isRequired,
    isVisible: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    inputFields: PropTypes.array,
    checkboxFields: PropTypes.array,
    isLoading: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number,
    isRightImage: PropTypes.bool.isRequired,
};

export default React.memo(ModalForm);
