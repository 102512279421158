import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './FormTitle.styles';

const FormTitle = ({ title }) => {
    return (
        <Styled.FormTitle>{title}</Styled.FormTitle>
    );
};

export default FormTitle;

FormTitle.defaultProps = {
    title: '',
};

FormTitle.propTypes = {
    title: PropTypes.string,
};
