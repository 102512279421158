import styled from 'styled-components';
import { COLORS } from '../../../../constants';
import { ReactComponent as DownCaret } from '../../../../assets/icons/accordion-arrow.svg';
import CONFIG from '../../../../config';

const { DEVICE_SIZE } = CONFIG;

const Container = styled.div`
  display: flex;
  width: 100%;
  .swiper-pagination {
    background-color: white;
    padding: 8px 0;
    border-radius: 12px;
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background-color: ${({ theme }) => theme.primaryColor} !important;
    }
    @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
      left: 60px !important;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: 44px;
  height: 44px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background-color: ${COLORS.WHITE};
  outline: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  z-index: 50;
  &.custom-swiper-button-prev {
    right: 10px;
  }
  &.custom-swiper-button-next {
    left: 10px;
  }
  &.swiper-button-disabled {
    opacity: 0;
  }
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: none;
  }
`;

const LeftIcon = styled(DownCaret)`
  transform: rotate(90deg);
`;

const RightIcon = styled(DownCaret)`
  transform: rotate(-90deg);
`;

const Styled = {
    Container,
    Image,
    NavigationButton,
    LeftIcon,
    RightIcon,
};

export default Styled;
