/* eslint-disable max-len */
import { MENUFACTURS } from '../constants';
import FACEBOOK from './facebook.png';
import PHONE from './phone.svg';
import MAIL from './mail.png';
import CREDIT_CARD from './credit_card.png';
import V from './v.svg';
import DOWNLOAD from './download.svg';
import ARROW from './arrow.svg';
import FAQ_ICON_ANSWER from './faq_icon_answer.png';
import PAGE_NOT_FOUND from './404_s.jpg';
import IMAGE_NOT_FOUND from './image-not-found.jpg';
import MAINTENANCE_IMAGE from './car.jpg';
import MAINTENANCE_IMAGE_SMALL from './car-small.jpg';
import ARROW_LEFT from './icons/arrow_left.png';
import ARROW_LEFT_AUDI from './icons/arrow_left_audi.svg';
import ARROW_RIGHT from './icons/arrow_right.png';
import THIN_ARROW_RIGHT from './icons/thin_arrow_right.png';
import MENU_ARROW_DOWN_BLACK from './icons/menu_arrow_down_black.png';
import C360 from './icons/360.png';
import GALLERY from './icons/gallery.png';
import C360_DARK from './icons/360_dark.png';
import GALLERY_DARK from './icons/gallery_dark.png';
import CHECK_MARK_WHITE from './icons/check_mark_white.png';
import HAMBURGER from './icons/hamburger.png';
import MENU_ARROW from './icons/menu_arrow.png';
import ADD from './icons/add.svg';
import REMOVE from './icons/remove.svg';
import CLOSE_BLACK from './icons/close-black.svg';
import BEFORE_ARROW_BLACK from './icons/before_arrow_black.svg';
import BEFORE_ARROW_WHITE from './icons/before_arrow_white.svg';
import SELECT_ARROW from './icons/select_arrow.svg';
import DOWNLAOD_SPECIFICATIONS from './icons/download_specification.png';
import SOCIAL_FACEBOOK from './icons/facebook.png';
import SOCIAL_INSTAGRAM from './icons/instagram.png';
import SOCIAL_YOUTUBE from './icons/youtube.png';
import SOCIAL_TIKTOK from './icons/tiktok.png';
import SOCIAL_FACEBOOK_DARK from './icons/facebook-dark.png';
import SOCIAL_INSTAGRAM_DARK from './icons/instagram-dark.png';
import SOCIAL_YOUTUBE_DARK from './icons/youtube-dark.png';
import SOCIAL_TIKTOK_DARK from './icons/tiktok-dark.png';
import CLOSE_WHITE from './icons/close_white.png';
import IDENTIFY_ICON from './icons/identify_icon.png';
import IDENTIFY_ICON_AUDI from './icons/identify_icon_audi.svg';
import LOCATION from './icons/location.svg';
import LOCATION_AUDI from './icons/location_audi.svg';
import CALCULATE from './icons/calculate.svg';
import PAYMENT from './icons/payment.svg';
import HAPPY_FACE from './icons/happy_face.svg';
import CHAT from './icons/chat.svg';
import CREDIT_CARD_PCI from './icons/creditguard_icons/pci.png';
import CREDIT_CARD_RAPIDSSL_SSL_CERTIFICATE from './icons/creditguard_icons/rapidssl_ssl_certificate.gif';
import DOWN_CHEVRON from './icons/down-chevron.svg';
import FACEBOOK_ICON from './icons/facebook.svg';
import WHATSAPP_ICON from './icons/whatsapp.svg';
import EMAIL_ICON from './icons/email.svg';
import CHEERS_ICON from './icons/cheers.svg';
import QUESTION_MARK_CUPRA from './icons/question_mark_r.svg';
import STEP_1 from './icons/howDoesItWork/step1-icon.svg';
import STEP_2 from './icons/howDoesItWork/step2-icon.svg';
import STEP_3 from './icons/howDoesItWork/step3-icon.svg';
import STEP_4 from './icons/howDoesItWork/step4-icon.svg';

const IMAGES = {
    FACEBOOK,
    PHONE,
    MAIL,
    CREDIT_CARD,
    V,
    DOWNLOAD,
    ARROW,
    FAQ_ICON_ANSWER,
    PAGE_NOT_FOUND,
    IMAGE_NOT_FOUND,
    MAINTENANCE_IMAGE,
    MAINTENANCE_IMAGE_SMALL,
    PLUS: {
        [MENUFACTURS.SEAT]: require('./icons/plus_s.png'),
        [MENUFACTURS.SKODA]: require('./icons/plus_c.png'),
        [MENUFACTURS.VOLKSWAGEN]: require('./icons/plus_v.png'),
        [MENUFACTURS.CHAMPION]: require('./icons/plus.png'),
    },
    CLOSE: {
        [MENUFACTURS.SEAT]: require('./icons/close_s.png'),
        [MENUFACTURS.SKODA]: require('./icons/close_c.png'),
        [MENUFACTURS.VOLKSWAGEN]: require('./icons/close_v.png'),
        [MENUFACTURS.CHAMPION]: require('./icons/close.png'),
    },
    CHECK_MARK: {
        [MENUFACTURS.SEAT]: require('./icons/check_mark_s.png'),
        [MENUFACTURS.SKODA]: require('./icons/check_mark_c.png'),
        [MENUFACTURS.VOLKSWAGEN]: require('./icons/check_mark_v.png'),
        [MENUFACTURS.CUPRA]: require('./icons/check_mark_r.png'),
        [MENUFACTURS.CHAMPION]: require('./icons/check_mark.png'),
    },
    QUESTION_MARK: {
        [MENUFACTURS.SEAT]: require('./icons/question_mark_s.png'),
        [MENUFACTURS.SKODA]: require('./icons/question_mark_c.png'),
        [MENUFACTURS.VOLKSWAGEN]: require('./icons/question_mark_v.png'),
        [MENUFACTURS.CUPRA]: QUESTION_MARK_CUPRA,
        [MENUFACTURS.AUDI]: require('./icons/question_mark.png'),
        [MENUFACTURS.CHAMPION]: require('./icons/question_mark.png'),
    },
    MENU_ARROW_DOWN: {
        [MENUFACTURS.SEAT]: require('./icons/menu_arrow_s.png'),
        [MENUFACTURS.SKODA]: require('./icons/menu_arrow_c.png'),
        [MENUFACTURS.VOLKSWAGEN]: require('./icons/menu_arrow_v.png'),
        [MENUFACTURS.CHAMPION]: require('./icons/menu_arrow.png'),
    },
    PERSON: {
        [MENUFACTURS.SEAT]: require('./icons/person_s.png'),
        [MENUFACTURS.SKODA]: require('./icons/person_c.png'),
        [MENUFACTURS.VOLKSWAGEN]: require('./icons/person_v.png'),
        [MENUFACTURS.CUPRA]: require('./icons/person_r.png'),
        [MENUFACTURS.CHAMPION]: require('./icons/person_s.png'),
        [MENUFACTURS.AUDI]: require('./icons/audi_person.svg'),
    },
    NEW_WINDOW_LINK: {
        [MENUFACTURS.AUDI]: require('./icons/new-window-link_a.png'),
        [MENUFACTURS.SEAT]: require('./icons/new-window-link_s.png'),
        [MENUFACTURS.SKODA]: require('./icons/new-window-link_c.png'),
        [MENUFACTURS.VOLKSWAGEN]: require('./icons/new-window-link_v.png'),
        [MENUFACTURS.CHAMPION]: require('./icons/new-window-link_r.png'),
    },
    ARROW_LEFT,
    ARROW_LEFT_AUDI,
    ARROW_RIGHT,
    THIN_ARROW_RIGHT,
    MENU_ARROW_DOWN_BLACK,
    C360,
    GALLERY,
    C360_DARK,
    GALLERY_DARK,
    CHECK_MARK_WHITE,
    HAMBURGER,
    MENU_ARROW,
    ADD,
    REMOVE,
    CLOSE_BLACK,
    BEFORE_ARROW_BLACK,
    BEFORE_ARROW_WHITE,
    SELECT_ARROW,
    DOWNLAOD_SPECIFICATIONS,
    SOCIAL_FACEBOOK,
    SOCIAL_INSTAGRAM,
    SOCIAL_YOUTUBE,
    SOCIAL_TIKTOK,
    SOCIAL_FACEBOOK_DARK,
    SOCIAL_INSTAGRAM_DARK,
    SOCIAL_YOUTUBE_DARK,
    SOCIAL_TIKTOK_DARK,
    CLOSE_WHITE,
    IDENTIFY_ICON,
    IDENTIFY_ICON_AUDI,
    LOCATION,
    LOCATION_AUDI,
    CALCULATE,
    PAYMENT,
    HAPPY_FACE,
    CHAT,
    CREDIT_CARD_PCI,
    CREDIT_CARD_RAPIDSSL_SSL_CERTIFICATE,
    DOWN_CHEVRON,
    FACEBOOK_ICON,
    WHATSAPP_ICON,
    EMAIL_ICON,
    CHEERS_ICON,
    HOW_DOES_IT_WORK: {
        STEP_1,
        STEP_2,
        STEP_3,
        STEP_4,
    },
};

export const ANIMATIONS = {
    ERROR: require('./animations/error_animation.json'),
};

export default IMAGES;
