import styled from 'styled-components';
import { pxToRem } from '../../../../utils/commonUtils';

export const Specifications = styled.div``;
export const Title = styled.h6`
  margin:0;
  padding: 10px 0 15px 0;
  font-size: ${pxToRem(15)};
  font-weight: 700;
`;
export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;
