import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import useIsMobile from '../../../../../hooks/useWindowSize';
import { getOriginalAdditionDetails } from '../../../../../fetch/configurator_options';
import CONFIG from '../../../../../config';
import Styled from './AdditionalOriginalAdditionDetailsTooltip.style.';

const { DEVICE_SIZE } = CONFIG;

const AdditionalOriginalAdditionDetailsTooltip = ({
    id,
    dataLink,
}) => {
    const [data, setData] = useState('');
    const isMobile = useIsMobile(DEVICE_SIZE.MEDIUM);

    useEffect(() => {
        const getAdditionalData = async () => {
            if (dataLink) {
                const response = await getOriginalAdditionDetails(dataLink);

                setData(response);
            }
        };

        getAdditionalData();
    }, []);

    return (
        <Styled.ToolTip
            aria-haspopup="true"
            className="extraClass"
            variant="light"
            globalCloseEvents={ {
                scroll: true,
                clickOutsideAnchor: true,
                escape: true,
                resize: true,
            } }
            isCapture={ isMobile }
            id={ id }
        >
            <Styled.DataContainer>
                { parse(data) }
            </Styled.DataContainer>
        </Styled.ToolTip>
    );
};

AdditionalOriginalAdditionDetailsTooltip.propTypes = {
    id: PropTypes.string.isRequired,
    dataLink: PropTypes.string.isRequired,
};

export default AdditionalOriginalAdditionDetailsTooltip;
