import styled, { css } from 'styled-components';
import CONFIG from '../../../../config';
import { GoBackButton } from '../../../../components/Configurator/GoBackButton/GoBackButton.styles';
import {
    GoBackButton as AudiGoBackButton,
} from '../../../../components/AudiConfigurator/GoBackButton/GoBackButton.styles';
import { pxToRem } from '../../../../utils/commonUtils';

const { DEVICE_SIZE } = CONFIG;

export const PresentationalRight = styled.div`
 flex: 1;
 position:relative;
 max-width:${({ $isFullScreen }) => $isFullScreen ? '100%' : '65%'};
 @media(min-width:${DEVICE_SIZE.EXTRA_LARGE}px){
    flex-basis:${({ $isFullScreen }) => $isFullScreen ? '100%' : '75%'};
    max-width:${({ $isFullScreen }) => $isFullScreen ? '100%' : '75%'};
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    width:100%;
    max-width:100%;
  }
`;
export const HeaderOnMobile = styled.header`
 /* padding: 0 10px;
 height:60px; */
 justify-content: space-between;
 align-items: center;
 display:none;
 border-bottom: ${({ $isAudi }) => $isAudi ? 'none' : '1px solid #ccc'};
 @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
   display:flex;
  }
`;

export const ModelDescription = styled.p`
 display:none;
  font-weight:500;
  font-size:${pxToRem(14)};
  margin:0;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
   display:block;
   padding: 10px;
  }
`;

export const GoBackButtonDesktop = styled(GoBackButton)`
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display:none;
  }
`;

export const AudiGoBackButtonDesktop = styled(AudiGoBackButton)`
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display:none;
  }
`;

export const GoBackButtonMobileWrapper = styled.div`
 display: none;
 align-items: center;
 padding: 10px;
 @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: flex;
  }
`;

export const GoBackButtonMobile = styled(GoBackButton)`
  position: static;
  margin-left: 10px;
`;

export const AudiGoBackButtonMobile = styled(AudiGoBackButton)`
  position: static;
  margin-left: 10px;
`;

export const StepTitle = styled.p`
  font-weight:700;
  font-size:${pxToRem(17)};
  margin:0;
  ${({ $isAudi }) => $isAudi && css`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  `}
`;
