import styled from 'styled-components';
import { pxToRem } from '../../../utils/commonUtils';

export const Question = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: ${pxToRem(18)};
  display:flex;
  align-items:center;
  justify-content: start;
  &:before{
    content: '🞩';
    display:block;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.primaryColor};
    transform: ${({ $isSelected }) => $isSelected ? 'rotate(0)' : 'rotate(45deg)'};
    transition: all 0.3s;
}
`;
