import styled from 'styled-components';
import { COLORS } from '../../../../../constants';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const FullPriceAndTooltip = styled.div`
  margin-top: 20px;
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
      display: ${({ $isShowInMobile }) => $isShowInMobile ? 'inline' : 'none'};
  }
`;

export const NisSymbol = styled.span`
  font-size: 22px;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: right;
  }
`;

export const TooltipWrapper = styled.div`
  display:flex;
  margin-right: 12px;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    display:none;
  }
`;

export const PriceTitle = styled.h4`
  font-weight: 700;
  font-size: 16px;
  margin:0;
`;

export const Price = styled.p`
  margin: 0;
  font-size: 28px;
  font-weight: 700;
  line-height:1;
  color:${COLORS.COLOR_MODEL_ITEM_PRICE};
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: right;
  }
`;

export const FullPriceWrapper = styled.div`
  display: flex;
  align-items: baseline;

`;

export const FullPriceDescription = styled.p`
  margin: 9px 0 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color:${COLORS.COLOR_TEXT_ITEM_CAR_LICENSE_FEE_PRICE};
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    display: none;
  }
`;
