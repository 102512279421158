import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { COLORS, ICONS_NAMES } from '../../../../../constants';
import { pxToRem } from '../../../../../utils/commonUtils';
import { staggerHorizontalChildVariants } from '../../../../../utils/animationsConfig';
import { getIconFromAssets } from '../../../../../utils/assetsUtils';
import { NoEntranceIcon } from '../../../../common/styles';
import {
    NoPackageTooltipContainer as TContainer,
    TooltipTitle as TTitle,
    TooltipInnerText as TInnerText,
} from '../../../InternalColor/Configuration/InternalColorItem/InternalColorItem.styles';
import CONFIG from '../../../../../config';

const checkMarkIcon = getIconFromAssets(ICONS_NAMES.CHECK_MARK_WHITE);
const { DEVICE_SIZE } = CONFIG;

export const HoopItem = styled(motion.li).attrs({
    variants: staggerHorizontalChildVariants,
})`
  /* padding: 30px 0; */
  position: relative;
  /* border-bottom: 1px solid ${COLORS.INTERNAL_COLOR_ITEM_BORDER_BOTTOM}; */
  /* &:first-child {
    padding-top: 0;
  } */
  ${NoEntranceIcon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Content = styled.div`
display: flex;
align-items:center;
opacity: ${({ $isPackageRelated }) => ($isPackageRelated ? 1 : '0.34')};
`;
export const Picker = styled.div`
  width:${({ theme }) => theme.steps.hoops.pickerItemWidth};
  height:${({ theme }) => theme.steps.hoops.pickerItemHeight};
  flex: 0 0 ${({ theme }) => theme.steps.hoops.pickerItemHeight};
  border-radius:${({ theme }) => theme.steps.hoops.pickerItemRadius};
  background-color: transparent;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${({ $image }) => $image});
  position: relative;
  cursor: pointer;
  ${({ $isPackageRelated }) => $isPackageRelated && css`
  ${({ $selected }) => $selected
    && css`
           border:${({ theme }) => theme.steps.hoops.pickerBorderItem}; 
      &:after {
        content: "";
        width: 24px;
        height: 24px;
        position: absolute;
        border-radius:50%;
        left: 0;
        top:0;
        transform:translate(-45%,-45%);
        background-color: ${({ theme }) => theme.primaryColor};
        background-image:url(${checkMarkIcon});
        background-repeat: no-repeat;
        background-position: center;
        @media(max-width:${DEVICE_SIZE.MEDIUM}px){
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          transform: translateY(-50%);
        }
      }
    `}
  `}
`;

export const Details = styled.div`
  margin-right: 30px;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    display:none;
  }
`;

const Text = styled.p`
  margin: 0;
  font-weight: 700;
  padding: 5px;
`;

export const Name = styled(Text)`
  font-size: ${pxToRem(15)};
`;
export const Price = styled(Text)`
  font-size: ${pxToRem(13)};
`;

export const NoPackageTooltipContainer = styled(TContainer)``;
export const TooltipTitle = styled(TTitle)``;
export const TooltipInnerText = styled(TInnerText)``;
