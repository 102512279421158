import { isArrayAndNotEmpty } from '../../../utils/commonUtils';
import { mapPollutionAndSafetyData } from '../utils';

export const getConfiguratorOptionsPending = (state) => ({
    ...state,
    error: null,
    isLoading: true,
});

export const getConfiguratorOptionsSuccess = (state, action) => {
    const { data } = action;

    return {
        ...state,
        externalColors: data,
        externalColorsError: null,
        error: null,
        isLoading: false,
    };
};

export const getConfiguratorOptionsFailure = (state, action) => {
    const { error } = action;

    return {
        ...state,
        isLoading: false,
        error,
    };
};

export const setExternalColorsError = (state, action) => {
    const { error } = action;
    
    return {
        ...state,
        externalColorsError: error,
    };
};

export const initializeConfiguratorOptions = (initialState) => {
    return initialState;
};

export const initializeConfiguratorData = (state) => {
    return {
        ...state,
        internalColors: {
            colors: {},
            isLoading: false,
            error: null,
        },
        originalPackages: {
            packages: {},
            isLoading: false,
            error: null,
        },
        modelLocalGroups: {
            addOns: {},
            addOnsExtraInformation: {},
            isLoading: false,
            error: null,
        },
        finalDiscount: {
            data: {
                modelPrice: null,
                specifications: null,
                addOns: null,
                licenseFee: null,
                totalPayment: null,
            },
            isLoading: false,
            error: null,
        },
        externalColors: [],
    };
};

export const getPollutionAndSafetyDetailsDataPending = (state) => ({
    ...state,
    pollutionAndSafetyDetails: {
        ...state.pollutionAndSafetyDetails,
        isLoading: true,
    },
});

export const getPollutionAndSafetyDetailsDataSuccess = ((state, action) => {
    const { data } = action;

    const parsedData = mapPollutionAndSafetyData(data);

    if (isArrayAndNotEmpty(parsedData)) {
        return {
            ...state,
            pollutionAndSafetyDetails: {
                ...state.pollutionAndSafetyDetails,
                dataList: [
                    ...parsedData,
                ],
                isLoading: false,
            },
        };
    }

    // const onlineCars = data.map(mapOnlineCars).flat();

    return {
        ...state,
        pollutionAndSafetyDetails: {
            ...state.pollutionAndSafetyDetails,
            dataList: [
                data,
            ],
            isLoading: false,
        },
    };
});

export const getPollutionAndSafetyDetailsDataFailure = ((state, action) => {
    const { error } = action;
    
    return {
        ...state,
        pollutionAndSafetyDetails: {
            ...state.pollutionAndSafetyDetails,
            isLoading: false,
            error,
        },
    };
});

export const getModelsAdditionalOptionsPending = (state) => ({
    ...state,
    subModels: {
        ...state.subModels,
        isLoading: true,
        error: null,
    },
});

export const getModelsAdditionalOptionsSuccess = (state, action) => {
    const { data } = action.data;

    return {
        ...state,
        subModels: {
            isLoading: false,
            subModels: data,
            error: null,
        },
    };
};

export const getModelsAdditionalOptionsFailure = (state, action) => {
    const { error } = action;
    
    return {
        ...state,
        subModels: {
            ...state.subModels,
            subModels: [],
            isLoading: false,
            error,
        },
    };
};
