import styled from 'styled-components';
import { pxToRem } from '../../../utils/commonUtils';
import { COLORS, MENUFACTURS } from '../../../constants';
import CONFIG from '../../../config';
import { nisCurrencySign, lineThrough } from '../../common/styles';

const { DEVICE_SIZE } = CONFIG;

export const PaymentDetailItem = styled.li`
  padding: 10px 0;
  border-bottom: 1px solid ${COLORS.SPECIFICATION_ITEM_COLOR_BORDER_BOTTOM};
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NameAndImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Image = styled.div`
    width:50px;
    height:50px;
    margin-left: 15px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${({ $image }) => $image});
`;

export const Name = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: ${pxToRem(15)};
  max-width: 70%;
`;
export const DeleteOption = styled.p`
  margin: 0px;
  margin-right: 63px;
  font-weight: 500;
  font-size: ${pxToRem(13)};
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &::before {
    content: "\\00d7";
    padding: ${({ $brand }) => $brand === MENUFACTURS.SEAT ? '0 0 4px 0' : '0 0 0 0'};
    font-size: ${pxToRem(26)};
    font-weight: 300;
    margin-left: 3px;
  }
`;
export const Price = styled.p`
  margin: 0;
  ${nisCurrencySign}
`;
export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex-direction: column;
    justify-content: center;;
  }
`;

export const PriceBeforeDiscount = styled(Price)`
  margin:0 0 0 10px;
  font-size: ${pxToRem(12)};
  position: relative;
  ${lineThrough}
`;
