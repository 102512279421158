import styled from 'styled-components';
import CONFIG from '../../config';
import { pxToRem } from '../../utils/commonUtils';

const { DEVICE_SIZE } = CONFIG;

export const CheckoutPage = styled.div`
`;

export const Container = styled.div`
    padding-top: 0;
    display: flex;
    max-width: 1600px;
    margin: auto;
    width: 95%;
    @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
        flex-direction: column;
    }
`;

export const Header = styled.div`
    position: relative;
    display: ${({ $isVisible }) => $isVisible ? 'flex' : 'none'};
    height: 50px;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color:${({ theme }) => theme.primaryColor};
`;

export const HeaderContent = styled.div`
    position: relative;
    display: ${({ $isVisible }) => $isVisible ? 'flex' : 'none'};
    height: 50px;
    width: 100%;
    max-width: 1500px;
    align-items: center;
    justify-content: space-between;
    background-color:${({ theme }) => theme.primaryColor};
`;

export const HeaderText = styled.p`
    color: white;
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0;
    margin-right: 15px;
    line-height: 26px;
`;

export const CallToRepresentative = styled.button`
background-color: transparent;
color: white;
border: 1px solid white;
font-size:${pxToRem(16)};
padding: 6px 15px;
font-weight: 400;
font-family: ${({ theme }) => theme?.['font-family']};
cursor: pointer;
position: absolute;
top: 50%;
left: 16px;
transform: translateY(-50%);
@media(max-width:${DEVICE_SIZE.MEDIUM}px){
      display:none;
    }
`;
