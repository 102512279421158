import styled from 'styled-components';
import CONFIG from '../../../config';
import { COLORS } from '../../../constants';
import { pxToRem } from '../../../utils/commonUtils';

const { DEVICE_SIZE } = CONFIG;

export const ConfiguratorDescriptionOnMobile = styled.div`
  display: ${({ $isAudi }) => $isAudi ? 'static' : 'none'};
  text-align:  ${({ $isAudi }) => $isAudi ? 'right' : 'center'};
  margin: ${({ $isAudi }) => $isAudi ? '0' : '20px 0px'};
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    display:block;
  }
   /* border:1px solid red; */
`;

export const Title = styled.h2`
  margin:0;
  color:${COLORS.BLACK};
  font-size:${({ $isAudi }) => $isAudi ? `${pxToRem(16)}` :  `${pxToRem(18)}`};
  font-weight:bold;
`;

export const TooltipInStock = styled.p`
    margin: ${({ $isAudi }) => $isAudi ? '4px 0 0 0' :  '7px 0'};
  color:${COLORS.BLACK};
  font-size: ${({ $isAudi }) => $isAudi ? `${pxToRem(16)}` :  `${pxToRem(18)}`};
  font-weight:300;
`;

export const NoPackageRelated = styled(Title)`
  margin:7px 0;
 color:${COLORS.COLOR_ERROR_RED_GENERAL_1}
`;
