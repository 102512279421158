import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import  ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import { useGA4Gtm } from '../../../../utils/gtm.hooks';
import {
    useActionDispatch,
    useCurrentAddOns,
    useCurrentAppCommonData,
    useCurrentBrand,
} from '../../../../hooks';
import { htmlDecode } from '../../../../utils';
import { parseSelectedAddOnsToArray } from '../../../../components/Steps/Summary/Summary.utils';
import { getIconFromAssets } from '../../../../utils/assetsUtils';
import {
    getManufacturerContactInfo,
    replacePlaceholdersInTemplate,
} from '../../../../utils/commonUtils';
import {
    COLORS, GTM_EVENT_TYPES, IFRAME_MESSAGES, ICONS_NAMES,
} from '../../../../constants';
import STRINGS from '../../../../language';
import * as Styled from './PaymentAndDownPayment.styles';

const CREDIT_GUARD_ORIGIN = 'creditguard.co.il';
const { CREDIT_CARD_PCI, CREDIT_CARD_RAPIDSSL_SSL_CERTIFICATE } = ICONS_NAMES;

const PaymentAndDownPayment = () => {
    const {
        commision,
        pickUpAndFinancing,
    } = useSelector(({ checkoutReducer }) => checkoutReducer);
    const currentBrand = useCurrentBrand();
    const selectedAddOns = useCurrentAddOns();
    const { isAudi } = useCurrentAppCommonData();
    const { createOrderAsync } = useActionDispatch();
    const [error, setError] = useState(null);
    const gtpPaymentSuccess = useGA4Gtm({ eventType: GTM_EVENT_TYPES.PURCHASE });
    const { creditGuardUrl, totalPayment, error: commisionError } = commision;
    const selectedAddOnsArray = parseSelectedAddOnsToArray(selectedAddOns);
    const { PHONE: contactPhoneNumber } = getManufacturerContactInfo(currentBrand);
    const screenErrorMsg = replacePlaceholdersInTemplate(
        STRINGS.ERRMSG_PROBLEM_IN_PURCHASE,
        [contactPhoneNumber],
    );
 
    const { isFinancing, selectedDealership } = pickUpAndFinancing;
    const handlePaymentFailure = (failureData) => {
        const decodedErrorText = htmlDecode(failureData?.error);
        const decodedErrorcCode = htmlDecode(failureData?.errorCode);
   
        const errorMessage = `${decodedErrorText} (${decodedErrorcCode})`;
        
        setError(errorMessage);
    };

    const handlePaymentSuccess = async (successData) => {
        const {
            cardToken, cardExp, authNumber, txId,
        } = successData;

        gtpPaymentSuccess(txId, totalPayment);

        const response = await createOrderAsync(
            authNumber,
            cardToken,
            cardExp,
            selectedDealership.value,
            selectedAddOnsArray,
            isFinancing,
        );

        if (!response) {
            setError(screenErrorMsg);
        }
    };

    const creditGuardPostMessageHandler = (e) => {
        // only listen to message events
        if (e?.type !== 'message') {
            return;
        }
 
        // only listen to known messages types (from iframe)
        if (!Object.values(IFRAME_MESSAGES).includes(e?.data?.message)) {
            return;
        }
        
        // only listen to events from credit-guard iframe
        if (!e?.origin?.toString().includes(CREDIT_GUARD_ORIGIN)) {
            return;
        }

        switch (e.data.message) {
            case IFRAME_MESSAGES.FAIL: {
                handlePaymentFailure(e?.data?.payload);
                break;
            }
            case IFRAME_MESSAGES.SUCCESS:
                handlePaymentSuccess(e?.data?.payload);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        window.addEventListener('message', creditGuardPostMessageHandler);
        
        return  () => {
            window.removeEventListener('message', creditGuardPostMessageHandler);
        };
    }, [creditGuardUrl, creditGuardPostMessageHandler]);

    const renderCreditGuardIcons = () => {
        const creditGuardPciIcon = getIconFromAssets(CREDIT_CARD_PCI);
        const creditGuardSSLIcon = getIconFromAssets(CREDIT_CARD_RAPIDSSL_SSL_CERTIFICATE);

        return (
            <Styled.CredidGuardIcons>
                <Styled.Icon
                    src={ creditGuardPciIcon }
                    alt={ CREDIT_CARD_PCI }
                />
                <Styled.Icon
                    src={ creditGuardSSLIcon }
                    alt={ CREDIT_CARD_RAPIDSSL_SSL_CERTIFICATE }
                />
            </Styled.CredidGuardIcons>
        );
    };

    const renderIframe = () => {
        if (error) {
            return (
                <Styled.ErrorContainer>
                    <ErrorMessage
                        color={ COLORS.COLOR_ERROR_RED_GENERAL_1 }
                        errorMessage={ error }
                    />
                    <ErrorMessage
                        color={ COLORS.COLOR_ERROR_RED_GENERAL_1 }
                        errorMessage={ screenErrorMsg }
                    />
                </Styled.ErrorContainer>
            );
        }
        
        return (
            <Styled.Content>
                <Styled.Title>{STRINGS.PAYMENT_TITLE}</Styled.Title>
                <Styled.Description>
                    { isAudi
                        ? STRINGS.AUDI_PAYMENT_MESSAGE_1
                        : STRINGS.PAYMENT_MESSAGE_1}
                    <Styled.BoldText>{totalPayment} ₪ </Styled.BoldText>
                    { isAudi
                        ? STRINGS.AUDI_PAYMENT_MESSAGE_2
                        : STRINGS.PAYMENT_MESSAGE_2}
                </Styled.Description>
                <Styled.IframeContainer>
                    <Styled.CreditGuardIFrame src={ creditGuardUrl } error={ commisionError } />
                </Styled.IframeContainer>
                {renderCreditGuardIcons()}
            </Styled.Content>
        );
    };

    return (
        <Styled.PaymentAndDownPayment>
            {renderIframe()}
        </Styled.PaymentAndDownPayment>
    );
};

export default PaymentAndDownPayment;
