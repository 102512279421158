import React from 'react';
import PropTypes from 'prop-types';
import { useIconFromAssets } from '../../hooks';
import { ICONS_NAMES } from '../../constants';
import * as Styled from './QuestionMarkTooltip.styles';

const QuestionMarkTooltip = ({ text, isAudi }) => {
    const questionMarkIcon = useIconFromAssets(ICONS_NAMES.QUESTION_MARK);

    return (
         
        <Styled.QuestionMarkTooltip $icon={ questionMarkIcon }>
            <Styled.ContentWrapper $isAudi={ isAudi }>
                <Styled.ContentText $isAudi={ isAudi }>
                    {text}
                </Styled.ContentText>
            </Styled.ContentWrapper>
        </Styled.QuestionMarkTooltip>

    );
};

QuestionMarkTooltip.defaultProps = {
    isAudi: false,
};

QuestionMarkTooltip.propTypes = {
    text: PropTypes.string.isRequired,
    isAudi: PropTypes.bool,
};

export default QuestionMarkTooltip;
