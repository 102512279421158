import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NoEntranceIcon } from '../common/styles';
import STRINGS from '../../language';
import * as Styled from './PickerItem.styles';

const PickerItem = ({
    text,
    image,
    selected,
    className,
    onClick,
    tooltipContent,
    value,
    price,
    inStockString,
    isPackageRelated,
    isToolipDisabled,
    isAudi,
}) => {
    const hasPrice = price !== 0;

    const renderTooltipContent = () => {
        if (!isPackageRelated) {
            return (
                <>
                    <Styled.TooltipTitle>{tooltipContent}</Styled.TooltipTitle>
                    <Styled.NoPackageTooltipContainer>
                        <NoEntranceIcon />
                        <Styled.TooltipDescription>
                            {STRINGS.THIS_COLOR_IS_NOT_INCLUDED_IN_THE_MODEL_YOU_CHOSE}
                        </Styled.TooltipDescription>
                    </Styled.NoPackageTooltipContainer>
                </>
            );
        }
        
        return (
            <>
                <Styled.TooltipTitle>{tooltipContent}</Styled.TooltipTitle>
                <Styled.TooltipDescription>
                    {hasPrice ? ` ${parseFloat(price).toLocaleString()} ₪` :  STRINGS.WITH_NO_EXTRA_COST}
                </Styled.TooltipDescription>
                {!!inStockString && (
                    <Styled.TooltipInStock>
                        {inStockString}
                    </Styled.TooltipInStock>
                )}
            </>
        );
    };

    return (
        <Styled.PickerItem $isAudi={ isAudi }>
            <Styled.ToolTip
                aria-haspopup="true"
                className="extraClass"
                effect="solid"
                id={ value }
            >
                {renderTooltipContent()}
            </Styled.ToolTip>
            <Styled.Container
                $isAudi={ isAudi }
                className={ classnames('PickerItem', className, {
                    selected,
                    hasText: !!text,
                    interactive: !!onClick,
                }) }
                $isPackageRelated={ isPackageRelated }
            >
                <Styled.PickerItemInner
                    $isAudi={ isAudi }
                    data-tooltip-id={ value }
                    data-tooltip-place="top"
                    data-tooltip-hidden={ isToolipDisabled }
                    $isPackageRelated={ isPackageRelated }
                    className={ classnames('PickerItem-inner', { selected }) }
                    style={ {
                        backgroundColor: 'transparent',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: `url(${image})`,
                    } }
                    onClick={ onClick }
                >
                    {!isPackageRelated && <NoEntranceIcon />}
                </Styled.PickerItemInner>
            </Styled.Container>
            {text && !isAudi && (
                <Styled.PickerText className="PickerItem-text">
                    {text}
                </Styled.PickerText>
            )}
        </Styled.PickerItem>
    );
};

PickerItem.propTypes = {
    text: PropTypes.string,
    image: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    tooltipContent: PropTypes.node,
    value: PropTypes.string.isRequired,
    price: PropTypes.number,
    inStockString: PropTypes.string,
    isPackageRelated: PropTypes.bool,
    isToolipDisabled: PropTypes.bool,
    isAudi: PropTypes.bool,
};

PickerItem.defaultProps = {
    text: null,
    selected: false,
    className: null,
    onClick: null,
    tooltipContent: null,
    price: 0,
    inStockString: '',
    isPackageRelated: true,
    isToolipDisabled: false,
    isAudi: false,
};

export default PickerItem;
