import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './ColorsRangeRulerItem.styles';

const ColorsRangeRulerItem = ({
    number,
    bg,
    textColor,
    isSelected,
    isManyModels,
}) => {
    return (
        <Styled.ColorsRangeRulerItem
            $textColor={ textColor }
            $bg={ bg }
            $isSelected={ isSelected }
            $isManyModels={ isManyModels }
        >
            {number}
        </Styled.ColorsRangeRulerItem>
    );
};

ColorsRangeRulerItem.defaultProps = {
    textColor: null,
    bg: null,
    isSelected: false,
    isManyModels: false,
};

ColorsRangeRulerItem.propTypes = {
    number: PropTypes.number.isRequired,
    bg: PropTypes.string,
    textColor: PropTypes.string,
    isSelected: PropTypes.bool,
    isManyModels: PropTypes.bool,
};

export default ColorsRangeRulerItem;
