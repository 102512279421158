import styled from 'styled-components';
import { lineThrough, nisCurrencySign } from '../../../common/styles';

export const PriceItem = styled.div`
  padding: 10px 0 15px 0;
  border-bottom: 1px solid #6A767D;
  display: flex;
  justify-content: space-between;
`;

export const Name = styled.p`
  font-weight: 700;
  font-size: 16px;
  margin: 0;
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Price = styled.span`
  font-size: 16px;
  font-weight: 400;
  ${nisCurrencySign}
`;

export const PriceBeforeDiscount = styled(Price)`
  margin:0 0 0 10px;
  font-size: 13px;
  position: relative;
  ${lineThrough}
`;
