import React from 'react';
import STRINGS from '../../../../language';
import { useCurrentBrand } from '../../../../hooks';
import {
    getEmailHrefString,
    getManufacturerContactInfo,
    getTelHrefString,
} from '../../../../utils/commonUtils';
import * as Styled from './PaymentFailure.style';

const PaymentFailure = () => {
    const currentBrand = useCurrentBrand();
    const manufacturerContactInfo = getManufacturerContactInfo(currentBrand);
    const { EMAIL, PHONE } = manufacturerContactInfo;
    const emailHref = getEmailHrefString(EMAIL);
    const phoneHref = getTelHrefString(PHONE);
      
    return (
        <Styled.Container>
            <Styled.StepHead>
                <Styled.ErrorAnimation />
                <Styled.Title>{ STRINGS.ERROR_ORDER_NOT_ACCEPTED }</Styled.Title>
            </Styled.StepHead>
            <Styled.MainContainer>
                <Styled.Seperator />
                <Styled.ContactInfoContainer>
                    <Styled.BoldText>{ STRINGS.YOU_CAN_CONTACT_US_VIA }</Styled.BoldText>
                    <Styled.ContactText>{ STRINGS.EMAIL } :
                        <Styled.Link href={ `${emailHref}` }> { EMAIL }</Styled.Link>
                    </Styled.ContactText>
                    <Styled.ContactText >{ STRINGS.PHONE } :
                        <Styled.Link href={ `${phoneHref}` }> { PHONE }</Styled.Link>
                    </Styled.ContactText>
                </Styled.ContactInfoContainer>
            </Styled.MainContainer>
        </Styled.Container>
    );
};

PaymentFailure.propTypes = {};

export default PaymentFailure;
