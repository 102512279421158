import styled from 'styled-components';
import { ConfiguratorFooterTitle } from '../../../../components/common/styles';
import CONFIG from '../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const Safety = styled.div`
  margin-bottom:30px;
`;

export const SafetyModelAndRange = styled.div`
  display: flex;
  padding: 10px 0;
  @media(max-width:${DEVICE_SIZE.LARGE}px){
    flex-direction:column;
  }
`;
export const SafetySystemDetails = styled.div`
  display: flex;
  @media(max-width:${DEVICE_SIZE.LARGE}px){
    flex-direction:column;
  }

`;

export const SafetySystemDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media(max-width:${DEVICE_SIZE.VERY_LARGE}px){
    display: flex;
    flex-direction:column;
  }
`;

export const Title = styled(ConfiguratorFooterTitle)``;
