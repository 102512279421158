/* eslint-disable max-len */
/* eslint-disable import/no-mutable-exports */
import CONFIG from '../../../config';
import { MENUFACTURS } from '../../../constants';

let ConfiguratorLayout;

switch (CONFIG.brand) {
    case MENUFACTURS.VOLKSWAGEN_COMMERCIAL:
        ConfiguratorLayout = require(
            '../../../components/CommercialConfigurator/ConfiguratorLayout/ConfiguratorLayout',
        ).default;
        break;
    case MENUFACTURS.AUDI:
        ConfiguratorLayout = require(
            '../../../components/AudiConfigurator/Configurator/ConfiguratorLayout/ConfiguratorLayout',
        ).AudiConfiguratorLayout;
        break;
    default:
        ConfiguratorLayout = require('./ConfiguratorLayout').default;
}

export default ConfiguratorLayout;
