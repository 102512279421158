import styled from 'styled-components';
import { Text } from '../Engine.styles';

export const EngineDataItem = styled.div`
 display:flex;
 &:nth-of-type(odd){
     background-color:#ebebeb;
 }
  `;
export const Title = styled(Text)`
 flex:1;
`;

export const Value = styled(Title)`
  direction: ${({ isLtr }) => isLtr ? 'ltr' : 'rtl'};
  text-align: right;
`;
