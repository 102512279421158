import React, { useState } from 'react';
import { useDataForPaymentDetails } from '../../../components/PaymentDetails/PaymentDetails.hooks';
import {
    useCurrentAppCommonData,
    useCurrentBrand,
    useCurrentCar,
    useCurrentExternalColor,
} from '../../../hooks';
import PaymentDetails from '../../../components/PaymentDetails/PaymentDetails';
import CarImage from '../../../components/CarImage';
import MobilePriceBar from './MobilePriceBar/MobilePriceBar';
import * as Styled from './CheckoutSiderBar.styles';

const CheckoutSiderBar = () => {
    const currentBrand = useCurrentBrand();
    const [isToggled, setIsToggled] = useState(false);
    const { modelGroupCode, trimLevelCode } = useCurrentCar();
    const { value: outColor } = useCurrentExternalColor();
    const showPricesAfterDiscount = true;
    const {
        specifications,
        addOns,
        originalAdditions,
        equipmentPackage,
        carPrices,
        licenseFeePrices,
        totalPrices,
    } = useDataForPaymentDetails(showPricesAfterDiscount);
    const { isAudi } = useCurrentAppCommonData();
    
    const toggleMobilePriceBarHandler = () => {
        setIsToggled((prevState) => !prevState);
        if (!isToggled) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'scroll';
        }
    };
    
    return (
        <Styled.CheckoutSiderBar $isAudi={ isAudi }>
            <Styled.InnerContent isToggled={ isToggled } $isAudi={ isAudi }>
                <Styled.ImageWrapper $isAudi={ isAudi }>
                    <CarImage
                        brand={ currentBrand }
                        outColor={ outColor }
                        modelGroup={ modelGroupCode?.toString() }
                        trimLevelCode={ trimLevelCode }
                    />
                </Styled.ImageWrapper>
                <PaymentDetails
                    carPrices={ carPrices }
                    specifications={ specifications }
                    addOns={ addOns }
                    licenseFeePrices={ licenseFeePrices }
                    totalPrices={ totalPrices }
                    currentBrand={ currentBrand }
                    originalAdditions={ originalAdditions }
                    equipmentPackage={ equipmentPackage }
                />
            </Styled.InnerContent>
            <MobilePriceBar
                totalPrice={ totalPrices.total }
                isToggled={ isToggled }
                onToggle={ toggleMobilePriceBarHandler }
            />
        </Styled.CheckoutSiderBar>
    );
};

export default CheckoutSiderBar;
