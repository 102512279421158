import React from 'react';
import Proptypes from 'prop-types';
import * as Styled from './StepCard.style';

const StepCard = ({
    title, content, icon,
}) => {
    return (
        <Styled.CardContainer>
            <Styled.HeaderContainer>
                <Styled.Icon src={ icon } />
                <Styled.Title>{ title }</Styled.Title>
            </Styled.HeaderContainer>
            <Styled.Content>{ content }</Styled.Content>
        </Styled.CardContainer>
    );
};

StepCard.propTypes = {
    title: Proptypes.string.isRequired,
    content: Proptypes.string.isRequired,
    icon: Proptypes.string.isRequired,
};

export default StepCard;
