import React from 'react';
import PropTypes from 'prop-types';
import { getStepTitle } from '../../../../utils/configuratorUtils';
import { CONFIGURATOR_STEPS } from '../../../../constants';
import { useCurrentAppCommonData } from '../../../../hooks';
import * as Styled from './ConfiguratorRight.style';

const { ADD_ONS } = CONFIGURATOR_STEPS;

const ConfiguratorRight = ({
    component,
    currentStep,
    configOptions,
}) => {
    const ConfComponent = component || (() => null);
    const { isAudi } = useCurrentAppCommonData();

    const isAddOnsStep = currentStep === ADD_ONS;

    return (
        <Styled.ConfigurationalRight isAddOnsStep={ isAddOnsStep }>
            <Styled.Header>
                <Styled.Title>{getStepTitle(currentStep, isAudi)}</Styled.Title>
            </Styled.Header>
            <Styled.Body>
                <ConfComponent configOptions={ configOptions } />
            </Styled.Body>
        </Styled.ConfigurationalRight>
    );
};

ConfiguratorRight.defaultProps = {
    component: null,
    currentStep: null,
};

ConfiguratorRight.propTypes = {
    component: PropTypes.elementType,
    currentStep: PropTypes.number,
    configOptions: PropTypes.object.isRequired,
};

export default ConfiguratorRight;
