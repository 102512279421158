import styled from 'styled-components';

export const TermsBody = styled.div`
text-align: right;
direction:rtl;
li{
    font-size:14px;
    line-height:20px;
    margin-bottom:10px;
    font-weight: 400;
}

`;
