export const getInternalColorsPending = (state) => ({
    ...state,
    internalColors: {
        ...state.internalColors,
        isLoading: true,
    },
});

export const getInternalColorsSuccess = (state, action) => {
    const { data, externalColorCode } = action;
        
    return {
        ...state,
        internalColors: {
            ...state.internalColors,
            colors: {
                ...state.internalColors.colors,
                [externalColorCode]: data,
            },
            isLoading: false,
        },
    };
};

export const getInternalColorsFailure = (state, action) => {
    const { error } = action;
  
    return {
        ...state,
        internalColors: {
            ...state.internalColors,
            error,
            isLoading: false,
        },
    };
};
