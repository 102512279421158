import styled, { css } from 'styled-components';
import { COLORS } from '../../constants';
import { pxToRem } from '../../utils/commonUtils';

export const InputFormGroup = styled.div`
 display:flex;
 flex-direction:column;
 margin-bottom:22px;

  & .input-text, .input-email, .input-tel{
      font-size:${pxToRem(17)};
      border:none;
      border-bottom:1px solid ${COLORS.BLACK};
      background-color:transparent;
     ${({ $isAudi }) => $isAudi && css`
      border: 1px solid ${COLORS.BLACK};
      height: 44px;
     `};
  } 

`;
export const Label = styled.label`
 font-size:${pxToRem(16)};
 font-weight:400;
 color:${({ $isErrorShown }) =>  $isErrorShown ? COLORS.COLOR_ERROR_CONTACT_US_FIELD  : COLORS.BLACK};
`;

export const ErrorMessage = styled.p`
  font-size:${pxToRem(14)};
  font-weight:500;
  margin: 7px 0 0 0;
  color:${COLORS.COLOR_ERROR_CONTACT_US_FIELD};

`;

export const Asterisk = styled.span`
 &:after{
     content:' * ';
 }
`;
