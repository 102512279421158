import { IS_VERIFIED_AUTHORIZATION } from '../../constants';
import * as actionTypes from '../actionTypes';
import * as dispatchers from '../dispatchers';

const initialState = {
    sms: {
        isLoading: false,
        isSuccess: false,
        error: null,
    },
    otp: {
        isLoading: false,
        isVerified: sessionStorage.getItem(IS_VERIFIED_AUTHORIZATION) === 'true' ?? false,
        maskedPhoneNumber: null,
        error: null,
    },
    auth: {
        isLoading: false,
        isAuthenticated: sessionStorage.getItem(IS_VERIFIED_AUTHORIZATION) === 'true' ?? false,
        token: null,
        error: null,
    },
};

export const authentication = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SEND_SMS_PENDING:
            return dispatchers.sendSmsPending(state);
        case actionTypes.SEND_SMS_SUCCESS:
            return dispatchers.sendSmsSuccess(state);
        case actionTypes.SEND_SMS_FAILURE:
            return dispatchers.sendSmsFailure(state, action);
        case actionTypes.IS_AUTHENTICATED_PENDING:
            return dispatchers.isAuthenticatedPending(state);
        case actionTypes.IS_AUTHENTICATED_SUCCESS:
            return dispatchers.isAuthenticatedSuccess(state);
        case actionTypes.IS_AUTHENTICATED_FAILURE:
            return dispatchers.isAuthenticatedFailure(state, action);
        case actionTypes.CHECK_OTP_PENDING:
            return dispatchers.checkOtpPending(state);
        case actionTypes.CHECK_OTP_SUCCESS:
            return dispatchers.checkOtpSuccess(state);
        case actionTypes.CHECK_OTP_FAILURE:
            return dispatchers.checkOtpFailure(state, action);
        case actionTypes.INITIALIZE_AUTHENTICATION:
            return dispatchers.initializeAuthentication(state);
        case actionTypes.INITIALIZE_OTP:
            return dispatchers.initializeOtp(state);
        case actionTypes.INITIALIZE_OTP_VERIFICATION:
            return dispatchers.initializeOtpVerification(state);
        case actionTypes.OTP_VERIFICATION_SUCCESS:
            return dispatchers.otpVerificationSuccess(state);
        case actionTypes.SET_MASKED_PHONE_NUMBER:
            return dispatchers.setMaskedPhoneNumber(state, action);
        case actionTypes.INITIALIZE_SMS:
            return dispatchers.initializeSms(state);
        case actionTypes.SAVE_TOKEN:
            return dispatchers.saveToken(state, action);
        default:
            return state;
    }
};
