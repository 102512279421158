import styled from 'styled-components';
import { COLORS } from '../../../../constants';
import { pxToRem } from '../../../../utils/commonUtils';
import {
    Label,
    InputFormGroup,
    Asterisk,
    ErrorMessage,
} from '../../../InputFormGroup/InputFormGroup.styles';

export const FormContainer = styled.div`
  ${Label} {
    font-size: ${pxToRem(14)};
    margin-bottom: 7px;
    color:${({ isError }) => isError ? COLORS.COLOR_ERROR_LABEL_INPUT_FIELD : null};
  }
  ${InputFormGroup} {
    margin-bottom: 10px;
  }
  ${Asterisk} {
    display: none;
  }
  ${ErrorMessage} {
    margin-top: 0;
  }
`;
export const Title = styled.h2`
  margin: 0 0 5px 0;
  font-size: ${pxToRem(26)};
`;
export const Description = styled.p`
  font-size: ${pxToRem(16)};
  font-weight: normal;
  margin: 0;
  line-height: 23px;
  max-width: 350px;
`;
export const Form = styled.form`
  margin-top: 45px;
`;
export const Terms = styled.div`
  margin-top: 50px;
`;
export const Footer = styled.footer`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
`;
export const NotMyPhoneBtn = styled.button`
  font-size: ${pxToRem(14)};
  border: none;
  outline: none;
  text-decoration: underline;
  font-weight: 400;
  padding:0;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;
export const ErrorContainer = styled.div`
    height:320px;
     display: flex;
    justify-content: center;
    align-items: center;
`;
export const ResendSmsCodeAgainBtn = styled(NotMyPhoneBtn)``;
