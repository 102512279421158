import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Styled from './CustomTextInput.style';

const CustomTextInput = ({
    inputType,
    inputName,
    inputValue,
    onChange,
    inputPlaceholder,
    label,
    isRequired,
    isErrorShown,
    isReadOnly,
    errorMessage,
    maxLength,
    isAutoFocused,
}) => {
    const [placeholder, setPlaceholder] = useState(' ');

    return (
        <Styled.Container>
            <Styled.InputContainer>
                <Styled.Input
                    name={ inputName }
                    type={ inputType }
                    value={ inputValue }
                    onChange={ onChange }
                    maxLength={ maxLength }
                    onFocus={ () => setPlaceholder(inputPlaceholder) }
                    onBlur={ () => setPlaceholder(' ') }
                    placeholder={ placeholder }
                    required={ isRequired }
                    readOnly={ isReadOnly }
                    autoFocus={ isAutoFocused }
                />
                <Styled.Label readOnly={ isReadOnly }> { label } </Styled.Label>
            </Styled.InputContainer>
            { isErrorShown && (
                <Styled.ErrorMessageStyle>{ errorMessage }</Styled.ErrorMessageStyle>
            ) }
        </Styled.Container>
    );
};

CustomTextInput.propTypes = {
    inputType: PropTypes.string,
    inputName: PropTypes.string.isRequired,
    inputValue: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    inputPlaceholder: PropTypes.string,
    isRequired: PropTypes.bool,
    isErrorShown: PropTypes.bool,
    errorMessage: PropTypes.string,
    maxLength: PropTypes.number,
    isReadOnly: PropTypes.bool,
    isAutoFocused: PropTypes.bool,
};

CustomTextInput.defaultProps = {
    inputType: 'text',
    inputValue: '',
    inputPlaceholder: undefined,
    label: '',
    isRequired: true,
    isErrorShown: false,
    errorMessage: null,
    maxLength: null,
    isReadOnly: false,
    isAutoFocused: false,
};

export default CustomTextInput;
