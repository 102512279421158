import styled from 'styled-components';
import { COLORS } from '../../../constants';
import { pxToRem } from '../../../utils/commonUtils';

export const Tooltip = styled.div`
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;

  &:hover div {
   opacity:1;
   visibility:visible;
   transition: opacity .8s ease-in;
  }
`;

export const ContentWrapper = styled.div`
  position: absolute;
  background-color: ${COLORS.BG_COLOR_TOOLTIP_CONTENT};
  padding: 15px;
  min-width:220px;
  border: 2px solid ${COLORS.BORDER_COLOR_TOOLTIP};
  word-wrap: break-word; /* IE 5.5-7 */
  white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
  white-space: pre-wrap; /* current browsers */
  transform: translate(-50% ,100%);
  left: 50%;
  box-shadow: 0px 0px 12px #00000071;
  opacity:0;
  visibility:hidden;
  transition: opacity .8s ease-out;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: ${COLORS.BG_COLOR_TOOLTIP_CONTENT};
    transform: rotate(45deg) translateY(-50%);
    bottom: auto;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
`;
export const ContentText = styled.p`
  margin: 0;
  color: ${COLORS.COLOR_TEXT_TOOLTIP_CONTENT};
  font-size: ${pxToRem(13)};
  line-height: 1.6;
`;
