import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../../language';
import { DASH_SIGN } from '../../../../../constants';
import * as Styled from './CarRank.styles';

const CarRank = ({ safety, pollution, isBeforeManufacture }) => {
    const renderPollutionData = () => {
        return pollution.map(
            (pollutionItem) => (
                <Styled.CarPollutionSquare
                    key={ pollutionItem }
                    $pollution={ isBeforeManufacture ? DASH_SIGN : pollutionItem }
                />
            ),
        );
    };

    const renderSafetyData = () => {
        return safety.map(
            (safetyItem) => (
                <Styled.CarSafetySquare
                    key={ safetyItem }
                    $safety={ isBeforeManufacture ? DASH_SIGN : safetyItem }
                />
            ),
        );
    };

    return (
        <Styled.CarRank>
            <Styled.CarRankDescription>
                { renderPollutionData() }
                {STRINGS.POLLUTION_LEVEL}
            </Styled.CarRankDescription>
            <Styled.CarRankDescription>
                { renderSafetyData() }
                {STRINGS.SAFETY_EQUIPMENT}
            </Styled.CarRankDescription>
        </Styled.CarRank>
    );
};

CarRank.propTypes = {
    safety: PropTypes.array.isRequired,
    pollution: PropTypes.array.isRequired,
    isBeforeManufacture: PropTypes.bool.isRequired,
};

export default CarRank;
