import styled, { css } from 'styled-components';
import { ContinueThemeButton }
    from '../../../../../components/ContinueThemeButton/ContinueThemeButton.styles';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const Form = styled.form`
  max-width: 365px;
  margin-top: 50px;
  ${ContinueThemeButton}{
    margin-top: 50px;
  }
  ${({ $isAudi }) => $isAudi && css`
    display: flex;
    max-width: none;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 50px;
    ${ContinueThemeButton}{
      max-width: 40%;
      @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
        max-width: 100%;
      }
    }
  `}
`;
