import {
    useCurrentConfigOptions,
    useSelectedCurrentData,
    useUpdateCarTotalPrice,
} from '../../hooks';
import {
    parseAddOnsToPaymentDetails,
    parseSpecificationsToPaymentDetails,
} from '../../screens/Configurator/configurator.utils';
import { parsePriceToDiscountObject } from '../../utils/commonUtils';

export const useDataForPaymentDetails = (showPricesAfterDiscount) => {
    /**
     * get finalDiscount object from store, which is initially null and only populated with data
       after sending GetCash request
     *  */

    const { finalDiscount } = useCurrentConfigOptions();

    /**
     * Get current data was selected during configorator process 
     */
    const {
        selectedCar,
        selectedExternalColor,
        selectedInternalColor,
        selectedHoop,
        selectedAddOns,
        selectedEquipmentPackage,
    } = useSelectedCurrentData();
    /**
     * pass the selected data to useUpdateCarTotalPrice hook, in order to show the most 
       updated price 
     */
    const totalPrice = useUpdateCarTotalPrice(
        selectedCar,
        selectedExternalColor,
        selectedInternalColor,
        selectedHoop,
        selectedAddOns,
        selectedEquipmentPackage,
    );
    
    /**
     * parse: equipmentPackage,externalColor,internalColor into paymentDeetails object
     */
    
    const specificationsBeforeDiscount = parseSpecificationsToPaymentDetails([
        selectedEquipmentPackage,
        selectedExternalColor,
        selectedInternalColor,
        selectedHoop,
    ]);

    const {
        price,
        licenseFee,
        originalAdditionDescriptions,
        equipmentPackageDescription,
    } = selectedCar;

    const addOnsBeforeDiscount = parseAddOnsToPaymentDetails(selectedAddOns);

    const { data: finalDiscountData } = finalDiscount;
    const {
        specifications: specificationsAfterDiscount,
        addOns: addOnsAfterDiscount,
        modelPrice: modelPriceAfterDiscount,
        licenseFee: licenseFeeAfterDiscount,
        totalPayment: totalPaymentAfterDiscount,
    } = finalDiscountData;
    
    let specifications = specificationsBeforeDiscount;

    let addOns = addOnsBeforeDiscount;

    let carPrices = parsePriceToDiscountObject(price);

    let licenseFeePrices =  parsePriceToDiscountObject(licenseFee);

    let totalPrices = parsePriceToDiscountObject(totalPrice);

    const totalPricesBeforeDiscount  = { ...totalPrices };

    if (showPricesAfterDiscount) {
        /**
         * if showPricesAfterDiscount === true - then check whether there is data in finalDiscountData
            which means that GetCash api call was sent
          1. if  finalDiscountData has data, replace before discount data with new after discount data
          2. if  finalDiscountData has no data, continue with the beforeDiscount  data
         */
        specifications =    specificationsAfterDiscount ?? specificationsBeforeDiscount;
        addOns = addOnsAfterDiscount ?? addOnsBeforeDiscount;
        carPrices =    modelPriceAfterDiscount ?? carPrices;
        licenseFeePrices =    licenseFeeAfterDiscount ?? licenseFeePrices;
        totalPrices =    totalPaymentAfterDiscount ?? totalPrices;
    }

    /**
     * Checking if the vehicle has discount -OR- one of the add ons has discount
     * If so - the should be a reveal price button.
     */

    const isHadAddonsDiscount = addOns.some((addon) => addon.isDiscount);

    const isDiscountExist = selectedCar.carHasDiscount || !!isHadAddonsDiscount;
    
    return {
        specifications,
        addOns,
        originalAdditions: Object.values(originalAdditionDescriptions ?? {}),
        equipmentPackage: equipmentPackageDescription,
        carPrices,
        licenseFeePrices,
        totalPrices,
        totalPricesBeforeDiscount,
        isDiscountExist,
    };
};
