import { useState } from 'react';

/**
 * 
 * @param {array} formNames 
 * @returns [isModalFormVisible, setIsModalFormVisible] 
   modal in following structure
   {
    [formName] : isShown(@bool),
    [formName] : isShown(@bool), 
   }
 */

export const useModalFormVisibility = (formNames) => {
    const initialModalState = formNames.reduce((acc, formName) => {
        acc[formName] = false;

        return acc;
    }, {});

    return useState(initialModalState);
};
