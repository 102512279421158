import React from 'react';
import PropTypes from 'prop-types';
import { useCurrentAppCommonData } from '../../../../hooks';
import { getOriginalAdditionImage } from '../../../../utils/assetsUtils';
import * as Styled from './OriginalAdditions.styles';

const OriginalAdditions = ({ originalAdditions }) => {
    const { currentBrand } = useCurrentAppCommonData();
    const renderOriginalAdditions = () => {
        return originalAdditions.map((originalAddition) => (
            <Styled.SingleOriginalAdditionContainer>
                <Styled.OriginalAdditionItem>
                    <Styled.OriginalAdditionNameAndImage
                        $image={ getOriginalAdditionImage(currentBrand) }
                    >
                        { originalAddition }
                    </Styled.OriginalAdditionNameAndImage>
                </Styled.OriginalAdditionItem>
            </Styled.SingleOriginalAdditionContainer>
        ));
    };

    return (
        <Styled.OriginalAdditionsContainer>
            { renderOriginalAdditions() }
        </Styled.OriginalAdditionsContainer>
    );
};

OriginalAdditions.defaultProps = {
    originalAdditions: [],
};

OriginalAdditions.propTypes = {
    originalAdditions: PropTypes.array,
};

export default OriginalAdditions;
