import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './FormSubTitle.styles';

const FormSubTitle = ({ subTitle }) => {
    return (
        <Styled.FormSubTitle>{subTitle}</Styled.FormSubTitle>
    );
};

export default FormSubTitle;

FormSubTitle.defaultProps = {
    subTitle: '',
};

FormSubTitle.propTypes = {
    subTitle: PropTypes.string,
};
