import styled from 'styled-components';
import CONFIG from '../../config';
import { Car360 } from '../Configurator/Car360/Car360.styles';

const { DEVICE_SIZE } = CONFIG;

export const Container = styled.div`
  height: 100%;
  max-height: 500px;
  position: relative;
  animation: fadeIn 1s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    max-width: 100%;
    min-height: 135px;
    display: block;
    ${Car360} {
      display: flex;
    }
  }
`;

export const ButtonWrapper = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  background-color: white;
  position: absolute;
  bottom: -60px;
  left: 20px;
  padding: 0;
  box-shadow: 0px 0px 10px #0000006F;
  z-index: 50;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    bottom: -30px;
  }
`;

export const SmallImage = styled.img`
  border: 3px solid white;
  width: 175px;
  height: 110px;
  object-fit: cover;

  @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    width: 100px;
    height: 60px;
  }
`;
