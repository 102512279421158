import styled, { keyframes, css } from 'styled-components';
import { pxToRem } from '../../utils/commonUtils';
import { COLORS } from '../../constants';

export const coolSlideUp = keyframes`
    0% {
        transform: translateY(180px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;

export const coolSlideDown = keyframes`
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;

export const coolSlideLeft = keyframes`
    0% {
        transform: translateX(180px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;

export const coolSlideRight = keyframes`
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;
export const Centered = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const footerContentBottomTextStyle = css`
  font-size: ${pxToRem(13.5)};
  font-weight: 400;
  color: ${({ theme }) => theme.bottomFooterColorText};
  margin: 0 0 10px 0;
  padding: 0;
`;

export const InlineText = styled.span``;

export const ConfiguratorFooterTitle = styled.h5`
  line-height: 20px;
  margin: 0 0 5px 0;
  font-size: ${pxToRem(14)};
  font-weight: 700;
`;

export const configurationLeftScrollBar = css`
  & {
    /**firefox */
    scrollbar-color: ${COLORS.BG_COLOR_CONF_SCROLLBAR_THUMB} ${COLORS.BORDER_COLOR_CONF_SCROLLBAR};
    scrollbar-width: thin;
    /**firefox */
  };
  &::-webkit-scrollbar {
    width: 5px;
  };

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  };

  &::-webkit-scrollbar-corner {
    background: transparent;
  };

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.BG_COLOR_CONF_SCROLLBAR_THUMB};
    border: 0 none ${COLORS.BORDER_COLOR_CONF_SCROLLBAR};
    border-radius: 50px;
  };

  &::-webkit-scrollbar-track {
    background: ${COLORS.BORDER_COLOR_CONF_SCROLLBAR};
    border: 0 none ${COLORS.BORDER_COLOR_CONF_SCROLLBAR};
    border-radius: 50px;
  };
`;

export const nisCurrencySign = css`
  &::before {
    content: "\\20AA";
    margin-right: 5px;
  }
`;

export const lineThrough = css`
  &:after {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #000;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

export const StickyConfigLeftFooterOnMobile = css`
    border-top: 1px solid #cecece;
    padding: 15px;
    background: #FFF;
    position: fixed;
    z-index: 140;
    width: 100%;
    bottom: 0;
    right: 0;
`;

export const NoEntranceIcon = styled.div`${css`
  background:rgb(36, 34, 33);
  min-width:30px;
  min-height:30px;
  max-width: 30px;
  max-height: 30px;
  border:2px solid black;
  border-radius:50%; 
  position:relative;
  
  &:after{
  content:'';
  left:0;
  top:0;
  bottom:0;
  right:0;
  border:2px solid white;
  display:block;
  position:absolute;
  border-radius:50%; 
   
}

&:before{
  content:'';
  display:block;
  border:1px solid white;
  position:absolute;
  top:0;
  bottom:0;
  left:50%;
  transform:translateX(-50%) rotate(-45deg);
}
`}`;
