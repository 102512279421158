/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { ScrollSync } from 'react-scroll-sync';
import { useCurrentAppCommonData, useCurrentConfigOptions } from '../../../../../hooks';
import { getOriginalAdditionsDetailsLinks } from '../../../../../utils/commonUtils';
import STRINGS from '../../../../../language';
import AdditionalOptionHeaderMobile from '../AdditionalOptionHeaderMobile/AdditionalOptionHeaderMobile';
import EquipmentPackageSection from '../EquipmentPackageSection/EquipmentPackageSection';
import OriginalAdditionSection from '../OriginalAdditionSection/OriginalAdditionSection';
import ColorsBar from '../ColorsBar/ColorsBar';
import AdditionalOptionFooter from '../AdditionalOptionFooter/AdditionalOptionFooter';
import LabelItemMobile from '../LabelItemMobile/LabelItemMobile';
import SyncedRow from '../SyncedRow/SyncedRow';
import Styled from './EquipmentPackagesMobile.style';

const EquipmentPackagesMobile = ({
    onSelect,
}) => {
    const { subModels: allSubModels } = useCurrentConfigOptions();
    const {
        isCupra,
        isAudi,
    } = useCurrentAppCommonData();
    const { subModels } = allSubModels;
    const renderAdditionalOptionsHeaders = () => {
        const headers = subModels?.map((model) => {
            const {
                hrefToConfigurator,
                price,
                totalPrice,
                licenseFee,
                arrivalDate,
            } = model;
            
            return (
                <AdditionalOptionHeaderMobile
                    hrefToConfigurator={ hrefToConfigurator }
                    onSelect={ () => onSelect(model) }
                    price={ price }
                    fullPrice={ totalPrice }
                    licenseFeePrice={ licenseFee }
                    arrivalDate={ arrivalDate }
                />
            );
        });

        return (
            <SyncedRow
                isStickyRow
                components={ headers }
            />
        );
    };

    const renderEquipmentPackagesSection = () => {
        const equipmentPackages = subModels?.map(({
            equipmentPackageDescription,
            equipmentPackageDetails,
        }) => {
            return (
                <EquipmentPackageSection
                    description={ equipmentPackageDescription ?? STRINGS.WITHOUT_EQUIPMENT_PACKAGE }
                    details={ equipmentPackageDetails }
                />
            );
        });

        return (
            <>
                <LabelItemMobile
                    title={ STRINGS.EQUIPMENT_PACKAGES }
                    alignTop
                />
                <SyncedRow
                    components={ equipmentPackages }
                />
            </>
        );
    };

    const renderOriginalAdditionsRows = () => {
        const originalAdditionsDetailsLinks = getOriginalAdditionsDetailsLinks(subModels);
        const originalAdditions = subModels?.reduce((allOriginalAdditions, model) => {
            return {
                ...allOriginalAdditions,
                ...model.originalAdditionDescriptions,
            };
        }, {});

        const originalAdditionsRows = Object.entries(originalAdditions).map(([key, value]) => {
            const originalAdditionRow = subModels?.map(({
                originalAdditionDescriptions,
            }) => {
                const isIncluded = !!originalAdditionDescriptions[key];

                return (
                    <OriginalAdditionSection
                        isIncluded={ isIncluded }
                    />
                );
            });

            return (
                <>
                    <LabelItemMobile
                        title={ value }
                        additionalData={ originalAdditionsDetailsLinks[key] }
                    />
                    <SyncedRow
                        components={ originalAdditionRow }
                    />
                </>
            );
        });

        return originalAdditionsRows;
    };

    const renderColorsRow = () => {
        const colorsRow = subModels?.map(({
            links,
        }) => {
            return (
                <ColorsBar
                    externalColors={ links.availableColorIconImages }
                    internalColors={ links.availableUpholsteryColorIconImages }
                />
            );
        });

        return (
            <>
                <LabelItemMobile
                    title={ STRINGS.COLORS }
                />
                <SyncedRow
                    components={ colorsRow }
                />
            </>
        );
    };

    const renderAdditionalOptionsFooter = () => {
        const footers = subModels?.map((model) => {
            return (
                <AdditionalOptionFooter
                    hrefToConfigurator={ model.hrefToConfigurator }
                    onClick={ () => onSelect(model) }
                    isAudi={ isAudi }
                    isCupra={ isCupra }
                    safetyLevel={ model.safetyEquipmentLevel }
                    pollutionLevel={ model.pollutionLevel }
                    isBeforeManufacture={ model.isBeforeManufacture }
                />
            );
        });

        return (
            <SyncedRow
                components={ footers }
            />
        );
    };

    return (
        <ScrollSync>
            <Styled.Container>
                <Styled.Content >
                    { renderAdditionalOptionsHeaders() }
                    { renderEquipmentPackagesSection() }
                    { renderOriginalAdditionsRows() }
                    { renderColorsRow() }
                    { renderAdditionalOptionsFooter() }
                </Styled.Content>
            </Styled.Container>
        </ScrollSync>
    );
};

EquipmentPackagesMobile.propTypes = {
    onSelect: PropTypes.func.isRequired,
};

export default EquipmentPackagesMobile;
