/* eslint-disable max-len */
import React, { useState } from 'react';
import MobilePriceBar from '../../../../../screens/Checkout/CheckoutSiderBar/MobilePriceBar/MobilePriceBar';
import {
    useCurrentAppCommonData,
    useCurrentBrand,
    useCurrentCar,
    useCurrentExternalColor,
    useSelectedCurrentData,
} from '../../../../../hooks';
import { getFullManufacturer } from '../../../../../utils/commonUtils';
import { useDataForPaymentDetails } from '../../../../PaymentDetails/PaymentDetails.hooks';
import CarImage from '../../../../CarImage/CarImage';
import PaymentDetails from '../../../PaymentDetails/PaymentDetails';
import AdditionalInformation from '../../../PaymentDetails/AdditionalInformation/AdditionalInformation';
import * as Styled from './CheckoutSideBar.styles';

const CheckoutSideBar = () => {
    const currentBrand = useCurrentBrand();
    const [isToggled, setIsToggled] = useState(false);
    const { modelGroupCode, trimLevelCode } = useCurrentCar();
    const { value: outColor } = useCurrentExternalColor();
    const showPricesAfterDiscount = true;
    const {
        specifications,
        addOns,
        originalAdditions,
        equipmentPackage,
        carPrices,
        licenseFeePrices,
        totalPrices,
    } = useDataForPaymentDetails(showPricesAfterDiscount);
    const { isAudi } = useCurrentAppCommonData();
    const currentCar = useCurrentCar();
    const {
        modelDescription,
        priceList,
        priceListDate,
    } = currentCar;

    const {
        selectedExternalColor,
        selectedInternalColor,
    } = useSelectedCurrentData();
    const { inStockString: inStockExternalString } = selectedExternalColor;
    const { inStockString: inStockInternalString } = selectedInternalColor;
    const inStockString = inStockInternalString ?? inStockExternalString;
    const manufacturerInHebrew = getFullManufacturer(currentBrand, 'hebrew');
    
    const toggleMobilePriceBarHandler = () => {
        setIsToggled((prevState) => !prevState);
        if (!isToggled) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'scroll';
        }
    };
    
    return (
        <Styled.CheckoutSideBar $isAudi={ isAudi }>
            <Styled.InnerContent $isToggled={ isToggled } $isAudi={ isAudi }>
                <Styled.ImageWrapper $isAudi={ isAudi }>
                    <CarImage
                        brand={ currentBrand }
                        outColor={ outColor }
                        modelGroup={ modelGroupCode?.toString() }
                        trimLevelCode={ trimLevelCode }
                    />
                </Styled.ImageWrapper>
                <PaymentDetails
                    carPrices={ carPrices }
                    specifications={ specifications }
                    addOns={ addOns }
                    licenseFeePrices={ licenseFeePrices }
                    totalPrices={ totalPrices }
                    currentBrand={ currentBrand }
                    originalAdditions={ originalAdditions }
                    equipmentPackage={ equipmentPackage }
                />
                <AdditionalInformation
                    priceList={ priceList }
                    manufacturerInHebrew={ manufacturerInHebrew }
                    modelDescription={ modelDescription }
                    priceListDate={ priceListDate }
                    inStockString={ inStockString }
                />
            </Styled.InnerContent>
            <MobilePriceBar
                totalPrice={ totalPrices.total }
                isToggled={ isToggled }
                onToggle={ toggleMobilePriceBarHandler }
            />
        </Styled.CheckoutSideBar>
    );
};

export default CheckoutSideBar;
