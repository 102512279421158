import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../../Input/Input';
import Select from '../../../../../Select/Select';
import { PHONE_PREFIXES } from '../../../../../../constants';
import * as Styled from './PhoneInput.styles';

const PhoneInput = ({
    inputType,
    inputName,
    inputValue,
    onChange,
    label,
    isRequired,
    isErrorShown,
    errorMessage,
    maxlength,
}) => {
    const SelectName = 'selectPhonePrefix';
    const splittedInputValue = inputValue.split('-');

    const [phonePrefix, setPhonePrefix] = useState(splittedInputValue[0] || PHONE_PREFIXES[0].value);
    const [phoneNumber, setPhoneNumber] = useState(splittedInputValue[1] || '');
    const [phoneNumberEvent, setPhoneNumberEvent] = useState(null);

    useEffect(() => {
        if (phoneNumber || phonePrefix) {
            onChange(phoneNumberEvent, `${phonePrefix}${phoneNumber}`, 'audiTel');
        }
    }, [phoneNumber, phonePrefix]);

    return (
        <Styled.PhoneInput $isErrorShown={ isErrorShown }>
            <Styled.Label $isErrorShown={ isErrorShown }>
                {label}
                {isRequired && <Styled.Asterisk />}
            </Styled.Label>

            <Styled.PhoneContainer>
                <Input
                    type={ inputType }
                    maxlength={ maxlength }
                    name={ inputName }
                    isRequired={ isRequired }
                    value={ phoneNumber }
                    onChange={ (e) => {
                        setPhoneNumberEvent(e);
                        setPhoneNumber(e.target.value);
                    } }
                />
                <Styled.DashSeperator>-</Styled.DashSeperator>
                <Select
                    name={ SelectName }
                    value={ phonePrefix }
                    onChange={ (e) => { setPhonePrefix(e.target.value); } }
                    options={ PHONE_PREFIXES }
                />
            </Styled.PhoneContainer>
            {isErrorShown && <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>}
        </Styled.PhoneInput>
    );
};

PhoneInput.defaultProps = {
    inputType: 'text',
    inputValue: '',
    isRequired: true,
    isErrorShown: false,
    errorMessage: null,
    maxlength: null,

};

PhoneInput.propTypes = {
    inputType: PropTypes.string,
    inputName: PropTypes.string.isRequired,
    inputValue: PropTypes.string,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    isErrorShown: PropTypes.bool,
    errorMessage: PropTypes.string,
    maxlength: PropTypes.number,
};

export default PhoneInput;
