import styled from 'styled-components';
import { nisCurrencySign } from '../../../../components/common/styles';
import { COLORS } from '../../../../constants';
import CONFIG from '../../../../config';
import { pxToRem } from '../../../../utils/commonUtils';

const { DEVICE_SIZE } = CONFIG;

export const MobilePriceBar = styled.div`
  padding:5px 15px;
  background-color:${({ theme }) => theme.primaryColor};
  box-shadow: 4px 8px 12px 1px rgb(0 0 0 / 34%);
  height: 60px;
  position: fixed;
    left: 0;
    top: 0;
    right:0;
    z-index: 200;
  display: none;

  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
      display: block;
  }
`;

export const Content = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;

`;
export const PriceDetails = styled.div``;
export const Title = styled.h2`
  margin: 0;
  font-weight: 400;
  font-size: ${pxToRem(17)};
  color:${COLORS.WHITE};
`;
export const TotalPrice = styled.p`
  margin: 0;
  display: inline;
  font-weight: 500;
  font-size: ${pxToRem(24)};
  color:${COLORS.WHITE};
  vertical-align: middle;
  ${nisCurrencySign}
  
`;
export const ToggleButton = styled.button`
  font-weight: 700;
  font-size: ${pxToRem(16)};
  color:${COLORS.BLACK};
  text-decoration: underline;
  border:none;
  outline: none;
  background-color:transparent;
  padding:0;
  vertical-align: middle;
  margin-right:15px;
`;
export const ToggleRoundButton = styled.button`
  width:41px;
  height:41px;
  border-radius:50%;
  background-color :${COLORS.WHITE};
  border:none;
  outline:none;
  padding:0;
  margin:0 7px;

  &:after{
    content: "\\2193";
    font-size: 23px;
    line-height: 0;
  }
`;
