import styled from 'styled-components';
import { pxToRem } from '../../../../../utils/commonUtils';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const MainCharacteristicsAndSpecification  = styled.div`
padding:30px 0;
`;

// export const Specification  = styled.p`
// font-size: ${pxToRem(13)};
// color:${({ theme }) => theme.primaryColor};
// font-weight: 500;
// text-decoration:none;
// display: flex;
// margin-right: auto;
// margin-bottom: auto;
// margin-top: 0;
// cursor: pointer;
// &:before{
//     content:'';
//     display:block;
//     background-image:url(${({ icon }) => icon});
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size:contain;
//     width:14px;
//     height:14px;
//     margin: 0 3px 0 6px;
// }
// `;
