import styled from 'styled-components';

export const ColorConfiguration = styled.div`
 /* border:10px solid red; */
`;
export const ErrorWrapper = styled.div`
      height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ColorPickerHeader = styled.h2`
  font-size: 18px;
  text-decoration: underline;
  text-underline-offset: 4px;
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
