import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    useActionDispatch,
    useCurrentConfigOptions,
    useIsSpecificationsModalVisible,
    useSelectedCurrentData,
} from '../../../../hooks';
import { CONFIGURATOR_STEPS } from '../../../../constants';
import SpecificationsModal from '../../../../screens/InnerPages/Specifications/SpecificationsModal';
import PresentationalLeft from '../PresentationLeft/PresentationLeft';
import ConfiguratorRight from '../ConfiguratorRight/ConfiguratorRight';
import AudiAddOnsStep from '../../Steps/Addons/AudiAddOnsStep';
import { useDataForPaymentDetails } from '../../../PaymentDetails/PaymentDetails.hooks';
import ConfiguratorFooter from '../ConfiguratorFooter/ConfiguratorFooter';
import { isArrayAndNotEmpty } from '../../../../utils/commonUtils';
import * as Styled from './ConfiguratorLayout.style';

const {
    MODEL,
    ENGINE_AND_TRIM_LEVEL,
    EQUIPMENT_PACKAGES,
    ADD_ONS,
} = CONFIGURATOR_STEPS;

const ConfiguratorLayout = ({
    footerComponent,
    presentationalComponent,
    configurationComponent,
    nextStep,
    currentStep,
    configOptions,
    modelGroup,
    onContinue,
    onGoBack,
}) => {
    const {
        externalColors, internalColors, originalPackages, modelLocalGroups, externalColorsError,
    } = useCurrentConfigOptions();
    const {
        selectedExternalColor,
    } = useSelectedCurrentData();
    const {
        totalPricesBeforeDiscount,
    } = useDataForPaymentDetails();
    const isSpecificationsModalVisible = useIsSpecificationsModalVisible();
    const { toggleSpecificationsModal } = useActionDispatch();
    
    const FooterComponent =  footerComponent || (() => null);
    const fullPriceParsed = parseFloat(totalPricesBeforeDiscount.total).toLocaleString();
    const { isLoading: isLoadingInternalColors, error: internalColorsError } = internalColors;
    const { isLoading: isLoadingOriginalPackages, error: originalPackagesError } = originalPackages;
    const { isLoading: isLoadingLocalGroups, error: modelLocalGroupsError } = modelLocalGroups;
    const doNotShowConfigurationLeftAndFooter = [MODEL, ENGINE_AND_TRIM_LEVEL, EQUIPMENT_PACKAGES]
        .find((step) => step === currentStep);
    const addOnsStep = [ADD_ONS]
        .find((step) => step === currentStep);
    const showCarImage = currentStep === ADD_ONS;
    const showGoBackButton = currentStep !== MODEL;

    const disableContinueBtnHandler = () => {
        return isLoadingInternalColors
            || isLoadingOriginalPackages
            || isLoadingLocalGroups
            || !isArrayAndNotEmpty(externalColors)
            || externalColorsError
            || internalColorsError
            || originalPackagesError
            || modelLocalGroupsError;
    };

    useEffect(() => {
        if (isSpecificationsModalVisible) {
            document.body.style.overflow = 'hidden';
            document.body.dataset.moreInfo = isSpecificationsModalVisible;
        } else {
            document.body.style.overflow = 'visible';
            document.body.dataset.moreInfo = isSpecificationsModalVisible;
        }
    }, [isSpecificationsModalVisible]);

    const renderConfigurationalRight = () => {
        if (doNotShowConfigurationLeftAndFooter) {
            return null;
        }

        return (
            <ConfiguratorRight
                configOptions={ configOptions }
                nextStep={ nextStep }
                currentStep={ currentStep }
                onContinue={ onContinue }
                component={ configurationComponent }
            />
        );
    };

    renderConfigurationalRight();

    const renderSplitOrWholeConfigurator = () => {
        return addOnsStep
            ? (
                <AudiAddOnsStep
                    currentStep={ currentStep }
                    configOptions={ configOptions }
                    onGoBack={ onGoBack }
                />
            ) : (
                <>
                    {renderConfigurationalRight()}
                    <PresentationalLeft
                        onGoBack={ onGoBack }
                        currentStep={ currentStep }
                        configOptions={ configOptions }
                        component={ presentationalComponent }
                    />
                </>
            );
    };
    
    return (
        <Styled.ConfiguratorLayout>
            <SpecificationsModal
                isVisible={ isSpecificationsModalVisible }
                onClose={ () => toggleSpecificationsModal() }
            />
            <Styled.ConfiguratorContent>
                { showGoBackButton && <Styled.AudiGoBackButtonDesktop onClick={ onGoBack } /> }
                {renderSplitOrWholeConfigurator()}
            </Styled.ConfiguratorContent>
            { !doNotShowConfigurationLeftAndFooter && (
                <ConfiguratorFooter
                    modelGroup={ modelGroup }
                    thumbnailCarImage={ selectedExternalColor?.welcomePicture }
                    fullPrice={ fullPriceParsed }
                    isContinueButtonDisabled={ disableContinueBtnHandler() }
                    nextStep={ nextStep }
                    showCarImage={ showCarImage }
                    onContinue={ onContinue }
                />
            ) }
            <FooterComponent configOptions={ configOptions } />
        </Styled.ConfiguratorLayout>
    );
};

ConfiguratorLayout.defaultProps = {
    footerComponent: null,
    configurationComponent: null,
    presentationalComponent: null,
    modelGroup: null,
    currentStep: null,
    nextStep: null,
    onContinue: () => {},
    onGoBack: () => {},
};

ConfiguratorLayout.propTypes = {
    footerComponent: PropTypes.elementType,
    presentationalComponent: PropTypes.elementType,
    configurationComponent: PropTypes.elementType,
    configOptions: PropTypes.object.isRequired,
    modelGroup: PropTypes.string,
    currentStep: PropTypes.number,
    nextStep: PropTypes.number,
    onContinue: PropTypes.func,
    onGoBack: PropTypes.func,
};

export { ConfiguratorLayout as AudiConfiguratorLayout };
