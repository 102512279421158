import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ModelImage from '../../../../Steps/Model/ModelImage/ModelImage';
import { AudiModelItem } from '../ModelItem/ModelItem';
import ModelItemsHeader from './ModelItemsHeader/ModelItemsHeader';
import * as Styled from './ModelGroupItem.style';

const ModelGroupItem = ({
    image,
    imageAlt,
    imageFallback,
    title,
    modelItems,
    modelListBounding,
    id,
    onToggleGroup,
    isSelected,
    selectedModelGroup,
}) => {
    const isDisabled = !isSelected && selectedModelGroup !== null;
    const modelGroupItemRef = useRef();
    const modelGroupRef = useRef();
    const { width: modelListWidth, right: modelListRight } = modelListBounding;

    const calculateOffsetMargin = () => {
        if (!modelGroupItemRef.current) {
            return null;
        }
        const modelGroupItemOffset = modelListRight
            - modelGroupItemRef.current.getBoundingClientRect().right;

        return modelGroupItemOffset;
    };

    const renderModelItems = () => {
        return modelItems.map((car) => {
            const { id: carId } = car;

            return <AudiModelItem model={ car } key={ carId } />;
        });
    };

    return (
        <Styled.ModelGroupItem
            ref={ modelGroupItemRef }
            $isGroupSelected={ isSelected }
            $isDisabled={ isDisabled }
            id={ id }
        >
            <Styled.ModelGroup
                ref={ modelGroupRef }
                onClick={ () => onToggleGroup(id, modelGroupItemRef.current) }
                $isGroupSelected={ isSelected }
            >
                <Styled.Information>
                    <Styled.Header>
                        <Styled.GroupModelTitle>{title}</Styled.GroupModelTitle>
                    </Styled.Header>
                </Styled.Information>
                <ModelImage alt={ imageAlt } src={ image } fallbackSrc={ imageFallback } />
            </Styled.ModelGroup>
            <Styled.ModelItems
                $offsetMargin={ calculateOffsetMargin() ?? 'auto' }
                $isGroupSelected={ isSelected }
                width={ modelListWidth }
            >
                <ModelItemsHeader
                    title={ title }
                    onClose={ () => onToggleGroup(null) }
                />
                {renderModelItems()}
                <Styled.HideModelItemsButton onClick={ () => onToggleGroup(id) } />
            </Styled.ModelItems>
        </Styled.ModelGroupItem>
    );
};

ModelGroupItem.defaultProps = {
    image: null,
    modelItems: [],
    onToggleGroup: () => {},
    isSelected: null,
    selectedModelGroup: null,
};

ModelGroupItem.propTypes = {
    imageAlt: PropTypes.string.isRequired,
    imageFallback: PropTypes.string.isRequired,
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    modelItems: PropTypes.array,
    modelListBounding: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    onToggleGroup: PropTypes.func,
    isSelected: PropTypes.bool,
    selectedModelGroup: PropTypes.string,
};

export { ModelGroupItem as AudiModelGroupItem };
