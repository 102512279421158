import styled from 'styled-components';
import { ConfiguratorFooterTitle } from '../../../../../components/common/styles';
import { pxToRem } from '../../../../../utils/commonUtils';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const PollutionRange = styled.div`
white-space: nowrap;
  @media(max-width:${DEVICE_SIZE.LARGE}px){
    margin-top:20px;
  
  }
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    white-space:initial;
  }


  & ul li {
    @media(max-width:${DEVICE_SIZE.SMALL}px){
    width:17.5px;
  }
  @media(max-width:${DEVICE_SIZE.VERY_SMALL}px){
    width:13.5px;
  }
  }
`;

export const Title = styled(ConfiguratorFooterTitle)``;

export const Footer = styled.footer`
  margin-top:7px;
`;

export const Text = styled.p`
  margin: 0;
  font-size: ${pxToRem(12)};
  font-weight: 400;
  line-height:18px;
  margin-top:2px;
`;
