export const staggerParentConfig = {
    variants: {
        hidden: { opacity: 0 },
        show: { opacity: 1, transition: { staggerChildren: 0.15 } },
    },
    initial: 'hidden',
    animate: 'show',
};

export const staggerVerticalChildVariants = {
    hidden: { opacity: 0, y: -20 },
    show: { opacity: 1, y: 0 },
};

export const staggerHorizontalChildVariants = {
    hidden: { opacity: 0, x: 30 },
    show: { opacity: 1, x: 0 },
};

export const presentationalFadeIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 0.5 },
};

export const stepTitleAnimation = {
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.2, ease: 'easeOut', delay: 0.2 },
};
