import CONFIG from '../config';
import { postServer } from './fetchUtils';

const GET_PRE_SALE_DATA_URL = `${CONFIG.BASE_URL}GetOffer`;
const START_PAYMENT_URL = `${CONFIG.BASE_URL}DoPayment`;
const COMPLETE_PAYMENT_URL = `${CONFIG.BASE_URL}DoCompleted`;
const LOG_FAILED_PAYMENT_URL = `${CONFIG.BASE_URL}LogFailedPayment`;

/**
 * Load pre-sale data
 */
export const fetchGetPreSaleData = ({ id }) => {
    return postServer(`${GET_PRE_SALE_DATA_URL}`, {
        sToken: id,
        sIP: '',
    });
};

/**
 * Load payment url ("iframe") from credit-card
 */
export const fetchStartPayment = (id) => {
    return postServer(`${START_PAYMENT_URL}`, {
        sToken: id,
        sIP: '',
    });
};

/**
 * Load available out colors for this car
 */
// export const fetchOutColors = ({ id }) => {
//     const TEMP_COLORS = [];

//     return new Promise((resolve, reject) => {
//         setTimeout(() => resolve(TEMP_COLORS), 1000);
//     });
// };

/**
 * Set success payment
 */
export const fetchCompletePayment = (
    sOfferToken,            // מזהה מהעמוד
    sIP,                    // לשלוח ריק
    sToken,                 // מזהה קרדיט גארד
    sCardValidityDate,      // תוקף כרטיס
    sConfirmationCode,      // authNumber
) => {
    return postServer(`${COMPLETE_PAYMENT_URL}`, {
        sOfferToken,
        sIP,
        sToken,
        sCardValidityDate,
        sConfirmationCode,
    });
};

/**
 * Log system failure
 * Log Payment errors
  */
export  const fetchLogFailure = (sOfferToken, sResultCode) => {
    postServer(`${LOG_FAILED_PAYMENT_URL}`, {
        sOfferToken,
        sIP: '',
        sResultCode,
    }).catch((error) => {
        console.log('fetchLogFailure error', error);
    });
};
