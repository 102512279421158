import { MODEL_GROUP_META_DATA, MODEL_META_DATA } from '../constants';

const { REACT_APP_TITLE, REACT_APP_DESCRIPTION } = process.env;

/**
 * 
 * @param {string} brand 
 * @param {numeric} modelGroupCode 
 * @returns {title:string,description:string}
 */
export const getMetaDataByModelGroup = (brand, modelGroupCode) => {
    const brandUpperCase = brand?.toUpperCase();

    if (!brandUpperCase || !MODEL_GROUP_META_DATA[brandUpperCase]?.[modelGroupCode]) {
        return      {
            title: REACT_APP_TITLE,
            description: REACT_APP_DESCRIPTION,
        };
    }

    return MODEL_GROUP_META_DATA[brandUpperCase][modelGroupCode];
};

/**
 * 
 * @param {string} brand 
 * @param {numeric} modelGroupCode 
 * @param {numeric} trimLevelCode 
 * @returns {title:string,description:string}
 */

export const getMetaDataByModel = (brand, modelGroupCode, trimLevelCode) => {
    const brandUpperCase = brand?.toUpperCase();

    if (!brandUpperCase
         || !MODEL_META_DATA[brandUpperCase]?.[modelGroupCode]?.[`${modelGroupCode}${trimLevelCode}`]) {
        return      {
            title: REACT_APP_TITLE,
            description: REACT_APP_DESCRIPTION,
        };
    }

    return MODEL_META_DATA[brandUpperCase][modelGroupCode][`${modelGroupCode}${trimLevelCode}`];
};
