import styled from 'styled-components';
import { COLORS } from '../../../../../constants';
import { pxToRem } from '../../../../../utils/commonUtils';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const TabItem = styled.li`
  font-size: ${pxToRem(14)};
  padding: 5px;
  cursor: pointer;
  border-radius: 0.5rem;
  color: ${({ $isSelected }) => ($isSelected ? COLORS.WHITE : COLORS.BLACK)};
  background-color: ${({ $isSelected, theme }) => $isSelected ? theme.primaryColor : 'transparent'};
  font-weight: 500;
  align-self: center;
  &:not(:last-child) {
    margin-left: 30px;
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
      margin-left:20px;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.primaryColor};
    color: ${COLORS.WHITE};
  }
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    white-space: nowrap;
    }
`;
