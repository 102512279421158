import React from 'react';
import Router from './screens/Router';
import HelmetTitleDescription from './components/HelmetTitleDescription/HelmetTitleDescription';
import { ThemeProvider } from './ThemeProvider';
import { StoreProvider } from './store';

const { REACT_APP_TITLE, REACT_APP_DESCRIPTION } = process.env;

const App = () => {
    return (
        <StoreProvider>
            <HelmetTitleDescription
                title={ REACT_APP_TITLE }
                description={ REACT_APP_DESCRIPTION }
            />
            <ThemeProvider>
                <Router />
            </ThemeProvider>
        </StoreProvider>
    );
};

export default App;
