import styled from 'styled-components';
import { motion } from 'framer-motion';
import { COLORS, CONFIGURATOR_STEPS } from '../../../constants';
import { pxToRem } from '../../../utils/commonUtils';

export const ConfiguratorStep = styled.li`
  color: ${COLORS.WHITE};
  font-size: ${pxToRem(14)};
  font-weight: 700;
  padding: 7px 15px;
  line-height: 1;
  display: ${({ $isVisible }) => $isVisible ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: ${({ $isFinished }) => $isFinished ? 'pointer' : 'auto'};
      &::before {
        content: "";
        display:inline-block;
        height: 16px;
        width: ${({ $id })  => $id !== CONFIGURATOR_STEPS.SUMMARY ? '12px' : 0};
        background-image: url(${({ $icon, $id })  => $id !== CONFIGURATOR_STEPS.SUMMARY ? $icon : null});
        background-repeat: no-repeat;
        background-position: center;
        margin-left:8px;
        opacity: ${({ $isFinished }) => $isFinished ? 1 : 0};
        transition: ${({ $isFinished }) => $isFinished ? 'opacity 1s' : 'default'};
      }
`;

export const UnderlineContainer = styled.div`
  height: 2px;
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin-right:  ${({ $isFinished }) => $isFinished ? 0 : '20px'};
  `;

export const Underline = styled(motion.div)`
  background-color: white;
  height: 2px;
  width: ${({ $isFinished }) => $isFinished ? 'calc(100% - 30px)' : 'calc(100% - 50px)'}; // 30px is the padding on the sides and 50px is padding + width of checkMarkIcon
  `;
