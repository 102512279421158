import styled, { css } from 'styled-components';
import { COLORS } from '../../constants';
import { pxToRem } from '../../utils/commonUtils';
import CONFIG from '../../config';

const { DEVICE_SIZE } = CONFIG;

export const QuestionMarkTooltip = styled.div`
  position: relative;
  display: inline-block;
  line-height: 1;
  &:after {
    content: "";
    display: inline-block;
    line-height: 1;
    cursor: pointer;
    width: 16px;
    height: 16px;
    /* border: 2px solid ${({ theme }) => theme.primaryColor}; */
    /* border-radius: 50%; */
    line-height: 1;
    background-image: url(${({ $icon }) => $icon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  &:hover div {
    display: inline-block;
  }
`;

export const ContentWrapper = styled.div`
  background-color: ${COLORS.BG_COLOR_TOOLTIP_CONTENT};
  padding: 10px;
  max-width: 360px;
  min-width: 210px;
  border-radius: 10px;
  border: 3px solid ${COLORS.BORDER_COLOR_TOOLTIP};
  word-wrap: break-word; /* IE 5.5-7 */
  white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
  white-space: pre-wrap; /* current browsers */
  position: absolute;
  bottom: 100%;
  right: -47px;
  margin-bottom: 8px;
  display:none;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    min-width: 120px;
    max-width: 250px;
  }
  ${({ isConfigurator }) => isConfigurator
    && css`
      font-size: ${pxToRem(20)};
      box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15),
        0 4px 80px -8px rgba(36, 40, 47, 0.25),
        0 4px 4px -2px rgba(91, 94, 105, 0.15);
      min-width: 170px;
      padding: 30px;
    `}
  ${({ $isAudi }) => $isAudi
    && css`
      font-size: ${pxToRem(24)};
      background-color: ${COLORS.WHITE};
      border-radius: 0;
      border: 1px solid ${COLORS.BLACK};
      position: absolute;
      bottom: 100%;
      right: -47px;
      margin-bottom: 8px;
    `}

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: ${COLORS.BG_COLOR_TOOLTIP_CONTENT};
    transform: rotate(45deg);
    bottom: -5px;
    right: 47px;
    margin: auto;
    ${({ $isAudi }) => $isAudi && css`
      bottom: -6px;
      background-color: ${COLORS.WHITE};
      border-color: ${COLORS.BLACK};
      border-bottom: 1px solid;
      border-right: 1px solid;
    `}
  }
`;
export const ContentText = styled.p`
  margin: 0;
  color: ${COLORS.COLOR_TEXT_TOOLTIP_CONTENT};
  font-size: ${pxToRem(13)};
  font-weight: 700;
  line-height: 1.5;
  ${({ isConfigurator }) => isConfigurator
    && css`
      text-align: center;
      font-weight: bold;
      font-size: 11px;
      line-height:1.8;
    `}
  ${({ $isAudi }) => $isAudi && css`
    color: ${COLORS.BLACK};
  `}
`;
