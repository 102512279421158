import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../language';
import { useRenderImageSrcOrFallback } from '../../../../hooks/utils';
import { replacePlaceholdersInTemplate } from '../../../../utils/commonUtils';
import * as Styled from './Header.styles';

const Header = ({ carImage, carImageFallBackSrc, modelDescription }) => {
    const [carImageSrc, setError] = useRenderImageSrcOrFallback(carImage, carImageFallBackSrc);
    const imageAlt = replacePlaceholdersInTemplate(
        STRINGS.PREVIEW_OF_MODEL_DESCRIPTION,
        [modelDescription],
    );
    const loadImgErrorHandler = () => {
        setError(true);
    };

    return (
        <Styled.Header>
            <Styled.Figure>
                <Styled.Img
                    onError={ loadImgErrorHandler }
                    alt={ imageAlt }
                    src={ carImageSrc }
                />
                <Styled.Figcaption>
                    <Styled.Title>{modelDescription}</Styled.Title>
                    <Styled.SubTitle>{STRINGS.SPECIFICATIONS_AND_TRIM_LEVEL}</Styled.SubTitle>
                </Styled.Figcaption>
            </Styled.Figure>
        </Styled.Header>
    );
};

Header.propTypes = {
    carImage: PropTypes.string.isRequired,
    carImageFallBackSrc: PropTypes.string.isRequired,
    modelDescription: PropTypes.string.isRequired,
};

export default Header;
