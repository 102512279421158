import styled from 'styled-components';
import IMAGES from '../../assets';

const Hr = styled.hr`
  height: 1px;
  background-color: #dadbde;
  border: 1px solid #dadbde;
`;

const HrBold = styled.hr`
  height: 1px;
  background-color: #636469;
  border: 1px solid #636469;
`;

const ExtraData = styled.ul`
  font-size: 14px;
  color: black;
  margin-top: 40px;

  @media (max-width: 700px) {
    padding-right: 20px;
  }
`;

const PaymentButton = styled.button`
  background-color: ${({ theme }) => theme?.primaryColor ?? '#F35515'};
  color: white;
  width: 250px;
  height: 50px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  text-align: right;
  font-weight: bold;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.8em 1.5em;
  transition: 0.3s all;
  font-size: 0.9rem;

  &:after {
    content: "";
    display: block;
    width: 26px;
    height: 26px;
    background: url(${IMAGES.ARROW}) no-repeat center center;
    background-size: contain;
  }

  &:not(:disabled):hover {
    background-color: #000000
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const ExtrasListItem = styled.li`
  margin-bottom: 0.33rem;
  font-weight: bold;
`;

export const Footer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 45px;
`;

const Style = {
    Hr,
    HrBold,
    ExtraData,
    PaymentButton,
    ExtrasListItem,
    Footer,
};

export default Style;
