import styled from 'styled-components';
import CONFIG from '../../../../../../config';

const { MEDIUM } = CONFIG.DEVICE_SIZE;

export const CardContainer = styled.div`
    max-width: 270px;
    height: 210px;
    display: flex;
    flex-direction: column;
    background-color: white;
    @media(max-width: ${MEDIUM}px) {
        max-width: none;
        padding: 1rem;
        padding-bottom: 1.5rem;
        border-radius: 5pt;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 15px 0px;
    }
`;

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    @media(max-width: ${MEDIUM}px) {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
    }
`;

export const Icon = styled.img`
    height: 40px;
    width: 40px;
`;

export const Title = styled.p`
    white-space: pre-line;
    font-size: 20px;
    font-weight: bold;
`;

export const Content = styled.p`
    margin: 0;
    font-size: 16px;
    color: #707070;
    @media(max-width: ${MEDIUM}px) {
        padding: 0;
    }
`;
