import styled from 'styled-components';
import { COLORS } from '../../../constants';
import { pxToRem } from '../../../utils/commonUtils';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const ConfiguratorFooter = styled.footer`
 background-color:${COLORS.BG_COLOR_CONFIGURATOR_FOOTER};
 width: 100%;
`;

export const Content = styled.div`
padding:20px;
margin:0 auto;
max-width:1400px;
display:flex;
@media(max-width:${DEVICE_SIZE.MEDIUM}px){
    flex-direction:column;
}

`;

export const ComparisonAndSpecifications = styled.section`
 max-width:310px;
 padding:20px;
 @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    max-width:100%;
    padding:0;
}

`;
export const PollutionAndSafety = styled.section`
padding:0 10px;
@media(max-width:${DEVICE_SIZE.MEDIUM}px){
    padding:0;
}
`;

export const Text = styled.p`
color: #5a5a5a;
font-weight: 500;
font-size:${pxToRem(16)};
`;

export const BeforeManufactureText = styled.p`
  margin: 1rem;
  font-size: ${pxToRem(18)};
  padding: 10px;
  width: 100%;
`;
